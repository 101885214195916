import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Spin, Switch, Tabs as AntTabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { LpPreview, PreviewLpSelect } from '../../../components/PreviewLpSelect';
import { Select } from '../../../components/Select';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { browsersService } from '../../../services/browsers';
import { MBAdvertiserCategoryName, companiesService } from '../../../services/companies';
import { countriesService } from '../../../services/countries';
import { landingPagesService } from '../../../services/landingPages';
import { osService } from '../../../services/os';
import { PixelType, productsService, ProductStatus } from '../../../services/products';
import { confirmClose } from '../../../utils/confirmClose';
import { useEffectTrigger } from '../../../utils/hooks';
import { useBeforeUnload } from '../../../utils/hooks/useBeforeUnload';
import { openSuccessNotification } from '../../../utils/notifications';
import { generateSelectOptionsWithIdValues } from '../../../utils/options';
import {
  breakGroupedCountries,
  confirmDeleteProductModal,
  groupCountriesWithSamePrices,
  markListedCountries
} from '../../../utils/products';
import { showApiErrors } from '../../../utils/showApiErrors';
import { withCopyIcon } from '../../media-campaigns/utils/withCopyIcon';
import styles from './AddOrEditProduct.module.css';
import { PixelDescriptions } from './PixelDescriptions';
import { PixelLimit } from './PixelLimit';
import { ProductPrices } from './ProductPrices';
import { ProductRules } from './ProductRules';
import { throwErrorIfActiveRuleNotificationsTurnedOff } from '../../../utils/functions';

const Option = Select.Option;
const { TextArea } = Input;
const { TabPane } = AntTabs;
const InputWithCopyIcon = withCopyIcon(Input);

const Tabs = {
  basic: 'Basic',
  prices: 'Prices',
  lps: 'LPs',
  pixelDescriptions: 'Pixel Descriptions',
  pixelLimit: 'Pixel Limit',
  rules: 'Rules'
};

export const AddOrEditProduct = ({ urlId, queryId, onSubmit, onDelete }) => {
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const [landingPages, setLandingPages] = useState([]);
  const [loadingLandingPages, setLoadingLandingPages] = useState(false);

  const [product, setProduct] = useState();
  const [loadingProduct, setLoadingProduct] = useState(false);

  const [os, setOS] = useState([]);
  const [osLoading, setOSloading] = useState(false);

  const [browser, setBrowser] = useState([]);
  const [browserLoading, setBrowserLoading] = useState(false);

  const [productTypes, setProductTypes] = useState([]);
  const [productTypesLoading, setProductTypesLoading] = useState(false);

  const [countryLists, setCountryLists] = useState({
    pl: [],
    p1: [],
    p2: [],
    p3: [],
    p4: [],
    p5: [],
    p6: [],
    p7: [],
    p8: []
  });
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [groupedPriceData, setGroupedPriceData] = useState([]);
  const [countriesAndIds, setCountriesAndIds] = useState([]);

  const [rulesNotifiedAt, setRulesNotifiedAt] = useState({});
  const [productStartSwitches, setProductStartSwitches] = useState({});
  const [productStartRuleMetrics, setProductStartRuleMetrics] = useState({});

  const [activeTab, setActiveTab] = useState(Tabs.basic);
  const [activePriceTab, setActivePriceTab] = useState(PixelType.pl);
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [loading, setLoading] = useState(false);

  const [fetchProductTrigger, activateFetchProductTrigger] = useEffectTrigger({});

  const [form] = Form.useForm();

  const isEdit = !!Number(urlId);
  const isClone = !!Number(queryId);

  const headingAndButtonText = `${isEdit ? 'Edit' : isClone ? 'Clone' : 'Add'} Product`;

  const history = useHistory();

  const openListPage = () => {
    history.push('/products');
    setWasFormChanged(false);
  };

  const handleClose = () => {
    wasFormChanged ? confirmClose(openListPage) : openListPage();
  };

  const handleReset = () => {
    wasFormChanged ? confirmClose(() => form.resetFields()) : form.resetFields();
  };

  const transformRulesFormData = (values) => {
    const productStartFormList = values;
    if (productStartFormList) {
      const transformedData = {
        product_rules: productStartFormList.map((elem) => {
          const {
            id,
            assignedUsers,
            productStartSwitch,
            productStartRuleMetric,
            productStartNumber,
            productStartSlackSwitch,
            productStartGmailSwitch
          } = elem;

          throwErrorIfActiveRuleNotificationsTurnedOff(productStartSwitch && !(productStartSlackSwitch || productStartGmailSwitch));

          const rulesField = {
            rule_data: {
              rule_metric: productStartRuleMetric,
              number: productStartNumber,
              notify_on_slack: productStartSlackSwitch,
              notify_on_mail: productStartGmailSwitch
            },
            assigned_users: assignedUsers,
            active: productStartSwitch
          };

          if (id && !queryId) {
            rulesField.id = id;
          }

          return rulesField;
        })
      };

      return transformedData;
    }

    return {
      product_rules: []
    };
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const formValues = { ...values };
      let { pl, p1, p2, p3, p4, p5, p6, p7, p8 } = formValues;
      pl = pl ?? [];
      p1 = p1 ?? [];
      p2 = p2 ?? [];
      p3 = p3 ?? [];
      p4 = p4 ?? [];
      p5 = p5 ?? [];
      p6 = p6 ?? [];
      p7 = p7 ?? [];
      p8 = p8 ?? [];

      formValues.product_lps = formValues.product_lps ?? [];
      formValues.product_prices = breakGroupedCountries(
        [...pl, ...p1, ...p2, ...p3, ...p4, ...p5, ...p6, ...p7, ...p8],
        countriesAndIds,
        isClone
      );
      formValues.os = formValues.os ?? [];
      formValues.browser = formValues.browser ?? [];
      formValues.status = values.active ? ProductStatus.Active : ProductStatus.Inactive;
      formValues.product_preview_url = undefined;

      const rulesFormData = transformRulesFormData(formValues.productStartFormList);
      const { product_rules } = rulesFormData;
      formValues.product_rules = product_rules;
      const finalValues = { ...formValues };
      Object.values(PixelType).forEach((pixelType) => {
        delete formValues[pixelType];
      });

      if (isEdit) {
        await productsService.editProduct(finalValues, product.id);
        activateFetchProductTrigger();
      } else {
        const { id } = await productsService.addProduct(finalValues);

        setTimeout(() => {
          history.push(`/products/${id}`);
        }, 0);
      }

      openSuccessNotification({ message: `Product successfully ${isEdit ? 'edited' : isClone ? 'cloned' : 'added'}!`, duration: 8 });
      setWasFormChanged(false);
      setActiveTab(Tabs.basic);
      onSubmit();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const formatJSON = () => {
    let text = form.getFieldValue('data');
    try {
      JSON.parse(text);
    } catch (e) {
      return;
    }
    let obj = JSON.parse(text);
    text = JSON.stringify(obj, null, 4);
    form.setFieldsValue({
      data: text
    });
  };

  useEffect(() => {
    const id = isEdit ? urlId : queryId;

    (async () => {
      if (id) {
        try {
          setLoadingProduct(true);
          const prod = await productsService.getById({ id });
          setProduct(prod);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setLoadingProduct(false);
        }
      }
    })();
  }, [isEdit, urlId, queryId, fetchProductTrigger]);

  useEffect(() => {
    form.resetFields();
  }, [form, product]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingCompanies(true);
        const companies = await companiesService.getAll({
          fields: 'id,name',
          assigned_type: 'product',
          categories_names: [MBAdvertiserCategoryName]
        });
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingCompanies(false);
      }
    })();

    (async () => {
      try {
        setLoadingLandingPages(true);
        const lps = await landingPagesService.getAll({
          fields: 'id,title,date_edited,products'
        });
        setLandingPages(lps);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingLandingPages(false);
      }
    })();

    (async () => {
      setOSloading(true);
      try {
        const os = await osService.getAll();
        setOS(os);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setOSloading(false);
      }
    })();

    (async () => {
      setBrowserLoading(true);
      try {
        const browser = await browsersService.getAll();
        setBrowser(browser);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setBrowserLoading(false);
      }
    })();

    (async () => {
      setProductTypesLoading(true);
      try {
        const productTypes = await productsService.getAllProductTypes();
        setProductTypes(productTypes);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductTypesLoading(false);
      }
    })();

    (async () => {
      try {
        setCountriesLoading(true);

        const countries = await countriesService.getAll({ fields: 'id,name,code2,img' });

        setCountryLists({
          pl: countries,
          p1: countries,
          p2: countries,
          p3: countries,
          p4: countries,
          p5: countries,
          p6: countries,
          p7: countries,
          p8: countries
        });
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCountriesLoading(false);
      }
    })();
  }, []);

  const initialValues = useMemo(() => {
    if (product) {
      let tempProduct = {
        ...product,
        title: queryId ? `${product.title} - clone` : product.title,
        active: product.status === ProductStatus.Active ? true : false
      };
      const { product_prices } = tempProduct;
      const { formatedPriceGroupsData, formatedCountriesAndIds } = groupCountriesWithSamePrices(product_prices);
      tempProduct = { ...tempProduct, ...formatedPriceGroupsData };
      delete tempProduct.product_prices;

      const { product_rules } = tempProduct;
      const notifiedAt = {};
      const initialProductStartRuleMetrics = {};
      const initialProductStartSwitches = {};

      const productStartFormList = product_rules.map((elem, index) => {
        const { id, active, rule_data, assigned_users, notified_at } = elem;

        const { rule_metric, number, notify_on_slack, notify_on_mail } = rule_data;

        notifiedAt[index] = isClone ? null : notified_at;
        initialProductStartRuleMetrics[index] = rule_metric ? rule_metric : null;
        initialProductStartSwitches[index] = active ? active : null;

        return {
          id: isClone ? undefined : id,
          productStartSwitch: active,
          assignedUsers: assigned_users,
          productStartRuleMetric: rule_metric,
          productStartNumber: number,
          productStartSlackSwitch: notify_on_slack,
          productStartGmailSwitch: notify_on_mail
        };
      });

      tempProduct = { ...tempProduct, productStartFormList };
      delete tempProduct.product_rules;

      setRulesNotifiedAt(notifiedAt);
      setProductStartSwitches(initialProductStartSwitches);
      setProductStartRuleMetrics(initialProductStartRuleMetrics);
      setGroupedPriceData(formatedPriceGroupsData);
      setCountriesAndIds(formatedCountriesAndIds);

      return tempProduct;
    } else {
      return { active: true };
    }
  }, [product, isClone, queryId]);

  useDeepCompareEffect(() => {
    if (groupedPriceData[activePriceTab]) {
      markListedCountries({
        incomingValue: groupedPriceData[activePriceTab],
        countryList: countryLists[activePriceTab],
        activePriceTab,
        countryListsSetter: setCountryLists
      });
    }
  }, [activePriceTab, groupedPriceData, countryLists]);

  useBeforeUnload(wasFormChanged);
  const isBasicTabActive = activeTab === Tabs.basic;

  return (
    <div className={styles.addOrEditProduct}>
      <div className={styles.titleAndCloseBtnWrapper}>
        <h1>{headingAndButtonText}</h1>

        <div className={styles.closeTabView} onClick={() => handleClose()}>
          <CloseOutlined />
        </div>
      </div>

      <Spin spinning={loadingProduct}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
          onValuesChange={(_, allValues) => {
            setWasFormChanged(true);
            setGroupedPriceData((oldValues) => ({ ...oldValues, [activePriceTab]: allValues[activePriceTab] }));
          }}
          initialValues={initialValues}
        >
          <AntTabs activeKey={activeTab} animated={false} onChange={setActiveTab}>
            <TabPane tab={Tabs.basic} key={Tabs.basic} forceRender>
              <Row className={styles.activeSwitchRow}>
                <Form.Item className={styles.activeSwitchFormItem} label="Active" name="active" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Row>

              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: 'Please type product name!'
                      }
                    ]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="URL Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select URL type!'
                      }
                    ]}
                    name="type"
                  >
                    <Select placeholder="Select" style={{ width: '100%' }}>
                      <Option value="url-static" title="URL Static">
                        URL Static
                      </Option>
                      <Option value="url-dynamic" title="URL Dynamic">
                        URL Dynamic
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Company"
                    rules={[
                      {
                        required: true,
                        message: 'Please select company!'
                      }
                    ]}
                    name="company"
                    valuePropName="selectValue"
                  >
                    <VirtualizedSelect
                      placeholder="Select company"
                      loading={loadingCompanies}
                      options={companiesService.generateOptions(companies)}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="OS" name="os">
                    <Select
                      placeholder={'Select OS'}
                      mode="tags"
                      style={{ width: '100%' }}
                      options={osService.generateOptions(os)}
                      loading={osLoading}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Browser" name="browser">
                    <Select
                      placeholder={'Select browser'}
                      mode="tags"
                      style={{ width: '100%' }}
                      options={browsersService.generateOptions(browser)}
                      loading={browserLoading}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Product Type" name="product_type">
                    <Select
                      placeholder={'Select product type'}
                      style={{ width: '100%' }}
                      loading={productTypesLoading}
                      showSearch
                      optionFilterProp="data-searchvalue"
                    >
                      {generateSelectOptionsWithIdValues(productTypes)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item label="Description" name="description">
                    <TextArea placeholder="Description" rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Data"
                    name="data"
                    rules={[
                      {
                        required: true
                      },
                      {
                        validator: async (rule, value) => {
                          if (value) {
                            try {
                              JSON.parse(value);
                            } catch (e) {
                              throw new Error('Please type correct JSON data!');
                            }
                          }
                          return;
                        }
                      }
                    ]}
                  >
                    <TextArea placeholder="JSON Data" rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              {isEdit && (
                <Row>
                  <Col span={24}>
                    <Form.Item name="product_preview_url" label="Preview URL">
                      <InputWithCopyIcon placeholder="Preview URL" text={form.getFieldsValue().product_preview_url} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </TabPane>

            <TabPane tab={Tabs.lps} key={Tabs.lps} style={{ minHeight: 323 }} forceRender>
              <Col span={12}>
                <Form.Item label="Landing Pages" name="product_lps" valuePropName="selectValue">
                  <PreviewLpSelect
                    options={landingPagesService.generateOptions(landingPages, true)}
                    loading={loadingLandingPages}
                    multi={true}
                    renderOptionComponent={(option) => <LpPreview option={option} />}
                  />
                </Form.Item>
              </Col>
            </TabPane>

            <TabPane tab={Tabs.prices} key={Tabs.prices} forceRender>
              <ProductPrices
                product={product}
                form={form}
                countryLists={countryLists}
                countriesLoading={countriesLoading}
                onPriceTabChange={setActivePriceTab}
                markImportedCountries={setGroupedPriceData}
              />
            </TabPane>

            <TabPane tab={Tabs.pixelDescriptions} key={Tabs.pixelDescriptions} forceRender>
              <PixelDescriptions />
            </TabPane>
            <TabPane tab={Tabs.pixelLimit} key={Tabs.pixelLimit} forceRender>
              <PixelLimit />
            </TabPane>
            <TabPane tab={Tabs.rules} key={Tabs.rules} forceRender>
              <ProductRules
                form={form}
                loadingTabsData={loading}
                rulesNotifiedAt={rulesNotifiedAt}
                productStartSwitches={productStartSwitches}
                productStartRuleMetrics={productStartRuleMetrics}
                onProductStartSwitchesChanged={setProductStartSwitches}
                onProductStartRuleMetricsChanged={setProductStartRuleMetrics}
              />
            </TabPane>
          </AntTabs>

          <Divider style={{ marginTop: isBasicTabActive ? 0 : 24 }} />

          <Row gutter={32}>
            {isBasicTabActive && (
              <Col span={6}>
                <Button className="myButton" type="default" onClick={formatJSON}>
                  Format JSON
                </Button>
              </Col>
            )}

            <Col span={isBasicTabActive ? 18 : 24} style={{ textAlign: 'right' }}>
              {product ? (
                <Button
                  className="myButton"
                  type="danger"
                  ghost
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    confirmDeleteProductModal(product, () => {
                      onDelete();
                      openListPage();
                    })
                  }
                  loading={loading}
                >
                  <DeleteOutlined /> Delete
                </Button>
              ) : (
                ''
              )}
              <Button className="myButton" type="primary" ghost style={{ marginLeft: 8 }} onClick={handleReset} loading={loading}>
                Clear All Fields
              </Button>

              <Button type="primary" htmlType="submit" className="myButton" style={{ marginLeft: 8 }} loading={loading}>
                {headingAndButtonText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};
