import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { UrgentPanelList } from './components/List';
import { UrgentPanelUpdate } from './components/Update';
import { ACCEPTS_ALL, TYPES, urgentPanelService } from '../../services/urgentPanel';
import { useMount } from 'react-use';

const BOX_KEYS = {
  SEARCH: 'searchBox',
  FILTER: 'filterBox'
};
export const UrgentPanel = () => {
  const [updateData, setUpdateData] = useState(undefined);
  const [fieldsData, setFieldsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldsForEdit, setFieldsForEdit] = useState([]);
  const [boxes, setBoxes] = useState({
    searchBox: { accepts: [ACCEPTS_ALL], items: [], name: TYPES.COLUMNS },
    filterBox: { accepts: [ACCEPTS_ALL], items: [], name: TYPES.FILTER }
  });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setFieldsForEdit(boxes.searchBox.items.filter((item) => item.edit));
  }, [boxes]);

  const getTreeFieldsData = async () => {
    try {
      const data = await urgentPanelService.getTree();
      setFieldsData(data.modelFields);
      setFilters(data.filters);
    } finally {
      setLoading(false);
    }
  };

  useMount(() => {
    getTreeFieldsData();
  });

  const handleFilterValueChange = ({ itemIndex, value, isSuffix }) => {
    setBoxes((prevState) => {
      const newState = { ...prevState };
      if (isSuffix) {
        newState.filterBox.items[itemIndex].suffix = value;
        newState.filterBox.items[itemIndex].filterValue = undefined;
      } else {
        newState.filterBox.items[itemIndex].filterValue = value;
      }
      return newState;
    });
  };

  const handleRemoveItem = (key, itemIndex) => {
    setBoxes((prevState) => {
      const newState = { ...prevState };
      newState[key].items = newState[key].items.filter((el, i) => i !== itemIndex);
      return newState;
    });
  };

  const handleDropItem = (key, item) => {
    let fieldName = item.value;
    if (item.fields) {
      if (key === BOX_KEYS.SEARCH) {
        fieldName = `${item.value}__${item.fields.label}`;
      } else {
        fieldName = `${item.value}`;
      }
    }
    if (!boxes[key].items.find((el) => el.value === fieldName)) {
      const newState = { ...boxes };

      newState[key] = {
        ...newState[key],
        items: [...newState[key].items, { ...item, value: fieldName }]
      };
      setBoxes(newState);
    }
  };

  return (
    <>
      {updateData ? (
        <UrgentPanelUpdate data={updateData} onCancel={() => setUpdateData(undefined)} />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <UrgentPanelList
            onEdit={setUpdateData}
            fieldsData={fieldsData}
            loading={loading}
            onFinishedLoadingCampaigns={() => setLoading(false)}
            onLoadingCampaigns={() => setLoading(true)}
            fieldsForEdit={fieldsForEdit}
            boxes={boxes}
            onFilterValueChange={handleFilterValueChange}
            onRemoveItem={handleRemoveItem}
            filters={filters}
            onDropItem={handleDropItem}
          />
        </DndProvider>
      )}
    </>
  );
};
