import { BarChartOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Radio, Row, Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePromise } from 'react-use';

import { ColumnsGroup, renderCollapseRows } from '../../../components/ColumnsGroup';
import { FilterSwitch } from '../../../components/FilterSwitch';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { accountsService } from '../../../services/accounts';
import { browsersService } from '../../../services/browsers';
import { STATS_CATEGORIES, companiesService } from '../../../services/companies';
import { countriesService } from '../../../services/countries';
import { deviceTypesService } from '../../../services/device-types';
import { identityService } from '../../../services/identity';
import { landingPagesService } from '../../../services/landingPages';
import { mediaCampaignsService } from '../../../services/media-campaigns';
import { osService } from '../../../services/os';
import { productsService } from '../../../services/products';
import { CampaignType, ChannelColumnGroups, FilterName, SourcesSearchType } from '../../../services/statistic';
import { userFiltersService } from '../../../services/user-filters';
import { PublisherModules } from '../../../utils/enums';
import { firstLetterUpperCase } from '../../../utils/functions';
import { useMount } from '../../../utils/hooks/useMount';
import {
  generateOptionsFromObject,
  generateSelectOptionsWithIdValues,
  generateOptionsFromObjectKeyValue,
  filterOptionsByLabelAndValue
} from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from '../ChannelStats.module.css';
import { CustomColumnModal } from './CustomColumnModal';
import { FilterModal } from './FilterModal';
import { IncludeExcludeSelect } from './IncludeExcludeSelect';
import { companyTagsService } from '../../../services/company-tags';

const { RangePicker } = DatePicker;

const ruleType = [
  { value: 1, label: 'Regular' },
  { value: 2, label: 'DDL' },
  { value: 3, label: 'External' }
];

const tempChecked = {
  channel: true,
  im: true,
  dw: true,
  pl: true,
  spent: true,
  earned: true,
  profit: true,
  roi: true,
  pf: true
};

const defaultActivePanelKeys = [
  ChannelColumnGroups.Dimensions,
  ChannelColumnGroups.Metrics,
  ChannelColumnGroups.CampaignInfo,
  ChannelColumnGroups.Pixels,
  ChannelColumnGroups.FiredPixels,
  ChannelColumnGroups.PixelsEarned,
  ChannelColumnGroups.Product,
  ChannelColumnGroups.PartnerInfo
];

export const Filters = ({ columns, onColumnCreate, onSubmit, onExport, loadingExportButton }) => {
  const [countries, setCountries] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(false);

  const [userFilters, setUserFilters] = useState([]);

  const [userSavedFilter, setUserSavedFilter] = useState();
  const [userSavedFilterLoading, setUserSavedFilterLoading] = useState(false);

  const [browsers, setBrowsers] = useState([]);
  const [browsersLoading, setBrowsersLoading] = useState(false);

  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceTypesLoading, setDeviceTypesLoading] = useState(false);

  const [identities, setIdentities] = useState([]);
  const [identitiesLoading, setIdentitiesLoading] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  const [productCompany, setProductCompany] = useState([]);
  const [productCompanyLoading, setProductCompanyLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const [channels, setChannels] = useState([]);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [displayFilters, setDisplayFilters] = useState(true);

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const [lps, setLps] = useState([]);
  const [lpsLoading, setLpsLoading] = useState(false);

  const [os, setOS] = useState([]);
  const [osLoading, setOSLoading] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [statusesLoading, setStatusesLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);

  const [filters, setFilters] = useState({ [FilterName.Excluded]: [], [FilterName.SourcesSearchType]: SourcesSearchType.Exact });
  const [checkedColumns, setCheckedColumns] = useState(tempChecked);

  const [showSourceFilters, setShowSourceFilters] = useState(false);

  const [visibleCreateColumnModal, setVisibleCreateColumnModal] = useState(false);

  const mounted = usePromise();

  const containsSearchTypeImageName =
    filters[FilterName.SourcesSearchType] === SourcesSearchType.Contains ? 'cube_transp_blue' : 'cube_transp_grey';
  const exactSearchTypeImageName = filters[FilterName.SourcesSearchType] === SourcesSearchType.Exact ? 'cube_blue' : 'cube_grey';

  useEffect(() => {
    const getChannels = async () => {
      try {
        setChannelsLoading(true);
        const channels = await mounted(mediaCampaignsService.getCampaigns());
        setChannels(channels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setChannelsLoading(false);
      }
    };

    const getLps = async () => {
      try {
        setLpsLoading(true);
        const lps = await mounted(landingPagesService.getAll());
        setLps(lps);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLpsLoading(false);
      }
    };

    const getUsers = async () => {
      try {
        setUsersLoading(true);
        const users = await mounted(accountsService.getAll());
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    };

    const getProducts = async () => {
      try {
        setProductsLoading(true);
        const products = await mounted(productsService.getAll());
        setProducts(products);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductsLoading(false);
      }
    };

    const getCountries = async () => {
      try {
        setCountriesLoading(true);
        const countries = await mounted(countriesService.getAll({ fields: 'id,name,code2,img' }));
        setCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCountriesLoading(false);
      }
    };

    const getBrowsers = async () => {
      try {
        setBrowsersLoading(true);
        const browsers = await mounted(browsersService.getAll());
        setBrowsers(browsers);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setBrowsersLoading(false);
      }
    };

    const getDeviceTypes = async () => {
      try {
        setDeviceTypesLoading(true);
        const deviceTypes = await mounted(deviceTypesService.getAll({ fields: 'name,regex' }));
        setDeviceTypes(deviceTypes);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setDeviceTypesLoading(false);
      }
    };

    const getIdentities = async () => {
      try {
        setIdentitiesLoading(true);
        const identities = await mounted(identityService.getAll());
        setIdentities(identities);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setIdentitiesLoading(false);
      }
    };

    const getCompanies = async () => {
      try {
        setCompaniesLoading(true);
        const companies = await mounted(companiesService.getTrafficCompanies());
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCompaniesLoading(false);
      }
    };

    const getProductCompany = async () => {
      try {
        setProductCompanyLoading(true);
        const productCompany = await mounted(companiesService.getProductCompanies());
        setProductCompany(productCompany);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductCompanyLoading(false);
      }
    };

    const getUserFilters = async () => {
      try {
        setUserSavedFilterLoading(true);
        let userFilters = await mounted(userFiltersService.getFilters());
        setUserFilters(userFilters);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUserSavedFilterLoading(false);
      }
    };

    const getOs = async () => {
      try {
        setOSLoading(true);
        let osFilters = await mounted(osService.getFilters());
        setOS(osFilters);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setOSLoading(false);
      }
    };

    const getStatuses = async () => {
      try {
        setStatusesLoading(true);
        const data = await mounted(mediaCampaignsService.getCampaignStatuses());
        setStatuses(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setStatusesLoading(false);
      }
    };

    const getTags = async () => {
      try {
        setTagsLoading(true);
        const data = await mounted(companyTagsService.getAll());
        setTags(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTagsLoading(false);
      }
    };

    getCompanies();
    getUserFilters();
    getChannels();
    getProductCompany();
    getBrowsers();
    getDeviceTypes();
    getIdentities();
    getProducts();
    getCountries();
    getUsers();
    getLps();
    getOs();
    getStatuses();
    getTags();
  }, [mounted]);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      filters: urlFilters || filters,
      dateRange: dateRange,
      checkedColumns: urlCheckedColumns || checkedColumns
    });
  };

  const setSavedFilter = (name) => {
    let selectedSavedFilter = userFilters.find((el) => el.name === name);
    const { filters, checkedColumns } = selectedSavedFilter;
    const updatedFilters = { ...filters, excluded: filters.excluded ?? [] };
    setFilters(updatedFilters);
    setCheckedColumns(checkedColumns);
  };

  useMount(() => {
    const search = new URLSearchParams(window.location.search);
    const channelString = search.get('channel');
    const columns = search.get('columns');
    const lpsString = search.get('lp_id');
    const dateFrom = search.get('date_from');
    const dateTo = search.get('date_to');
    const advCompanyId = search.get('adv_company_id');
    const excluded = search.get('excluded');

    if (dateFrom && dateTo) {
      setDateRange({ from: moment(dateFrom), to: moment(dateTo) });
    }
    const urlFilters = {
      ...filters,
      [FilterName.Channels]: channelString ? channelString.split(',') : [],
      [FilterName.LPs]: lpsString ? lpsString.split(',') : [],
      [FilterName.TrafficCompanies]: advCompanyId ? advCompanyId.split(',') : [],
      [FilterName.Excluded]: excluded ? excluded.split(',') : []
    };

    setFilters(urlFilters);
    let urlCheckedColumns = null;
    if (columns) {
      urlCheckedColumns = {};
      columns &&
        columns.split(',').forEach((el) => {
          urlCheckedColumns[el] = true;
        });
      setCheckedColumns(urlCheckedColumns);
    }
  });

  const handleExcludedChange = (isExcluded, filterName) => {
    const excludedFiltersClone = [...filters[FilterName.Excluded]];
    const filterNameArrayIndex = excludedFiltersClone.indexOf(filterName);

    if (isExcluded && filterNameArrayIndex < 0) {
      excludedFiltersClone.push(filterName);
    } else if (!isExcluded && filterNameArrayIndex > -1) {
      excludedFiltersClone.splice(filterNameArrayIndex, 1);
    }

    setFilters({ ...filters, excluded: excludedFiltersClone });
  };

  return (
    <div className={styles.spanFontWeight500}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <Row gutter={32}>
            <Col>
              <Button type="primary" ghost onClick={() => setDisplayFilters(!displayFilters)}>
                Filters
              </Button>
            </Col>

            <Col>
              <ColumnsGroup
                wrapperHeight="95vh"
                popoverPlacement="right"
                showUpdateButton={true}
                onUpdate={handleUpdate}
                renderColumnRows={() =>
                  renderCollapseRows({
                    panelHeaderTitles: ChannelColumnGroups,
                    defaultActivePanelKeys,
                    allColumns: columns,
                    checkedColumns,
                    onChange: setCheckedColumns
                  })
                }
              />
            </Col>

            <Col>
              <Button type="primary" onClick={() => setVisibleCreateColumnModal(true)}>
                Create Column
              </Button>

              <CustomColumnModal
                visibleModal={visibleCreateColumnModal}
                onCancel={() => setVisibleCreateColumnModal(false)}
                onColumnCreate={(newColumn) => {
                  const { value: columnValue } = newColumn;

                  onColumnCreate(newColumn);
                  setCheckedColumns((oldCheckedColumns) => Object.assign({}, oldCheckedColumns, { [columnValue]: true }));
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={32}>
            <Col>
              <Button className="export-btn" type="primary" ghost>
                <Link
                  to={`/stubs/channel_chart/?channel=${
                    filters && filters[FilterName.Channels] ? filters[FilterName.Channels] : ''
                  }&products=${filters && filters[FilterName.Products] ? filters[FilterName.Products].join() : ''}&browser=${
                    filters && filters[FilterName.Browsers] ? filters[FilterName.Browsers].join() : ''
                  }&os=${filters && filters[FilterName.OSs] ? filters[FilterName.OSs].join() : ''}&source=${
                    filters && filters[FilterName.Sources] ? filters[FilterName.Sources].join() : ''
                  }&users=${filters && filters[FilterName.AccountManagers] ? filters[FilterName.AccountManagers].join() : ''}&lps=${
                    filters && filters[FilterName.LPs] ? filters[FilterName.LPs].join() : ''
                  }&country=${filters && filters[FilterName.Countries] ? filters[FilterName.Countries].join(',') : ''}&advertiser_comp=${
                    filters && filters[FilterName.TrafficCompanies] ? filters[FilterName.TrafficCompanies].join(',') : ''
                  }&product_comp=${filters && filters[FilterName.ProductCompanies] ? filters[FilterName.ProductCompanies].join(',') : ''}`}
                >
                  <BarChartOutlined /> &nbsp; Graph view
                </Link>
                {/** {exportFinished ? <Icon type="right-square" /> : null}*/}
              </Button>
            </Col>
            <Col>
              <FilterModal filters={filters} checkedColumns={checkedColumns} onUpdate={setUserFilters} userFilters={userFilters} />
            </Col>
            <Col>
              <Button
                className="export-btn"
                type="primary"
                ghost
                onClick={() => onExport({ filters, checkedColumns, dateRange, type: 'csv' })}
                icon={<DownloadOutlined />}
                loading={loadingExportButton}
              >
                Export data
              </Button>
            </Col>
            <Col>
              <Button
                style={{ display: 'flex' }}
                className="export-btn"
                type="primary"
                ghost
                onClick={() => onExport({ filters, checkedColumns, dateRange, type: 'google_sheet' })}
                loading={loadingExportButton}
              >
                <img alt="" width="14" src="/images/google_sheets.png" style={{ alignSelf: 'center', marginRight: '8px' }} />
                Open in Google Sheets
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {displayFilters ? (
        <div className={styles.filtersWrapper}>
          <Row gutter={32}>
            <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={5}>
              <span className={styles.spanMarginBottom}>Date range </span>
              <div>
                <RangePicker
                  format="DD.MM.YYYY"
                  ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 2 Days': [moment().subtract(1, 'days'), moment()],
                    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
                  }}
                  onChange={(momentDates, dates) => {
                    setDateRange({ from: momentDates ? momentDates[0] : null, to: momentDates ? momentDates[1] : null });
                  }}
                  value={[dateRange.from, dateRange.to]}
                  style={{ width: '100%' }}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>
                Channels
                <span></span>
              </span>

              <VirtualizedSelect
                options={mediaCampaignsService.generateOptions(channels)}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.Channels]: value });
                }}
                channels={channels}
                multi={true}
                diffPasteHandler={true}
                selectValue={filters[FilterName.Channels]}
                placeholder={'Select channels'}
                loading={channelsLoading}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Countries</span>
              <IncludeExcludeSelect
                options={countriesService.generateOptions(countries)}
                mode="multiple"
                value={filters[FilterName.Countries]}
                placeholder={'Select countries'}
                loading={countriesLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.Countries)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.Countries]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.Countries)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Products</span>
              <IncludeExcludeSelect
                options={productsService.generateOptions(products)}
                mode="multiple"
                value={filters[FilterName.Products]}
                placeholder={'Select products'}
                loading={productsLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.Products)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.Products]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.Products)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Rule types</span>
              <IncludeExcludeSelect
                options={ruleType}
                mode="multiple"
                value={filters[FilterName.RuleTypes]}
                placeholder={'Select rule types'}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.RuleTypes)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.RuleTypes]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.RuleTypes)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>OSs</span>
              <IncludeExcludeSelect
                options={osService.generateOptionsForFilters(os)}
                mode="tags"
                value={filters[FilterName.OSs]}
                placeholder={'Select os/s'}
                loading={osLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.OSs)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.OSs]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.OSs)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>OS Versions</span>
              <IncludeExcludeSelect
                mode="tags"
                value={filters[FilterName.OSVersions]}
                placeholder={'Select os versions'}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.OSVersions)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.OSVersions]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.OSVersions)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Browsers</span>
              <IncludeExcludeSelect
                options={browsersService.generateOptionsWithNameAsValue(browsers)}
                mode="multiple"
                value={filters[FilterName.Browsers]}
                placeholder={'Select browsers'}
                loading={browsersLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.Browsers)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.Browsers]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.Browsers)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Browser Versions</span>
              <IncludeExcludeSelect
                mode="tags"
                value={filters[FilterName.BrowserVersions]}
                placeholder={'Select browser versions'}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.BrowserVersions)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.BrowserVersions]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.BrowserVersions)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Device Types</span>
              <IncludeExcludeSelect
                options={deviceTypesService.generateOptionsForStatsFilter(deviceTypes)}
                mode="multiple"
                value={filters[FilterName.DeviceTypes]}
                placeholder={'Select device types'}
                loading={deviceTypesLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.DeviceTypes)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.DeviceTypes]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.DeviceTypes)}
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Identities</span>
              <VirtualizedSelect
                options={identityService.generateOptions(identities)}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.Identities]: value });
                }}
                multi={true}
                selectValue={filters[FilterName.Identities]}
                placeholder={'Select identities'}
                loading={identitiesLoading}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Account managers</span>
              <IncludeExcludeSelect
                options={accountsService.generateOptions(users)}
                mode="multiple"
                value={filters[FilterName.AccountManagers]}
                placeholder={'Select account managers'}
                loading={usersLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.AccountManagers)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.AccountManagers]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.AccountManagers)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Landing pages</span>
              <IncludeExcludeSelect
                options={landingPagesService.generateOptions(lps)}
                mode="multiple"
                value={filters[FilterName.LPs]}
                placeholder={'Select landing pages'}
                loading={lpsLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.LPs)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.LPs]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.LPs)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Product Companies</span>
              <IncludeExcludeSelect
                options={companiesService.generateOptions(productCompany)}
                mode="multiple"
                value={filters[FilterName.ProductCompanies]}
                placeholder={'Select product companies'}
                loading={productCompanyLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.ProductCompanies)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.ProductCompanies]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.ProductCompanies)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Traffic Companies</span>
              <IncludeExcludeSelect
                options={companiesService.generateOptions(companies)}
                mode="multiple"
                value={filters[FilterName.TrafficCompanies]}
                placeholder={'Select traffic companies'}
                loading={companiesLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.TrafficCompanies)}
                onValuesChange={(value) => {
                  setFilters({ ...filters, [FilterName.TrafficCompanies]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.TrafficCompanies)}
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <div className={styles.sourcesHeadingWrapper}>
                <span className={styles.spanMarginBottom}>Sources</span>

                <Radio.Group
                  value={filters[FilterName.SourcesSearchType]}
                  onChange={(e) => {
                    setFilters({ ...filters, [FilterName.SourcesSearchType]: e.target.value });
                  }}
                >
                  <Tooltip title="Contains">
                    <Radio.Button value="contains">
                      <img src={`/images/${containsSearchTypeImageName}.png`} alt="Contains" />
                    </Radio.Button>
                  </Tooltip>

                  <Tooltip title="Exact">
                    <Radio.Button value="exact">
                      <img src={`/images/${exactSearchTypeImageName}.png`} alt="Exact" />
                    </Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </div>

              <Select
                style={{ width: '100%' }}
                mode="tags"
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.Sources]: value });
                }}
                value={filters[FilterName.Sources]}
                allowClear
                tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                placeholder={'Source'}
              >
                {null}
              </Select>
            </Col>

            {showSourceFilters && (
              <>
                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 1</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources1]: value });
                    }}
                    value={filters[FilterName.Sources1]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 1'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 2</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources2]: value });
                    }}
                    value={filters[FilterName.Sources2]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 2'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 3</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources3]: value });
                    }}
                    value={filters[FilterName.Sources3]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 3'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 4</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources4]: value });
                    }}
                    value={filters[FilterName.Sources4]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 4'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 5</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources5]: value });
                    }}
                    value={filters[FilterName.Sources5]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 5'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 6</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources6]: value });
                    }}
                    value={filters[FilterName.Sources6]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 6'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 7</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources7]: value });
                    }}
                    value={filters[FilterName.Sources7]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 7'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 8</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources8]: value });
                    }}
                    value={filters[FilterName.Sources8]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 8'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 9</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.Sources9]: value });
                    }}
                    value={filters[FilterName.Sources9]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 9'}
                  >
                    {null}
                  </Select>
                </Col>
              </>
            )}

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Filters</span>
              <Select
                value={userSavedFilter}
                onChange={(value) => {
                  setUserSavedFilter(value);
                  if (value) {
                    setSavedFilter(value);
                  }
                }}
                options={userFiltersService.generateOptions(userFilters)}
                style={{ width: '100%' }}
                loading={userSavedFilterLoading}
                placeholder={'Select user filter'}
              >
                {null}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Publisher Modules</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.PublisherModules]: value });
                }}
                value={filters[FilterName.PublisherModules]}
                placeholder={'Select publisher module'}
                allowClear
              >
                {generateOptionsFromObject(PublisherModules, firstLetterUpperCase)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Campaign Types</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.CampaignTypes]: value });
                }}
                value={filters[FilterName.CampaignTypes]}
                placeholder={'Select campaign type'}
                allowClear
              >
                {generateOptionsFromObject(CampaignType)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Campaign Titles</span>
              <Select
                style={{ width: '100%' }}
                mode="tags"
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.CampaignTitles]: value });
                }}
                value={filters[FilterName.CampaignTitles]}
                placeholder={'Type campaign title'}
                allowClear
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Campaign Statuses</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.CampaignStatuses]: value });
                }}
                value={filters[FilterName.CampaignStatuses]}
                placeholder={'Select campaign status'}
                loading={statusesLoading}
                allowClear
              >
                {generateSelectOptionsWithIdValues(statuses)}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Company category</span>
              <Select
                value={filters[FilterName.CompanyCategory]}
                placeholder={'Select company category'}
                allowClear
                style={{ width: '100%' }}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.CompanyCategory]: value });
                }}
                showSearch
                optionFilterProp="data-searchvalue"
              >
                {generateOptionsFromObjectKeyValue(STATS_CATEGORIES)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Brought by</span>
              <Select
                options={accountsService.generateOptions(users)}
                mode="multiple"
                value={filters[FilterName.BroughtBy]}
                placeholder={'Brought by'}
                loading={usersLoading}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.BroughtBy]: value });
                }}
                showSearch
                filterOption={filterOptionsByLabelAndValue}
              ></Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Tags</span>
              <Select
                style={{ width: '100%' }}
                value={filters[FilterName.Tags]}
                placeholder={'Select tag'}
                allowClear
                loading={tagsLoading}
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.Tags]: value });
                }}
                showSearch
                optionFilterProp="data-searchvalue"
              >
                {generateSelectOptionsWithIdValues(tags)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Domains</span>
              <Select
                style={{ width: '100%' }}
                mode="tags"
                onChange={(value) => {
                  setFilters({ ...filters, [FilterName.Domains]: value });
                }}
                value={filters[FilterName.Domains]}
                placeholder={'Type domains'}
                allowClear
              />
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12}>
              <Button onClick={() => handleUpdate()} type="primary">
                Update
              </Button>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Row gutter={32}>
                <Col span={8}>
                  <FilterSwitch
                    label="Include Errors"
                    checked={!!filters[FilterName.IncludeErrors]}
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.IncludeErrors]: value ? 1 : 0 });
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FilterSwitch
                    label="No profit"
                    checked={!!filters[FilterName.NonProfit]}
                    onChange={(value) => {
                      setFilters({ ...filters, [FilterName.NonProfit]: value ? 1 : 0 });
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FilterSwitch
                    label="QS filters"
                    checked={showSourceFilters}
                    onChange={setShowSourceFilters}
                    className={styles.sourceFiltersSwitch}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
