import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table as AntTable, Pagination } from 'antd';

import { CampaignNameCol } from '../CampaignNameCol';

import styles from './Vertical.module.css';

export const Table = ({ campaigns, totalRows, currentPage, pageSize, loadingCampaigns, shrinkMenu, urlId, onPaginationChange }) => {
  const history = useHistory();

  const columns = [
    {
      title: 'Campaign Name',
      key: 'campaign_name',
      //className: styles.campaignNameColumn,
      width: 200,
      render: ({ id, creator_details, channel, company_details }) => {
        const showCheckIcon = urlId === id.toString();
        return (
          <CampaignNameCol
            creator={creator_details}
            channel={channel}
            companyDetails={company_details}
            onlyInitials={shrinkMenu}
            showCheckIcon={showCheckIcon}
          />
        );
      }
    }
  ];

  return (
    <div className={styles.campaignsTable}>
      <AntTable
        columns={columns}
        dataSource={campaigns}
        loading={loadingCampaigns}
        pagination={false}
        rowKey={({ id }) => id}
        showHeader={false}
        onRow={({ id }, rowIndex) => {
          return {
            onClick: (event) => {
              history.push(`/media-campaigns/${id}`);
            }
          };
        }}
        footer={() => {
          return !shrinkMenu ? (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              simple
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={['10', '20', '30']}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          ) : null;
        }}
      />
    </div>
  );
};
