import React from 'react';
import { CopyTwoTone } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'antd';
import { openSuccessNotification } from '../../../utils/notifications';

export const withCopyIcon = (WrappedItem) => (props) => {
  const { additionalIcon, additionalIconTooltip, ...otherProps } = props;
  const { text } = otherProps;

  const suffixProp = {
    suffix: (
      <>
        {additionalIcon && <Tooltip title={additionalIconTooltip}>{additionalIcon}</Tooltip>}
        <Tooltip title="Copy to Clipboard">
          <CopyToClipboard text={text} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
            <CopyTwoTone style={{ display: text ? 'inline' : 'none' }} />
          </CopyToClipboard>
        </Tooltip>
      </>
    )
  };

  return <WrappedItem {...otherProps} {...suffixProp} disabled />;
};
