import { UnlockFilled } from '@ant-design/icons';
import { Button, Form, Radio } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MediaCampaignTabKeys } from '.';
import { ImportFromCampaignModal } from '../../../../components/ImportFromCampaignModal';
import { ImportPopover } from '../../../../components/ImportPopover';
import { Select } from '../../../../components/Select';
import { withRefetching } from '../../../../components/SelectWithSideLoader';
import { Spinner } from '../../../../components/Spinner';
import { TargetingForm } from '../../../../components/TargetingForm';
import { mediaCampaignsService } from '../../../../services/media-campaigns';
import { getGlobalTargetingLists, useCustomQuery } from '../../../../utils/hooks/queries';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { DEFAULT_AUTOMATION_RULES_VALUE, DEFAULT_S2S_ADVANCED_VALUE, targetingInitialValues } from '../../../../utils/targeting';
import { getSelectOptionsWithTooltip } from '../../../lists/utils/options';
import { repeatPeriodTypes } from '../../utils/enums';
import styles from './Tabs.module.css';

const SelectWithRefetching = withRefetching(Select);

export const Targeting = ({ form, initialData, loadingTabsData, targetingRadioValue, formChangedTrigger, onRadioChange, onFormChange }) => {
  const [loadingGlobalList, setLoadingGlobalList] = useState(false);
  const [radioDidChange, setRadioDidChange] = useState(false);
  const [selectedGlobalList, setSelectedGlobalList] = useState(undefined);
  const [globalListData, setGlobalListData] = useState(null);
  const [localListData, setLocalListData] = useState(null);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const {
    isLoading: allGlobalTargetingListsLoading,
    isFetching: allGlobalTargetingListsRefetching,
    data: allGlobalTargetingLists
  } = useCustomQuery(getGlobalTargetingLists);

  const isGlobalListDeleted = useCallback(() => {
    const { id } = globalListData;
    const initialGlobalList = allGlobalTargetingLists.filter((list) => {
      return id === list.id;
    });

    return initialGlobalList.length === 0;
  }, [globalListData, allGlobalTargetingLists]);

  const initialValues = useMemo(() => {
    if (!globalListData) {
      return {
        globalListRadio: false,
        patternFormList: [
          {
            repeatEveryNumber: 0,
            repeatEveryPeriod: repeatPeriodTypes.DAY,
            schedulerEnd: moment('2030-01-01'),
            patternSetupFormList: [
              {
                automationRules: [DEFAULT_AUTOMATION_RULES_VALUE],
                s2s: 100,
                s2sExtended: DEFAULT_S2S_ADVANCED_VALUE,
                percentage: 100,
                optimiseBySource: false
              }
            ]
          }
        ]
      };
    }

    const { id, global_list } = globalListData;

    if (global_list && allGlobalTargetingLists.length && isGlobalListDeleted()) {
      setGlobalListData(null);
      return;
    }

    const globalListRadio = global_list;
    const globalTargetingList = globalListRadio ? id : undefined;

    const getInitialValues = targetingInitialValues(globalListData);
    getInitialValues.globalListRadio = globalListRadio;
    getInitialValues.globalTargetingList = globalTargetingList;
    setSelectedGlobalList(globalTargetingList);

    if (!globalListRadio) {
      setLocalListData(globalListData);
    }
    return getInitialValues;
  }, [globalListData, allGlobalTargetingLists, isGlobalListDeleted]);

  useEffect(() => {
    const radioValue = initialData && initialData.global_list;
    onRadioChange(radioValue);
    setGlobalListData(initialData);
  }, [initialData, onRadioChange]);

  useEffect(() => {
    let didCancel = false;

    const getGlobalTargetingListData = async () => {
      const globalListId = globalListData ? globalListData.id : null;

      if (selectedGlobalList && selectedGlobalList !== globalListId) {
        try {
          setLoadingGlobalList(true);
          const listData = await mediaCampaignsService.getFormatedTargetingList({ id: selectedGlobalList });

          if (!didCancel) {
            setGlobalListData(listData);
          }
        } catch (e) {
          showApiErrors(e);
        } finally {
          setLoadingGlobalList(false);
        }
      }
    };

    getGlobalTargetingListData();

    return () => {
      didCancel = true;
    };
  }, [selectedGlobalList, globalListData]);

  useEffect(() => {
    const listId = globalListData ? globalListData.id : null;
    const isGlobal = globalListData ? globalListData.global_list : null;

    if (!targetingRadioValue && listId && isGlobal && radioDidChange) {
      setSelectedGlobalList(undefined);
      setGlobalListData(localListData);
    }
  }, [targetingRadioValue, globalListData, radioDidChange, localListData]);

  const unlockPattern = () => {
    const correctedPatterns = globalListData.targeting_patterns_details.map((pattern) => {
      const correctedPatternSetup = pattern.targeting_pattern_setups_details.map((setup) => ({ ...setup, id: undefined }));
      return { ...pattern, id: undefined, targeting_pattern_setups_details: correctedPatternSetup };
    });
    const correctedGlobalListData = { ...globalListData, id: undefined, global_list: false, targeting_patterns_details: correctedPatterns };
    setGlobalListData(correctedGlobalListData);
    onRadioChange(false);
  };

  useEffect(() => form.resetFields(), [form, globalListData]);

  const handleFormChange = useCallback(() => {
    const globalTargetingList = form.getFieldValue('globalTargetingList');
    setSelectedGlobalList(globalTargetingList);
  }, [form]);

  useEffect(() => {
    handleFormChange();
  }, [formChangedTrigger, handleFormChange]);

  const checkIfRadioChanged = ({ globalListRadio }) => {
    if (globalListRadio !== undefined) {
      setRadioDidChange(true);
    }
  };

  const openImportModal = () => setImportModalVisible(true);
  const closeImportModal = () => setImportModalVisible(false);

  const loading = loadingTabsData || loadingGlobalList;

  return (
    <Form
      form={form}
      name="targetingForm"
      initialValues={initialValues}
      layout="vertical"
      onValuesChange={(changedValues) => {
        // activateFormChangeTrigger func is called in onFormChange prop
        onFormChange(true);
        checkIfRadioChanged(changedValues);
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {/* <div className={styles.note}>NOTE: Changes will be applied after you click 'Save Changes' in top right corner!</div> */}
          <div className={styles.headerWithClear}>
            <div className={styles.targetingRadioAndSelectWrapper}>
              <div className={styles.customRadio}>
                <Form.Item name="globalListRadio">
                  <Radio.Group className={styles.radioBtnGroup}>
                    <Radio value={false}>Local List</Radio>
                    <Radio value={true}>Global List</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className={styles.formItemWrapper4} style={{ display: targetingRadioValue ? 'block' : 'none', marginRight: '24px' }}>
                <Form.Item name="globalTargetingList">
                  <SelectWithRefetching
                    placeholder="Select Global Targeting List"
                    optionFilterProp="data-searchvalue"
                    showSearch
                    loading={allGlobalTargetingListsLoading}
                    refetching={allGlobalTargetingListsRefetching}
                  >
                    {getSelectOptionsWithTooltip(allGlobalTargetingLists)}
                  </SelectWithRefetching>
                </Form.Item>
              </div>

              <div style={{ display: targetingRadioValue && selectedGlobalList ? 'block' : 'none', marginBottom: '15px' }}>
                <Button onClick={unlockPattern}>
                  <UnlockFilled />
                  Unlock And Make Changes
                </Button>
              </div>
            </div>

            <div className={styles.importAndClearWrapper}>
              <ImportPopover onImportClick={openImportModal} />
              {importModalVisible && (
                <ImportFromCampaignModal
                  tabKey={MediaCampaignTabKeys.targeting}
                  modalVisible={importModalVisible}
                  onImportData={setGlobalListData}
                  onClose={closeImportModal}
                />
              )}
            </div>
          </div>

          <TargetingForm
            form={form}
            patternDetails={initialData?.targeting_patterns_details}
            disabled={targetingRadioValue && selectedGlobalList}
            formChangedTrigger={formChangedTrigger}
          />
        </div>
      )}
    </Form>
  );
};
