import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Row, Col, Select } from 'antd';

// import { NewsTicker } from './NewsTicker';
import { auth } from '../services/auth';
import { useMount } from '../utils/hooks';
import { Block } from '../components/Header/Block';
import { generateOptionsFromObject, snakeCaseToProperCase } from '../utils/options';
import { Emitter, EMITTER_EVENTS } from '../utils/emitter';
import { dashboardsService, DASH_RANGE_TYPE } from '../services/dashboards';
import { showApiErrors } from '../utils/showApiErrors';
import styles from './Header.module.css';

// import { newsService } from '../services/news';
const DASH_RANGE_TYPE_COMPARE = {
  YESTERDAY: DASH_RANGE_TYPE.YESTERDAY,
  LAST_MONTH: DASH_RANGE_TYPE.LAST_MONTH,
  THIS_DAY_LAST_MONTH: DASH_RANGE_TYPE.THIS_DAY_LAST_MONTH
};

const ZERO_HEADER_VALUES = {
  roi: '0.00',
  spent: '0.00',
  earned: '0.00',
  profit: '0.00',
  number_of_active: 0,
  number_of_created: 0,
  number_of_optimized: 0
};

const COMPARING_VALUES = {
  [DASH_RANGE_TYPE.YESTERDAY]: { from: DASH_RANGE_TYPE.TODAY, to: DASH_RANGE_TYPE.YESTERDAY },
  [DASH_RANGE_TYPE.LAST_MONTH]: { from: DASH_RANGE_TYPE.LAST_MONTH, to: DASH_RANGE_TYPE.THIS_MONTH },
  [DASH_RANGE_TYPE.THIS_DAY_LAST_MONTH]: { from: DASH_RANGE_TYPE.TODAY, to: DASH_RANGE_TYPE.THIS_DAY_LAST_MONTH }
};
export const Header = () => {
  // const [news, setNews] = useState([]);
  // useEffect(() => {
  //   const getNews = async () => {
  //     try {
  //       const data = []; //await newsService.getActive();
  //       setNews(data);
  //     } catch {}
  //   };
  //   getNews();
  //   const intervalId = setInterval(getNews, 15000);

  //   return () => clearInterval(intervalId);
  // }, []);

  const [currentUser, setCurrentUser] = useState(undefined);
  const [headerData, setHeaderData] = useState(undefined);
  const [isAdminHeader, setIsAdminHeader] = useState(false);
  const [compare, setCompare] = useState(DASH_RANGE_TYPE.YESTERDAY);
  const [loadingHeader, setLoadingHeader] = useState(true);

  // const [loadingUser, setLoadingUser] = useState(fa);

  useMount(() => {
    const getUserInfo = async () => {
      const user = await auth.getUserProfile();
      setCurrentUser(user);
    };
    getUserInfo();
  }, []);

  useEffect(() => {
    Emitter.on(EMITTER_EVENTS.SWITCH_ADMIN_DASH, (value) => setIsAdminHeader(value));
    return () => {
      Emitter.on(EMITTER_EVENTS.SWITCH_ADMIN_DASH, (value) => setIsAdminHeader(value));
    };
  });

  const loadSalesDashData = useCallback(async () => {
    try {
      setLoadingHeader(true);
      const thisMonthSalesDash = await dashboardsService.getSalesDashboard({ rangeType: DASH_RANGE_TYPE.THIS_MONTH, isHeader: true });
      setHeaderData({
        current: thisMonthSalesDash.length ? thisMonthSalesDash[0].data : ZERO_HEADER_VALUES,
        compare: undefined
      });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoadingHeader(false);
    }
  }, []);

  const loadAdminDashData = useCallback(async () => {
    try {
      setLoadingHeader(true);
      const compareFrom = await dashboardsService.getAdminDashboard({ rangeType: COMPARING_VALUES[compare].from, isHeader: true });
      const compareTo = await dashboardsService.getAdminDashboard({ rangeType: COMPARING_VALUES[compare].to, isHeader: true });

      setHeaderData({
        current: compareFrom.length ? compareFrom[0].data : ZERO_HEADER_VALUES,
        compare: compareTo.length ? compareTo[0].data : ZERO_HEADER_VALUES
      });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoadingHeader(false);
    }
  }, [compare]);

  useEffect(() => {
    (() => {
      if (isAdminHeader) {
        loadAdminDashData();
      } else {
        loadSalesDashData();
      }
    })();
  }, [compare, loadAdminDashData, loadSalesDashData, isAdminHeader]);

  return (
    <Layout.Header className={styles.header}>
      <Row style={{ flexWrap: 'nowrap', width: '100%', height: '100%', justifyContent: 'space-betweeen' }}>
        {/* <Col style={{ width: '100%', marginRight: '15px' }}>{<NewsTicker news={news} /> }</Col> */}
        <Col span={5} className={styles.flexCenter}>
          <Row style={{ marginLeft: '10px' }} gutter={[16, 16]}>
            {isAdminHeader && (
              <>
                <Col span={24}>
                  <Row gutter={[16, 16]} style={{ height: '100%' }}>
                    <Col className={styles.alignSelfCenter}>Compare to:</Col>
                    <Col>
                      <Select placeholder="Compare to" value={compare} onChange={(value) => setCompare(value)} style={{ width: '150px' }}>
                        {generateOptionsFromObject(DASH_RANGE_TYPE_COMPARE, snakeCaseToProperCase)}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col span={19} style={{ display: 'flex' }}>
          <Row className={styles.rowStats} gutter={[20, 20]}>
            <Col className={styles.blockParent}>
              <Block
                color="#FAAD14"
                label="TOTAL SPENT"
                currentValue={headerData?.current.spent}
                compareValue={headerData?.compare?.spent}
                loading={loadingHeader}
              />
            </Col>
            <Col className={styles.blockParent}>
              <Block
                color="#1990FF"
                label="REVENUE"
                currentValue={headerData?.current.earned}
                compareValue={headerData?.compare?.earned}
                loading={loadingHeader}
              />
            </Col>
            <Col className={styles.blockParent}>
              <Block
                color="#58A54F"
                label="PROFIT"
                currentValue={headerData?.current.profit}
                compareValue={headerData?.compare?.profit}
                loading={loadingHeader}
              />
            </Col>
            <Col className={styles.blockParent}>
              <Block
                color="#58A54F"
                label="ACTIVE"
                currentValue={headerData?.current.number_of_active}
                compareValue={headerData?.compare?.number_of_active}
                loading={loadingHeader}
              />
            </Col>
            <Col className={styles.blockParent}>
              <Block
                color="#58A54F"
                label="CREATED"
                currentValue={headerData?.current.number_of_created}
                compareValue={headerData?.compare?.number_of_created}
                loading={loadingHeader}
              />
            </Col>
            <Col className={styles.blockParent}>
              <Block
                color="#FAAD14"
                label="OPTIMIZED"
                currentValue={headerData?.current.number_of_optimized}
                compareValue={headerData?.compare?.number_of_optimized}
                loading={loadingHeader}
              />
            </Col>

            <Col>{currentUser && `${currentUser.first_name} ${currentUser.last_name}`}</Col>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  );
};
