import EventEmitter from 'eventemitter3';
const eventEmitter = new EventEmitter();
export const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload)
};
Object.freeze(Emitter);

export const EMITTER_EVENTS = {
  SWITCH_ADMIN_DASH: 'switch_admin_dash'
};

export const EMITTER_FUNCTIONS = {
  SWITCH_ON_ADMIN_DASH: true, // There is variable isAdminHeader in state, we will set it TRUE
  SWITCH_OFF_ADMIN_DASH: false // There is variable isAdminHeader in state, we will set it FALSE
};
