import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import { Header } from './components/Header';
import { Table } from './components/Table';
import { AddOrEditProduct } from './components/AddOrEditProduct';

import { productsService } from '../../services/products';
import { useDebounce, useFetch } from '../../utils/hooks';
import { SortDirection } from '../../services/statistic';

export const Products = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const urlId = props.match.params.id;
  const queryId = queryString.parse(props.location.search).productId;

  const loadProducts = (cancelToken) =>
    productsService.getPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, filters, sorting, cancelToken });

  const [
    {
      data: { results: products, count: totalItems },
      isFetching
    },
    getProducts
  ] = useFetch(loadProducts);

  useEffect(() => {
    getProducts();
  }, [currentPage, filters, sorting, debouncedSearchValue, getProducts]);

  useEffect(() => setCurrentPage(1), [filters, debouncedSearchValue]);

  return (
    <div>
      {urlId ? (
        <AddOrEditProduct urlId={urlId} queryId={queryId} onSubmit={getProducts} onDelete={getProducts} />
      ) : (
        <>
          <h1>Products</h1>

          <Header filters={filters} searchValue={searchValue} onFilter={setFilters} onSearch={setSearchValue} />

          <Table
            loading={isFetching}
            products={products}
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            countries={filters.country}
            sorting={sorting}
            onPaginationChange={setCurrentPage}
            onDelete={getProducts}
            onSortingClick={setSorting}
          />
        </>
      )}
    </div>
  );
};
