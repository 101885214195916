import { Row, Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { TagListWithEditModal } from './TagListWithEditModal';
import { AutomationRulesModal } from './AutomationRulesModal';

export const AutomationRulesFieldName = 'automationRules';

export const AutomationRulesTargetingSection = ({ form, field, pathName, isEdit }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [tagLabels, setTagLabels] = useState([]);

  const handleAutomationRulesChange = useCallback(() => {
    const automationRulesValues = form.getFieldValue([...pathName, AutomationRulesFieldName]);
    if (automationRulesValues) {
      const tags = automationRulesValues.map(({ isActive, condition: { metric, operator, value } }) =>
        isActive && metric && operator && value ? `${metric}${operator}${value}` : ''
      );
      setTagLabels(tags);
    }
  }, [form, pathName]);

  useEffect(() => {
    if (isEdit) {
      handleAutomationRulesChange();
    } else {
      setModalVisible(true);
    }
  }, [isEdit, handleAutomationRulesChange]);

  return (
    <Row gutter={8} style={{ width: '100%' }}>
      <Col span={24}>
        <TagListWithEditModal tagLabels={tagLabels} onModalOpen={() => setModalVisible(true)} style={{ marginBottom: 3, width: '100%' }} />

        <AutomationRulesModal
          field={field}
          form={form}
          pathName={[...pathName, AutomationRulesFieldName]}
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
            handleAutomationRulesChange();
          }}
        />
      </Col>
    </Row>
  );
};
