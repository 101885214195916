import { getQueryString } from '../utils/query';
import { callApi } from './api';

class TestsService {
  async getAll({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/tests/${query}` });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const testsService = new TestsService();
