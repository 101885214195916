import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const ListTypes = {
  country_lists: 'country_lists',
  os_lists: 'os_lists',
  browser_lists: 'browser_lists',
  subid_lists: 'subid_lists',
  ip_lists: 'ip_lists',
  price_lists: 'price_lists_formatted',
  targeting_lists: 'targeting_lists_formatted',
  device_type_lists: 'device_type_lists'
};

export const ListTypesPlural = {
  country_lists: 'countries_list',
  os_lists: 'operating_systems_list',
  browser_lists: 'browsers_list',
  subid_lists: 'subids',
  ip_lists: 'ips',
  device_type_lists: 'device_type_list'
};

class ListsService {
  async getListsPaged({ currentPage, pageSize, searchValue, cancelToken, listType, globalList, fields, filters, sorting }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      fields,
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      global_list: globalList,
      assigned: filters.assigned,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({ url: `/api/${listType}/${query}`, cancelToken });
  }

  async getSubidLists({ fields } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/subid_lists/${query}` });
  }

  async getIpLists({ fields = 'id,name,is_default,is_default_whitelist' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/ip_lists/${query}` });
  }

  async getGlobalPriceLists() {
    return await callApi({ url: '/api/campaign_price_lists/?fields=id,name&global_list=true' });
  }

  async getFormatedPriceList(id) {
    return await callApi({ url: `/api/price_lists_formatted/${id}/` });
  }

  async getGlobalTargetingLists() {
    return await callApi({ url: '/api/targeting_lists/?fields=id,name,global_list,private&global_list=true' });
  }

  async getCountryAndCountryLists() {
    return await callApi({ url: '/api/country_lists/country_options_list/' });
  }

  async getCountryLists() {
    return await callApi({ url: '/api/country_lists/' });
  }

  async getOSAndOSLists() {
    return await callApi({ url: '/api/os_lists/os_options_list/' });
  }

  async getOSLists() {
    return await callApi({ url: '/api/os_lists/' });
  }

  async getBrowserAndBrowserLists() {
    return await callApi({ url: '/api/browser_lists/browsers_options_list/' });
  }

  async getBrowserLists() {
    return await callApi({ url: '/api/browser_lists/' });
  }

  async getDefaultIpBlockLists() {
    return await callApi({ url: '/api/media_campaigns/get_default_ip_block_lists/' });
  }

  async getDeviceTypeLists() {
    return await callApi({ url: '/api/device_type_lists/' });
  }

  async getCountryList(id) {
    return await callApi({ url: `/api/country_lists/${id}` });
  }

  async getOSList(id) {
    return await callApi({ url: `/api/os_lists/${id}` });
  }

  async getBrowserList(id) {
    return await callApi({ url: `/api/browser_lists/${id}` });
  }

  async getDeviceTypeList(id) {
    return await callApi({ url: `/api/device_type_lists/${id}` });
  }

  async getSubidList(id) {
    return await callApi({ url: `/api/subid_lists/${id}` });
  }

  exportPriceListUrl(id) {
    return `/api/price_lists_formatted/${id}/csv_export/`;
  }

  async addList(data, listType) {
    return await callApi({
      url: `/api/${listType}/`,
      method: 'POST',
      data
    });
  }

  async importPriceList(data) {
    return await callApi({
      url: '/api/price_lists_formatted/csv_import/',
      method: 'POST',
      data
    });
  }

  async editList(id, data, listType) {
    return await callApi({
      url: `/api/${listType}/${id}/`,
      method: 'PATCH',
      data
    });
  }

  async deleteList(id, listType) {
    return await callApi({
      url: `/api/${listType}/${id}/`,
      method: 'DELETE'
    });
  }

  async downloadImage(url) {
    return await callApi({
      method: 'GET',
      url,
      responseType: 'blob'
    });
  }
}

export const listsService = new ListsService();
