import React, { useEffect, useState, useCallback } from 'react';
import { CloseOutlined, DeleteOutlined, LinkOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin, DatePicker, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { Select } from '../../../components/Select';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { companiesService } from '../../../services/companies';
import { userNotificationsService } from '../../../services/user-notifications';
import { confirmClose } from '../../../utils/confirmClose';
import { useBeforeUnload } from '../../../utils/hooks/useBeforeUnload';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';
import { confirmDeleteUserNotificationModal, addLinkToDescriptionUserNotificationModal } from '../../../utils/user-notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from './UserNotificationForm.module.css';
import moment from 'moment';

const Option = Select.Option;
const { TextArea } = Input;

export const UserNotificationForm = ({ urlId, onAction, isClone, closeModal }) => {
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const [userNotification, setUserNotification] = useState();
  const [loadingUserNotification, setLoadingUserNotification] = useState(false);

  const [initialFormValues, setInitialFormValues] = useState({});

  const notificationIconsList = [
    {
      id: 1,
      value: '/resources/notification_icons/awesome-lightbulb.png',
      label: 'Light bulb'
    },
    {
      id: 2,
      value: '/resources/notification_icons/awesome-newspaper.png',
      label: 'Newspaper'
    },
    {
      id: 3,
      value: '/resources/notification_icons/icon-awesome-newspaper.png',
      label: 'Awesome newspaper'
    },
    {
      id: 4,
      value: '/resources/notification_icons/icon-awesome-newspaper2.png',
      label: 'Second awesome newspaper '
    },
    {
      id: 5,
      value: '/resources/notification_icons/metro-dollar2.png',
      label: 'Dollar'
    }
  ];

  const buttonNameList = [
    {
      id: 1,
      value: 'I Have Read Suggestions for Traffic Quality Improvements'
    },
    {
      id: 2,
      value: 'I Understand'
    },
    {
      id: 3,
      value: 'Okay'
    }
  ];

  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [wasTemporaryFormSubmited, setWasTemporaryFormSubmited] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedOfferWall, setSelectedOfferWall] = useState(false);
  const [selectedAffiliatePrograms, setSelectedAffiliatePrograms] = useState(false);
  const [selectedPublisher, setSelectedPublisher] = useState(false);
  const [selectedSearchFeedN2S, setSelectedSearchFeedN2S] = useState(false);
  const [selectedSearchFeedExtension, setSelectedSearchFeedExtension] = useState(false);
  const [isNotificationModuleSelectedError, setIsNotificationModuleSelectedError] = useState(false);

  const [selectedBroadcastNotificationType, setSelectedBroadcastNotificationType] = useState(false);
  const [selectedNotificationBarNotificationType, setSelectedNotificationBarNotificationType] = useState(false);
  const [selectedInterstitialType, setSelectedInterstitialType] = useState(false);
  const [isNotificationTypeSelectedError, setIsNotificationTypeSelectedError] = useState(false);

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [selectedAllCompanies, setSelectedAllCompanies] = useState(true);

  const [form] = Form.useForm();

  const isEdit = !!Number(urlId);
  const headingAndButtonText = `${isEdit ? 'Edit' : 'New'} Notification`;

  const history = useHistory();

  const openListPage = () => {
    history.push('/user-notifications');
    setWasFormChanged(false);
  };

  const handleClose = () => {
    wasFormChanged ? confirmClose(openListPage) : openListPage();
  };

  const handleSubmit = async (values) => {
    setWasTemporaryFormSubmited(true);
    checkNotificationModuleStatus();
    checkNotificationTypeStatus();

    if (!isNotificationModuleSelectedError && !isNotificationTypeSelectedError) {
      values.modules = [];
      values.type = [];
      if (selectedOfferWall) {
        values.modules.push(1);
      }
      if (selectedPublisher) {
        values.modules.push(2);
      }
      if (selectedSearchFeedExtension) {
        values.modules.push(3);
      }
      if (selectedSearchFeedN2S) {
        values.modules.push(4);
      }
      if (selectedAffiliatePrograms) {
        values.modules.push(5);
      }
      if (selectedBroadcastNotificationType) {
        values.type = 'Broadcast';
      }
      if (selectedNotificationBarNotificationType) {
        values.type = 'Notification Bar';
      }
      if (selectedInterstitialType) {
        values.type = 'Interstitial';
      }
    }

    if (values.end_date) {
      values.end_date = values.end_date.utc().format('YYYY-MM-DD HH:mm:ss.SSSS'); //values.end_date = values.end_date.endOf('Day').format('YYYY-MM-DD HH:mm:ss.SSSS');
    }

    if (values.start_date) {
      values.start_date = values.start_date.utc().format('YYYY-MM-DD HH:mm:ss.SSSS');
    }

    setLoading(true);
    try {
      var res;
      let formValues = { ...values };

      if (!formValues.icon) {
        formValues.icon = '';
      }

      if (selectedAllCompanies) {
        formValues = { ...formValues, all_companies: true };
        delete formValues.companies;
      }
      delete formValues.switch_companies;
      await userNotificationsService.getPermissions();
      if (userNotification && isEdit && !isClone) {
        res = await userNotificationsService.editNotification(formValues, userNotification.id);
      } else {
        res = await userNotificationsService.addNotification(formValues); //const { id } = 1; //
        if (isClone) {
          closeModal();
        }
      }

      if (res.success) {
        setTimeout(() => {
          history.push('/user-notifications'); //history.push(`/user-notifications/${res.data.id}`);
        }, 500);

        openSuccessNotification({
          message: `Successfully ${userNotification ? (!isClone ? 'edited' : 'cloned') : 'added'} notification!`,
          duration: 8
        });
      } else {
        // TODO: Remove after setting responses status on backend
        openErrorNotification({ message: res.message });
      }

      setWasFormChanged(false);
      onAction();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (isEdit) {
        try {
          setLoadingUserNotification(true);
          const userNotif = await userNotificationsService.getById({ id: urlId });
          if (userNotif) {
            const userNotifTemp = { ...userNotif, companies: [] };
            delete userNotifTemp.company;
            userNotifTemp.title = userNotif.title;
            userNotifTemp.description = userNotif.description;
            userNotifTemp.icon = userNotif.icon;
            userNotifTemp.interstitial_button_name = userNotif.interstitial_button_name;
            userNotifTemp.companies = userNotif.company;
            userNotifTemp.all_companies = userNotif.all_companies;
            userNotifTemp.end_date = moment(userNotif.end_date);
            userNotifTemp.start_date = moment(userNotif.start_date);
            setInitialFormValues(userNotifTemp);
            setSelectedAllCompanies(userNotif.all_companies);
          }
          if (userNotif.module && userNotif.module.length) {
            userNotif.module.forEach((moduleId) => {
              switch (moduleId) {
                case 1:
                  setSelectedOfferWall(true);
                  break;
                case 2:
                  setSelectedPublisher(true);
                  break;
                case 3:
                  setSelectedSearchFeedExtension(true);
                  break;
                case 4:
                  setSelectedSearchFeedN2S(true);
                  break;
                case 5:
                  setSelectedAffiliatePrograms(true);
                  break;
                default:
                  break;
              }
            });
          }
          if (userNotif.type) {
            userNotif.type === 'Broadcast'
              ? setSelectedBroadcastNotificationType(true)
              : userNotif.type === 'Notification Bar'
              ? setSelectedNotificationBarNotificationType(true)
              : setSelectedInterstitialType(true);
          }
          setUserNotification(userNotif);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setLoadingUserNotification(false);
        }
      }
    })();
  }, [isEdit, urlId, setInitialFormValues]);

  useEffect(() => {
    form.resetFields();
  }, [form, userNotification]);

  const setLinkToDescription = (values) => {
    form.setFieldsValue({ title: values.title, description: values.description });
    setWasFormChanged(true);
  };

  useBeforeUnload(wasFormChanged);

  const checkNotificationModuleStatus = () => {
    if (selectedPublisher || selectedOfferWall || selectedSearchFeedN2S || selectedSearchFeedExtension || selectedAffiliatePrograms) {
      setIsNotificationModuleSelectedError(false);
    } else {
      setIsNotificationModuleSelectedError(true);
    }
  };

  const checkNotificationTypeStatus = () => {
    selectedBroadcastNotificationType || selectedNotificationBarNotificationType || selectedInterstitialType
      ? setIsNotificationTypeSelectedError(false)
      : setIsNotificationTypeSelectedError(true);
  };

  useEffect(() => {
    if (wasTemporaryFormSubmited && isNotificationModuleSelectedError) {
      checkNotificationModuleStatus();
    }
    if (wasTemporaryFormSubmited && isNotificationTypeSelectedError) {
      checkNotificationTypeStatus();
    }
  });

  const getCompanies = useCallback(
    async ({ selectedOfferWall, selectedPublisher, selectedSearchFeedExtension, selectedSearchFeedN2S, selectedAffiliatePrograms }) => {
      try {
        setLoadingCompanies(true);
        var pub_permissions = [];
        if (selectedOfferWall) {
          pub_permissions = [...pub_permissions, 1];
        }
        if (selectedPublisher) {
          pub_permissions = [...pub_permissions, 2];
        }
        if (selectedSearchFeedExtension) {
          pub_permissions = [...pub_permissions, 3];
        }
        if (selectedSearchFeedN2S) {
          pub_permissions = [...pub_permissions, 4];
        }
        if (selectedAffiliatePrograms) {
          pub_permissions = [...pub_permissions, 5];
        }
        const companies = await companiesService.getAllByModule({ fields: 'id,name', pub_permissions });
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingCompanies(false);
      }
    },
    []
  );

  useEffect(() => {
    getCompanies({
      selectedOfferWall,
      selectedPublisher,
      selectedSearchFeedExtension,
      selectedSearchFeedN2S,
      selectedAffiliatePrograms
    });
  }, [selectedOfferWall, selectedPublisher, selectedSearchFeedExtension, selectedSearchFeedN2S, selectedAffiliatePrograms, getCompanies]);

  useEffect(() => {
    if (!selectedCompanies.company__in && initialFormValues.companies) {
      const userNotifCompanies = {};
      userNotifCompanies.company__in = initialFormValues.companies;
      setSelectedCompanies({ ...userNotifCompanies });
    }
  }, [initialFormValues, selectedCompanies, setSelectedCompanies]);

  const stylesButtonLink = {
    marginTop: '30px'
  };

  return (
    <div className={styles.addOrEditProduct}>
      {!isClone && (
        <div className={styles.titleAndCloseBtnWrapper}>
          <h1>{headingAndButtonText}</h1>
          <div className={styles.closeTabView} onClick={() => handleClose()}>
            <CloseOutlined />
          </div>
        </div>
      )}
      <Spin spinning={loadingUserNotification}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={({ errorFields }) => {
            checkNotificationModuleStatus();
            checkNotificationTypeStatus();
            setWasTemporaryFormSubmited(true);
            form.scrollToField(errorFields[0].name);
          }}
          onValuesChange={(_, allValues) => {
            let tempForm = {
              title: allValues.title,
              description: allValues.description
            };
            setInitialFormValues(tempForm);
            setWasFormChanged(true);
          }}
          initialValues={initialFormValues}
        >
          <Row gutter={32}>
            <Col span={!isClone ? 8 : 24}>
              <Form.Item
                label="Notification title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please input notification title!'
                  }
                ]}
              >
                <Input placeholder="Enter notification title" maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={!isClone ? 8 : 24}>
              <Form.Item
                label="Notification description"
                name="description"
                tooltip={{
                  title: 'Description may contain URL, which will open in a new tab.',
                  icon: <InfoCircleOutlined />
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please input notification description!'
                  }
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="This is notification description example, it can be as long as it needed. It can also contain links, such as https://link-example.com, which will open in a new tab."
                />
              </Form.Item>
            </Col>
            <Col span={!isClone ? 12 : 24}>
              <Button
                className="myButton"
                style={!isClone ? stylesButtonLink : { marginBottom: '20px' }}
                onClick={() => addLinkToDescriptionUserNotificationModal(initialFormValues, setInitialFormValues, setLinkToDescription)}
                loading={loading}
              >
                <LinkOutlined /> Add link to description
              </Button>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={!isClone ? 8 : 24}>
              <Form.Item label="Notification icon" name="icon">
                <Select
                  value={initialFormValues.icon}
                  onChange={(value) => {
                    let tempFormValues = { ...initialFormValues };
                    tempFormValues.icon = value;
                    setInitialFormValues(tempFormValues);
                  }}
                >
                  {notificationIconsList.map((el) => {
                    return (
                      <Option key={el.id} value={el.value}>
                        <img style={{ marginRight: '3px' }} src={el.value} alt="" /> {el.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Select module"
            name="modules"
            {...(isNotificationModuleSelectedError ? { validateStatus: 'error', help: 'Please select module!' } : {})}
          >
            <Row gutter={16}>
              <Col>
                <div
                  className={!selectedOfferWall ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedOfferWall(!selectedOfferWall);
                  }}
                >
                  <span>Offerwall</span>
                </div>
              </Col>
              <Col>
                <div
                  className={!selectedAffiliatePrograms ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedAffiliatePrograms(!selectedAffiliatePrograms);
                  }}
                >
                  <span>Affiliate programs</span>
                </div>
              </Col>
              <Col>
                <div
                  className={!selectedPublisher ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedPublisher(!selectedPublisher);
                  }}
                >
                  <span>Publisher</span>
                </div>
              </Col>
              <Col>
                <div
                  className={!selectedSearchFeedExtension ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedSearchFeedExtension(!selectedSearchFeedExtension);
                  }}
                >
                  <span>Search Feed Extension</span>
                </div>
              </Col>
              <Col>
                <div
                  className={!selectedSearchFeedN2S ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedSearchFeedN2S(!selectedSearchFeedN2S);
                  }}
                >
                  <span>Search Feed N2S</span>
                </div>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="Notification type"
            name="type"
            {...(isNotificationTypeSelectedError ? { validateStatus: 'error', help: 'Please select notification type!' } : {})}
          >
            <Row gutter={16}>
              <Col>
                <div
                  className={!selectedBroadcastNotificationType ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedBroadcastNotificationType(true);
                    setSelectedNotificationBarNotificationType(false);
                    setSelectedInterstitialType(false);
                  }}
                >
                  Broadcast
                </div>
              </Col>
              <Col>
                <div
                  className={!selectedNotificationBarNotificationType ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedNotificationBarNotificationType(true);
                    setSelectedBroadcastNotificationType(false);
                    setSelectedInterstitialType(false);
                  }}
                >
                  Notification Bar
                </div>
              </Col>
              <Col>
                <div
                  className={!selectedInterstitialType ? styles.methodTypeSelect : styles.methodTypeSelected}
                  onClick={() => {
                    setSelectedInterstitialType(true);
                    setSelectedNotificationBarNotificationType(false);
                    setSelectedBroadcastNotificationType(false);
                  }}
                >
                  Interstitial
                </div>
              </Col>
            </Row>
            {selectedInterstitialType && (
              <p className={styles.warningInterstitial}>Adding this notification will delete all of previous interstitial notifications!</p>
            )}
          </Form.Item>
          <Row gutter={32}>
            <Col span={!isClone ? 8 : 24}>
              <Form.Item label="Notification start date" name="start_date">
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} placeholder="Notification start date" />
              </Form.Item>
            </Col>
          </Row>
          {selectedBroadcastNotificationType && (
            <Row gutter={32}>
              <Col span={!isClone ? 8 : 24}>
                <Form.Item
                  label="Broadcast notification end date"
                  name="end_date"
                  rules={[{ required: true, message: 'Please select end date!' }]}
                >
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: '100%' }}
                    placeholder="Broadcast notification end date"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedInterstitialType && (
            <>
              <Row gutter={32}>
                <Col span={!isClone ? 8 : 24}>
                  <Form.Item
                    label="Interstitial button name"
                    name="interstitial_button_name"
                    tooltip={{
                      title: 'Chosen value will appear on button which will be used for reading interstitial notification.',
                      icon: <InfoCircleOutlined />
                    }}
                  >
                    <Select
                      defaultValue={'I Have Read Suggestions for Traffic Quality Improvements'}
                      value={initialFormValues.interstitial_button_name}
                      onChange={(value) => {
                        let tempFormValues = { ...initialFormValues };
                        tempFormValues.interstitial_button_name = value;
                        setInitialFormValues(tempFormValues);
                      }}
                    >
                      {buttonNameList.map((el) => {
                        return (
                          <Option key={el.id} value={el.value}>
                            {el.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={32}>
            <Col span={!isClone ? 8 : 24}>
              <Form.Item
                label="Display notification for"
                rules={[
                  {
                    required: !selectedAllCompanies,
                    message: 'Please select company!'
                  }
                ]}
                name="companies"
              >
                <VirtualizedSelect
                  placeholder={'Select company'}
                  multi={true}
                  style={{ width: '100%' }}
                  disabled={selectedAllCompanies}
                  loading={loadingCompanies}
                  selectValue={selectedAllCompanies ? ['all'] : selectedCompanies.company__in}
                  onChange={(value) => {
                    setSelectedCompanies({ ...selectedCompanies, company__in: value });
                  }}
                  options={selectedAllCompanies ? [{ value: 'all', label: 'All Companies' }] : companiesService.generateOptions(companies)}
                />
              </Form.Item>
            </Col>
            <Col span={!isClone ? 8 : 24}>
              <Form.Item label="Send to one/more companies">
                <Switch
                  checked={!selectedAllCompanies}
                  onChange={(value) => {
                    setSelectedAllCompanies(!value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={!isClone ? 18 : 24} style={{ textAlign: 'right' }}>
              {userNotification && !isClone ? (
                <Button
                  className="myButton"
                  type="danger"
                  ghost
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    confirmDeleteUserNotificationModal(userNotification, () => {
                      onAction();
                      openListPage();
                    })
                  }
                  loading={loading}
                >
                  <DeleteOutlined /> Delete
                </Button>
              ) : (
                ''
              )}
              <Button type="primary" htmlType="submit" className="myButton" style={{ marginLeft: 8 }} loading={loading}>
                {userNotification ? (!isClone ? 'Edit notification' : 'Clone notification') : 'Create notification'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};
