import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';
import { handleOrdering } from '../utils/functions';

class LeadSourcesService {
  async getAll({ fields = 'id,name', is_active } = {}) {
    const query = getQueryString({ fields, is_active });
    return await callApi({ url: `/api/origin/${query}` });
  }

  async getPaged({ currentPage, pageSize, searchValue, cancelToken, sorting }) {
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({ url: `/api/origin/${query}`, cancelToken });
  }

  async add(data) {
    return await callApi({ url: '/api/origin/', method: 'POST', data });
  }

  async edit(data, id) {
    return await callApi({ url: `/api/origin/${id}/`, method: 'PUT', data });
  }

  async delete(id) {
    return await callApi({ url: `/api/origin/${id}/`, method: 'DELETE' });
  }
}

export const leadSourcesService = new LeadSourcesService();
