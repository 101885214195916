import React, { useState, useEffect } from 'react';

import { Heading } from '../Heading';
import { Table } from './Table';
import { AddOrEditOSListModal } from './AddOrEditOSListModal';

import { osService } from '../../../../services/os';
import { listsService, ListTypes } from '../../../../services/lists';
import { accountsService } from '../../../../services/accounts';
import { useDebounce, useFetch } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { SortDirection } from '../../../../services/statistic';

// import styles from '../../Lists.module.css';

const listType = ListTypes.os_lists;

export const OSsTab = () => {
  const [allOSs, setAllOSs] = useState([]);
  const [allOSLoading, setAllOsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };
    const getOS = async () => {
      setAllOsLoading(true);
      try {
        const os = await osService.getAll({ fields: 'id,name,img' });
        setAllOSs(os);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllOsLoading(false);
      }
    };
    getAccounts();
    getOS();
  }, []);

  const loadOSLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      fields:
        'id,img,name,creator_details,date_created,operating_systems_list,operating_systems_details,operating_systems,assigned,exclude,private',
      filters,
      sorting
    });

  const [
    {
      data: { results: osLists, count: totalRows },
      isFetching
    },
    getOSLists
  ] = useFetch(loadOSLists);

  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getOSLists();
  }, [debouncedSearchValue, currentPage, getOSLists, filters, sorting]);

  const handleDelete = () => {
    if (osLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getOSLists();
  };

  const loader = { allAccounts: allAccountsLoading, allOSs: allOSLoading };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="OS"
        allAccounts={allAccounts}
        areAccountsLoading={allAccountsLoading}
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        allOSs={allOSs}
        allAccounts={allAccounts}
        osLists={osLists}
        loading={loading}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loader={loader}
        sorting={sorting}
        onClone={getOSLists}
        onEdit={getOSLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />

      <AddOrEditOSListModal
        visibleModal={visibleAddModal}
        allOSs={allOSs}
        allAccounts={allAccounts}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getOSLists}
        loader={loader}
      />
    </div>
  );
};
