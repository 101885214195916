import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Button, Modal, Input, Row, Col, Form } from 'antd';
import { Select } from '../../components/Select';
import {
  ArrowLeftOutlined,
  CodeFilled,
  SaveOutlined,
  Html5Filled,
  CodeOutlined,
  DesktopOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined
} from '@ant-design/icons';
import classNames from 'classnames';
import { debounce } from 'debounce';
import AceEditor from 'react-ace';
import { html_beautify, css_beautify, js_beautify } from 'js-beautify';

import styles from './Editor.module.css';
import { CreatePrelanderCampaignModal } from './components/CreatePrelanderCampaignModal';
import { Logo } from '../../components/Logo';
import { initialHead, initialHtml, initialCss, initialJs } from './utils/initialLpContent';
import { landingPagesService } from '../../services/landingPages';
import { productsService } from '../../services/products';
import { accountsService } from '../../services/accounts';
import { useMount } from '../../utils/hooks';
import { showApiErrors } from '../../utils/showApiErrors';
import { redirectTo404 } from '../../utils/redirectTo404';
import { generateSelectOptionsWithIdValues } from '../../utils/options';
import { getAccountsSelectOptions } from '../../pages/lists/utils/options';
import { Prompt } from 'react-router-dom';
import { useBeforeUnload } from '../../utils/hooks/useBeforeUnload';
import { openSuccessNotification } from '../../utils/notifications';

import ace from 'ace-builds/src-noconflict/ace';
/* eslint import/no-webpack-loader-syntax: off */
import cssWorkerUrl from 'file-loader!ace-builds/src-noconflict/worker-css';
import htmlWorkerUrl from 'file-loader!ace-builds/src-noconflict/worker-html';
import jsWorkerUrl from 'file-loader!ace-builds/src-noconflict/worker-javascript';

import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-min-noconflict/snippets/html';
import 'ace-builds/src-min-noconflict/snippets/javascript';
import 'ace-builds/src-min-noconflict/snippets/css';

ace.config.setModuleUrl('ace/mode/css_worker', cssWorkerUrl);
ace.config.setModuleUrl('ace/mode/html_worker', htmlWorkerUrl);
ace.config.setModuleUrl('ace/mode/javascript_worker', jsWorkerUrl);

const SaveButton = ({ isUpdate, loading }) => (
  <Button type="primary" loading={loading} htmlType="submit">
    <SaveOutlined /> {isUpdate ? 'Update' : 'Save'}
  </Button>
);

const modalProps = {
  footer: null,
  width: '700px',
  closable: false
};

const Editors = {
  HTML: 0,
  CSS: 1,
  JAVASCRIPT: 2
};

const States = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

const commonEditorProps = {
  theme: 'monokai',
  width: '100%',
  height: 'calc(100vh - 108px)',
  showPrintMargin: false,
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  enableSnippets: true,
  showLineNumbers: true,
  tabSize: 2
};

export const Editor = ({ history, match, location }) => {
  const [headModalVisible, setHeadModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditorExpanded, setShowEditorExpanded] = useState(false);
  const [selectedEditor, setSelectedEditor] = useState(Editors.HTML);
  const [allProducts, setAllProducts] = useState([]);
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [lpContent, setLpContent] = useState({});
  const [form] = Form.useForm();
  const [allProductsLoading, setAllProductsLoading] = useState([]);
  const [products, setProducts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [createPrelanderCampaignModalVisible, setCreatePrelanderCampaignModalVisible] = useState(false);

  const previewRef = useRef();
  const reloadPreviewRef = useRef(
    debounce(() => {
      if (previewRef.current) {
        previewRef.current.contentWindow.location.reload();
      }
    }, 1000)
  );
  const previewContentRef = useRef();
  const loadIframeRef = useRef();

  const id = match.params.id;
  const isUpdate = !!id;

  const goBack = () => history.goBack();

  const handleSave = async (values) => {
    let data = { ...values };
    data.tags = data.tags.join(',');

    try {
      setLoading(true);
      if (isUpdate) {
        await landingPagesService.edit(id, data);
        openSuccessNotification({ message: `Successfully updated landing page with ID - ${id}`, duration: 4 });
      } else {
        const lp = await landingPagesService.create(data);
        openSuccessNotification({ message: `Successfully created landing page, assigned ID - ${lp.id}`, duration: 4 });
        //wasFormChanged is async, it takes a while until it changes to false, if we exit without Timeout prompt will appear
        setTimeout(() => history.push(`/editor/${lp.id}`), 0);
      }
      setWasFormChanged(false);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const toggleHeadModal = () => setHeadModalVisible((visible) => !visible);

  const initialValues = useMemo(() => {
    if (!id) {
      return {
        title: '',
        description: '',
        tags: [],
        css: initialCss,
        js: initialJs,
        html: initialHtml,
        status: States.ACTIVE,
        head: initialHead,
        products: [],
        assigned: []
      };
    }
    return {
      title: lpContent.title,
      description: lpContent.description,
      tags: (!!lpContent.tags && lpContent.tags.split(',')) || [],
      css: css_beautify(lpContent.css),
      js: js_beautify(lpContent.js),
      html: html_beautify(lpContent.html),
      status: lpContent.status,
      head: lpContent.head,
      products: lpContent.products,
      assigned: lpContent.assigned
    };
  }, [id, lpContent]);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          setLoading(true);
          const lp = await landingPagesService.getById(id);
          setLpContent(lp);
        } catch (e) {
          if (e?.response?.status === 404) {
            redirectTo404(location, history);
          }
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [id, location, history]);

  useEffect(() => {
    (async () => {
      try {
        setAllAccountsLoading(true);
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    })();
  }, []);

  const updatePreview = useCallback(() => {
    if (previewContentRef.current) {
      previewContentRef.current.content.innerHTML = form.getFieldValue('html');
      previewContentRef.current.styles.innerHTML = form.getFieldValue('css');
    }
  }, [form]);

  useEffect(() => {
    // update ref for iframe onload event so it has fresh content from state
    loadIframeRef.current = () => {
      const doc = previewRef.current.contentDocument;
      const content = doc.getElementById('content');
      const styles = doc.getElementById('styles');
      previewContentRef.current = { content, styles };

      updatePreview();

      const body = doc.getElementsByTagName('body')[0];
      const script = doc.createElement('script');
      script.textContent = form.getFieldValue('js');
      script.type = 'text/javascript';
      body.appendChild(script);
    };
  });

  useMount(() => {
    previewRef.current.onload = () => {
      loadIframeRef.current();
    };

    (async () => {
      setAllProductsLoading(true);
      try {
        const products = await productsService.getAll();
        setAllProducts(products);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllProductsLoading(false);
      }
    })();
  });

  useEffect(() => {
    form.resetFields();
    reloadPreviewRef.current();
  }, [form, initialValues, updatePreview]);

  useBeforeUnload(wasFormChanged);

  return (
    <>
      <header>
        <Logo />
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col>
            <Button onClick={goBack}>
              <ArrowLeftOutlined /> Go back
            </Button>
          </Col>
          <Col>
            <Button className={styles.headerBtn} onClick={toggleHeadModal}>
              <CodeFilled /> Edit {'<head>'}
            </Button>
          </Col>
        </Row>
      </header>

      <Prompt when={wasFormChanged} message="" />
      <Form
        form={form}
        onValuesChange={(values) => {
          setWasFormChanged(true);
          if (values.html || values.css) {
            updatePreview();
          }
          if (values.js) {
            reloadPreviewRef.current();
          }
        }}
        initialValues={initialValues}
        onFinish={(values) => {
          handleSave(values);
        }}
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
      >
        <div className={styles.editorContainer}>
          <div className={styles.expander} onClick={() => setShowEditorExpanded((exp) => !exp)}>
            {showEditorExpanded ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <div className={classNames(styles.editorTabs, { [styles.expanded]: showEditorExpanded })}>
            <div className={styles.tabButtons}>
              <span className={selectedEditor === Editors.HTML ? styles.activeTab : ''} onClick={() => setSelectedEditor(Editors.HTML)}>
                <Html5Filled /> HTML5
              </span>
              <span className={selectedEditor === Editors.CSS ? styles.activeTab : ''} onClick={() => setSelectedEditor(Editors.CSS)}>
                <CodeOutlined /> CSS
              </span>
              <span
                className={selectedEditor === Editors.JAVASCRIPT ? styles.activeTab : ''}
                onClick={() => setSelectedEditor(Editors.JAVASCRIPT)}
              >
                <CodeOutlined /> Javascript
              </span>
            </div>
            <Modal
              visible={headModalVisible}
              onCancel={toggleHeadModal}
              wrapClassName={styles.editorModal}
              {...modalProps}
              forceRender={true}
            >
              <Form.Item name="head">
                <AceEditor mode="html" name="head-editor" {...commonEditorProps} height="400px" />
              </Form.Item>
            </Modal>

            <Form.Item name="html" style={{ display: selectedEditor !== Editors.HTML ? 'none' : 'block' }}>
              <AceEditor mode="html" name="html-editor" {...commonEditorProps} />
            </Form.Item>

            <Form.Item name="css" style={{ display: selectedEditor !== Editors.CSS ? 'none' : 'block' }}>
              <AceEditor mode="css" name="css-editor" {...commonEditorProps} />
            </Form.Item>

            <Form.Item name="js" style={{ display: selectedEditor !== Editors.JAVASCRIPT ? 'none' : 'block' }}>
              <AceEditor mode="javascript" name="javascript-editor" {...commonEditorProps} />
            </Form.Item>
          </div>

          <div className={classNames(styles.preview, { [styles.hidden]: showEditorExpanded })}>
            <iframe title="preview" src="/iframe.html" ref={previewRef} />
          </div>

          <div className={styles.lpForm}>
            <div className={styles.inputField}>
              <label>Title</label>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please input title!'
                  }
                ]}
              >
                <Input placeholder="Landing Page Title" />
              </Form.Item>
            </div>

            <div className={styles.inputField}>
              <label>Description</label>
              <Form.Item name="description">
                <Input.TextArea placeholder={'Landing Page Description'} />
              </Form.Item>
            </div>

            <div className={styles.inputField}>
              <label>Tags</label>
              <Form.Item name="tags">
                <Select mode="tags" style={{ width: '100%' }} placeholder="Tags" />
              </Form.Item>
            </div>

            <div className={styles.inputField}>
              <label>Products</label>
              <Form.Item name="products">
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Products"
                  showSearch
                  allowClear
                  optionFilterProp="data-searchvalue"
                  onChange={setProducts}
                  value={products}
                  loading={allProductsLoading}
                >
                  {generateSelectOptionsWithIdValues(allProducts)}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.inputField + ' ' + styles.statusSelect}>
              <label>Status</label>
              <Form.Item name="status">
                <Select style={{ width: '100%' }}>
                  <Select.Option value={States.ACTIVE}>Active</Select.Option>
                  <Select.Option value={States.INACTIVE}>Inactive</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className={styles.inputField}>
              <label>Assigned</label>
              <Form.Item name="assigned">
                <Select mode="multiple" placeholder="Assigned" allowClear optionFilterProp="data-searchvalue" loading={allAccountsLoading}>
                  {getAccountsSelectOptions(allAccounts)}
                </Select>
              </Form.Item>
            </div>

            {isUpdate && (
              <div>
                <Button className={styles.createPrelanderCampaignBtn} onClick={() => setCreatePrelanderCampaignModalVisible(true)}>
                  <PlusOutlined />
                  Create Prelander Campaign
                </Button>

                <CreatePrelanderCampaignModal
                  modalVisible={createPrelanderCampaignModalVisible}
                  lpId={id}
                  onCancel={() => setCreatePrelanderCampaignModalVisible(false)}
                />
              </div>
            )}

            <div className={styles.inputField + ' ' + styles.lpFormBtnsWrapper}>
              {isUpdate && (
                <Button>
                  <a href={lpContent?.lp_preview_url ?? ''} target="_blank" rel="noopener noreferrer">
                    <DesktopOutlined /> Live Preview
                  </a>
                </Button>
              )}

              <SaveButton loading={loading} isUpdate={isUpdate} />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
