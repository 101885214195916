import React, { useState } from 'react';
import { Form } from 'antd';
import { getSelectOptionsWithTooltip } from '../utils/options';
import { Select } from '../../../components/Select';
import styles from '../Lists.module.css';

export const MultipleItemsSelect = ({ form, title, name, message, allItems, pasteHandler = false, loading = false }) => {
  const [search, setSearch] = useState('');

  const onPasteHandle = (pastedValue) => {
    const pasted = pastedValue
      .split(/\n|\r/)
      .filter((el) => el)
      .map((item) => item.trim());
    let existing = form.getFieldValue(name) ? form.getFieldValue(name) : [];
    existing = existing.filter((i) => pasted.indexOf(i) === -1); // prevent adding duplicates
    form.setFieldsValue({
      [name]: [...existing, ...pasted]
    });
  };

  return (
    <div className={styles.marginBotModal}>
      <span>{title}</span>
      <br />
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: message
          }
        ]}
      >
        <Select
          mode={allItems ? 'multiple' : 'tags'}
          allowClear
          onPaste={(e) => {
            if (pasteHandler) {
              e.preventDefault();
              onPasteHandle(e.clipboardData.getData('Text'));
            }
          }}
          dropdownClassName={allItems ? styles.selectDropdownWithImg : styles.hideSelectDropdown}
          optionFilterProp="data-searchvalue"
          loading={loading}
          onSearch={(s) => setSearch(s)}
          searchValue={search}
          onInputKeyDown={(e) => {
            if (e.key === 'Enter' && !allItems) {
              // override enter key only in tags mode
              const existing = form.getFieldValue(name) ? form.getFieldValue(name) : [];
              if (existing.indexOf(search) !== -1) {
                // prevent adding duplicates
                e.stopPropagation();
                e.preventDefault();
              }
              setSearch('');
            }
          }}
        >
          {getSelectOptionsWithTooltip(allItems || [])}
        </Select>
      </Form.Item>
    </div>
  );
};
