import { Modal, Select } from 'antd';
import React from 'react';
import { getSelectOptionsWithIdValues } from '../utils/options';

export const ModalForGlobalListSelector = ({ visible, onCancel, allGlobalPriceListsLoading, allGlobalPriceLists, onChange }) => {
  const renderModal = (
    <Modal visible={true} footer={null} onCancel={onCancel} title="Please select global list">
      <Select
        placeholder="Select Global Price list"
        optionFilterProp="data-searchvalue"
        showSearch
        loading={allGlobalPriceListsLoading}
        onChange={onChange}
        style={{ width: '100%' }}
      >
        {getSelectOptionsWithIdValues(allGlobalPriceLists)}
      </Select>
    </Modal>
  );
  return <div>{visible && renderModal}</div>;
};
