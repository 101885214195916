import React from 'react';
import { FiltersForm } from './components/FiltersForm';

export const FraudReports = () => {
  return (
    <>
      <h1>Fraud Reports</h1>

      <FiltersForm />
    </>
  );
};
