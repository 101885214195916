import React from 'react';

import { AddOrEditIpAddressListModal } from './AddOrEditIpAddressListModal';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { ListTypes, ListTypesPlural } from '../../../../services/lists';

export const ActionsTableCol = ({ allAccounts, ipAddressList, onClone, onEdit, onDelete, allAccountsLoading }) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.ip_lists}
      contentForCopy={ipAddressList[ListTypesPlural.ip_lists]}
      allAccounts={allAccounts}
      list={ipAddressList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => (
        <AddOrEditIpAddressListModal {...commonProps} ipAddressList={ipAddressList} allAccountsLoading={allAccountsLoading} />
      )}
    />
  );
};
