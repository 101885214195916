import { Pagination, Table as AntTable } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

import { ActionsTableCol } from './ActionsTableCol';
import { TableTitle } from '../../../components/TableTitle';

const TitleValue = {
  Id: 'id',
  Name: 'name',
  ActiveFrom: 'active_from',
  ActiveTo: 'active_to'
};

export const Table = ({
  leadSources,
  leadSourcesLoading,
  totalItems,
  currentPage,
  pageSize,
  sorting,
  onPaginationChange,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const columns = [
    {
      title: (
        <TableTitle
          text="Id"
          value={TitleValue.Id}
          order={sorting.find((s) => s.key === TitleValue.Id)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'id'
    },
    {
      title: (
        <TableTitle
          text="Name"
          value={TitleValue.Name}
          order={sorting.find((s) => s.key === TitleValue.Name)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'name'
    },
    {
      title: (
        <TableTitle
          text="Active from"
          value={TitleValue.ActiveFrom}
          order={sorting.find((s) => s.key === TitleValue.ActiveFrom)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'active_from',
      render: (activeFrom) => dayjs(activeFrom).format('DD.MM.YYYY.'),
      align: 'center'
    },
    {
      title: (
        <TableTitle
          text="Active to"
          value={TitleValue.ActiveTo}
          order={sorting.find((s) => s.key === TitleValue.ActiveTo)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'active_to',
      render: (activeTo) => (activeTo ? dayjs(activeTo).format('DD.MM.YYYY.') : '-'),
      align: 'center'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (leadSource) => <ActionsTableCol leadSource={leadSource} onEdit={onEdit} onDelete={onDelete} />
    }
  ];

  return (
    <div>
      <AntTable
        style={{ marginTop: '3%' }}
        loading={leadSourcesLoading}
        rowKey={({ id }) => id}
        dataSource={leadSources}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
