import { useState } from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import React from 'react';
import Select from 'react-virtualized-select';
import { Spin, Tooltip } from 'antd';
import './VirtualizedSelect.css';
import { LockTwoTone } from '@ant-design/icons';

const stylesForLock = { color: '#1890ff', height: '11px', width: '12.5px', marginRight: '11px', cursor: 'pointer' };

export const VirtualizedSelect = ({
  isEdit = false,
  options,
  onChange = () => {},
  multi,
  selectValue,
  placeholder,
  diffPasteHandler = false,
  channels,
  loading = false,
  disabled = false,
  selectResetInput = false,
  optionComponent = null,
  valueRenderer = null,
  filterOptions
}) => {
  const [disabledLocal, setDisabledLocal] = useState(true);
  const disabledItem = disabled || (isEdit && disabledLocal);
  const stylesForOverlay = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '9999',
    borderRadius: '2px',
    justifyContent: 'flex-end',
    display: disabledLocal ? 'flex' : 'none',
    alignItems: 'center',
    cursor: 'not-allowed'
  };

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      onPaste={(e) => {
        if (diffPasteHandler) {
          const allChannelIds = {};
          channels.forEach((c) => {
            allChannelIds[c.channel] = c.id;
          });

          const list = e.clipboardData.getData('Text');
          const channelsToPaste = list
            .split(/,|;|\n| /)
            .map((channel) => {
              channel = channel.trim();
              return allChannelIds[channel] || null;
            })
            .filter((channel) => {
              return !!channel;
            });

          const selectedChannels = [...selectValue, ...channelsToPaste];

          const selectedChannelsWithoutDuplicates = [...new Set(selectedChannels)];

          if (channelsToPaste.length) {
            e.preventDefault();
            onChange(selectedChannelsWithoutDuplicates);
          }
        }
      }}
    >
      {/* TODO FIGURE OUT BETTER WAY FOR PASSING STATE */}
      {isEdit ? (
        <div style={stylesForOverlay}>
          <Tooltip title="Unlock to Edit">
            <LockTwoTone style={stylesForLock} onClick={() => setDisabledLocal(false)} />
          </Tooltip>
        </div>
      ) : (
        ''
      )}
      <Spin spinning={loading}>
        <Select
          options={options}
          style={{ width: '100%' }}
          closeOnSelect={!multi}
          onSelectResetsInput={selectResetInput}
          isClearable={true}
          placeholder={placeholder}
          multi={multi}
          onChange={(selection) => {
            const value = multi ? selection.map((el) => el.value) : selection ? selection.value : selection;
            onChange(value);
          }}
          value={selectValue}
          className={
            isEdit && disabledLocal ? 'custom-css-virtualized-select custom-css-for-removing-indicators' : 'custom-css-virtualized-select'
          }
          disabled={disabledItem}
          optionRenderer={optionComponent}
          valueRenderer={valueRenderer}
          autoBlur={!multi}
          filterOptions={filterOptions}
        />
      </Spin>
    </div>
  );
};
