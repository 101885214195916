import React from 'react';
import { Modal } from 'antd';
import { UserNotificationForm } from './UserNotificationForm';

export const CloneNotificationModal = ({
  notificationId,
  modalVisible,
  onAction,
  onClose
}) => {

  const titleAndButtonText = 'Clone notification';

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      centered
      width={600}
      title={titleAndButtonText}
      footer={false}
      visible={modalVisible}
      onCancel={handleCancel}
    >
      <UserNotificationForm
        urlId={notificationId}
        onAction={onAction}
        isClone={true}
        closeModal={onClose}
      />
    </Modal>
  );
};
