import React, { useState } from 'react';
import { Modal, Popover } from 'antd';

import { AddOrEditModal } from './AddOrEditModal';

import { externalURLsServices } from '../../../services/external-urls';

import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';

import styles from '../ExternalURLs.module.css';

export const ActionsTableCol = ({ externalURLData, onEdit, onDelete }) => {
  const [visibleEditModal, setVisibleEditModal] = useState(false);

  const handleOpenEditModal = () => setVisibleEditModal(true);

  const handleCloseEditModal = () => setVisibleEditModal(false);

  const handleDelete = async (id) => {
    try {
      await externalURLsServices.deleteExternalURL(id);
      openSuccessNotification({ message: 'External URL successfully deleted!' });
      await onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDelete = () => {
    const { name, id } = externalURLData;

    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleDelete(id),
      onCancel() {}
    });
  };

  return (
    <div className={styles.actions}>
      <Popover content="Edit" trigger="hover">
        <div onClick={handleOpenEditModal}>
          <img src="/images/edit_icon.svg" alt="Edit" width="18px" />
        </div>
      </Popover>

      <Popover content="Delete" trigger="hover">
        <div onClick={confirmDelete}>
          <img src="/images/delete_icon.png" alt="Delete" width="21px" />
        </div>
      </Popover>

      <AddOrEditModal
        visibleModal={visibleEditModal}
        externalURLData={externalURLData}
        onCloseModal={handleCloseEditModal}
        onEdit={onEdit}
      />
    </div>
  );
};
