import { mediaCampaignsService } from '../../../services/media-campaigns';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';

const getMessagePart = (title) => `${typeof title === 'string' ? 'Campaign' : 'Campaigns'} ${title} successfully`;

export const activateCampaign = async (id, title, onActivate) => {
  try {
    await mediaCampaignsService.activateCampaign(id);
    openSuccessNotification({ message: `${getMessagePart(title)} activated!`, duration: 4 });
    onActivate();
  } catch (e) {
    showApiErrors(e);
  }
};

export const pauseCampaign = async (id, title, onPause) => {
  try {
    await mediaCampaignsService.pauseCampaign(id);
    openSuccessNotification({ message: `${getMessagePart(title)} paused!`, duration: 4 });
    onPause();
  } catch (e) {
    showApiErrors(e);
  }
};

export const suspendCampaigns = async (ids, emailData) => {
  try {
    const data = {
      ids,
      campaign_pub_module: emailData.notify_on_mail ? emailData.campaign_pub_module : undefined
    };
    await mediaCampaignsService.suspendCampaigns(data);
  } catch (e) {
    throw e;
  }
};

export const deleteCampaign = async (id, title, onDelete) => {
  try {
    await mediaCampaignsService.deleteCampaign(id);
    openSuccessNotification({ message: `${getMessagePart(title)} deleted!`, duration: 4 });
    onDelete();
  } catch (e) {
    showApiErrors(e);
  }
};
