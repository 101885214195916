import React, { useState, useEffect } from 'react';

import { Heading } from './components/Heading';
import { Table } from './components/Table';
import { AddOrEditModal } from './components/AddOrEditModal';

import { externalURLsServices } from '../../services/external-urls';

import { useDebounce, useFetch } from '../../utils/hooks';
import { SortDirection } from '../../services/statistic';

export const ExternalURLs = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'id', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadExternalURLsData = (cancelToken) =>
    externalURLsServices.getExternalURLsDataPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: externalURLsData, count: totalRows },
      isFetching
    },
    getExternalURLsData
  ] = useFetch(loadExternalURLsData);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, pageSize, sorting]);

  useEffect(() => {
    getExternalURLsData();
  }, [currentPage, pageSize, sorting, getExternalURLsData, debouncedSearchValue]);

  const handleSearch = (searchValue) => setSearchValue(searchValue);

  const handleOpenAddModal = () => setVisibleAddModal(true);

  const handleCloseAddModal = () => setVisibleAddModal(false);

  const handlePaginationChange = (currentPage, pageSize) => {
    setCurrentPage(currentPage);
    setPageSize(pageSize);
  };

  const handleDelete = () => {
    if (externalURLsData.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getExternalURLsData();
  };

  return (
    <div>
      <Heading searchValue={searchValue} onSearch={handleSearch} onCreateExternalURL={handleOpenAddModal} />

      <Table
        externalURLsData={externalURLsData}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={isFetching}
        sorting={sorting}
        onPaginationChange={handlePaginationChange}
        onEdit={getExternalURLsData}
        onDelete={handleDelete}
        onSortingClick={setSorting}
      />

      <AddOrEditModal visibleModal={visibleAddModal} onCloseModal={handleCloseAddModal} onAdd={getExternalURLsData} />
    </div>
  );
};
