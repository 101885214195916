import { useForm as useAntdForm } from 'antd/lib/form/Form';
import { useMemo } from 'react';

export function useForm(changeCallback, form) {
  const [antdForm] = useAntdForm();

  let formInstance = form ?? antdForm;

  const wrapForm = useMemo(
    () => ({
      ...formInstance,
      resetFields: (fields) => {
        formInstance.resetFields(fields);
        changeCallback();
      },
      setFields: (fields) => {
        formInstance.setFields(fields);
        changeCallback();
      },
      setFieldsValue: (values) => {
        formInstance.setFieldsValue(values);
        changeCallback();
      }
    }),
    [formInstance, changeCallback]
  );

  return [wrapForm];
}
