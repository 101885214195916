import { Popover } from 'antd';
import React, { useState } from 'react';

import styles from './ActionsTableCol.module.css';
import { AddOrEdit } from './AddOrEdit';

export const ActionsTableCol = ({ companyTag, onEdit, onDelete }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <div className={styles.actionsWrapper}>
      <Popover content="Edit" trigger="hover">
        <div className={styles.action} onClick={handleOpenModal}>
          <img src="/images/edit_icon.svg" alt="Edit" width="18px" />
        </div>
      </Popover>

      <AddOrEdit
        modalVisible={modalVisible}
        companyTag={companyTag}
        onClose={handleCloseModal}
        onEdit={() => {
          onEdit();
          handleCloseModal();
        }}
      />
    </div>
  );
};
