import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { landingPagesService } from '../../../services/landingPages';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';

import styles from './UploadImageModal.module.css';

const { Dragger } = Upload;

export const UploadImageModal = ({ modalVisible, lpId, onCancel, onUpload }) => {
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [form] = Form.useForm();

  const titleAndButtonText = 'Upload Image';

  const handleCancel = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const handleUpload = async ({ image }) => {
    try {
      setUploading(true);
      await landingPagesService.uploadLpImage(lpId, image);
      openSuccessNotification({ message: 'Successfully uploaded image!' });
      onUpload();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setUploading(true);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      form.resetFields();
    }
  }, [form, modalVisible]);

  return (
    <Modal
      title={titleAndButtonText}
      visible={modalVisible}
      wrapClassName={styles.uploadImageModal}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={uploading} onClick={() => form.submit()}>
          {titleAndButtonText}
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={handleUpload}
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <Form.Item
          name="image"
          valuePropName="file"
          getValueFromEvent={(e) => e?.file}
          rules={[{ required: true, message: 'Please upload image!' }]}
        >
          <Dragger multiple={false} listType="picture" beforeUpload={() => false}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};
