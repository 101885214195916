import React, { useState, useEffect } from 'react';
import { Row, Col, Button, DatePicker, Divider } from 'antd';
import { companiesService } from '../../services/companies';
import { mediaCampaignsService } from '../../services/media-campaigns';
import { VirtualizedSelect } from '../../components/VirtualizedSelect';
import styles from './S2SLog.module.css';
import moment from 'moment';
import { showApiErrors } from '../../utils/showApiErrors';

const pixels = [
  {
    label: 'Fired',
    value: 'pf'
  },
  {
    label: 'PF 1',
    value: 'f1'
  },
  {
    label: 'PF 2',
    value: 'f2'
  },
  {
    label: 'PF 3',
    value: 'f3'
  },
  {
    label: 'PF 4',
    value: 'f4'
  },
  {
    label: 'PF 5',
    value: 'f5'
  },
  {
    label: 'PF 6',
    value: 'f6'
  },
  {
    label: 'PF 7',
    value: 'f7'
  },
  {
    label: 'PF 8',
    value: 'f8'
  }
];

export const Header = ({ onExport, loading }) => {
  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(true);

  const [channels, setChannels] = useState([]);
  const [channelsLoading, setChannelsLoading] = useState(true);

  // const [pixels, setPixels] = useState([]);
  // const [pixelsLoading, setPixelsLoading] = useState(true);

  const [filters, setFilters] = useState({
    dateRange: {
      from: moment(),
      to: moment()
    }
  });

  const rangeData = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 2 Days': [moment().subtract(1, 'days'), moment()],
    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(30, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
  };
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const getChannels = async () => {
      try {
        setChannelsLoading(true);
        const channels = await mediaCampaignsService.getCampaigns();
        setChannels(channels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setChannelsLoading(false);
      }
    };
    const getCompanies = async () => {
      try {
        setCompaniesLoading(true);
        const companies = await companiesService.getAll({ assigned_type: 'traffic' });
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCompaniesLoading(false);
      }
    };

    getChannels();
    getCompanies();
  }, []);

  return (
    <div>
      <Row gutter={24}>
        <Col>
          <span className={styles.spanMarginBottom}>Date range </span>
          <div style={{ width: '100%' }}>
            <RangePicker
              format="DD.MM.YYYY"
              ranges={rangeData}
              onChange={(momentDates, dates) => {
                setFilters(() => ({
                  ...filters,
                  dateRange: {
                    from: momentDates[0],
                    to: momentDates[1]
                  }
                }));
              }}
              value={[moment(filters.dateRange.from), moment(filters.dateRange.to)]}
            />
          </div>
        </Col>
      </Row>

      <Divider />

      <Row gutter={24} className={styles.filtersWrapper}>
        <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={4}>
          <span className={styles.spanMarginBottom}>Company </span>
          <VirtualizedSelect
            options={companiesService.generateOptions(companies)}
            onChange={(value) => {
              setFilters({ ...filters, companies: value });
            }}
            multi={true}
            selectValue={filters.companies}
            placeholder={'Select company'}
            loading={companiesLoading}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={4}>
          <span className={styles.spanMarginBottom}>Channel</span>
          <VirtualizedSelect
            options={mediaCampaignsService.generateOptions(channels)}
            onChange={(value) => {
              setFilters({ ...filters, campaigns: value });
            }}
            multi={true}
            selectValue={filters.campaigns}
            placeholder={'Select channels'}
            loading={channelsLoading}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={4}>
          <span className={styles.spanMarginBottom}>Pixel</span>
          <VirtualizedSelect
            options={pixels}
            onChange={(value) => {
              setFilters({ ...filters, pixel: value });
            }}
            // multi={true}
            selectValue={filters.pixel}
            placeholder={'Select pixel'}
            // loading={pixelsLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button type="primary" loading={loading} onClick={() => onExport(filters)}>
            Export
          </Button>
        </Col>{' '}
      </Row>
    </div>
  );
};
