import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { accountsService } from '../../services/accounts';
import { countriesService } from '../../services/countries';
import { companiesService, COMPANY_TYPE, STATUS } from '../../services/companies';
import { Header } from './components/Header';
import { Table } from './components/Table';
import { openSuccessNotification } from '../../utils/notifications';
import { useDebounce, useEffectTrigger, useFetch } from '../../utils/hooks';
import { showApiErrors } from '../../utils/showApiErrors';
import { usePromise, useUpdateEffect } from 'react-use';
import { SortDirection } from '../../services/statistic';

export const Companies = ({ match }) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesLoading, setAllCountriesLoading] = useState(false);
  const [allPublisherCategories, setAllPublisherCategories] = useState([]);
  const [allAdvertiserCategories, setAllAdvertiserCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [tags, setTags] = useState([]);
  const [status, setStatus] = useState(STATUS.ACTIVE);
  const [publisherCategories, setPublisherCategories] = useState([]);
  const [advertiserCategories, setAdvertiserCategories] = useState([]);
  const [complexFilter, setComplexFilter] = useState(undefined);
  const [activity, setActivity] = useState(true);
  const [accountManager, setAccountManager] = useState();
  const [broughtByManagers, setBroughtByManagers] = useState();
  const [showAllCompaniesTrigger, activateShowAllCompaniesTrigger] = useEffectTrigger();

  const utcOffset = -new Date().getTimezoneOffset() / 60;
  const [broughtByDate, setBroughtByDate] = useState({
    broughtByDateRange: {
      from: moment()?.utcOffset(utcOffset, true),
      to: moment()?.utcOffset(utcOffset, true)
    }
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sorting, setSorting] = useState([{ key: 'id', value: SortDirection.Descending }]);

  const mounted = usePromise();

  const urlId = match.params.id;

  useEffect(() => {
    const getUsers = async () => {
      setUsersLoading(true);
      try {
        const users = await mounted(accountsService.getAll());
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    };

    const getCountries = async () => {
      setAllCountriesLoading(true);
      try {
        const countries = await mounted(countriesService.getAll({ fields: 'id,name,code2,img' }));
        setCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllCountriesLoading(false);
      }
    };

    const getCategories = async () => {
      try {
        setCategoriesLoading(true);
        const categories = await mounted(companiesService.getCategories());
        setAllPublisherCategories(categories.filter(({ type }) => type === COMPANY_TYPE.PUBLISHER.toLowerCase()));
        setAllAdvertiserCategories(categories.filter(({ type }) => type === COMPANY_TYPE.ADVERTISER.toLowerCase()));
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCategoriesLoading(false);
      }
    };

    getUsers();
    getCountries();
    getCategories();
  }, [mounted]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadCompanies = (cancelToken) =>
    companiesService.getPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      status,
      tags,
      complexFilter,
      publisherCategories,
      advertiserCategories,
      activity,
      accountManager,
      broughtByManagers,
      broughtByDateRange: {
        from: broughtByDate.from,
        to: broughtByDate.to
      },
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: companies, count: totalItems },
      isFetching
    },
    getCompanies
  ] = useFetch(loadCompanies);

  const loading = isFetching || isRequesting;

  useEffect(
    () => setCurrentPage(1),
    [
      debouncedSearchValue,
      status,
      publisherCategories,
      advertiserCategories,
      tags,
      activity,
      accountManager,
      broughtByManagers,
      broughtByDate,
      complexFilter,
      sorting
    ]
  );

  useEffect(() => {
    getCompanies();
  }, [
    debouncedSearchValue,
    currentPage,
    getCompanies,
    status,
    publisherCategories,
    advertiserCategories,
    tags,
    activity,
    accountManager,
    broughtByManagers,
    broughtByDate,
    complexFilter,
    sorting
  ]);

  useEffect(() => {
    if (urlId) {
      setSearchValue(urlId);
    }
  }, [urlId]);

  const clearFilters = () => {
    setSearchValue('');
    setTags([]);
    setBroughtByManagers([]);
    setBroughtByDate([]);
    setStatus(undefined);
    setPublisherCategories([]);
    setAdvertiserCategories([]);
    setComplexFilter(undefined);
    setActivity(undefined);
  };

  useUpdateEffect(() => {
    clearFilters();
  }, [showAllCompaniesTrigger]);

  const onDelete = async (id) => {
    setIsRequesting(true);
    try {
      await companiesService.delete(id);
      openSuccessNotification({ message: 'Successfully deleted', duration: 8 });
      if (companies.length === 1 && !isFetching && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      await getCompanies();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setIsRequesting(false);
    }
  };

  const loader = { users: usersLoading, countries: countriesLoading };
  return (
    <div>
      <h1>Companies</h1>

      <Header
        countries={countries}
        users={users}
        onCompanyAdded={getCompanies}
        loader={loader}
        searchValue={searchValue}
        publisherCategories={publisherCategories}
        advertiserCategories={advertiserCategories}
        selectedTags={tags}
        status={status}
        activity={activity}
        selectedAccountManager={accountManager}
        selectedBroughtByManagers={broughtByManagers}
        broughtByDate={broughtByDate}
        clearFiltersTrigger={showAllCompaniesTrigger}
        categoriesLoading={categoriesLoading}
        allPublisherCategories={allPublisherCategories}
        allAdvertiserCategories={allAdvertiserCategories}
        onSearch={(search) => setSearchValue(search)}
        onTagsChange={setTags}
        onStatusChange={(value) => setStatus(value)}
        onActivityChange={(value) => setActivity(value)}
        onClearFiltersActivate={activateShowAllCompaniesTrigger}
        onPublisherCategoryChange={(value) => setPublisherCategories(value)}
        onAdvertiserCategoryChange={(value) => setAdvertiserCategories(value)}
        onAdvancedFiltering={setComplexFilter}
        onAccountManagerFilter={(accountManager) => setAccountManager(accountManager)}
        onBroughtByManagersChange={setBroughtByManagers}
        onBroughtByDateChange={setBroughtByDate}
      />

      <Table
        companies={companies}
        loading={loading}
        users={users}
        loader={loader}
        countries={countries}
        onDelete={onDelete}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        categoriesLoading={categoriesLoading}
        allPublisherCategories={allPublisherCategories}
        allAdvertiserCategories={allAdvertiserCategories}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onEdit={getCompanies}
        onReject={getCompanies}
        onSuspend={getCompanies}
        onSortingClick={setSorting}
      />
    </div>
  );
};
