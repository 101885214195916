import React, { useState, useEffect } from 'react';

import { landingPagesService } from '../../services/landingPages';
import { accountsService } from '../../services/accounts';
import { Header } from './components/Header';
import { Table } from './components/Table';
import { openSuccessNotification } from '../../utils/notifications';
import { useDebounce, useFetch } from '../../utils/hooks';
import { showApiErrors } from '../../utils/showApiErrors';
import { SortDirection } from '../../services/statistic';
export const LandingPages = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sorting, setSorting] = useState([{ key: 'id', value: SortDirection.Descending }]);

  useEffect(() => {
    const getUsers = async () => {
      setUsersLoading(true);
      try {
        const users = await accountsService.getAll();
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    };

    getUsers();
  }, []);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadLandingPages = (cancelToken) =>
    landingPagesService.getPaged({
      currentPage,
      pageSize,
      searchValue,
      filters,
      cancelToken,
      fields: 'id,title,tags,creator,date_created,date_edited,status,lp_preview_url,uploaded_image_url',
      sorting
    });

  const [
    {
      data: { results: landingPages, count: totalItems },
      isFetching
    },
    getLandingPages
  ] = useFetch(loadLandingPages);

  const loading = isFetching || isRequesting;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters, sorting]);

  useEffect(() => {
    getLandingPages();
  }, [debouncedSearchValue, currentPage, filters, sorting, getLandingPages]);

  const onDelete = async (id) => {
    setIsRequesting(true);
    try {
      const res = await landingPagesService.delete(id);
      if (res.success === false) {
        throw new Error(res.message);
      }
      openSuccessNotification({ message: `Successfully deleted landing page with ID - ${id}`, duration: 8 });
      if (landingPages.length === 1 && !isFetching && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      await getLandingPages();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setIsRequesting(false);
    }
  };

  const onClone = async (id) => {
    setIsRequesting(true);
    try {
      await landingPagesService.clone(id);
      openSuccessNotification({ message: `Successfully cloned landing page with ID - ${id}`, duration: 8 });
      await getLandingPages();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <div>
      <h1>Landing Pages</h1>

      <Header filters={filters} onFilterChange={setFilters} onSearch={(search) => setSearchValue(search)} />

      <Table
        landingPages={landingPages}
        loading={loading}
        users={users}
        usersLoading={usersLoading}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onClone={onClone}
        onUpload={getLandingPages}
        onDelete={onDelete}
        onSortingClick={setSorting}
      />
    </div>
  );
};
