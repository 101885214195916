import React from 'react';

import { ListTypes } from '../../../../services/lists';
import { AddOrEditListModal } from '../AddOrEditListModal';
import { MultipleItemsSelect } from '../MultipleItemsSelect';

export const AddOrEditBrowserListModal = ({
  visibleModal,
  allBrowsers,
  allAccounts,
  browserList,
  isClone,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  loader
}) => {
  const fieldName = 'browsers';

  const renderItemsSelect = () => (
    <MultipleItemsSelect
      loading={loader.allBrowsers}
      title="List of Browsers"
      name={fieldName}
      message="Please choose browser/s!"
      allItems={allBrowsers}
    />
  );

  return (
    <AddOrEditListModal
      visibleModal={visibleModal}
      allAccounts={allAccounts}
      onCloseModal={onCloseModal}
      onClone={onClone}
      onEdit={onEdit}
      onAdd={onAdd}
      list={browserList}
      listType={ListTypes.browser_lists}
      itemsFieldName={fieldName}
      title="Browser List"
      nameFieldTitle="Browser List Name"
      renderItemsSelect={renderItemsSelect}
      isClone={isClone}
      areAccountsLoading={loader.allAccounts}
    />
  );
};
