import React, { useState, useEffect, useMemo } from 'react';

import { Header } from './components/Header';
import { Table } from './components/Table';

import { gpssService } from '../../services/gpss';
import { useDebounce, useFetch } from '../../utils/hooks';
import { SortDirection } from '../../services/statistic';
import { getCountries, useCustomQuery } from '../../utils/hooks/queries';

export const GPSS = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState([{ key: 'date_edited', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadGPSSRules = (cancelToken) =>
    gpssService.getPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, filters, sorting, cancelToken });

  const [
    {
      data: { results: rules, count: totalItems },
      isFetching
    },
    getGPSSRules
  ] = useFetch(loadGPSSRules);

  const {
    isFetching: allCountriesRefetching,
    isLoading: allCountriesLoading,

    data: allCountries
  } = useCustomQuery(getCountries);
  const filteredCountries = useMemo(() => {
    const indexOfAny = allCountries.findIndex(({ id }) => id === 1);
    const allCountriesCopy = [...allCountries];
    allCountriesCopy.splice(indexOfAny, 1);
    return allCountriesCopy;
  }, [allCountries]);

  useEffect(() => {
    getGPSSRules();
  }, [currentPage, filters, sorting, debouncedSearchValue, getGPSSRules]);

  useEffect(() => setCurrentPage(1), [filters, debouncedSearchValue, sorting]);

  return (
    <div>
      <h1>GPSS</h1>
      <Header
        filters={filters}
        searchValue={searchValue}
        countries={filteredCountries}
        countriesLoading={allCountriesLoading}
        countriesRefetching={allCountriesRefetching}
        onFilter={setFilters}
        onSearch={setSearchValue}
        onAdd={getGPSSRules}
      />
      <Table
        rulesLoading={isFetching}
        rules={rules}
        countries={filteredCountries}
        countriesLoading={allCountriesLoading}
        countriesRefetching={allCountriesRefetching}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onClone={getGPSSRules}
        onEdit={getGPSSRules}
        onDelete={getGPSSRules}
        onSortingClick={setSorting}
      />
    </div>
  );
};
