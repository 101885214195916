import React from 'react';

import { ListTypes, ListTypesPlural } from '../../../../services/lists';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { AddOrEditDeviceTypeListModal } from './AddOrEditDeviceTypeListModal';

export const ActionsTableCol = ({ allDeviceTypes, allAccounts, deviceTypeList, onClone, onEdit, onDelete, loader }) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.device_type_lists}
      contentForCopy={deviceTypeList[ListTypesPlural.device_type_lists]}
      allAccounts={allAccounts}
      list={deviceTypeList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => (
        <AddOrEditDeviceTypeListModal {...commonProps} allDeviceTypes={allDeviceTypes} deviceTypeList={deviceTypeList} loader={loader} />
      )}
    />
  );
};
