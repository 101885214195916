import { Dropdown, Menu, Pagination, Row, Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TableTitle } from '../../components/TableTitle';
import { statisticServices, allChannelStatColumns, SortDirection } from '../../services/statistic';
import { useFetch } from '../../utils/hooks/useFetch';
import { openWarningNotification } from '../../utils/notifications';
import { showApiErrors } from '../../utils/showApiErrors';
import styles from './ChannelStats.module.css';
import { Filters } from './components/Filters';

export const Channels = () => {
  const [allColumns, setAllColumns] = useState(allChannelStatColumns);
  const [customColumns, setCustomColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loadingExportButton, setLoadingExportButton] = useState(false);

  const pageSizeOptions = [20, 40, 60, 80];

  const [sorting, setSorting] = useState([]);

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      if (sumOfData[0]) {
        let sumOfDataRefactored = { ...sumOfData[0] };
        if (sumOfData[0].spent) {
          sumOfDataRefactored.spent = '$ ' + parseFloat(sumOfData[0].spent).toFixed(2);
        }
        if (sumOfData[0].earned) {
          sumOfDataRefactored.earned = '$ ' + parseFloat(sumOfData[0].earned).toFixed(2);
        }
        if (sumOfData[0].roi) {
          sumOfDataRefactored.roi = parseFloat(sumOfData[0].roi).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr) {
          sumOfDataRefactored.cr = parseFloat(sumOfData[0].cr).toFixed(2) + ' %';
        }
        if (sumOfData[0].ctr) {
          sumOfDataRefactored.ctr = parseFloat(sumOfData[0].ctr).toFixed(2) + ' %';
        }
        if (sumOfData[0].profit) {
          sumOfDataRefactored.profit = '$ ' + parseFloat(sumOfData[0].profit).toFixed(2);
        }
        if (sumOfData[0].cpm) {
          sumOfDataRefactored.cpm = '$ ' + parseFloat(sumOfData[0].cpm).toFixed(2);
        }
        if (sumOfData[0].rpm) {
          sumOfDataRefactored.rpm = '$ ' + parseFloat(sumOfData[0].rpm).toFixed(2);
        }
        if (sumOfData[0].rpl) {
          sumOfDataRefactored.rpl = '$ ' + parseFloat(sumOfData[0].rpl).toFixed(2);
        }
        if (sumOfData[0].partner_rpl) {
          sumOfDataRefactored.partner_rpl = '$ ' + parseFloat(sumOfData[0].partner_rpl).toFixed(2);
        }
        if (sumOfData[0].ecpa) {
          sumOfDataRefactored.ecpa = '$ ' + parseFloat(sumOfData[0].ecpa).toFixed(3);
        }
        if (sumOfData[0].pl__earned) {
          sumOfDataRefactored.pl__earned = '$ ' + parseFloat(sumOfData[0].pl__earned).toFixed(2);
        }
        if (sumOfData[0].p1__earned) {
          sumOfDataRefactored.p1__earned = '$ ' + parseFloat(sumOfData[0].p1__earned).toFixed(2);
        }
        if (sumOfData[0].p2__earned) {
          sumOfDataRefactored.p2__earned = parseFloat(sumOfData[0].p2__earned).toFixed(2) + ' %';
        }
        if (sumOfData[0].p3__earned) {
          sumOfDataRefactored.p3__earned = parseFloat(sumOfData[0].p3__earned).toFixed(2) + ' %';
        }
        if (sumOfData[0].p4__earned) {
          sumOfDataRefactored.p4__earned = parseFloat(sumOfData[0].p4__earned).toFixed(2) + ' %';
        }
        if (sumOfData[0].p5__earned) {
          sumOfDataRefactored.p5__earned = '$ ' + parseFloat(sumOfData[0].p5__earned).toFixed(2);
        }
        if (sumOfData[0].p6__earned) {
          sumOfDataRefactored.p6__earned = '$ ' + parseFloat(sumOfData[0].p6__earned).toFixed(2);
        }
        if (sumOfData[0].p7__earned) {
          sumOfDataRefactored.p7__earned = '$ ' + parseFloat(sumOfData[0].p7__earned).toFixed(2);
        }
        if (sumOfData[0].p8__earned) {
          sumOfDataRefactored.p8__earned = '$ ' + parseFloat(sumOfData[0].p8__earned).toFixed(2);
        }
        if (sumOfData[0].wp__earned) {
          sumOfDataRefactored.wp__earned = '$ ' + parseFloat(sumOfData[0].wp__earned).toFixed(2);
        }
        if (sumOfData[0].wp_loop__earned) {
          sumOfDataRefactored.wp_loop__earned = '$ ' + parseFloat(sumOfData[0].wp_loop__earned).toFixed(2);
        }
        if (sumOfData[0].search_rpl) {
          sumOfDataRefactored.search_rpl = '$ ' + parseFloat(sumOfData[0].search_rpl).toFixed(2);
        }
        if (sumOfData[0].rpl_px8) {
          sumOfDataRefactored.rpl_px8 = '$ ' + parseFloat(sumOfData[0].rpl_px8).toFixed(2);
        }
        if (sumOfData[0].cr_px1) {
          sumOfDataRefactored.cr_px1 = parseFloat(sumOfData[0].cr_px1).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px2) {
          sumOfDataRefactored.cr_px2 = parseFloat(sumOfData[0].cr_px2).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px3) {
          sumOfDataRefactored.cr_px3 = parseFloat(sumOfData[0].cr_px3).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px4) {
          sumOfDataRefactored.cr_px4 = parseFloat(sumOfData[0].cr_px4).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px5) {
          sumOfDataRefactored.cr_px5 = parseFloat(sumOfData[0].cr_px5).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px6) {
          sumOfDataRefactored.cr_px6 = parseFloat(sumOfData[0].cr_px6).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px7) {
          sumOfDataRefactored.cr_px7 = parseFloat(sumOfData[0].cr_px7).toFixed(2) + ' %';
        }
        if (sumOfData[0].cr_px8) {
          sumOfDataRefactored.cr_px8 = parseFloat(sumOfData[0].cr_px8).toFixed(2) + ' %';
        }
        if (sumOfData[0].ecpa_px5) {
          sumOfDataRefactored.ecpa_px5 = '$ ' + parseFloat(sumOfData[0].ecpa_px5).toFixed(3);
        }
        if (sumOfData[0].cr_px5_px6) {
          sumOfDataRefactored.cr_px5_px6 = parseFloat(sumOfData[0].cr_px5_px6).toFixed(2) + ' %';
        }
        return sumOfDataRefactored;
      }
    }
  };

  const refactorTableData = () => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          if (refactor.channel) {
            refactor.channel = (
              <div>
                <Dropdown
                  overlayStyle={{ width: '200px' }}
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={`/media-campaigns/${refactor.campaign_id}`} target="_blank">
                          Edit channel
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Link target="_blank" to={`/media-campaigns/clone?campaignId=${refactor.campaign_id}`}>
                          Clone channel
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <Link to={`/stubs/channel_chart/?channel=${refactor.campaign_id}`}>Graph view</Link>
                      </Menu.Item>
                      <Menu.Item key="4">
                        <Link
                          target="_blank"
                          to={`/stubs/channel_stats/?channel=${refactor.campaign_id}&date_from=${selectedFilters.dateRange.from.format(
                            'YYYY-MM-DD'
                          )}&date_to=${selectedFilters.dateRange.to.format(
                            'YYYY-MM-DD'
                          )}&columns=channel,lp_id,product_title,im,dw,pl,pf,spent,earned,profit,roi,rpm,cr`}
                        >
                          Performance check
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="5">
                        <Link
                          target="_blank"
                          to={`/stubs/channel_stats/?channel=${
                            refactor.campaign_id
                          }&columns=channel,country,im,dw,pl,pf,spent,earned,profit,roi,cr,pcr&date_from=${selectedFilters.dateRange.from.format(
                            'YYYY-MM-DD'
                          )}&date_to=${selectedFilters.dateRange.to.format('YYYY-MM-DD')}`}
                        >
                          Country breakdown{' '}
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <img src="/images/plus.png" alt="Delete" width="21px" />
                  </span>
                </Dropdown>
                &nbsp; {refactor.channel}
              </div>
            );
          }
          if (refactor.country) {
            refactor.country = (
              <Row>
                <img
                  style={{ alignSelf: 'center' }}
                  alt={refactor.country}
                  src={`/media/countries/${refactor.country.toLowerCase()}.png`}
                />
                <span>&nbsp;{refactor.country}</span>
              </Row>
            );
          }
          refactor.creator_first_name = refactor.creator_first_name + ' ' + refactor.creator_last_name;
          refactor.prod_brought_by_first_name = refactor.prod_brought_by_first_name + ' ' + refactor.prod_brought_by_last_name;
          refactor.adv_brought_by_first_name = refactor.adv_brought_by_first_name + ' ' + refactor.adv_brought_by_last_name;
          refactor.earned = refactor.earned && '$ ' + refactor.earned.toFixed(2);
          refactor.spent = refactor.spent && '$ ' + refactor.spent.toFixed(2);
          refactor.profit = refactor.profit && '$ ' + refactor.profit.toFixed(2);
          refactor.cpm = refactor.cpm && '$ ' + refactor.cpm.toFixed(2);
          refactor.pcr = refactor.pcr && refactor.pcr.toFixed(2) + ' %';
          refactor.pcrf8 = refactor.pcrf8 && refactor.pcrf8.toFixed(2) + ' %';
          refactor.prpm = refactor.prpm && '$ ' + refactor.prpm.toFixed(2);
          refactor.rpm = refactor.rpm && '$ ' + refactor.rpm.toFixed(2);
          refactor.rpl = refactor.rpl && '$ ' + refactor.rpl.toFixed(2);
          refactor.partner_rpl = refactor.partner_rpl && '$ ' + refactor.partner_rpl.toFixed(2);
          refactor.ecpa = refactor.ecpa && '$ ' + refactor.ecpa.toFixed(3);
          refactor.cr = refactor.cr && refactor.cr.toFixed(2) + ' %';
          refactor.ctr = refactor.ctr && refactor.ctr.toFixed(2) + ' %';
          refactor.roi = refactor.roi && refactor.roi.toFixed(2) + ' %';
          refactor.pl__earned = refactor.pl__earned && '$ ' + refactor.pl__earned.toFixed(2);
          refactor.p1__earned = refactor.p1__earned && '$ ' + refactor.p1__earned.toFixed(2);
          refactor.p2__earned = refactor.p2__earned && '$ ' + refactor.p2__earned.toFixed(2);
          refactor.p3__earned = refactor.p3__earned && '$ ' + refactor.p3__earned.toFixed(2);
          refactor.p4__earned = refactor.p4__earned && '$ ' + refactor.p4__earned.toFixed(2);
          refactor.p5__earned = refactor.p5__earned && '$ ' + refactor.p5__earned.toFixed(2);
          refactor.p6__earned = refactor.p6__earned && '$ ' + refactor.p6__earned.toFixed(2);
          refactor.p7__earned = refactor.p7__earned && '$ ' + refactor.p7__earned.toFixed(2);
          refactor.p8__earned = refactor.p8__earned && '$ ' + refactor.p8__earned.toFixed(2);
          refactor.wp__earned = refactor.wp__earned && '$ ' + refactor.wp__earned.toFixed(2);
          refactor.wp_loop__earned = refactor.wp_loop__earned && '$ ' + refactor.wp_loop__earned.toFixed(2);
          refactor.search_rpl = refactor.search_rpl && '$ ' + refactor.search_rpl.toFixed(2);
          refactor.rpl_px8 = refactor.rpl_px8 && '$ ' + refactor.rpl_px8.toFixed(2);
          refactor.date = refactor.date ? moment(refactor.date, 'YYYYMMDD').format('DD.MM.YYYY.') : '';
          refactor.cr_px1 = refactor.cr_px1 && refactor.cr_px1.toFixed(2) + ' %';
          refactor.cr_px2 = refactor.cr_px2 && refactor.cr_px2.toFixed(2) + ' %';
          refactor.cr_px3 = refactor.cr_px3 && refactor.cr_px3.toFixed(2) + ' %';
          refactor.cr_px4 = refactor.cr_px4 && refactor.cr_px4.toFixed(2) + ' %';
          refactor.cr_px5 = refactor.cr_px5 && refactor.cr_px5.toFixed(2) + ' %';
          refactor.cr_px6 = refactor.cr_px6 && refactor.cr_px6.toFixed(2) + ' %';
          refactor.cr_px7 = refactor.cr_px7 && refactor.cr_px7.toFixed(2) + ' %';
          refactor.cr_px8 = refactor.cr_px8 && refactor.cr_px8.toFixed(2) + ' %';
          refactor.ecpa_px5 = refactor.ecpa_px5 && '$ ' + refactor.ecpa_px5.toFixed(3);
          refactor.cr_px5_px6 = refactor.cr_px5_px6 && refactor.cr_px5_px6.toFixed(2) + ' %';
          return {
            key: index,
            ...refactor
          };
        })
      : '';
    return tableDataRefactored;
  };

  const loadStatsData = async (cancelToken) => {
    const asyncExport = !!(selectedFilters?.dateRange?.to.diff(selectedFilters?.dateRange?.from, 'months') >= 2);

    const response = await statisticServices.getChannelsPaged({
      selectedFilters,
      asyncExport,
      customColumns,
      currentPage,
      pageSize,
      sorting,
      cancelToken
    });

    if (asyncExport) {
      openWarningNotification({
        message:
          "You requested a lot of data. No worries, we'll send you an e-mail cointaining stats you required. Make sure you check it in the next 48h."
      });
    }

    return response;
  };

  const total = [];
  const table = [];
  const rows = 0;

  const [
    {
      data: { total: sumOfData, table: tableData, rows: totalItems },
      isFetching
    },
    getStatsData
  ] = useFetch(loadStatsData, { table, total, rows });

  const loading = isFetching;

  useEffect(() => {
    const visibleColumns = allColumns.filter((column) => !column.hidden);
    setVisibleColumns(visibleColumns);
  }, [allColumns]);

  useEffect(() => setCurrentPage(1), [pageSize, selectedFilters]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getStatsData();
    }
  }, [sorting, pageSize, selectedFilters, getStatsData, currentPage]);

  const mobileViewData = (record) => {
    let dataMobile = [];
    let checkedColumnsObj = {};
    allColumns.filter((column) => (selectedFilters.checkedColumns[column.value] ? (checkedColumnsObj[column.value] = column.text) : ''));
    if (record !== undefined) {
      for (let key in record) {
        if (record.hasOwnProperty(key) && checkedColumnsObj[key]) {
          dataMobile.push(
            <div className={styles.mobileViewData} key={key}>
              {checkedColumnsObj[key]}:<span style={{ width: '10px' }} /> {record[key]}
            </div>
          );
        }
      }
    } else {
      for (let key in total) {
        if (total.hasOwnProperty(key) && key in selectedFilters.checkedColumns) {
          dataMobile.push(
            <div className={styles.mobileViewData} key={key}>
              total_{checkedColumnsObj[key]}:<span style={{ width: '10px' }} /> {total[key]}
            </div>
          );
        }
      }
    }
    return dataMobile;
  };

  const updateColumns = useCallback(
    (value) => {
      setTableColumns(() => {
        let newTableColumn = [];
        visibleColumns
          .filter((column) => value.checkedColumns[column['value']])
          .forEach(function ({ text, value }) {
            newTableColumn.push({
              title: (
                <TableTitle text={text} order={sorting.find((s) => s.key === value)?.value} value={value} onSortingClick={setSorting} />
              ),
              dataIndex: value
            });
          });
        return newTableColumn;
      });
    },
    [visibleColumns, sorting]
  );

  useEffect(() => {
    if (selectedFilters.filters) {
      updateColumns(selectedFilters);
    }
  }, [sorting, updateColumns, selectedFilters]);

  const handleExport = async ({ filters, checkedColumns, dateRange, type }) => {
    try {
      setLoadingExportButton(true);
      let url;
      if (type === 'csv') {
        url = statisticServices.getChannelsExportUrl({ filters, checkedColumns, dateRange, customColumns, sorting, resType: type });
      } else {
        url = await statisticServices.getGoogleSheetUrl({ filters, checkedColumns, dateRange, customColumns, sorting });
      }
      window.open(url);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoadingExportButton(false);
    }
  };

  const setDefaultSorting = (checkedColumns) => {
    if (checkedColumns.date && checkedColumns.hour) {
      setSorting([
        { key: 'date', value: SortDirection.Descending },
        { key: 'hour', value: SortDirection.Descending }
      ]);
    } else if (checkedColumns.date) {
      setSorting([{ key: 'date', value: SortDirection.Descending }]);
    } else if (checkedColumns.hour) {
      setSorting([{ key: 'hour', value: SortDirection.Descending }]);
    } else if (checkedColumns.spent) {
      setSorting([{ key: 'spent', value: SortDirection.Descending }]);
    }
  };

  return (
    <div className="Reports">
      <h1>Channel stats</h1>
      <Filters
        columns={visibleColumns}
        onColumnCreate={(newCustomColumn) => {
          setAllColumns((oldColumns) => oldColumns.concat(newCustomColumn));
          setCustomColumns((oldCustomColumns) => oldCustomColumns.concat(newCustomColumn));
        }}
        onSubmit={(value) => {
          setDefaultSorting(value.checkedColumns);
          updateColumns(value);
          setSelectedFilters(value);
        }}
        updateLoading={loading}
        onExport={handleExport}
        loadingExportButton={loadingExportButton}
      />
      <Table
        style={{ marginTop: '2%' }}
        columns={tableColumns}
        dataSource={refactorTableData()}
        rowKey={({ key }) => key}
        loading={loading}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        expandedRowRender={
          window.innerWidth < 700 ? (record) => mobileViewData(refactorTableData()[record.key], tableColumns, total) : null
        }
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor();
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {sumOfDataRefactored[el.dataIndex]}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={(value) => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
            />
          );
        }}
        rowClassName={(record) => {
          const classNames = [];
          // moments method day() returns 6 and 0 for Saturday and Sunday respectively
          if (record.date && !(moment(record.date, 'DD.MM.YYYY.').day() % 6)) {
            classNames.push(styles.weekend);
          }

          if (record.profit && record.profit !== 0) {
            let profit = record.profit.split('$').pop();
            if (profit < 0) {
              classNames.push(styles.negative);
            }
          }

          return classNames.join(' ');
        }}
      />
    </div>
  );
};
