import { Divider } from 'antd';
import React from 'react';
import { SortDirection } from '../services/statistic';
import { openErrorNotification } from './notifications';

import styles from './Utils.module.css';

export const firstLetterUpperCase = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const generateMacrosContent = (macros, handleMacrosClick) => {
  return (
    <div className={styles.macrosContent}>
      {macros.map((el) => (
        <div key={el.value}>
          <span onClick={() => handleMacrosClick(el.value)}>{el.value}</span>
          <Divider dashed />
        </div>
      ))}
    </div>
  );
};

export const getFormatedPriceRow = (priceData, countryAndIdArr, shouldRemoveId) => {
  const { country: countryArr, browser, os, device_type } = priceData;
  if (countryArr && countryArr.length === 0) {
    return [{ country: [], browser, os, device_type }];
  }
  return countryArr.map((country) => {
    const formatedRow = { ...priceData, country, browser, os, device_type };

    if (countryAndIdArr) {
      const countryAndId = countryAndIdArr.find(
        (item) => country === item.country && browser === item.browser && os === item.os && device_type === item.device_type
      );

      if (countryAndId?.id && !shouldRemoveId) {
        formatedRow.id = countryAndId.id;
      } else {
        delete formatedRow.id;
      }
    }

    return formatedRow;
  });
};

export const groupCountriesWithSamePrices = (priceData) => {
  const groupedData = {};
  const countriesAndIds = [];

  priceData.forEach((row) => {
    const { browser, os, price, country, id, device_type } = row;
    const groupedDataKey = `${browser}${os}${parseFloat(price)}${device_type}`; // Countries with the same pixel type, spent and earned will be stored in object with key generated from those properties

    if (groupedData.hasOwnProperty(groupedDataKey)) {
      if (Array.isArray(country)) {
        country.forEach(
          (el) => groupedData[groupedDataKey].country.push(el) // Existing key
        );
      } else {
        groupedData[groupedDataKey].country.push(country);
      }
    } else {
      const arrCountry = [];
      if (Array.isArray(country)) {
        country.forEach((el) => arrCountry.push(el));
      } else {
        arrCountry.push(country);
      }
      // New key
      groupedData[groupedDataKey] = {
        browser,
        os,
        price,
        country: arrCountry,
        device_type
      };
    }

    countriesAndIds.push({
      // We need this data to connect every country with product price id on form submit
      country,
      browser,
      os,
      id,
      device_type
    });
  });

  return {
    groupedData: Object.values(groupedData),
    countriesAndIds
  };
};

export const breakGroupedCountries = (formPriceData, countriesAndIds) => {
  const formatedFormPriceData = [];

  formPriceData.forEach((priceData) => {
    formatedFormPriceData.push(...getFormatedPriceRow(priceData, countriesAndIds));
  });

  return formatedFormPriceData;
};

export const flagListedCountries = (countryList, listedCountries) => {
  return countryList.map((countryData) => {
    const listedCountry = listedCountries.find((countryId) => countryId === countryData.id);
    if (listedCountry) {
      return { ...countryData, listed: true };
    } else {
      return { ...countryData, listed: false };
    }
  });
};

export const markListedCountries = ({ incomingValue, countryList, countryListSetter }) => {
  let listedCountries = [];

  incomingValue.forEach(({ country: countryArr }) => {
    let rowCountries = [];

    if (countryArr) {
      rowCountries = countryArr.map((country) => country);
    }

    listedCountries = listedCountries.concat(rowCountries);
  });

  const updatedCountryList = flagListedCountries(countryList, listedCountries);

  countryListSetter(updatedCountryList);
};

export const removeDuplicateCountriesHelper = ({ form, rowIndex, formFieldName, country }) => {
  const pricesFormData = [...form.getFieldValue(formFieldName)];

  const updatedPricesData = pricesFormData.map((rowPriceData, index) => {
    const { country: countryArr } = rowPriceData;
    let filteredRowCountryArr = [];

    if (countryArr && countryArr.length) {
      filteredRowCountryArr = countryArr.filter((countryLocal) => !(countryLocal === country && rowIndex !== index));
    } else {
      return rowPriceData;
    }

    if (filteredRowCountryArr.length) {
      return { ...rowPriceData, country: filteredRowCountryArr };
    } else {
      return undefined;
    }
  });

  const filteredPricesData = updatedPricesData.filter((rowPriceData) => rowPriceData !== undefined);

  form.setFieldsValue({ [formFieldName]: filteredPricesData });
};

export const throwErrorIfActiveRuleNotificationsTurnedOff = (areNotificationsTurnedOff) => {
  if (areNotificationsTurnedOff) {
    openErrorNotification({ message: 'Turn on either Slack or Gmail rule notification!' });
    throw new Error();
  }
};

export const handleOrdering = (sorting) => {
  return sorting.map((order) => (order.value === SortDirection.Ascending ? order.key : `-${order.key}`));
};

export const deleteCookie = (name, path, domain) => {
  const pathPart = path ? `;path=${path}` : '';
  const domainPart = domain ? `;domain=${domain}` : '';
  document.cookie = `${name}=${pathPart}${domainPart};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const clearCSRFCookies = () => {
  deleteCookie('csrftoken', '/');
  const domain = window.location.hostname;
  const domainParts = domain.split('.');

  for (let i = 0; i < domainParts.length; i++) {
    const cookieDomain = (i > 0 ? '.' : '') + domainParts.slice(i).join('.');
    deleteCookie('csrftoken', '/', cookieDomain);
  }
};

export const getCookieValue = (name) => {
  return (document.cookie.match(RegExp(`${name}=[^;]+`)) || [''])[0].replace(`${name}=`, '');
};

export const getUserFullName = (user, replacement = 'Unknown') => {
  if (!user) {
    return replacement;
  }

  const name = `${user.first_name} ${user.last_name}`;
  return name.trim() ? name : replacement;
};

export const getName = (component, replacement = '/') => {
  if (!component) {
    return replacement;
  }
  const name = `${component.name}`;
  return name.trim() ? name : replacement;
};

export const getTitle = (component, replacement = '/') => {
  if (!component) {
    return replacement;
  }
  const title = `${component.title}`;
  return title.trim() ? title : replacement;
};

export const setDummyCookies = () => {
  const domain = window.location.hostname;
  const domainParts = domain.split('.');

  for (let i = 0; i < domainParts.length; i++) {
    const cookieDomain = (i > 0 ? '.' : '') + domainParts.slice(i).join('.');
    document.cookie = `a=b;domain=${cookieDomain};path=/`;
  }
};
