import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const ProductStatus = {
  Active: 'Active',
  Inactive: 'Inactive'
};

export const PixelType = {
  pl: 'pl',
  p1: 'p1',
  p2: 'p2',
  p3: 'p3',
  p4: 'p4',
  p5: 'p5',
  p6: 'p6',
  p7: 'p7',
  p8: 'p8'
};

class ProductsService {
  async getAll({ fields = 'id,title' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/products/${query}` });
  }

  async getPaged({ currentPage, pageSize, filters, searchValue, sorting, cancelToken }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      company: filters.company,
      os__in: filters.os && filters.os.join(','),
      browser__in: filters.browser && filters.browser.join(','),
      product_prices_country__in: filters.country && filters.country.join(','),
      status: filters.status,
      search: searchValue,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({
      url: `/api/products/${query}`,
      cancelToken
    });
  }

  async getAllProductTypes() {
    return await callApi({ url: '/api/product_types/' });
  }

  async addProduct(data) {
    return await callApi({ url: '/api/products/', method: 'POST', data });
  }

  async deleteProduct(id) {
    return await callApi({ url: `/api/products/${id}`, method: 'DELETE' });
  }

  async editProduct(data, id) {
    return await callApi({ url: `/api/products/${id}/`, method: 'PATCH', data });
  }

  async getById({ id, fields }) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/products/${id}${query}` });
  }

  async importPrices(data) {
    return await callApi({
      url: '/api/products/csv_import/',
      method: 'POST',
      data
    });
  }

  getPricesCsvUrl(product_id) {
    return `/api/products/${product_id}/csv_export/`;
  }

  async getRuleMetrics() {
    return await callApi({ url: '/api/product_rules/rule_metrics/' });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.title,
        value: option.id
      };
    });
  };
}

export const productsService = new ProductsService();
