import React from 'react';

import { ListTypes } from '../../../../services/lists';
import { AddOrEditListModal } from '../AddOrEditListModal';
import { MultipleItemsSelect } from '../MultipleItemsSelect';

export const AddOrEditDeviceTypeListModal = ({
  visibleModal,
  allDeviceTypes,
  deviceTypeList,
  allAccounts,
  isClone,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  loader
}) => {
  const fieldName = 'device_types';

  const renderItemsSelect = () => (
    <MultipleItemsSelect
      loading={loader.allDeviceTypes}
      title="List of Device Types"
      name={fieldName}
      message="Please choose Device Types!"
      allItems={allDeviceTypes}
    />
  );

  return (
    <AddOrEditListModal
      visibleModal={visibleModal}
      allAccounts={allAccounts}
      onCloseModal={onCloseModal}
      onClone={onClone}
      onEdit={onEdit}
      onAdd={onAdd}
      list={deviceTypeList}
      listType={ListTypes.device_type_lists}
      itemsFieldName={fieldName}
      title="Device Type List"
      nameFieldTitle="Device Type List Name"
      renderItemsSelect={renderItemsSelect}
      isClone={isClone}
      areAccountsLoading={loader.allAccounts}
    />
  );
};
