import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';

class CompanyTagsService {
  async getAll({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/company_tags/${query}` });
  }

  async getPaged({ currentPage, pageSize, searchValue, cancelToken }) {
    const query = getQueryString({ ...getOffsetLimitFromPagination({ currentPage, pageSize }) });
    return await callApi({ url: `/api/company_tags/${query}`, cancelToken });
  }

  async add(data) {
    return await callApi({ url: '/api/company_tags/', method: 'POST', data });
  }

  async edit(data, id) {
    return await callApi({ url: `/api/company_tags/${id}/`, method: 'PUT', data });
  }

  // async delete(id) {
  //   return await callApi({ url: `/api/company_tags/${id}/`, method: 'DELETE' });
  // }
}

export const companyTagsService = new CompanyTagsService();
