import { Checkbox, Col, Divider, Form, Row } from 'antd';
import React from 'react';

export const SuspendModalContent = ({ form, onSubmit }) => {
  return (
    <Form form={form} initialValues={{ notify_on_mail: false }} onFinish={onSubmit}>
      <Divider />

      <Row>
        <Col>
          <Form.Item name="notify_on_mail" valuePropName="checked">
            <Checkbox>Send Mail</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
