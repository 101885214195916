import React, { useState, useEffect } from 'react';

import { Heading } from '../Heading';
import { Table } from './Table';
import { AddOrEditPriceListModal } from './AddOrEditPriceListModal';

import { countriesService } from '../../../../services/countries';
import { listsService, ListTypes } from '../../../../services/lists';
import { accountsService } from '../../../../services/accounts';
import { useDebounce, useFetch } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { browsersService } from '../../../../services/browsers';
import { osService } from '../../../../services/os';
import { deviceTypesService } from '../../../../services/device-types';
import { SortDirection } from '../../../../services/statistic';

// import styles from '../../Lists.module.css';

const listType = ListTypes.price_lists;
const globalList = true;

export const PricesTab = () => {
  const [allCountries, setAllCountries] = useState([]);
  const [allCountriesLoading, setAllCountriesLoading] = useState(false);

  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);

  const [allOSs, setAllOSs] = useState([]);
  const [allOSLoading, setAllOsLoading] = useState(false);

  const [allBrowsers, setAllBrowsers] = useState([]);
  const [allBrowsersLoading, setAllBrowsersLoading] = useState(false);

  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [allDeviceTypesLoading, setAllDeviceTypesLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const getAccounts = async () => {
      try {
        setAllAccountsLoading(true);
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };
    const getCountries = async () => {
      try {
        setAllCountriesLoading(true);
        const countries = await countriesService.getAll({ fields: 'id,name,code2,img' });
        setAllCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllCountriesLoading(false);
      }
    };
    const getOS = async () => {
      setAllOsLoading(true);
      try {
        const os = await osService.getAll({ fields: 'id,name,img' });
        setAllOSs(os);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllOsLoading(false);
      }
    };
    const getBrowsers = async () => {
      setAllBrowsersLoading(true);
      try {
        const browsers = await browsersService.getAll({ fields: 'id,name,img' });
        setAllBrowsers(browsers);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllBrowsersLoading(false);
      }
    };
    const getDeviceTypes = async () => {
      setAllDeviceTypesLoading(true);
      try {
        const data = await deviceTypesService.getAll({ fields: 'id,name' });
        setAllDeviceTypes(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllDeviceTypesLoading(false);
      }
    };
    getOS();
    getBrowsers();
    getAccounts();
    getCountries();
    getDeviceTypes();
  }, []);

  const loadPriceLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      globalList,
      fields: 'id,img,name,creator_details,date_created,list_prices,assigned,exclude,private',
      filters,
      sorting
    });

  const [
    {
      data: { results: priceLists, count: totalRows },
      isFetching
    },
    getPriceLists
  ] = useFetch(loadPriceLists);

  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getPriceLists();
  }, [debouncedSearchValue, currentPage, getPriceLists, filters, sorting]);

  const handleDelete = () => {
    if (priceLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getPriceLists();
  };

  const loader = {
    allAccounts: allAccountsLoading,
    allCountries: allCountriesLoading,
    allOSs: allOSLoading,
    allBrowsers: allBrowsersLoading,
    allDeviceTypes: allDeviceTypesLoading
  };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="Price"
        allAccounts={allAccounts}
        areAccountsLoading={allAccountsLoading}
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        allCountries={allCountries}
        allAccounts={allAccounts}
        allOSs={allOSs}
        allBrowsers={allBrowsers}
        allDeviceTypes={allDeviceTypes}
        priceLists={priceLists}
        loading={loading}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loader={loader}
        sorting={sorting}
        onClone={getPriceLists}
        onEdit={getPriceLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />
      <AddOrEditPriceListModal
        visibleModal={visibleAddModal}
        allCountries={allCountries}
        allAccounts={allAccounts}
        allOSs={allOSs}
        allBrowsers={allBrowsers}
        allDeviceTypes={allDeviceTypes}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getPriceLists}
        loader={loader}
      />
    </div>
  );
};
