import React, { useState } from 'react';
import { Modal, Popover } from 'antd';

import { AddOrEditTargetingList } from './AddOrEditTargetingList';

import { listsService } from '../../../../services/lists';
import { openSuccessNotification } from '../../../../utils/notifications';

import { showApiErrors } from '../../../../utils/showApiErrors';
import styles from '../../Lists.module.css';

export const ActionsTableCol = ({ targetingList, listType, onClone, onEdit, onDelete }) => {
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [isClone, setIsClone] = useState(false);

  const handleOpenEditModal = () => setVisibleEditModal(true);

  const handleCloseEditModal = () => setVisibleEditModal(false);

  const handleDeleteList = async (id) => {
    try {
      await listsService.deleteList(id, listType);
      openSuccessNotification({ message: 'List successfully deleted!' });
      await onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDeleteList = () => {
    const { name, id } = targetingList;
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleDeleteList(id),
      onCancel() {}
    });
  };

  return (
    <div className={styles.actions}>
      <Popover content="Clone" trigger="hover">
        <div
          onClick={() => {
            setIsClone(true);
            handleOpenEditModal();
          }}
        >
          <img src="/images/copy.png" alt="Clone" width="19px" />
        </div>
      </Popover>

      <Popover content="Edit" trigger="hover">
        <div
          onClick={() => {
            setIsClone(false);
            handleOpenEditModal();
          }}
        >
          <img src="/images/edit_icon.svg" alt="Edit" width="18px" />
        </div>
      </Popover>

      <Popover content="Delete" trigger="hover">
        <div onClick={confirmDeleteList}>
          <img src="/images/delete_icon.png" alt="Delete" width="21px" />
        </div>
      </Popover>

      <AddOrEditTargetingList
        visibleModal={visibleEditModal}
        listType={listType}
        list={targetingList}
        isClone={isClone}
        onCloseModal={handleCloseEditModal}
        onClone={onClone}
        onEdit={onEdit}
      />
    </div>
  );
};
