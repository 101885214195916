import React from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import styles from './TableTitle.module.css';
import { SortDirection } from '../services/statistic';

export const TableTitle = ({ onSortingClick, value, order, text }) => {
  let upArrowClass = null;
  let downArrowClass = null;

  if (order === SortDirection.Descending) {
    upArrowClass = null;
    downArrowClass = 'active-arrow';
  } else if (order === SortDirection.Ascending) {
    upArrowClass = 'active-arrow';
    downArrowClass = null;
  }

  const handleSorting = (column, order, multi = false) => {
    onSortingClick((oldSorting) => {
      const newSorting = multi ? oldSorting.filter((s) => s.key !== column) : [];
      newSorting.push({ key: column, value: order });
      return newSorting;
    });
  };

  const handleAscSortingClick = (e) => {
    handleSorting(value, SortDirection.Ascending, e.shiftKey);
  };

  const handleDescSortingClick = (e) => {
    handleSorting(value, SortDirection.Descending, e.shiftKey);
  };

  return (
    <div className={styles.TableTitle}>
      <span className="title">{text}</span>
      <div className="arrows">
        <div className={upArrowClass}>
          <CaretUpOutlined onClick={handleAscSortingClick} />
        </div>
        <div className={downArrowClass}>
          <CaretDownOutlined onClick={handleDescSortingClick} />
        </div>
      </div>
    </div>
  );
};
