import React from 'react';
import { Popover } from 'antd';

import styles from './ProductTableActions.module.css';
import { useHistory } from 'react-router';
import { confirmDeleteProductModal } from '../../../utils/products';
import { Link } from 'react-router-dom';
import { CopyTwoTone } from '@ant-design/icons';
import { openSuccessNotification } from '../../../utils/notifications';
import CopyToClipboard from 'react-copy-to-clipboard';

export const ProductTableActions = ({ product, previewUrl, onDelete }) => {
  const history = useHistory();

  const { id } = product;

  return (
    <div className={styles.actions}>
      <Popover content="Edit" trigger="hover">
        <div
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/products/${id}`);
          }}
        >
          <img src="/images/edit_icon.svg" alt="Edit" width="19px" />
        </div>
      </Popover>

      <Popover
        content="Clone"
        trigger="hover"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <Link target="_blank" to={`/products/clone?productId=${id}`}>
            <img src="/images/copy.png" alt="Clone" width="20px" />
          </Link>
        </div>
      </Popover>

      <Popover content="Copy Preview URL" trigger="hover">
        <div onClick={(e) => e.stopPropagation()}>
          <CopyToClipboard text={previewUrl} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
            <CopyTwoTone style={{ fontSize: 18, marginTop: 4 }} />
          </CopyToClipboard>
        </div>
      </Popover>

      <Popover
        content="Delete"
        trigger="hover"
        onClick={(e) => {
          e.stopPropagation();
          confirmDeleteProductModal(product, onDelete);
        }}
      >
        <div>
          <img src="/images/delete_icon.png" alt="Delete" width="22px" />
        </div>
      </Popover>
    </div>
  );
};
