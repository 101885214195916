import { Select, Spin, Tooltip } from 'antd';
import React from 'react';
import { filterOptionsByLabelAndValue } from '../../../utils/options';

import styles from './IncludeExcludeSelect.module.css';

export const IncludeExcludeSelect = ({
  options,
  placeholder,
  mode,
  value,
  loading,
  isExcluded,
  onFilter,
  onValuesChange,
  onExcludedChange
}) => {
  return (
    <Spin spinning={loading ?? false}>
      <div className={styles.incluceExcludeSelectWrapper}>
        <Select
          options={options}
          placeholder={placeholder}
          mode={mode}
          value={value}
          showSearch
          allowClear
          showArrow={false}
          style={{ width: '100%' }}
          filterOption={onFilter ?? filterOptionsByLabelAndValue}
          onChange={onValuesChange}
        />

        <Tooltip title={isExcluded ? 'Excluded' : 'Included'}>
          <div
            className={`${styles.includeExcludeBox} ${isExcluded ? styles.excludeBox : styles.includeBox}`}
            onClick={() => onExcludedChange(!isExcluded)}
          ></div>
        </Tooltip>
      </div>
    </Spin>
  );
};
