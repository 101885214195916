import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import dayjs from 'dayjs';

import { Spinner } from '../../../../components/Spinner';

import { mediaCampaignsService } from '../../../../services/media-campaigns';

import { useDebounce, useFetch } from '../../../../utils/hooks';

import styles from './Tabs.module.css';
import { ActivityHeader } from './ActivityHeader';
import { TitleWithDivider } from '../../../../components/TitleWithDivider';

const getChangeMessageFormated = (changeMessage) => {
  let changeMessageString = '';

  changeMessage.forEach((row) => {
    changeMessageString += row + '\n';
  });

  return changeMessageString;
};

const getMessagesByDate = (activityLogData) => {
  const messagesByDate = {};

  activityLogData.forEach((activity) => {
    const { action_date: actionData, change_message: changeMessage } = activity;
    if (changeMessage.length) {
      if (messagesByDate[actionData]?.length) {
        messagesByDate[actionData].push(activity);
      } else {
        messagesByDate[actionData] = [activity];
      }
    }
  });

  return messagesByDate;
};

const ActivityRows = ({ activities }) => {
  const activitiesByDate = getMessagesByDate(activities);

  return (
    <div>
      {Object.keys(activitiesByDate).map((dateKey) => {
        return (
          <div key={dateKey}>
            <div className={styles.activitiesDate}>
              <TitleWithDivider title={dayjs(dateKey).format('DD MMM YYYY')} />
            </div>
            {activitiesByDate[dateKey].map((activity, index) => {
              const { action_time: actionTime, change_message: changeMessage, user } = activity;
              const formattedMessage = getChangeMessageFormated(changeMessage ?? []);
              return (
                <div key={index} className={styles.activityRowWrapper}>
                  <div className={styles.activityRowMessage}>{formattedMessage}</div>
                  <div className={styles.activityRowInfo}>
                    By <span className={styles.activityInfoUser}>{user}</span> at {actionTime}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const Activity = ({ campaignId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({
    showAllChanges: true,
    showTargetingChanges: true,
    showPricingChanges: true,
    showAutomationRulesChanges: false
  });
  const [searchValue, setSearchValue] = useState('');

  const debounceSearchValue = useDebounce(searchValue);

  const loadActivities = (cancelToken) =>
    mediaCampaignsService.getActivitiesPaged({
      campaignId,
      fields: 'img,content_type,action_date,action_time,change_message,user',
      currentPage,
      pageSize,
      filters,
      search: debounceSearchValue,
      cancelToken
    });

  const [
    {
      data: { results: activities, count: totalRows },
      isFetching
    },
    getActivities
  ] = useFetch(loadActivities);

  useEffect(() => {
    getActivities();
  }, [currentPage, pageSize, getActivities, campaignId, filters, debounceSearchValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, pageSize, debounceSearchValue]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div className={styles.activityTab}>
      <ActivityHeader filters={filters} searchValue={searchValue} onFilterChange={setFilters} onSearch={setSearchValue} />

      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <ActivityRows activities={activities} />

          <Pagination
            className="ant-table-pagination ant-table-pagination-right"
            total={totalRows}
            pageSize={pageSize}
            current={currentPage}
            showSizeChanger
            pageSizeOptions={['10', '20', '40']}
            onChange={handlePaginationChange}
            onShowSizeChange={setPageSize}
          />
        </>
      )}
    </div>
  );
};
