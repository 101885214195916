import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Modal, Popover, Dropdown, Menu, Spin, Form } from 'antd';

import { activateCampaign, deleteCampaign, pauseCampaign, suspendCampaigns } from '../../utils/functions';

import styles from './Show.module.css';
import { CopyTwoTone, StopOutlined } from '@ant-design/icons';
import { mediaCampaignsService } from '../../../../services/media-campaigns';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { openSuccessNotification } from '../../../../utils/notifications';
import { SuspendModalContent } from './SuspendModalContent';

const STATUS = {
  active: 'Active',
  paused: 'Paused',
  suspended: 'Suspended'
};

export const ActionsTableCol = ({ id, title, status, onActivate, onPause, onSuspend, onDelete }) => {
  const [loadingCampaignInfo, setLoadingCampaignInfo] = useState(false);

  const [suspendedForm] = Form.useForm();

  const history = useHistory();

  const openEditPage = () => {
    history.push(`/media-campaigns/${id}`);
  };

  const handleCopy = async () => {
    try {
      setLoadingCampaignInfo(true);
      const campaignInfo = await mediaCampaignsService.getCampaignsInfo(id);
      openSuccessNotification({ message: 'Successfully copied to clipboard!' });
      navigator.clipboard.writeText(campaignInfo);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoadingCampaignInfo(false);
    }
  };

  const handleSuspend = async (values) => {
    try {
      await suspendCampaigns([id], values);
      openSuccessNotification({ message: `Successfully suspended campaign with id ${id}` });
      onSuspend();
    } catch (e) {
      showApiErrors(e);
    } finally {
    }
  };
  const confirmActivateCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to activate ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => activateCampaign(id, title, onActivate),
      onCancel() {}
    });
  };

  const confirmPauseCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to pause ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => pauseCampaign(id, title, onPause),
      onCancel() {}
    });
  };

  const confirmSuspendCampaigns = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to suspend ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      content: <SuspendModalContent form={suspendedForm} onSubmit={(values) => handleSuspend(values)} />,
      onOk: () => suspendedForm.submit(),
      onCancel: () => suspendedForm.resetFields()
    });
  };

  const confirmDeleteCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteCampaign(id, title, onDelete),
      onCancel() {}
    });
  };

  return (
    <Spin spinning={loadingCampaignInfo}>
      <div className={styles.actions}>
        <div>
          <Dropdown
            overlayStyle={{ width: '200px' }}
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link to={`/stubs/channel_stats/?channel=${id}`}>Channel stats</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={`/stubs/channel_chart/?channel=${id}`}>Graph view</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link
                    target="_blank"
                    to={`/stubs/channel_stats/?channel=${id}&columns=channel,lp_id,product_title,im,dw,pl,pf,spent,earned,profit,roi,rpm,cr`}
                  >
                    Performance check{' '}
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link
                    target="_blank"
                    to={`/stubs/channel_stats/?channel=${id}&columns=channel,country,im,dw,pl,pf,spent,earned,profit,roi,cr,pcr`}
                  >
                    Country breakdown{' '}
                  </Link>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <span style={{ cursor: 'pointer' }}>
              <img src="/images/plus.png" alt="Delete" width="19px" />
            </span>
          </Dropdown>
        </div>

        <Popover content="Campaign Info" trigger="hover">
          <div onClick={handleCopy}>
            <CopyTwoTone style={{ fontSize: 18, marginTop: 3 }} />
          </div>
        </Popover>

        <Popover content="Clone" trigger="hover">
          <div>
            <Link target="_blank" to={`/media-campaigns/clone?campaignId=${id}`}>
              <img src="/images/copy.png" alt="Clone" width="20px" />
            </Link>
          </div>
        </Popover>

        <Popover content="Edit" trigger="hover">
          <div onClick={openEditPage}>
            <img src="/images/edit_icon.svg" alt="Edit" width="19px" />
          </div>
        </Popover>

        {status === STATUS.active ? (
          <>
            <Popover content="Pause" trigger="hover">
              <div onClick={confirmPauseCampaign}>
                <img src="/images/pause_icon.png" alt="Pause" width="19px" />
              </div>
            </Popover>

            <Popover content="Suspend" trigger="hover">
              <div onClick={confirmSuspendCampaigns}>
                <StopOutlined style={{ fontSize: 18, marginTop: 3, color: '#F84747' }} />
              </div>
            </Popover>
          </>
        ) : (
          <Popover content="Activate" trigger="hover">
            <div onClick={confirmActivateCampaign}>
              <img src="/images/activate_icon.png" alt="Activate" width="20px" />
            </div>
          </Popover>
        )}

        <Popover content="Delete" trigger="hover">
          <div onClick={confirmDeleteCampaign}>
            <img src="/images/delete_icon.png" alt="Delete" width="21px" />
          </div>
        </Popover>
      </div>
    </Spin>
  );
};
