import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import styles from './Vertical.module.css';

export const LiveCampaigns = ({ liveCampaigns, shrinkMenu, onShrinkClicked }) => {
  return (
    <div className={styles.liveCampaigns} onClick={onShrinkClicked}>
      <div className={styles.liveCampaignsText}>
        {liveCampaigns} {!shrinkMenu ? <span>live campaigns</span> : null}
      </div>
      <div>{shrinkMenu ? <RightOutlined /> : <LeftOutlined />}</div>
    </div>
  );
};
