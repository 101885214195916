import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import React from 'react';
import { automationRuleMetricOptions, comparationSignOptions } from '../services/media-campaigns';
import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { DEFAULT_AUTOMATION_RULES_VALUE } from '../utils/targeting';
import { AutomationRulesFieldName } from './AutomationRulesTargetingSection';

import styles from './AutomationRulesModal.module.css';

const WrapperFieldName = {
  Condition: 'condition',
  Desired: 'desired'
};

const ConditionFieldName = {
  Metric: 'metric',
  Operator: 'operator',
  Value: 'value'
};

const DesiredFieldName = {
  Metric: 'metric',
  Value: 'value'
};

export const AutomationRulesModal = ({ visible, onClose, field: parentField, form, pathName }) => {
  const handleValidateFieldsAndClose = () => {
    const automationRules = form.getFieldValue(pathName);
    const fieldsToValidate = [];

    automationRules.forEach((_, index) => {
      Object.values(ConditionFieldName).forEach((fieldName) =>
        fieldsToValidate.push([...pathName, index, WrapperFieldName.Condition, fieldName])
      );
      Object.values(DesiredFieldName).forEach((fieldName) =>
        fieldsToValidate.push([...pathName, index, WrapperFieldName.Desired, fieldName])
      );
    });

    form.validateFields(fieldsToValidate).then(() => onClose());
  };

  return (
    <Modal
      title="Automation rules"
      visible={visible}
      width={850}
      onCancel={handleValidateFieldsAndClose}
      footer={[
        <Button key="submit" type="primary" onClick={handleValidateFieldsAndClose}>
          Confirm
        </Button>
      ]}
    >
      <Form.List {...parentField} name={[parentField.name, AutomationRulesFieldName]}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <Row gutter={32} key={index}>
                <Col span={14}>
                  <Row gutter={8}>
                    <Col span={0}>
                      <Form.Item {...field} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']} hidden>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <Form.Item {...field} name={[field.name, 'isActive']} fieldKey={[field.fieldKey, 'isActive']} valuePropName="checked">
                        <Checkbox />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, WrapperFieldName.Condition, ConditionFieldName.Metric]}
                        fieldKey={[field.fieldKey, WrapperFieldName.Condition, ConditionFieldName.Metric]}
                        rules={[{ required: true, message: 'Please select metric!' }]}
                      >
                        <Select placeholder="Metric" optionFilterProp="data-searchvalue" options={automationRuleMetricOptions} />
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Form.Item
                        {...field}
                        name={[field.name, WrapperFieldName.Condition, ConditionFieldName.Operator]}
                        fieldKey={[field.fieldKey, WrapperFieldName.Condition, ConditionFieldName.Operator]}
                        rules={[{ required: true, message: 'Please select comparation sign!' }]}
                      >
                        <Select placeholder="Comparation sign" optionFilterProp="data-searchvalue" options={comparationSignOptions} />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, WrapperFieldName.Condition, ConditionFieldName.Value]}
                        fieldKey={[field.fieldKey, WrapperFieldName.Condition, ConditionFieldName.Value]}
                        rules={[{ required: true, message: 'Please enter comparation value!' }]}
                      >
                        <InputNumber placeholder="Value" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={10}>
                  <Row gutter={8} key={index}>
                    <Col span={20}>
                      <Row gutter={8} align="end">
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, WrapperFieldName.Desired, DesiredFieldName.Metric]}
                            fieldKey={[field.fieldKey, WrapperFieldName.Desired, DesiredFieldName.Metric]}
                            rules={[{ required: true, message: 'Please select metric!' }]}
                          >
                            <Select placeholder="Metric" optionFilterProp="data-searchvalue" options={automationRuleMetricOptions} />
                          </Form.Item>
                        </Col>

                        <Col>
                          <span>=</span>
                        </Col>

                        <Col span={10}>
                          <Form.Item
                            {...field}
                            name={[field.name, WrapperFieldName.Desired, DesiredFieldName.Value]}
                            fieldKey={[field.fieldKey, WrapperFieldName.Desired, DesiredFieldName.Value]}
                            rules={[{ required: true, message: 'Please enter desired value!' }]}
                          >
                            <InputNumber placeholder="Value" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      span={2}
                      className={`${styles.moveRuleOuterWrapperCol} ${
                        index === 0 || index === fields.length - 1 ? styles.singleMoveRuleOptionOuterWrapper : ''
                      }`}
                    >
                      <Row style={{ display: index === 0 ? 'none' : 'flex' }}>
                        <Col className={styles.moveRule} onClick={() => move(index, index - 1)}>
                          <UpOutlined />
                        </Col>
                      </Row>
                      <Row style={{ display: index === fields.length - 1 ? 'none' : 'flex' }}>
                        <Col className={styles.moveRule} onClick={() => move(index, index + 1)}>
                          <DownOutlined />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={2}>
                      <img src="/images/delete_icon.png" alt="Delete" width="21px" height="21px" onClick={() => remove(field.name)} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button ghost type="primary" onClick={() => add(DEFAULT_AUTOMATION_RULES_VALUE)} block>
                    <PlusOutlined /> Add field
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </Modal>
  );
};
