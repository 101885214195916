import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../services/auth';

export const Index = () => {
  const history = useHistory();

  useEffect(() => {
    if (auth.hasPermission('view_salesdashboard')) {
      history.push('/sales-dashboard');
    } else if (auth.hasPermission('view_admindashboard')) {
      history.push('/admin-dashboard');
    } else if (auth.hasPermission('view_mediacampaign')) {
      history.push('/media-campaigns');
    }
  }, [history]);

  return <div></div>;
};
