import { Tabs } from 'antd';
import React, { useState } from 'react';

import { BrowsersTab } from './components/browsers';
import { CountriesTab } from './components/countries';
import { DeviceTypesTab } from './components/device-types';
import { IpAddressesTab } from './components/ip-addresses';
import { OSsTab } from './components/operating-systems';
import { PricesTab } from './components/prices';
import { SUBIDsTab } from './components/subids';
import { TargetingTab } from './components/targeting';
import styles from './Lists.module.css';

const { TabPane } = Tabs;

const TabKeys = {
  countries: 'countries',
  oss: 'oss',
  browsers: 'browsers',
  subids: 'subids',
  ipAddresses: 'ipAddresses',
  prices: 'prices',
  targeting: 'targeting',
  deviceTypes: 'deviceTypes'
};

export const Lists = (props) => {
  const [activeTab, setActiveTab] = useState(TabKeys.countries);

  return (
    <div className={styles.lists}>
      <h1>Lists</h1>

      <Tabs activeKey={activeTab} animated={false} onChange={setActiveTab}>
        <TabPane tab="Countries" key={TabKeys.countries} forceRender>
          <CountriesTab />
        </TabPane>

        <TabPane tab="OSs" key={TabKeys.oss} forceRender>
          <OSsTab />
        </TabPane>

        <TabPane tab="Browsers" key={TabKeys.browsers} forceRender>
          <BrowsersTab />
        </TabPane>

        <TabPane tab="SUBIDs" key={TabKeys.subids} forceRender>
          <SUBIDsTab />
        </TabPane>

        <TabPane tab="IP Addresses" key={TabKeys.ipAddresses} forceRender>
          <IpAddressesTab />
        </TabPane>

        <TabPane tab="Prices" key={TabKeys.prices} forceRender>
          <PricesTab />
        </TabPane>

        <TabPane tab="Targeting" key={TabKeys.targeting} forceRender>
          <TargetingTab />
        </TabPane>
        <TabPane tab="Device Types" key={TabKeys.deviceTypes} forceRender>
          <DeviceTypesTab />
        </TabPane>
      </Tabs>
    </div>
  );
};
