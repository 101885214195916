import { Modal, Popover } from 'antd';
import React, { useState } from 'react';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { AddOrEditRule } from './AddOrEditRule';
import styles from './ActionsTableCol.module.css';
import { gpssService } from '../../../services/gpss';

export const ActionsTableCol = ({ rule, countries, countriesLoading, countriesRefetching, onClone, onEdit, onDelete }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isClone, setIsClone] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  const handleDelete = async () => {
    try {
      await gpssService.delete(rule.id);
      openSuccessNotification({ message: 'Successfully deleted gpss rule!' });
      onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${rule.name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDelete,
      onCancel() {}
    });
  };

  return (
    <div className={styles.actionsWrapper}>
      <Popover content="Edit" trigger="hover">
        <div
          className={styles.action}
          onClick={() => {
            setIsClone(false);
            handleOpenModal();
          }}
        >
          <img src="/images/edit_icon.svg" alt="Edit" width="18px" />
        </div>
      </Popover>

      <Popover content="Clone" trigger="hover">
        <div
          className={styles.action}
          onClick={() => {
            setIsClone(true);
            handleOpenModal();
          }}
        >
          <img src="/images/copy.png" alt="Clone" width="18px" />
        </div>
      </Popover>

      <Popover content="Delete" trigger="hover">
        <div className={styles.action} onClick={confirmDelete}>
          <img src="/images/delete_icon.png" alt="Delete" width="21px" />
        </div>
      </Popover>

      <AddOrEditRule
        modalVisible={modalVisible}
        rule={rule}
        countries={countries}
        countriesLoading={countriesLoading}
        countriesRefetching={countriesRefetching}
        onClone={() => {
          onClone();
          handleCloseModal();
        }}
        isClone={isClone}
        onClose={handleCloseModal}
        onEdit={() => {
          onEdit();
          handleCloseModal();
        }}
      />
    </div>
  );
};
