import { Col, Form, Row, DatePicker, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { companiesService } from '../../../services/companies';
import { usePromise } from 'react-use';
import { showApiErrors } from '../../../utils/showApiErrors';
import { mediaCampaignsService } from '../../../services/media-campaigns';
import { filterOptionsByLabelAndValue, generateOptionsFromObjectKeyValue } from '../../../utils/options';
import { fraudReportsServices } from '../../../services/fraud-reports';
import { CSVDownload } from 'react-csv';
import { openSuccessNotification } from '../../../utils/notifications';

const { RangePicker } = DatePicker;
const { Item: FormItem } = Form;

const CAMPAIGN_COLUMNS = {
  campaign_id: 'Campaign ID',
  campaign_title: 'Campaign Title'
};

const ADDITIONAL_COLUMNS = {
  remote_addr: 'Remote Address',
  spent: 'Spent'
};

export const FiltersForm = () => {
  const [trafficCompanies, setTrafficCompanies] = useState([]);
  const [trafficCompaniesLoading, setTrafficCompaniesLoading] = useState(false);
  const [mediaCampaigns, setMediaCampaigns] = useState([]);
  const [mediaCampaignsLoading, setMediaCampaignsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [csvData, setCsvData] = useState('');

  const [selectedTrafficCompany, setSelectedTrafficCompany] = useState();

  const [form] = Form.useForm();
  const mounted = usePromise();

  useEffect(() => {
    (async () => {
      try {
        setTrafficCompaniesLoading(true);
        const trafficCompanies = await mounted(companiesService.getTrafficCompanies());
        setTrafficCompanies(trafficCompanies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTrafficCompaniesLoading(false);
      }
    })();

    (async () => {
      setMediaCampaignsLoading(true);
      try {
        const campaigns = await mediaCampaignsService.getCampaigns();
        setMediaCampaigns(campaigns);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setMediaCampaignsLoading(false);
      }
    })();
  }, [mounted]);

  useEffect(() => {
    if (selectedTrafficCompany) {
      (async () => {
        setMediaCampaignsLoading(true);
        try {
          const campaigns = await mediaCampaignsService.getCampaigns({ company: selectedTrafficCompany });
          setMediaCampaigns(campaigns);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setMediaCampaignsLoading(false);
        }
      })();
    }
  }, [selectedTrafficCompany]);

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const csvData = await fraudReportsServices.getCSV(values);
      setCsvData(csvData);
      openSuccessNotification({ message: 'Successfully generated CSV!', duration: 8 });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setCsvData('');
      setSubmitting(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      onValuesChange={(values) => {
        if (values.trafficCompany) {
          setSelectedTrafficCompany(values.trafficCompany);
          form.setFieldsValue({ campaigns: undefined });
        }
      }}
    >
      <Row gutter={32}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <FormItem name="dateRange" rules={[{ required: true, message: 'Please select date range!' }]}>
            <RangePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              disabledDate={(current) =>
                current < moment().subtract(2, 'months').startOf('month') || current > moment().subtract(2, 'days')
              }
              ranges={{
                'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                'Month before last month': [moment().subtract(2, 'months').startOf('month'), moment().subtract(2, 'months').endOf('month')]
              }}
            />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <FormItem name="trafficCompany" rules={[{ required: true, message: 'Please select traffic company!' }]}>
            <Select
              options={companiesService.generateOptions(trafficCompanies)}
              placeholder="Select traffic company"
              loading={trafficCompaniesLoading}
              showSearch
              filterOption={filterOptionsByLabelAndValue}
              style={{ width: '100%' }}
            />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <FormItem name="campaigns">
            <Select
              options={mediaCampaignsService.generateOptions(mediaCampaigns)}
              mode="multiple"
              placeholder="Select media campaigns"
              loading={mediaCampaignsLoading}
              allowClear
              showSearch
              filterOption={filterOptionsByLabelAndValue}
              style={{ width: '100%' }}
            />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <FormItem name="campaignColumns" rules={[{ required: true, message: 'Please select at least one campaign column!' }]}>
            <Select placeholder="Campaign columns" mode="multiple" style={{ width: '100%' }}>
              {generateOptionsFromObjectKeyValue(CAMPAIGN_COLUMNS)}
            </Select>
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <FormItem name="additionalColumns">
            <Select placeholder="Additional columns" mode="multiple" allowClear style={{ width: '100%' }}>
              {generateOptionsFromObjectKeyValue(ADDITIONAL_COLUMNS)}
            </Select>
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <FormItem name="sources">
            <Select
              placeholder="Sources"
              mode="tags"
              tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
              allowClear
              style={{ width: '100%' }}
            />
          </FormItem>
        </Col>
      </Row>

      {csvData !== '' ? <CSVDownload data={csvData} target="_blank" /> : null}
      <Row justify="end" style={{ width: '100%' }}>
        <Button type="primary" loading={submitting} onClick={() => form.submit()}>
          Get report
        </Button>
      </Row>
    </Form>
  );
};
