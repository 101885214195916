import { Modal } from 'antd';
import { PixelType, productsService } from '../services/products';
import { flagListedCountries, getFormatedPriceRow } from './functions';
import { openErrorNotification, openSuccessNotification } from './notifications';
import { showApiErrors } from './showApiErrors';

export const splitProductPricesArr = (productPriceArr) => {
  const pl = [];
  const p1 = [];
  const p2 = [];
  const p3 = [];
  const p4 = [];
  const p5 = [];
  const p6 = [];
  const p7 = [];
  const p8 = [];

  productPriceArr.forEach((prodPrice) => {
    const { pixel_type } = prodPrice;

    switch (pixel_type) {
      case PixelType.pl:
        pl.push(prodPrice);
        break;

      case PixelType.p1:
        p1.push(prodPrice);
        break;

      case PixelType.p2:
        p2.push(prodPrice);
        break;

      case PixelType.p3:
        p3.push(prodPrice);
        break;

      case PixelType.p4:
        p4.push(prodPrice);
        break;

      case PixelType.p5:
        p5.push(prodPrice);
        break;

      case PixelType.p6:
        p6.push(prodPrice);
        break;

      case PixelType.p7:
        p7.push(prodPrice);
        break;

      case PixelType.p8:
        p8.push(prodPrice);
        break;

      default:
        openErrorNotification({ message: 'There is a product price without pixel type!' });
    }
  });

  return {
    pl,
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    p7,
    p8
  };
};

export const confirmDeleteProductModal = (product, onDelete) => {
  const { title } = product;

  Modal.confirm({
    title: `Are you sure that you want to delete product ${title}?`,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => handleDeleteProduct(product, onDelete),
    onCancel: () => {}
  });
};

export const handleDeleteProduct = async (product, onDelete) => {
  const { id, title } = product;

  try {
    await productsService.deleteProduct(id);
    openSuccessNotification({ message: `Successfully deleted product ${title}!`, duration: 8 });
    onDelete();
  } catch (e) {
    showApiErrors(e);
  }
};

export const groupCountriesWithSamePrices = (priceData) => {
  const groupedData = {};
  const countriesAndIds = [];

  priceData.forEach((row) => {
    const { pixel_type, spent, earned, country, id } = row;
    const groupedDataKey = `${pixel_type}${parseFloat(spent)}${parseFloat(earned)}`; // Countries with the same pixel type, spent and earned will be stored in object with key generated from those properties

    if (groupedData.hasOwnProperty(groupedDataKey)) {
      groupedData[groupedDataKey].country.push(country); // Existing key
    } else {
      // New key
      groupedData[groupedDataKey] = {
        pixel_type,
        spent,
        earned,
        country: [country]
      };
    }

    countriesAndIds.push({
      // We need this data to connect every country with product price id on form submit
      country,
      id,
      pixel_type
    });
  });

  const formatedPriceGroupsData = splitProductPricesArr(Object.values(groupedData));
  const formatedCountriesAndIds = splitProductPricesArr(countriesAndIds);

  return {
    formatedPriceGroupsData,
    formatedCountriesAndIds
  };
};

export const breakGroupedCountries = (formPriceData, countriesAndIds, shouldRemoveId) => {
  const formatedFormPriceData = [];

  formPriceData.forEach((priceData) => {
    const { pixel_type } = priceData;

    formatedFormPriceData.push(...getFormatedPriceRow(priceData, countriesAndIds[pixel_type], shouldRemoveId));
  });

  return formatedFormPriceData;
};

export const markListedCountries = ({ incomingValue, countryList, activePriceTab, countryListsSetter }) => {
  let listedCountries = [];

  incomingValue
    .filter((data) => data !== undefined)
    .forEach(({ country: countryArr }) => {
      let rowCountries = [];

      if (countryArr) {
        rowCountries = countryArr.map((country) => country);
      }

      listedCountries = listedCountries.concat(rowCountries);
    });

  const updatedCountryList = flagListedCountries(countryList, listedCountries);

  countryListsSetter((oldValues) => ({ ...oldValues, [activePriceTab]: updatedCountryList }));
};

//  formatedPriceGroupsData: {
//   pl: [...],
//   p1: [...],
//   p2: [...],
//   p3: [...],
//   p4: [...],
//   ...
// }
export const markListedCountriesInAllTabs = ({ incomingValues, countryLists, countryListsSetter }) => {
  Object.entries(incomingValues).forEach(([key, value]) => {
    markListedCountries({
      incomingValue: value,
      countryList: countryLists[key],
      activePriceTab: key,
      countryListsSetter
    });
  });
};
