export const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return '';
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== '';
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
  return query ? (withoutQuestionMark ? query : `?${query}`) : '';
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({ limit: pageSize, offset: (currentPage - 1) * pageSize });
