import React, { useState, useEffect } from 'react';
import { Col, Button, Row, Select, Input } from 'antd';
import { identityService } from '../../../services/identity';
import moment from 'moment';
import { DatePicker } from 'antd';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { ColumnsGroup, renderRows } from '../../../components/ColumnsGroup';
import styles from '../ZoneStats.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';
import { testsService } from '../../../services/tests';
import { generateSelectOptionsWithIdValues } from '../../../utils/options';

const { RangePicker } = DatePicker;

export const Filters = ({ onSubmit, allColumns }) => {
  const [identity, setIdentity] = useState([]);
  const [identityLoading, setIdentityLoading] = useState(false);

  const [tests, setTests] = useState([]);
  const [testsLoading, setTestsLoading] = useState(false);

  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [filters, setFilters] = useState({});
  const [displayFilters, setDisplayFilters] = useState(true);

  const tempChecked = {
    flagged_time: true,
    identity: true,
    domain: true,
    testing_platform_code: true,
    spent: true
  };
  const [checkedColumns, setCheckedColumns] = useState(tempChecked);
  useEffect(() => {
    const getIdentity = async () => {
      try {
        setIdentityLoading(true);
        const identity = await identityService.getAll();
        setIdentity(identity);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setIdentityLoading(false);
      }
    };

    const getTests = async () => {
      try {
        setTestsLoading(true);
        const data = await testsService.getAll();
        setTests(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTestsLoading(false);
      }
    };

    getIdentity();
    getTests();
  }, []);
  const handleUpdate = () => {
    onSubmit({
      filters,
      dateRange: dateRange,
      checkedColumns
    });
  };

  return (
    <div className="">
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <Row gutter={32}>
            <Col>
              <Button type="primary" ghost onClick={() => setDisplayFilters(!displayFilters)}>
                Filters
              </Button>
            </Col>
            <Col>
              <ColumnsGroup
                showUpdateButton={true}
                onUpdate={handleUpdate}
                renderColumnRows={() => renderRows({ columnsData: allColumns, checkedColumns, onChange: setCheckedColumns })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <br></br>
      {displayFilters ? (
        <div className={styles.spanFontWeight500}>
          <Row gutter={32}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
              <span className={styles.spanMarginBottom}>Date range </span>
              <div style={{ width: '100%' }}>
                <RangePicker
                  format="DD.MM.YYYY"
                  ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 2 Days': [moment().subtract(1, 'days'), moment()],
                    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
                  }}
                  onChange={(momentDates, dates) => {
                    setDateRange(() => ({
                      from: momentDates ? momentDates[0] : null,
                      to: momentDates ? momentDates[1] : null
                    }));
                  }}
                  defaultValue={[moment(dateRange.from), moment(dateRange.to)]}
                />
              </div>
            </Col>
          </Row>
          <br></br>
          <Row gutter={32}>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Identity</span>
              <VirtualizedSelect
                options={identityService.generateOptions(identity)}
                onChange={(value) => {
                  setFilters({ ...filters, identity: value });
                }}
                multi={true}
                selectValue={filters.identity}
                placeholder={'Select identity'}
                loading={identityLoading}
              />
            </Col>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Tests</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Tests"
                showSearch
                allowClear
                optionFilterProp="data-searchvalue"
                loading={testsLoading}
                onChange={(value) => {
                  setFilters({ ...filters, tests: value });
                }}
              >
                {generateSelectOptionsWithIdValues(tests)}
              </Select>
            </Col>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Search</span>
              <Input
                placeholder="Search"
                style={{ width: '100%' }}
                value={filters.searchValue}
                onChange={(e) => {
                  setFilters({ ...filters, searchValue: e.target.value });
                }}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ display: 'flex' }}>
              <br />
              <Button style={{ alignSelf: 'center' }} onClick={handleUpdate} type="primary">
                Search
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
};
