import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

export const withRefetching = (Component) => {
  return ({ refetching, suffixIcon = null, ...props }) => {
    return (
      <Component
        {...props}
        suffixIcon={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {refetching ? <LoadingOutlined /> : null} {suffixIcon}
          </div>
        }
      />
    );
  };
};
