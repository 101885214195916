import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import { VerticalMenu } from './components/vertical-menu';
import { MediaCampaignTabs } from './components/media-campaign-tabs';
import { ShowMediaCampaigns } from './components/show-media-campaigns';

import { mediaCampaignsService } from '../../services/media-campaigns';
import { companiesService } from '../../services/companies';

import { useDebounce, useFetch } from '../../utils/hooks';
import { showApiErrors } from '../../utils/showApiErrors';

import styles from './MediaCampaigns.module.css';
import { SortDirection } from '../../services/statistic';
import { usePromise } from 'react-use';

export const MediaCampaigns = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [identities, setIdentities] = useState([]);
  const [identitiesLoading, setIdentitiesLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState([{ key: 'id', value: SortDirection.Descending }]);

  const mounted = usePromise();

  const debouncedSearchValue = useDebounce(searchValue);
  const debounceUtmCampaignValue = useDebounce(utmCampaign);

  const urlId = props.match.params.id;
  const queryId = queryString.parse(props.location.search).campaignId;
  const showVerticalMenu = Number(urlId) || queryId;

  const loadMediaCampaigns = (cancelToken) =>
    mediaCampaignsService.getCampaignsPaged({
      currentPage,
      pageSize,
      filters,
      searchValue: debouncedSearchValue,
      utmCampaign: debounceUtmCampaignValue,
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching
    },
    getMediaCampaigns
  ] = useFetch(loadMediaCampaigns);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        setCompaniesLoading(true);
        const companies = await mounted(companiesService.getAll({ fields: 'id,name', assigned_type: 'traffic' }));
        setCompanies(companies);
      } catch (err) {
        showApiErrors(err);
      } finally {
        setCompaniesLoading(false);
      }
    };

    const getIdentities = async () => {
      try {
        setIdentitiesLoading(true);
        const identities = await mounted(mediaCampaignsService.getIdentities());
        setIdentities(identities);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setIdentitiesLoading(false);
      }
    };

    getCompanies();
    getIdentities();
  }, [mounted]);

  useEffect(() => setCurrentPage(1), [filters, debouncedSearchValue, debounceUtmCampaignValue, pageSize, sorting]);

  useEffect(() => {
    getMediaCampaigns();
  }, [filters, debouncedSearchValue, debounceUtmCampaignValue, currentPage, pageSize, getMediaCampaigns, sorting]);

  const handleDelete = () => {
    if (campaigns.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getMediaCampaigns();
  };

  return (
    <div>
      {urlId ? (
        <div className={showVerticalMenu ? styles.verticalMenuAndTabsWrapper : ''} style={{ display: showVerticalMenu ? 'flex' : 'block' }}>
          {showVerticalMenu ? (
            <VerticalMenu
              campaigns={campaigns}
              totalRows={totalRows}
              currentPage={currentPage}
              pageSize={pageSize}
              loadingCampaigns={isFetching}
              searchValue={searchValue}
              companies={companies}
              identities={identities}
              identitiesLoading={identitiesLoading}
              companiesLoading={companiesLoading}
              filters={filters}
              urlId={urlId}
              onPaginationChange={(currentPage, pageSize) => {
                setCurrentPage(currentPage);
                setPageSize(pageSize);
              }}
              onSearch={(search) => setSearchValue(search)}
              onFilter={setFilters}
            />
          ) : null}
          <MediaCampaignTabs
            urlId={urlId}
            queryId={queryId}
            identities={identities}
            identitiesLoading={identitiesLoading}
            onDelete={handleDelete}
          />
        </div>
      ) : (
        <ShowMediaCampaigns
          campaigns={campaigns}
          totalRows={totalRows}
          currentPage={currentPage}
          pageSize={pageSize}
          loadingCampaigns={isFetching}
          companies={companies}
          identities={identities}
          companiesLoading={companiesLoading}
          identitiesLoading={identitiesLoading}
          searchValue={searchValue}
          filters={filters}
          utmCampaign={utmCampaign}
          sorting={sorting}
          onPaginationChange={(currentPage, pageSize) => {
            setCurrentPage(currentPage);
            setPageSize(pageSize);
          }}
          onFilter={setFilters}
          onSearch={setSearchValue}
          onUtmCampaignChange={setUtmCampaign}
          onActivate={getMediaCampaigns}
          onPause={getMediaCampaigns}
          onSuspend={getMediaCampaigns}
          onDelete={handleDelete}
          onSortingClick={setSorting}
        />
      )}
    </div>
  );
};
