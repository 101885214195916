import { CheckCircleFilled, CloseCircleFilled, MailOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Input, Modal, Popover, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { OnOffSwitch } from '../../../components/OnOffSwitch';
import { Select } from '../../../components/Select';
import { companiesService, COMPANY_TYPE, MBAdvertiserCategoryName, MBTrafficCategory, STATUS } from '../../../services/companies';
import { confirmClose } from '../../../utils/confirmClose';
import { useBeforeUnload } from '../../../utils/hooks/useBeforeUnload';
import { openErrorNotification, openSuccessNotification, openWarningNotification } from '../../../utils/notifications';
import { generateCountriesOptions, generateSelectOptionsWithIdValues, generateUserOptions, generateOptions } from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';
import { generateApiKey, tooltipLabel } from '../utils/misc';
import { pubPermissionsService } from '../../../services/pub-permissions';
import { auth } from '../../../services/auth';
import styles from './AddOrEditCompany.module.css';
import { usePromise } from 'react-use';
import { s2sMacros } from '../../../services/s2s';
import { generateMacrosContent, getUserFullName } from '../../../utils/functions';
import { leadSourcesService } from '../../../services/lead-sources';
import { SwitchWithLabelOnRight } from '../../../components/SwitchWithLabelOnRight';
import { TitleWithDivider } from '../../../components/TitleWithDivider';
import { Tabs } from 'antd';
import { CompanyDiary } from './CompanyDiary';
import { companyTagsService } from '../../../services/company-tags';
import TextArea from 'antd/lib/input/TextArea';
import { CustomDivider } from '../../../components/CustomDivider';
import moment from 'moment';
const { Panel: AntdPanel } = Collapse;

const Panel = {
  GeneralInformation: '1. General Information',
  ContactInformation: '2. Contact Information',
  Notes: '3. Notes',
  CompanyManagement: '4. Company Management',
  TechSettings: '5. Tech Settings',
  PlatformManagement: '6. Platform Management'
};

const leadSourceOther = {
  id: 'other',
  name: 'Other...'
};

const affiliateProgramsModule = 'affiliate_programs';
const offerwallModule = 'offerwall';
const advertiserPlatform = 'is_ssp_platform';

const DspAdvertiserCategory = {
  name: 'dsp_advertiser',
  title: 'DSP Advertiser'
};

// TODO: Fetch this when finished on backend
const ModuleToCategoriesMap = {
  affiliate_programs: ['ow_affiliate_programs'],
  offerwall: ['mb_traffic_source_cpm_cpc', 'mb_traffic_source_cpa_rs'],
  publisher: ['webmasters'],
  extension: ['search'],
  n2s: ['n2s'],
  search_feed: ['search_advertiser'],
  social_to_search: ['social_to_search']
};

const PlatformManagementDetails = ({
  platform,
  isActiveAndLastLoginData,
  resendingMail,
  onMailResend,
  deactivating2Fa,
  on2FaDeactivation,
  is2FaDisabled
}) => {
  const { isActive, lastLogin } = isActiveAndLastLoginData;
  return (
    <Row gutter={32} style={{ marginBottom: 20 }}>
      <Col>
        <Button size="middle" icon={<MailOutlined />} loading={resendingMail} onClick={onMailResend} disabled={isActive}>
          Resend {platform} Activation Mail
        </Button>
      </Col>

      <Col className={styles.platformStatusCol}>
        <>
          <span>
            {isActive ? (
              <>
                <CheckCircleFilled style={{ color: '#58A54F', marginRight: 5 }} /> Activated
              </>
            ) : (
              <>
                <CloseCircleFilled style={{ color: '#CE5153', marginRight: 5 }} /> Not activated
              </>
            )}
          </span>
        </>
      </Col>
      <Col className={styles.platformStatusCol}>
        <>
          <span>
            {is2FaDisabled ? (
              <>
                <CloseCircleFilled style={{ color: '#CE5153', marginRight: 5 }} /> 2FA not activated
              </>
            ) : (
              <>
                <CheckCircleFilled style={{ color: '#58A54F', marginRight: 5 }} /> 2FA activated
              </>
            )}
          </span>
          <Col>
            <Button
              className={styles.twoFaButton}
              size="middle"
              loading={deactivating2Fa}
              onClick={on2FaDeactivation}
              disabled={is2FaDisabled}
            >
              Deactivate 2FA
            </Button>
          </Col>
          <span className={styles.lastLogin}>
            <b>Last login:</b> {lastLogin ?? 'N/A'}
          </span>
        </>
      </Col>
    </Row>
  );
};

const RegFormInfo = ({ company }) => {
  return (
    // NOTE: Form.Item used only for styling
    <>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Geos">
            <Select mode="multiple" readOnly value={company.geos.map((g) => g.name)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Traffic Types">
            <Select mode="multiple" readOnly value={company.traffic_types.map((tt) => tt.name)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Other CPA Networks/Affiliate Programs I have experience with">
            <Input readOnly value={company.other_networks_experience} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Monthly Earnings">
            <Select readOnly value={company.monthly_earnings && company.monthly_earnings.label} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Preferable Language of Communication">
            <Select readOnly value={company.preferable_language && company.preferable_language.label} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const separateMBFromOtherPubCategories = (publisherCategories) => {
  const cpmCpc = {
    ...publisherCategories.find((category) => category.name === MBTrafficCategory.CpmCpc.name),
    title: MBTrafficCategory.CpmCpc.title
  };
  const cpaRs = {
    ...publisherCategories.find((category) => category.name === MBTrafficCategory.CpaRs.name),
    title: MBTrafficCategory.CpaRs.title
  };
  const mbCategories = [cpmCpc, cpaRs];

  const otherPublisherCategories = publisherCategories.filter(
    ({ name }) => name !== MBTrafficCategory.CpmCpc.name && name !== MBTrafficCategory.CpaRs.name
  );

  return [mbCategories, otherPublisherCategories];
};

export const AddOrEditCompany = ({
  countries,
  users,
  onSubmitSuccess,
  company,
  onFormChanged,
  wasFormChanged,
  isShown,
  loader,
  onCancel,
  isApprove,
  categoriesLoading,
  publisherCategories,
  advertiserCategories
}) => {
  const [loading, setLoading] = useState(false);
  const [pubPermissionsLoading, setPubPermissionsLoading] = useState(false);
  const [pubPermissions, setPubPermissions] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [currentUserId, setCurrentUserId] = useState();
  const [leadSources, setLeadSources] = useState([]);
  const [leadSourcesLoading, setLeadSourcesLoading] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [communicationChannels, setCommunicationChannels] = useState([]);
  const [communicationChannelsLoading, setCommunicationChannelsLoading] = useState(false);
  const [apiChecked, setApiChecked] = useState(false);
  const [publisherChecked, setPublisherChecked] = useState(false);
  const [advertiserChecked, setAdvertiserChecked] = useState(false);
  const [resendingPublisherMail, setResendingPublisherMail] = useState(false);
  const [resendingSSPMail, setResendingSSPMail] = useState(false);
  const [emailValidationPublisherPermission, setEmailValidationPublisherPermissionCompany] = useState({});
  const [emailValidationSSPPermission, setEmailValidationSSPPermissionCompany] = useState({});
  const [isActiveAndLastLoginPublisherData, setIsActiveAndLastLoginPublisherData] = useState({});
  const [isActiveAndLastLoginAdvertiserData, setIsActiveAndLastLoginAdvertiserData] = useState({});
  const [isActiveAndLastLoginDataLoading, setIsActiveAndLastLoginDataLoading] = useState(false);
  const [disabling2FaPublisher, setDisabling2FaPublisher] = useState(false);
  const [is2FaDisabledPublisher, setIs2FaDisabledPublisher] = useState(true);
  const [is2FaDisabledLoadingPublisher, setIs2FaDisabledLoadingPublisher] = useState(false);
  const [disabling2FaDsp, setDisabling2FaDsp] = useState(false);
  const [is2FaDisabledDsp, setIs2FaDisabledDsp] = useState(true);
  const [is2FaDisabledLoadingDsp, setIs2FaDisabledLoadingDsp] = useState(false);
  const [leadSource, setLeadSource] = useState();
  const [categoriesData, setCategoriesData] = useState({});
  const [whereTheCursorWas, setWhereTheCursorWas] = useState();
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [showLabels, setShowLabels] = useState([]);

  const [form] = Form.useForm();

  const formWrapperRef = useRef(null);

  const mounted = usePromise();

  const editing = !!company;
  const hasApiUrl = company?.api_url;
  const apiFieldsSpan = hasApiUrl ? 6 : 8;

  const [mbCategories, otherPublisherCategories] = separateMBFromOtherPubCategories(publisherCategories);

  const allCategories = useMemo(() => [...publisherCategories, ...advertiserCategories], [publisherCategories, advertiserCategories]);

  useEffect(() => {
    (async () => {
      if (company) {
        try {
          setIsActiveAndLastLoginDataLoading(true);
          const { publisher_last_login, ssp_last_login, publisher_is_active, ssp_is_active } = await mounted(
            companiesService.getPublisherAndAdvertiserInfo(company.id)
          );

          setIsActiveAndLastLoginPublisherData({
            isActive: publisher_is_active,
            lastLogin: publisher_last_login
          });

          setIsActiveAndLastLoginAdvertiserData({
            isActive: ssp_is_active,
            lastLogin: ssp_last_login
          });
        } catch (e) {
          showApiErrors(e);
        } finally {
          setIsActiveAndLastLoginDataLoading(false);
        }
      }
    })();
  }, [company, mounted]);

  useEffect(() => {
    (async () => {
      if (company && company.is_partner) {
        try {
          setIs2FaDisabledLoadingPublisher(true);
          const { is_2fa_active } = await mounted(companiesService.is2FaActivatedPublisher(company.id));
          // If 2FA is activated then button should be enabled
          setIs2FaDisabledPublisher(!is_2fa_active);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setIs2FaDisabledLoadingPublisher(false);
        }
      } else {
        setIs2FaDisabledPublisher(true);
      }
    })();
  }, [company, mounted]);

  useEffect(() => {
    (async () => {
      if (company && company.is_ssp_platform) {
        try {
          setIs2FaDisabledLoadingDsp(true);
          const { is_2fa_active } = await mounted(companiesService.is2FaActivatedDsp(company.id));
          // If 2FA is activated then button should be enabled
          setIs2FaDisabledDsp(!is_2fa_active);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setIs2FaDisabledLoadingDsp(false);
        }
      } else {
        setIs2FaDisabledDsp(true);
      }
    })();

    (async () => {
      if (company) {
        try {
          setNotesLoading(true);
          const notes = await mounted(companiesService.getCompanyNotes(company.id));
          setNotes(notes);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setNotesLoading(false);
        }
      }
    })();
  }, [company, mounted]);

  useEffect(() => {
    const getThisUser = async () => {
      try {
        setLoadingUser(true);
        const { id } = await mounted(auth.getUserProfile());
        setCurrentUserId(id);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingUser(false);
      }
    };

    const getPubPermissions = async () => {
      try {
        setPubPermissionsLoading(true);
        const pubPermissions = await mounted(pubPermissionsService.getAll());
        const sortedPubPermissions = [...pubPermissions].sort((a, b) => {
          // affiliate programs should always be first
          if (a.short_name === affiliateProgramsModule) {
            return -1;
          } else if (b.short_name === affiliateProgramsModule) {
            return 1;
          } else {
            return a.id - b.id;
          }
        });
        setPubPermissions(sortedPubPermissions);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setPubPermissionsLoading(false);
      }
    };

    const getActiveLeadSources = async () => {
      try {
        setLeadSourcesLoading(true);
        const activeLeadSources = await mounted(leadSourcesService.getAll({ is_active: true }));
        activeLeadSources.push(leadSourceOther);
        setLeadSources(activeLeadSources);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLeadSourcesLoading(false);
      }
    };

    const getCommunicationChannels = async () => {
      try {
        setCommunicationChannelsLoading(true);
        const communicationChannels = await mounted(companiesService.getCommunicationChannels());
        setCommunicationChannels(communicationChannels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCommunicationChannelsLoading(false);
      }
    };

    const getTags = async () => {
      try {
        setTagsLoading(true);
        const data = await mounted(companyTagsService.getAll());
        setTags(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTagsLoading(false);
      }
    };

    getThisUser();
    getPubPermissions();
    getActiveLeadSources();
    getCommunicationChannels();
    getTags();
  }, [mounted]);

  const isBroughtByDisabled = useMemo(() => company && company.brought_by, [company]);

  const initialCategoriesValues = useMemo(() => {
    const initialCategoriesFormValues = {};
    const initialCategoriesData = {};

    allCategories.forEach(({ name, default_am: accountManager, default_sub_managers: accountSubmanagers }) => {
      initialCategoriesFormValues[name] = {
        enabled: false,
        account_manager: accountManager || undefined,
        account_sub_managers: accountSubmanagers
      };
      initialCategoriesData[name] = {
        enabled: false,
        accountManager,
        accountSubmanagers
      };
    });
    initialCategoriesFormValues[MBTrafficCategory.CpaRsCpmCpc.name] = initialCategoriesFormValues[MBTrafficCategory.CpaRs.name];
    initialCategoriesData[MBTrafficCategory.CpaRsCpmCpc.name] = initialCategoriesData[MBTrafficCategory.CpaRs.name];

    setCategoriesData((oldValues) => ({ ...oldValues, ...initialCategoriesData }));

    return [initialCategoriesFormValues, initialCategoriesData];
  }, [allCategories]);

  const initialValues = useMemo(() => {
    const categories = { ...initialCategoriesValues[0] };
    if (!company) {
      return {
        api_show_only_live_domains: true,
        status: STATUS.ACTIVE,
        categories
      };
    }

    // map values for form:
    const apiConfigParsed = JSON.parse(company.api_config || null) || {};
    const time_zone = company.time_zone === null ? undefined : `${company.time_zone}`;
    const country = company.country || undefined;
    const type = company.type || undefined;
    const ocamba_zones = JSON.parse(company.ocamba_zones || null) || [];
    const api_ips_white_list = JSON.parse(company.api_ips_white_list || null) || [];
    const api_show_only_live_domains = !!apiConfigParsed.api_show_only_live_domains;
    const api_show_campaign_id = !!apiConfigParsed.api_show_campaign_id;
    const api_show_campaign_type = !!apiConfigParsed.api_show_campaign_type;
    const api_show_lead_prices = !!apiConfigParsed.api_show_lead_prices;
    const api_show_description = !!apiConfigParsed.api_show_description;
    const api_show_pattern_settings = !!apiConfigParsed.api_show_pattern_settings;
    const api_cmp_use_redirect = !!apiConfigParsed.api_cmp_use_redirect;
    // On create status is pending
    const status = isApprove ? STATUS.ACTIVE : company.status;
    const brought_by = company.brought_by_details?.id;
    const brought_by_date = moment(company.brought_by_date);
    const tags = company.tags.map((el) => el.id);

    const enabledCategories = {};
    company.categories.forEach(({ category: categoryId, account_manager, account_sub_managers }) => {
      const categoryName = allCategories.find(({ id }) => categoryId === id).name;
      enabledCategories[categoryName] = {
        enabled: true,
        accountManager: account_manager,
        accountSubmanagers: account_sub_managers
      };
      categories[categoryName] = {
        enabled: true,
        account_manager,
        account_sub_managers
      };

      if (categoryName === MBTrafficCategory.CpaRs.name || categoryName === MBTrafficCategory.CpmCpc.name) {
        const CpaRsCpmCpcName = MBTrafficCategory.CpaRsCpmCpc.name;
        enabledCategories[CpaRsCpmCpcName] = {
          enabled: true,
          accountManager: account_manager,
          accountSubmanagers: account_sub_managers
        };
        categories[CpaRsCpmCpcName] = {
          enabled: true,
          account_manager,
          account_sub_managers
        };
      }
    });
    setCategoriesData((oldValues) => ({ ...oldValues, ...enabledCategories }));

    const checkedPubPermissions = {};
    pubPermissions.forEach((el) =>
      company.pub_permissions.length > 0 && company.pub_permissions.indexOf(el.id) > -1
        ? (checkedPubPermissions[el.short_name] = true)
        : null
    );

    let origin = company.origin?.id ?? undefined;
    let custom_origin = undefined;

    if (company.custom_origin) {
      custom_origin = company.custom_origin.name;
      origin = leadSourceOther.id;
      setLeadSource(leadSourceOther.id);
    }

    const initialNotes = [];
    const initialShowLabels = [];
    notes.forEach(
      ({
        id,
        note,
        date_created: dateCreated,
        date_edited: dateEdited,
        date_created,
        date_edited,
        creator_details: { first_name: firstNameCreator, last_name: lastNameCreator },
        editor_details: { first_name: firstNameEditor, last_name: lastNameEditor }
      }) => {
        initialNotes.push({
          id,
          note,
          date_created,
          date_edited,
          dateCreated: moment(dateCreated),
          dateEdited: moment(dateEdited),
          creator: `${firstNameCreator} ${lastNameCreator}`,
          editor: `${firstNameEditor} ${lastNameEditor}`
        });

        if (dateCreated) {
          initialShowLabels.push(true);
        } else {
          initialShowLabels.push(false);
        }
      }
    );
    setShowLabels(initialShowLabels);

    return {
      ...company,
      ocamba_zones,
      time_zone,
      api_ips_white_list,
      country,
      type,
      api_show_only_live_domains,
      api_show_campaign_id,
      api_show_campaign_type,
      api_show_lead_prices,
      api_show_description,
      api_show_pattern_settings,
      api_cmp_use_redirect,
      status,
      brought_by,
      brought_by_date,
      categories,
      tags,
      origin,
      custom_origin,
      notes: initialNotes,
      ...checkedPubPermissions
    };
  }, [company, notes, isApprove, pubPermissions, allCategories, initialCategoriesValues]);

  const setInitialChecked = useMemo(() => {
    return () => {
      if (!company) {
        setApiChecked(false);
        setPublisherChecked(false);
        setAdvertiserChecked(false);
      } else {
        const { api, is_partner, is_ssp_platform } = company;
        setApiChecked(!!api);
        setPublisherChecked(!!is_partner);
        setAdvertiserChecked(!!is_ssp_platform);
      }
    };
  }, [company]);

  useBeforeUnload(wasFormChanged);

  useEffect(() => setInitialChecked(), [company, setInitialChecked]);

  const resetForm = useCallback(() => {
    form.resetFields();
    setInitialChecked();
    onFormChanged(false);
    setEmailValidationPublisherPermissionCompany({});
    setEmailValidationSSPPermissionCompany({});
  }, [form, onFormChanged, setInitialChecked]);

  useEffect(() => resetForm(), [company, resetForm]);

  const validateEmail = useCallback(
    async (email) => {
      if (email) {
        try {
          const data = await companiesService.validateEmail(email);

          const publisherPermissionCompany = data.find((localCompany) => {
            const { id: localCompanyId, publisher_account_id, is_partner } = localCompany;
            const hasPublisherAccount = !!publisher_account_id || is_partner;

            return company?.id !== localCompanyId && hasPublisherAccount;
          });

          const sspPermissionCompany = data.find((localCompany) => {
            const { id: localCompanyId, ssp_account_id, is_ssp_platform } = localCompany;
            const hasSSPAccount = !!ssp_account_id || is_ssp_platform;

            return company?.id !== localCompanyId && hasSSPAccount;
          });

          setEmailValidationPublisherPermissionCompany(publisherPermissionCompany);
          setEmailValidationSSPPermissionCompany(sspPermissionCompany);
        } catch (e) {
          showApiErrors(e);
        }
      }
    },
    [company]
  );

  useEffect(() => {
    if (isShown) {
      resetForm();

      if (editing) {
        validateEmail(company.contact_mail);
      }
    } else {
      setLeadSource(undefined);
      setCategoriesData({ ...initialCategoriesValues[1] });
      setShowLabels([]);
    }

    onFormChanged(false);
  }, [isShown, notes, editing, company, resetForm, validateEmail, onFormChanged, initialCategoriesValues]);

  const handleReset = () => {
    if (wasFormChanged) {
      confirmClose(() => {
        resetForm();
      });
    } else {
      resetForm();
    }
  };

  const countriesOptions = useMemo(() => generateCountriesOptions(countries), [countries]);
  const usersOptions = useMemo(() => generateUserOptions(users), [users]);

  useEffect(() => {
    if (apiChecked) {
      const key = (company && company.api_key) || generateApiKey();
      form.setFieldsValue({ api_key: key });
    } else {
      form.resetFields(['api_key', 'api_ips_white_list', 'default_link_ttl']);
    }
  }, [apiChecked, company, form]);

  const handleCancel = () => {
    wasFormChanged ? confirmClose(() => onCancel()) : onCancel();
  };

  const handleSubmit = async (values) => {
    const data = { ...values };
    const formCategoriesData = { ...data.categories };

    const enabledCategories = Object.keys(formCategoriesData).filter((category) => !!formCategoriesData[category].enabled);
    if (!enabledCategories.length) {
      openErrorNotification({ message: 'Please enable at least one category!' });
      return;
    }
    // NOTE: Check create mb categories Billing accounts logic on Coinis backend
    let cpaRsCpmCpcIndex = -1;
    const cpaRsCpmCpcEnabled = !!enabledCategories.find((category, index) => {
      cpaRsCpmCpcIndex = index;
      return category === MBTrafficCategory.CpaRsCpmCpc.name;
    });

    if (cpaRsCpmCpcEnabled) {
      const cpaRsEnabled = !!enabledCategories.find(
        (category) => category === MBTrafficCategory.CpaRs.name || category === MBTrafficCategory.CpmCpc.name
      );
      const cpmCpcEnabled = !!enabledCategories.find(
        (category) => category === MBTrafficCategory.CpaRs.name || category === MBTrafficCategory.CpmCpc.name
      );

      if (!cpaRsEnabled && !cpmCpcEnabled) {
        openErrorNotification({
          message: `Please enable ${MBTrafficCategory.CpaRs.title} or ${MBTrafficCategory.CpmCpc.title}, or disable ${MBTrafficCategory.CpaRsCpmCpc.title}!`
        });
        return;
      }

      if (cpaRsEnabled) {
        formCategoriesData[MBTrafficCategory.CpaRs.name] = formCategoriesData[MBTrafficCategory.CpaRsCpmCpc.name];
      }
      if (cpmCpcEnabled) {
        formCategoriesData[MBTrafficCategory.CpmCpc.name] = formCategoriesData[MBTrafficCategory.CpaRsCpmCpc.name];
      }

      enabledCategories.splice(cpaRsCpmCpcIndex, 1);
    }

    setLoading(true);

    data.brought_by_date = data.brought_by_date && data.brought_by_date.format('YYYY-MM-DD');

    data.categories = enabledCategories.map((categoryName) => ({
      ...formCategoriesData[categoryName],
      enabled: undefined,
      category: allCategories.find(({ name }) => categoryName === name).id
    }));

    if (!data.has_traffic) {
      data.has_traffic = 0;
    } else {
      data.has_traffic = 1;
    }
    if (!data.is_partner) {
      data.is_partner = 0;
    } else {
      data.is_partner = 1;
    }
    if (!data.is_ssp_platform) {
      data.is_ssp_platform = 0;
    } else {
      data.is_ssp_platform = 1;
    }
    if (!data.has_product) {
      data.has_product = 0;
    } else {
      data.has_product = 1;
    }
    if (!apiChecked) {
      data.api = 0;
      data.api_key = null;
    } else {
      data.api = 1;
    }

    if (data.ocamba_zones) {
      const ocamba_zones = data.ocamba_zones.map((value) => {
        return parseInt(value, 10);
      });
      data.ocamba_zones = JSON.stringify(ocamba_zones);
    }
    if (data.api_ips_white_list && data.api_ips_white_list.length) {
      data.api_ips_white_list = JSON.stringify(data.api_ips_white_list);
    } else {
      data.api_ips_white_list = null;
    }

    data.pub_permissions = pubPermissions.map((el) => (data[el.short_name] ? el.id : undefined)).filter((el) => el);

    if (data.origin === leadSourceOther.id) {
      data.origin = undefined;
    } else {
      data.custom_origin = undefined;
    }

    try {
      var res;
      if (editing) {
        res = await companiesService.edit(company.id, data);
        openSuccessNotification({ message: `Successfully ${isApprove ? 'approved' : 'edited'} company`, duration: 8 });
      } else {
        res = await companiesService.create(data);
        openSuccessNotification({ message: 'Successfully added company', duration: 8 });
      }

      let messages = [];

      res.warnings.forEach((warning, index) => {
        messages.push(<div key={index}>- {warning}</div>);
      });

      if (messages.length) {
        openWarningNotification({ message: <div>{messages}</div>, duration: 8 });
      }

      onSubmitSuccess();
      onFormChanged(false);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const generateCategoryCheckboxField = (name, title, circleCheckbox = true) => {
    const fieldsToValidate = Object.keys(ModuleToCategoriesMap); // TODO: Validate only module field relative to category
    fieldsToValidate.push(advertiserPlatform);
    return (
      <Form.Item
        className={circleCheckbox && styles.categoryCheckboxFormItem}
        name={['categories', `${name}`, 'enabled']}
        valuePropName="checked"
      >
        <Checkbox
          className={styles.categoryCheckbox}
          onChange={() => {
            form.validateFields(fieldsToValidate);
          }}
        >
          {title}
        </Checkbox>
      </Form.Item>
    );
  };

  const generateAccountManagerAndSubmanagersFields = (name) => {
    const isCurrentUserAdmin = auth.getPermissions() === true;
    const defaultAccountManagerId = initialCategoriesValues[1][name].accountManager;
    const currentAccountManagerId = form.getFieldValue(['categories', `${name}`, 'account_manager']);
    const selectDisabled =
      !isCurrentUserAdmin && !(currentUserId === defaultAccountManagerId) && !(currentUserId === currentAccountManagerId);

    return (
      <Col span={24} className={styles.accountManagerAndSubmanagersWrapperCol}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Account manager" name={['categories', `${name}`, 'account_manager']} shouldUpdate>
              <Select
                placeholder="Select account manager"
                optionFilterProp="data-searchvalue"
                showSearch
                allowClear
                disabled={selectDisabled}
              >
                {usersOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Account submanagers" name={['categories', `${name}`, 'account_sub_managers']} shouldUpdate>
              <Select
                placeholder="Select account submanager/s"
                mode="multiple"
                optionFilterProp="data-searchvalue"
                showSearch
                allowClear
                disabled={selectDisabled}
              >
                {usersOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    );
  };

  const generateCategories = (categories) => {
    return categories.map(({ name, title }) => {
      return (
        <Row key={name}>
          <Col span={6}>{generateCategoryCheckboxField(name, title)}</Col>

          {categoriesData[name]?.enabled && generateAccountManagerAndSubmanagersFields(name)}
        </Row>
      );
    });
  };

  const generatePubPermissions = () => {
    return pubPermissions.map(({ id, name, short_name: shortName }, index) => {
      return (
        <Col key={id} span={index < 4 ? 5 : 4}>
          <Form.Item
            className={styles.switchWrapper}
            name={shortName}
            valuePropName="checked"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, switchValue) {
                  if (switchValue) {
                    const relatedCategories = ModuleToCategoriesMap[shortName];
                    const enabledRelatedCategories = relatedCategories.filter(
                      (category) => !!getFieldValue(['categories', category, 'enabled'])
                    );
                    let relatedCategoriesNamesMsgPart = '';

                    if (enabledRelatedCategories.length !== relatedCategories.length) {
                      if (shortName === offerwallModule) {
                        relatedCategoriesNamesMsgPart = `MB Traffic Source - ${MBTrafficCategory.CpaRs.title} and MB Traffic Source - ${MBTrafficCategory.CpmCpc.title}`;
                      } else {
                        relatedCategoriesNamesMsgPart = allCategories.find(({ name }) => relatedCategories[0] === name).title;
                      }

                      return Promise.reject(
                        new Error(`Can not enable ${name} module without enabling ${relatedCategoriesNamesMsgPart} category!`)
                      );
                    }
                  }

                  return Promise.resolve();
                }
              })
            ]}
          >
            <SwitchWithLabelOnRight
              label={name}
              onChange={() => {
                form.validateFields([offerwallModule, affiliateProgramsModule]);
              }}
            />
          </Form.Item>
        </Col>
      );
    });
  };

  const resendPublisherMail = async () => {
    try {
      setResendingPublisherMail(true);
      await companiesService.resendPublisherMail(company.id);
      openSuccessNotification({ message: 'Successfully resent mail!', duration: 8 });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setResendingPublisherMail(false);
    }
  };

  const resendSSPMail = async () => {
    try {
      setResendingSSPMail(true);
      await companiesService.resendSSPMail(company.id);
      openSuccessNotification({ message: 'Successfully resent mail!', duration: 8 });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setResendingSSPMail(false);
    }
  };

  const disable2FaPublisher = async () => {
    try {
      setDisabling2FaPublisher(true);
      await companiesService.disable2FaPublisher(company.id);
      setIs2FaDisabledPublisher(true);
      openSuccessNotification({ message: 'Successfully deactivated 2Fa', duration: 8 });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setDisabling2FaPublisher(false);
    }
  };

  const disable2FaDsp = async () => {
    try {
      setDisabling2FaDsp(true);
      await companiesService.disable2FaDsp(company.id);
      setIs2FaDisabledDsp(true);
      openSuccessNotification({ message: 'Successfully deactivated 2Fa', duration: 8 });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setDisabling2FaDsp(false);
    }
  };

  const handleMacrosClick = (macroContent) => {
    const oldS2sUrlValue = form.getFieldsValue().s2s_url || '';

    const cursorWasAt = whereTheCursorWas;
    if (cursorWasAt !== undefined) {
      setWhereTheCursorWas((prevState) => (prevState += macroContent.length));
    }

    const newS2sUrlValue =
      cursorWasAt !== undefined
        ? oldS2sUrlValue.substring(0, cursorWasAt) + macroContent + oldS2sUrlValue.substring(cursorWasAt, oldS2sUrlValue.length)
        : oldS2sUrlValue + macroContent;

    form.setFieldsValue({ s2s_url: newS2sUrlValue });
  };

  const handleNoteRemove = (index) => {
    setShowLabels((prevState) => prevState.filter((_, noteIndex) => noteIndex !== index));
  };

  const generateManagerNames = ({ productManagers, trafficManagers }) => {
    const managers = productManagers.concat(trafficManagers);

    return managers.map((manager) => getUserFullName(manager, 'Unknown manager')).join(', ');
  };

  const handleFormValuesChange = (changedValueObj, allValues) => {
    onFormChanged(true);

    const { origin, categories } = changedValueObj;

    if (origin) {
      setLeadSource(origin);
    }

    if (categories) {
      Object.entries(categories).forEach(([categoryName, categoryData]) => {
        const { enabled: newEnabled, account_manager: newAccountManager, account_sub_managers: newAccountSubmanagers } = categoryData;

        const categoryChangedData = {};
        // Check with undefined if enabled is changed, because it is boolean type
        if (newEnabled !== undefined) {
          categoryChangedData.enabled = newEnabled;
        } else if (categoryData.hasOwnProperty('account_manager')) {
          // account_manager has undefined value if removed, so we check with hasOwnProperty
          categoryChangedData.accountManager = newAccountManager;
        } else if (newAccountSubmanagers) {
          categoryChangedData.accountSubmanagers = newAccountSubmanagers;
        }

        setCategoriesData((oldValues) => ({ ...oldValues, [categoryName]: { ...oldValues[categoryName], ...categoryChangedData } }));
      });
    }
  };

  const arePrefixesRequired =
    categoriesData[MBTrafficCategory.CpaRs.name]?.enabled ||
    categoriesData[MBTrafficCategory.CpmCpc.name]?.enabled ||
    categoriesData[MBAdvertiserCategoryName]?.enabled;

  const CompanyInfoForm = (
    <div ref={formWrapperRef}>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={() => {
          const firstErrorElement = formWrapperRef.current.getElementsByClassName('ant-form-item-has-error')[0];
          firstErrorElement.scrollIntoView();
        }}
        initialValues={initialValues}
        onValuesChange={handleFormValuesChange}
      >
        <Collapse
          className={styles.collapse}
          defaultActiveKey={[Panel.GeneralInformation, Panel.ContactInformation, Panel.CompanyManagement, Panel.PlatformManagement]}
          bordered={false}
          ghost={true}
        >
          <AntdPanel header={<TitleWithDivider title={Panel.GeneralInformation} />} key={Panel.GeneralInformation}>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item
                  name="prefixes"
                  label="Prefixes"
                  rules={[
                    {
                      required: arePrefixesRequired,
                      message: 'Please type company prefixes!'
                    }
                  ]}
                >
                  <Select mode="tags" placeholder="Type company prefixes" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please type company name!'
                    }
                  ]}
                >
                  <Input placeholder="Type company name" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="is_individual" label="Is individual" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={8}>
                <Form.Item name="business_name" label="Business name">
                  <Input placeholder="Read only" readOnly />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="legal_name" label="Legal name">
                  <Input placeholder="Read only" readOnly />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="group_name" label="Group name">
                  <Input placeholder="Read only" readOnly />
                </Form.Item>
              </Col>
            </Row>
          </AntdPanel>

          <AntdPanel header={<TitleWithDivider title={Panel.ContactInformation} />} key={Panel.ContactInformation}>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item
                  label="First name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please type first name!'
                    }
                  ]}
                >
                  <Input placeholder="Type first name" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Last name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please type last name!'
                    }
                  ]}
                >
                  <Input placeholder="Type last name" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Email"
                  name="contact_mail"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    },
                    {
                      required: true,
                      message: 'Please type email!'
                    }
                  ]}
                >
                  <Input placeholder="Type email" onBlur={(e) => validateEmail(e.target.value)} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="Phone" name="contact_phone">
                  <Input placeholder="Type phone number" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Channel" name="communication_channel">
                  <Select
                    placeholder="Select channel"
                    loading={communicationChannelsLoading}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                  >
                    {generateSelectOptionsWithIdValues(communicationChannels)}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Channel contact" name="contact_skype">
                  <Input placeholder="Type channel contact" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="Website" name="website">
                  <Input placeholder="Type website URL" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Address" name="address">
                  <Input placeholder="Type address" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="ZIP code" name="zip_code">
                  <Input placeholder="Type ZIP code" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="City" name="city">
                  <Input placeholder="Type city name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Country" name="country">
                  <Select
                    showSearch
                    placeholder="Select country"
                    style={{ width: '100%' }}
                    optionFilterProp="data-searchvalue"
                    loading={loader.countries}
                  >
                    {countriesOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </AntdPanel>
          <AntdPanel header={<TitleWithDivider title={Panel.Notes} />} key={Panel.Notes}>
            <Form.List name="notes" loading={notesLoading}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <Row gutter={32}>
                        <Col span={16}>
                          <Form.Item
                            name={[field.name, 'note']}
                            fieldKey={[field.fieldKey, 'note']}
                            span={16}
                            rules={[
                              {
                                required: true,
                                message: 'Please write note or delete it!'
                              }
                            ]}
                          >
                            <TextArea placeholder="Note" />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <span
                            style={{ marginLeft: 5, cursor: 'pointer' }}
                            onClick={() => {
                              handleNoteRemove(index);
                              remove(index);
                            }}
                          >
                            <img src="images/delete_icon.png" alt="Delete" width="22px" />{' '}
                          </span>
                        </Col>
                      </Row>
                      {showLabels[index] && (
                        <Row gutter={32}>
                          <Col span={4}>
                            <Form.Item
                              label={'Created by:'}
                              name={[field.name, 'creator']}
                              fieldKey={[field.fieldKey, 'creator']}
                              wrapperCol={{ span: 24 }}
                            >
                              <Input className={styles.notesInfoField} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label={'Date created:'}
                              name={[field.name, 'dateCreated']}
                              fieldKey={[field.fieldKey, 'dateCreated']}
                              wrapperCol={{ span: 24 }}
                            >
                              <DatePicker format="YYYY-MM-DD HH:mm:ss" className={styles.notesInfoField} />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label={'Edited by:'}
                              name={[field.name, 'editor']}
                              fieldKey={[field.fieldKey, 'editor']}
                              wrapperCol={{ span: 24 }}
                            >
                              <Input className={styles.notesInfoField} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label={'Date edited:'}
                              name={[field.name, 'dateEdited']}
                              fieldKey={[field.fieldKey, 'dateEdited']}
                              wrapperCol={{ span: 24 }}
                            >
                              <DatePicker format="YYYY-MM-DD HH:mm:ss" className={styles.notesInfoField} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      <CustomDivider margin="0 0 24px 0" />
                    </div>
                  ))}
                  <Form.Item>
                    <Button ghost type="primary" onClick={() => add()}>
                      <PlusOutlined /> Add new Note
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </AntdPanel>
          <AntdPanel header={<TitleWithDivider title={Panel.CompanyManagement} />} key={Panel.CompanyManagement}>
            <Row className={styles.publisherCategoriesWrapperRow}>
              <Col span={24}>
                <Row>
                  <Col>
                    <h4>Publisher category</h4>
                  </Col>
                </Row>

                {categoriesLoading ? (
                  <Spin size="large" />
                ) : (
                  <>
                    <Row key={MBTrafficCategory.CpaRsCpmCpc.name}>
                      <Col span={6}>
                        {generateCategoryCheckboxField(MBTrafficCategory.CpaRsCpmCpc.name, MBTrafficCategory.CpaRsCpmCpc.title)}
                      </Col>

                      {categoriesData[MBTrafficCategory.CpaRsCpmCpc.name]?.enabled && (
                        <>
                          <Col span={8} className={styles.mbCategoriesCheckboxesWrapperCol}>
                            <Row gutter={24}>
                              {mbCategories.map(({ name, title }) => (
                                <Col span={9} key={name}>
                                  {generateCategoryCheckboxField(name, title, false)}
                                </Col>
                              ))}
                            </Row>
                          </Col>
                          {generateAccountManagerAndSubmanagersFields(MBTrafficCategory.CpaRsCpmCpc.name)}
                        </>
                      )}
                    </Row>

                    {generateCategories(otherPublisherCategories)}
                  </>
                )}
              </Col>
            </Row>

            <Row className={styles.advertiserCategoriesWrapperRow}>
              <Col span={24}>
                <Row>
                  <Col>
                    <h4>Advertiser category</h4>
                  </Col>
                </Row>

                {categoriesLoading ? <Spin size="large" /> : generateCategories(advertiserCategories)}
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="Lead source" name="origin">
                  <Select placeholder="Type or select lead source" loading={leadSourcesLoading} allowClear>
                    {generateSelectOptionsWithIdValues(leadSources)}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Brought by" name="brought_by">
                  <Select
                    placeholder="Select user"
                    style={{ width: '100%' }}
                    disabled={isBroughtByDisabled}
                    loading={loadingUser}
                    optionFilterProp="data-searchvalue"
                    showSearch
                  >
                    {usersOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Brought by date" name="brought_by_date">
                  <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} disabled={isBroughtByDisabled}></DatePicker>
                </Form.Item>
              </Col>
            </Row>

            {leadSource === leadSourceOther.id && (
              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item
                    label="Custom lead source"
                    name="custom_origin"
                    rules={[{ required: true, message: 'Please type custom lead source' }]}
                  >
                    <Input placeholder="Type custom lead source" />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="Traffic assigned users" name="assigned">
                  <Select
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) => {
                      if (isNaN(input)) {
                        return option['data-searchvalue'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      } else {
                        return option.value.toString() === input;
                      }
                    }}
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                    placeholder="Traffic assigned users"
                    loading={loader.users}
                    allowClear
                  >
                    {usersOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Product assigned users" name="product_users">
                  <Select
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) => {
                      if (isNaN(input)) {
                        return option['data-searchvalue'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      } else {
                        return option.value.toString() === input;
                      }
                    }}
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                    placeholder="Product assigned users"
                    loading={loader.users}
                    allowClear
                  >
                    {usersOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Tags" name="tags">
                  <Select
                    placeholder="Select tags"
                    style={{ width: '100%' }}
                    loading={tagsLoading}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    mode="multiple"
                    allowClear
                  >
                    {generateOptions(tags, 'name', 'id')}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </AntdPanel>

          <AntdPanel header={<TitleWithDivider title={Panel.TechSettings} />} key={Panel.TechSettings}>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item label="CID" name="click_param">
                  <Input placeholder="cid" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="SID" name="source_param">
                  <Input placeholder="sid" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Additional parameters" name="additional_params">
                  <Input placeholder="cid=[CLICK_ID]&sid=[SUB_ID]" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  label="S2S URL"
                  name="s2s_url"
                  onBlur={(e) => {
                    setWhereTheCursorWas(e.target.selectionStart);
                  }}
                >
                  <Input
                    placeholder="S2s URL"
                    addonAfter={
                      <Popover title="System Macros" trigger="click" content={generateMacrosContent(s2sMacros, handleMacrosClick)}>
                        <span style={{ cursor: 'pointer' }}>Macros</span>
                      </Popover>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col>
                <Form.Item label="Traffic" name="has_traffic" valuePropName="checked" className={styles.formItemToHorizontal}>
                  <Checkbox />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item label="Product" name="has_product" valuePropName="checked" className={styles.formItemToHorizontal}>
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col>
                <Form.Item label="API" /*name="api" valuePropName="checked"*/ className={styles.formItemToHorizontal}>
                  <Checkbox checked={apiChecked} onClick={() => setApiChecked((prevChecked) => !prevChecked)} />
                </Form.Item>
              </Col>

              {apiChecked ? (
                <Col span={24}>
                  <Row gutter={32}>
                    <Col span={apiFieldsSpan}>
                      <Form.Item
                        label="API key"
                        name="api_key"
                        rules={[
                          {
                            required: true,
                            message: 'Please type API key!'
                          }
                        ]}
                      >
                        <Input placeholder="Type API key" />
                      </Form.Item>
                    </Col>

                    {company && company.api_url ? (
                      <Col span={apiFieldsSpan}>
                        <Form.Item label="Api url" name="api_url">
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    ) : null}

                    <Col span={apiFieldsSpan}>
                      <Form.Item
                        label="API ips white list"
                        name="api_ips_white_list"
                        rules={[
                          {
                            validator: async (_, value) => {
                              if (value && value.length > 100) {
                                throw new Error('No more than 100 APIs');
                              }
                            }
                          }
                        ]}
                      >
                        <Select
                          mode="tags"
                          style={{ width: '100%' }}
                          //onChange={handleTag}
                          tokenSeparators={[',']}
                        >
                          {/* TODO: ???*/}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={apiFieldsSpan}>
                      <Form.Item
                        label="Default link ttl"
                        name="default_link_ttl"
                        rules={[
                          {
                            // TODO: maybe use number input?
                            validator: async (_, value) => {
                              if (isNaN(value) && value !== undefined) {
                                throw new Error('Enter valid number');
                              }
                            }
                          }
                        ]}
                      >
                        <Input placeholder="Type default ttl" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col span={4}>
                      <Form.Item
                        label={tooltipLabel('API 1 domain', 'API export for each campaign only one live domain')}
                        name="api_show_only_live_domains"
                        valuePropName="checked"
                      >
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={tooltipLabel('API C. Type', 'API Export Campaign Type')}
                        name="api_show_campaign_type"
                        valuePropName="checked"
                      >
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label={tooltipLabel('API ID', 'API Campaign ID')} name="api_show_campaign_id" valuePropName="checked">
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={tooltipLabel('API Prices', 'API Export Prices')}
                        name="api_show_lead_prices"
                        valuePropName="checked"
                      >
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={tooltipLabel('API Description', 'API Export Description')}
                        name="api_show_description"
                        valuePropName="checked"
                      >
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={tooltipLabel('API Pattern', 'API Export Pattern Info')}
                        name="api_show_pattern_settings"
                        valuePropName="checked"
                      >
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={tooltipLabel('API Export redirect domain', 'API export campaign redirect domain instead of content domain')}
                        name="api_cmp_use_redirect"
                        valuePropName="checked"
                      >
                        <OnOffSwitch />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Row>
          </AntdPanel>

          <AntdPanel header={<TitleWithDivider title={Panel.PlatformManagement} />} key={Panel.PlatformManagement}>
            <Row gutter={32}>
              <Col span={24}>
                <Row gutter={32}>
                  <Col>
                    <Form.Item label="Publisher platform" name="is_partner" valuePropName="checked" className={styles.formItemToHorizontal}>
                      <Checkbox
                        checked={publisherChecked}
                        onClick={() => setPublisherChecked((prevChecked) => !prevChecked)}
                        disabled={emailValidationPublisherPermission?.id}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  {publisherChecked ? (
                    <Col span={24}>
                      <Spin spinning={pubPermissionsLoading}>
                        <Row gutter={32}>{generatePubPermissions()}</Row>
                      </Spin>
                    </Col>
                  ) : null}
                </Row>
                {editing &&
                  publisherChecked &&
                  (!isActiveAndLastLoginDataLoading && !is2FaDisabledLoadingPublisher ? (
                    <PlatformManagementDetails
                      platform={COMPANY_TYPE.PUBLISHER}
                      isActiveAndLastLoginData={isActiveAndLastLoginPublisherData}
                      resendingMail={resendingPublisherMail}
                      onMailResend={resendPublisherMail}
                      deactivating2Fa={disabling2FaPublisher}
                      on2FaDeactivation={disable2FaPublisher}
                      is2FaDisabled={is2FaDisabledPublisher}
                    />
                  ) : (
                    <Spin size="large" />
                  ))}

                <Row>
                  <Col span={24}>
                    {emailValidationPublisherPermission?.id ? (
                      <div className={styles.emailValidationMessage}>
                        There is already a company with this e-mail and Publisher platform permission. Company ID:{' '}
                        {emailValidationPublisherPermission.id}, Account Managers:{' '}
                        {generateManagerNames({
                          productManagers: emailValidationPublisherPermission.product_managers_details || [],
                          trafficManagers: emailValidationPublisherPermission.traffic_managers_details || []
                        })}
                        .
                      </div>
                    ) : null}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Item
                      name={advertiserPlatform}
                      valuePropName="checked"
                      rules={[
                        {
                          validator: async (_, checked) => {
                            if (checked && !form.getFieldValue(['categories', DspAdvertiserCategory.name, 'enabled'])) {
                              throw new Error(
                                `Can not enable advertiser platform without enabling ${DspAdvertiserCategory.title} category!`
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Checkbox disabled={emailValidationSSPPermission?.id}>Advertiser platform</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                {editing &&
                  advertiserChecked &&
                  (!isActiveAndLastLoginDataLoading && !is2FaDisabledLoadingDsp ? (
                    <PlatformManagementDetails
                      platform={COMPANY_TYPE.ADVERTISER}
                      isActiveAndLastLoginData={isActiveAndLastLoginAdvertiserData}
                      resendingMail={resendingSSPMail}
                      onMailResend={resendSSPMail}
                      deactivating2Fa={disabling2FaDsp}
                      on2FaDeactivation={disable2FaDsp}
                      is2FaDisabled={is2FaDisabledDsp}
                    />
                  ) : (
                    <Spin size="large" />
                  ))}
                <Row>
                  <Col span={24}>
                    {emailValidationSSPPermission?.id ? (
                      <div className={styles.emailValidationMessage}>
                        There is already a company with this e-mail and SSP platform permission. Company ID:{' '}
                        {emailValidationSSPPermission.id}, Account Managers:{' '}
                        {generateManagerNames({
                          productManagers: emailValidationSSPPermission.product_managers_details || [],
                          trafficManagers: emailValidationSSPPermission.traffic_managers_details || []
                        })}
                        .
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </AntdPanel>
        </Collapse>

        {!editing ? (
          <Row gutter={32}>
            <Col span={6} />
            <Col span={6} />
            <Col span={6}>
              <Form.Item className="modal_buttons">
                <Button className="modal-button" type="danger" style={{ marginLeft: 8 }} onClick={handleReset}>
                  Clear All Fields
                </Button>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item className="modal_buttons">
                <Button type="primary" htmlType="submit" className="modal-button" loading={loading}>
                  Add Company
                </Button>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <>
            <Row
              gutter={32}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Col span={12}>
                <Row
                  gutter={20}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Col>
                    <Button size="large" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>

                  <Col>
                    <Button type="primary" size="large" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
                      {isApprove ? 'Approve company' : 'Save Changes'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );

  return (
    <Modal
      title={isApprove ? 'Approve company' : editing ? 'Edit company' : 'Add new company'}
      wrapClassName="vertical-center-modal"
      visible={isShown}
      onCancel={handleCancel}
      footer={null}
      width="80%"
      forceRender
    >
      <Tabs>
        <Tabs.TabPane tab="Info" key="info" forceRender>
          {CompanyInfoForm}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Diary" key="diary" forceRender>
          <CompanyDiary companyId={company?.id} onAction={onCancel} />
        </Tabs.TabPane>
        {editing && (
          <Tabs.TabPane tab="Reg. form info" key="reg-form-info" forceRender>
            <RegFormInfo company={company}></RegFormInfo>
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
};
