import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { DomainsInfoTable } from './DomainsInfoTable';
import { identityService } from '../../../services/identity';
import { useFetch } from '../../../utils/hooks';

export const IdentityInfoModal = ({ modalVisible, info, onCloseModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const id = info['id'];

  const loadDomains = (cancelToken) => identityService.getPagedDomains({ id, currentPage, pageSize, cancelToken });

  const [
    {
      data: { results: domains, count: totalItems },
      isFetching
    },
    getDomains
  ] = useFetch(loadDomains);

  useEffect(() => {
    getDomains();
  }, [currentPage, getDomains, info]);

  const listOfDetails = [
    { name: 'Id', value: info['id'] },
    { name: 'Name', value: info['name'] },
    { name: 'Status', value: info['status'] },
    { name: 'Type', value: info['type'] },
    { name: 'Https', value: info['https'] ? 'True' : 'False' },
    { name: 'Description', value: info['description'] },
    { name: 'Active Domains Number', value: info['active_domains_num'] },
    { name: 'Replacement Interval', value: info['replacement_interval'] },
    { name: 'Tests', value: info['tests'].length > 0 ? info['tests'].join(', ') : '/' },
    { name: 'Rate limit enabled', value: info['rate_limit_enabled'] === true ? 'True' : 'False' },
    { name: 'Notify users', value: info['notify_users'].length > 0 ? info['notify_users'].join(', ') : '/' },
    { name: 'Notify on Slack', value: info['notify_on_slack'] === true ? 'True' : 'False' },
    { name: 'Notify on Mail', value: info['notify_on_mail'] === true ? 'True' : 'False' }
  ];

  return (
    <Modal title="Identity Info" visible={modalVisible} onCancel={onCloseModal} width={1200}>
      <Row gutter={24}>
        <Col span={10}>
          <h2>Identity Info:</h2>
          {listOfDetails.map((el) => (
            <Row key={el.name} gutter={32} style={{ justifyContent: 'left', display: 'flex', marginTop: 5 }}>
              <Col style={{ textAlign: 'left' }} span={12}>
                <b>{el.name}</b>
              </Col>
              <Col span={12} style={{ textAlign: 'left' }}>
                {el.value}
              </Col>
            </Row>
          ))}
        </Col>
        <Col span={14}>
          <DomainsInfoTable
            loading={isFetching}
            domains={domains}
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginationChange={(page) => setCurrentPage(page)}
          />
        </Col>
      </Row>
    </Modal>
  );
};
