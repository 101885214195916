import { EyeOutlined } from '@ant-design/icons';
import { Col, Radio, Row, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { dashboardsService, DASH_RANGE_TYPE } from '../../services/dashboards';
import { openWarningNotification } from '../../utils/notifications';
import { showApiErrors } from '../../utils/showApiErrors';
import styles from './index.module.css';

const columnsTopCampaigns = [
  {
    title: 'Campaign name',
    dataIndex: 'channel'
  },
  {
    title: 'Spent $',
    dataIndex: 'spent'
  },
  {
    title: 'Earned $',
    dataIndex: 'earned'
  },
  {
    title: 'Profit $',
    dataIndex: 'profit'
  },
  {
    title: 'ROI %',
    dataIndex: 'roi'
  }
];

const columnsTopProducts = [
  {
    title: 'Product title',
    dataIndex: 'product_title'
  },
  {
    title: 'Spent %',
    dataIndex: 'spent_percentage'
  }
];

const columnsTopProductCompanies = [
  {
    title: 'Company name',
    dataIndex: 'company_name'
  },
  {
    title: 'Spent $',
    dataIndex: 'spent'
  },
  {
    title: 'Earned $',
    dataIndex: 'earned'
  },
  {
    title: 'Profit $',
    dataIndex: 'profit'
  },
  {
    title: 'ROI %',
    dataIndex: 'roi'
  },
  {
    title: 'Top 5 Products',
    render: ({ top_products }) => {
      const table = <Table pagination={false} columns={columnsTopProducts} dataSource={top_products} />;
      return (
        <Tooltip title={table}>
          <EyeOutlined />
        </Tooltip>
      );
    }
  }
];

const columnsTopNegativeCampaigns = [
  {
    title: 'Campaign name',
    dataIndex: 'channel'
  },
  {
    title: 'Country',
    dataIndex: 'country'
  },
  {
    title: 'OS',
    dataIndex: 'os'
  },
  {
    title: 'Browser',
    dataIndex: 'browser'
  },
  {
    title: 'Profit',
    dataIndex: 'profit'
  }
];

const columnsOptimizationCampaigns = [
  {
    title: 'AM name',
    dataIndex: 'am_name'
  },
  {
    title: 'Optimized campaigns',
    dataIndex: 'number_of_optimized'
  }
];
export const SalesDash = () => {
  const [rangeType, setRangeType] = useState(DASH_RANGE_TYPE.TODAY);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const sales = await dashboardsService.getSalesDashboard({ rangeType, isHeader: false });
        if (sales.length > 0) {
          setData(sales[0].data);
        } else {
          openWarningNotification({ message: 'Sorry, there are no results.' });
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [rangeType]);

  return (
    <div className={styles.customTables}>
      <Radio.Group value={rangeType} onChange={(e) => setRangeType(e.target.value)} style={{ marginBottom: 16 }}>
        <Space>
          <Radio.Button value={DASH_RANGE_TYPE.TODAY}>Today</Radio.Button>
          <Radio.Button value={DASH_RANGE_TYPE.THIS_MONTH}>This month</Radio.Button>
          <Radio.Button value={DASH_RANGE_TYPE.YESTERDAY}>Yesterday</Radio.Button>
        </Space>
      </Radio.Group>
      {loading ? (
        <Spinner />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  dataSource={data?.top_10_campaigns}
                  columns={columnsTopCampaigns}
                  pagination={false}
                  title={() => <h3>Top 10 Campaigns</h3>}
                  className={styles.greenTable}
                />
              </Col>
              <Col span={24}>
                <Table
                  dataSource={data?.top_10_negative_campaigns}
                  columns={columnsTopNegativeCampaigns}
                  pagination={false}
                  title={() => <h3>Top 10 Negative Campaigns</h3>}
                  className={styles.redTable}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  dataSource={data?.top_10_product_campaigns_with_top_5_products}
                  columns={columnsTopProductCompanies}
                  pagination={false}
                  title={() => <h3>Top 10 Product Companies</h3>}
                  className={styles.greenTable}
                />
              </Col>

              <Col span={24}>
                <Table
                  dataSource={data?.optimization_counter_per_am}
                  columns={columnsOptimizationCampaigns}
                  pagination={false}
                  title={() => <h3>Optimization</h3>}
                  className={styles.blueTable}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};
