import React, { useState } from 'react';
import { Modal, Popover } from 'antd';

import { listsService } from '../../../services/lists';
import { openSuccessNotification } from '../../../utils/notifications';

import styles from '../Lists.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';
import { ExportOutlined } from '@ant-design/icons';

export const ActionsTableColCommon = ({ listType, allAccounts, list, onClone, onEdit, onDelete, renderModal, contentForCopy }) => {
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [isClone, setIsClone] = useState(false);

  const handleOpenEditModal = () => setVisibleEditModal(true);

  const handleCloseEditModal = () => setVisibleEditModal(false);

  const handleDeleteList = async (id) => {
    try {
      await listsService.deleteList(id, listType);
      openSuccessNotification({ message: 'List successfully deleted!' });
      await onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDeleteList = () => {
    const { name, id } = list;
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleDeleteList(id),
      onCancel() {}
    });
  };
  const handleCopy = () => {
    if (contentForCopy) {
      navigator.clipboard.writeText(
        contentForCopy
          .split(/\n|\r|,/)
          .filter((el) => el.trim())
          .join('\n')
      );
      openSuccessNotification({ message: 'Succesfully copied to clipboard.' });
    }
  };
  const commonModalProps = { visibleModal: visibleEditModal, isClone, onCloseModal: handleCloseEditModal, allAccounts, onClone, onEdit };

  return (
    <div className={styles.actions}>
      {contentForCopy && (
        <Popover content="Copy to cliboard" trigger="hover">
          <div onClick={handleCopy}>
            <ExportOutlined />
          </div>
        </Popover>
      )}
      <Popover content="Clone" trigger="hover">
        <div
          onClick={() => {
            setIsClone(true);
            handleOpenEditModal();
          }}
        >
          <img src="/images/copy.png" alt="Clone" width="19px" />
        </div>
      </Popover>

      <Popover content="Edit" trigger="hover">
        <div
          onClick={() => {
            setIsClone(false);
            handleOpenEditModal();
          }}
        >
          <img src="/images/edit_icon.svg" alt="Edit" width="18px" />
        </div>
      </Popover>

      <Popover content="Delete" trigger="hover">
        <div onClick={confirmDeleteList}>
          <img src="/images/delete_icon.png" alt="Delete" width="21px" />
        </div>
      </Popover>

      {renderModal(commonModalProps)}
    </div>
  );
};
