import React from 'react';
import { Table as AntTable, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { ProductTableActions } from './ProductTableActions';
import { PixelType } from '../../../services/products';
import { TableTitle } from '../../../components/TableTitle';
import { getUserFullName } from '../../../utils/functions';

const getPayout = (productPrices, countries) => {
  let payoutStr = '';

  const plProductPrices = productPrices.filter((price) => price.pixel_type === PixelType.pl);

  if (plProductPrices.length) {
    if (countries && countries.length) {
      countries.forEach((country) => {
        const prodPrice = plProductPrices.find((priceInfo) => priceInfo.country === country);

        if (prodPrice) {
          payoutStr += `${country} ${parseFloat(prodPrice.earned).toFixed(2)}; `;
        }
      });
    } else {
      let minEarned = plProductPrices[0].earned;
      let maxEarned = plProductPrices[0].earned;
      let minCountry = plProductPrices[0].country;
      let maxCountry = plProductPrices[0].country;

      if (plProductPrices.length > 1) {
        for (let i = 1; i < plProductPrices.length; i++) {
          const currEarned = plProductPrices[i].earned;
          const currCountry = plProductPrices[i].country;

          if (currEarned < minEarned) {
            minEarned = currEarned;
            minCountry = currCountry;
          }

          if (currEarned > maxEarned) {
            maxEarned = currEarned;
            maxCountry = currCountry;
          }
        }

        payoutStr = `${minCountry} ${parseFloat(minEarned).toFixed(2)} - ${maxCountry} ${parseFloat(maxEarned).toFixed(2)}`;
      } else {
        payoutStr = `${minCountry} ${parseFloat(minEarned).toFixed(2)}`;
      }
    }
  }

  return payoutStr;
};

export const Table = ({
  loading,
  products,
  totalItems,
  currentPage,
  pageSize,
  countries,
  sorting,
  onPaginationChange,
  onSubmit,
  onDelete,
  onSortingClick
}) => {
  const history = useHistory();

  const columns = [
    {
      title: <TableTitle text="Id" value="id" order={sorting.find((s) => s.key === 'id')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'id',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        };
      }
    },
    {
      title: (
        <TableTitle text="Title" value="title" order={sorting.find((s) => s.key === 'title')?.value} onSortingClick={onSortingClick} />
      ),
      dataIndex: 'title'
    },
    {
      title: (
        <TableTitle
          text="Company"
          value="company"
          order={sorting.find((s) => s.key === 'company')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'company_name'
    },
    {
      title: (
        <TableTitle
          text="Creator"
          value="creator"
          order={sorting.find((s) => s.key === 'creator')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'creator_details',
      render: (creator_details) => getUserFullName(creator_details)
    },
    {
      title: (
        <TableTitle text="Status" value="status" order={sorting.find((s) => s.key === 'status')?.value} onSortingClick={onSortingClick} />
      ),
      dataIndex: 'status'
    },
    {
      title: 'Payout',
      key: 'payout',
      render: ({ product_prices: productPrices }) => <span>{getPayout(productPrices, countries)}</span>
    },
    {
      title: (
        <TableTitle
          text="Date Created"
          value="date_created"
          order={sorting.find((s) => s.key === 'date_created')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_created',
      align: 'center',
      render: (date_created) => dayjs(date_created).format('DD.MM.YYYY. - HH:mm:ss')
    },
    {
      title: 'Actions',
      align: 'center',
      render: (record) => (
        <ProductTableActions product={record} previewUrl={record.product_preview_url} onSubmit={onSubmit} onDelete={onDelete} />
      )
    }
  ];

  return (
    <div>
      <AntTable
        loading={loading}
        dataSource={products}
        style={{ marginTop: '3%' }}
        rowKey={({ id }) => id}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        onRow={({ id }) => {
          return {
            onClick: () => {
              history.push(`/products/${id}`);
            }
          };
        }}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
