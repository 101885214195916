import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { userNotificationsService } from '../../../services/user-notifications';
import dayjs from 'dayjs';
import { showApiErrors } from '../../../utils/showApiErrors';

export const LogTable = ({ notificationId, getNotificationCreator }) => {
  const [historyData, setHistoryData] = useState([]);
  const [loadHistory, setLoadHistory] = useState(false);

  const tableColumns = [
    {
      title: 'Date edited',
      dataIndex: 'date_edited',
      render: (date_edited) => (date_edited ? dayjs(date_edited).format('DD.MM.YYYY. - HH:mm:ss') : '/')
    },
    {
      title: 'Action',
      dataIndex: 'action'
    },
    {
      title: 'Action taken by',
      dataIndex: 'creator',
      render: (creator) => getNotificationCreator(creator)
    }
  ];

  const refactoredHistoryData = (data) => {
    const dataFormated = [...data];
    dataFormated.map((item) => {
      if (item.action === 'Created') {
        dataFormated.pop(item);
      }
    });
    return dataFormated;
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoadHistory(true);
      try {
        const res = await userNotificationsService.getNotificationHistory(notificationId);
        setHistoryData(refactoredHistoryData(res));
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadHistory(false);
      }
    };
    getDetails();
  }, [notificationId]);

  return (
    <Table
      columns={tableColumns}
      dataSource={historyData}
      loading={loadHistory}
      rowKey={({ id }) => id}
      scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      pagination={{ pageSize: 5, total: historyData.length }}
    />
  );
};
