import { Col, Row, Button, Divider, DatePicker } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { productsService } from '../../../services/products';
import { countriesService } from '../../../services/countries';
import { accountsService } from '../../../services/accounts';
import { landingPagesService } from '../../../services/landingPages';
import { osService } from '../../../services/os';
import { browsersService } from '../../../services/browsers';
import { companiesService } from '../../../services/companies';
import { Filters } from './Filters';
import { mediaCampaignsService } from '../../../services/media-campaigns';
import { useMount } from '../../../utils/hooks/useMount';
import styles from '../ChartView.module.css';
import { userFiltersService } from '../../../services/user-filters';
import { FilterSwitch } from '../../../components/FilterSwitch';
import { showApiErrors } from '../../../utils/showApiErrors';

const { RangePicker } = DatePicker;

export const Header = ({ onSubmit, loading }) => {
  const rangeData = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 2 Days': [moment().subtract(1, 'days'), moment()],
    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(30, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
  };

  const [lines, setLines] = useState([{ metrics: ['im', 'earned'] }]);

  const [countries, setCountries] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const [browsers, setBrowsers] = useState([]);
  const [browsersLoading, setBrowsersLoading] = useState(false);

  const [channels, setChannels] = useState([]);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const [productCompany, setProductCompany] = useState([]);
  const [productCompanyLoading, setProductCompanyLoading] = useState(false);

  const [perHour, setPerHour] = useState(true);

  const [userFilters, setUserFilters] = useState([]);
  const [userFiltersLoading, setUserFiltersLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const [lps, setLps] = useState([]);
  const [lpsLoading, setLpsLoading] = useState([]);

  const [os, setOS] = useState([]);
  const [osLoading, setOSLoading] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState([]);

  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [compareDateRange, setCompareDateRange] = useState({});

  const [includeErrors, setIncludeErrors] = useState(false);

  const closeLine = (id) => {
    setLines((prevState) => {
      let newArr = [...prevState];
      newArr.splice(id, 1);
      return newArr;
    });
  };

  const addNewLine = () => {
    setLines((prevState) => {
      return [...prevState, { metrics: ['im'] }];
    });
  };

  useEffect(() => {
    const getUserFilters = async () => {
      try {
        setUserFiltersLoading(true);
        let userFilters = await userFiltersService.getFilters();
        setUserFilters(userFilters);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUserFiltersLoading(false);
      }
    };

    const getChannels = async () => {
      try {
        setChannelsLoading(true);
        const channels = await mediaCampaignsService.getCampaigns();
        setChannels(channels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setChannelsLoading(false);
      }
    };

    const getOS = async () => {
      try {
        setOSLoading(true);
        const os = await osService.getFilters();
        setOS(os);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setOSLoading(false);
      }
    };

    const getLps = async () => {
      try {
        setLpsLoading(true);
        const lps = await landingPagesService.getAll();
        setLps(lps);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLpsLoading(false);
      }
    };

    const getUsers = async () => {
      try {
        setUsersLoading(true);
        const users = await accountsService.getAll();
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    };

    const getProducts = async () => {
      try {
        setProductsLoading(true);
        const products = await productsService.getAll();
        setProducts(products);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductsLoading(false);
      }
    };

    const getCountries = async () => {
      try {
        setCountriesLoading(true);
        const countries = await countriesService.getAll({ fields: 'id,name,code2,img' });
        setCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCountriesLoading(false);
      }
    };

    const getBrowsers = async () => {
      try {
        setBrowsersLoading(true);
        const browsers = await browsersService.getAll();
        setBrowsers(browsers);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setBrowsersLoading(false);
      }
    };

    const getCompanies = async () => {
      try {
        setCompaniesLoading(true);
        const companies = await companiesService.getTrafficCompanies();
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCompaniesLoading(false);
      }
    };

    const getProductCompany = async () => {
      try {
        setProductCompanyLoading(true);
        const productCompany = await companiesService.getProductCompanies();
        setProductCompany(productCompany);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductCompanyLoading(false);
      }
    };

    getUserFilters();
    getChannels();
    getProductCompany();
    getCompanies();
    getBrowsers();
    getProducts();
    getCountries();
    getUsers();
    getLps();
    getOS();
  }, []);
  useMount(() => {
    const search = new URLSearchParams(window.location.search);
    const channelString = search.get('channel');
    const productString = search.get('products');
    const countryString = search.get('country');
    const browserString = search.get('browser');
    const osString = search.get('os');
    const sourceString = search.get('source');
    const advCompString = search.get('advertiser_comp');
    const prodCompString = search.get('product_comp');
    const lpsString = search.get('lps');
    const usersString = search.get('users');
    const publisherModule = search.get('pub_module');

    if (channelString || productString || countryString || browserString || advCompString || prodCompString || osString) {
      const linesFromUrl = [
        {
          metrics: ['im', 'earned'],
          channels: channelString ? channelString.split(',') : [],
          products: productString ? productString.split(',') : [],
          countries: countryString ? countryString.split(',') : [],
          browser: browserString ? browserString.split(',') : [],
          os: osString ? osString.split(',') : [],
          creator_id: usersString ? usersString.split(',') : [],
          lps: lpsString ? lpsString.split(',') : [],
          source: sourceString ? sourceString.split(',') : [],
          advertiserCompany: advCompString ? advCompString.split(',') : [],
          productCompany: prodCompString ? prodCompString.split(',') : [],
          pub_module: publisherModule
        }
      ];
      setLines(linesFromUrl);
      onSubmit({
        lines: linesFromUrl,
        dateRange,
        perHour,
        compareDateRange,
        includeErrors
      });
    }
  });

  return (
    <div className={styles.spanFontWeight500}>
      <Row gutter={32}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <span className={styles.spanMarginBottom}>Date range </span>
          <div style={{ width: '100%' }}>
            <RangePicker
              format="DD.MM.YYYY"
              ranges={rangeData}
              onChange={(momentDates, dates) => {
                setDateRange(() => ({
                  from: momentDates ? momentDates[0] : null,
                  to: momentDates ? momentDates[1] : null
                }));
              }}
              defaultValue={[moment(dateRange.from), moment(dateRange.to)]}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <span className={styles.spanMarginBottom}>Compare date range </span>
          <div style={{ width: '100%' }}>
            <RangePicker
              format="DD.MM.YYYY"
              ranges={rangeData}
              onChange={(momentDates, dates) => {
                setCompareDateRange({ from: momentDates ? momentDates[0] : null, to: momentDates ? momentDates[1] : null });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3} style={{ display: 'flex' }}>
          <FilterSwitch label="Chart per hour" defaultChecked onChange={(value) => setPerHour(value)} />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3} style={{ display: 'flex' }}>
          <FilterSwitch label="Include Errors" onChange={(value) => setIncludeErrors(value)} />
        </Col>
      </Row>
      {lines.map((line, index) => (
        <div key={index}>
          <Divider />
          <Filters
            filters={line}
            products={products}
            companies={companies}
            users={users}
            lps={lps}
            showClose={index > 0}
            channels={channels}
            productCompany={productCompany}
            browsers={browsers}
            os={os}
            userFilters={userFilters}
            countries={countries}
            onChange={(value) => {
              setLines((prevState) => {
                const newLines = [...prevState];
                newLines[index] = value;
                return newLines;
              });
            }}
            onClose={() => closeLine(index)}
            loaders={{
              companies: companiesLoading,
              products: productsLoading,
              users: usersLoading,
              lps: lpsLoading,
              channels: channelsLoading,
              productCompany: productCompanyLoading,
              browser: browsersLoading,
              os: osLoading,
              userFilters: userFiltersLoading,
              countries: countriesLoading,
              browsers: browsersLoading
            }}
          />
        </div>
      ))}

      <Row gutter={24}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Button
            ghost
            type="primary"
            onClick={() => {
              addNewLine();
            }}
          >
            Add new line
          </Button>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Button
            loading={loading}
            className=""
            type="primary"
            onClick={() => {
              onSubmit({
                lines,
                dateRange,
                perHour,
                compareDateRange,
                includeErrors
              });
            }}
          >
            Make Graph
          </Button>
        </Col>
      </Row>
    </div>
  );
};
