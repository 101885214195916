import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';
import { handleOrdering } from '../utils/functions';

class LandingPagesService {
  async getAll({ fields = 'id,title' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/lps/${query}` });
  }

  async getPaged({ currentPage, pageSize, searchValue, filters, cancelToken, fields, sorting }) {
    const { showLpsWithObsoleteUploadedImage } = filters;
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      obsolete_uploaded_image: !!showLpsWithObsoleteUploadedImage,
      fields,
      ordering: ordering && ordering.join(',')
    });

    return await callApi({ url: `/api/lps/${query}`, cancelToken });
  }

  async getById(id, { fields } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/lps/${id}/${query}` });
  }

  async getLpPreview(id) {
    return (await this.getById(id, { fields: 'html_preview' })).html_preview;
  }

  async uploadLpImage(id, image) {
    const data = new FormData();
    data.append('file', image, image.name);

    return await callApi({ url: `/api/lps/${id}/image_upload/`, method: 'POST', data });
  }

  async create(data) {
    return await callApi({ url: '/api/lps/', method: 'POST', data });
  }

  async createPrelanderCampaign(id, data) {
    return await callApi({ url: `/api/lps/${id}/create_prelander_campaign/`, method: 'POST', data });
  }

  async edit(id, data) {
    return await callApi({ url: `/api/lps/${id}/`, method: 'PUT', data });
  }

  async delete(id) {
    return await callApi({ url: `/api/lps/${id}/`, method: 'DELETE' });
  }

  async clone(id) {
    return await callApi({ url: `/api/lps/${id}/clone/`, method: 'POST' });
  }

  generateOptions = (arr, fullLengthLabel) => {
    return arr.map((option) => {
      const { id, title, date_edited, uploaded_image_url } = option;
      const label = id + '. ' + (title.length > 30 && !fullLengthLabel ? title.substring(0, 29) + '...' : title);

      return {
        label,
        value: id,
        dateEdited: date_edited,
        uploadedImage: uploaded_image_url
      };
    });
  };
}

export const landingPagesService = new LandingPagesService();
