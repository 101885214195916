import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Modal, Popconfirm, Row, Spin } from 'antd';
import React, { useState } from 'react';

import { userFiltersService } from '../../../services/user-filters';
import { showApiErrors } from '../../../utils/showApiErrors';

const confirm = Modal.confirm;
const Panel = Collapse.Panel;

export const FilterModal = ({ userFilters, filters, checkedColumns, onUpdate }) => {
  const [form] = Form.useForm();
  const [userFiltersModalIsOpen, setUserFiltersModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const showOverwriteConfirm = (name) => {
    confirm({
      maskClosable: true,
      title: `Are you sure you want to change existing (${name}) filter ?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleSaveFilter(name);
      },
      onCancel() {}
    });
  };

  const handleSubmit = (values) => {
    let checkNames = userFilters.filter((filter) => filter.name === values.name.toLowerCase());
    if (checkNames.length > 0) {
      showOverwriteConfirm(values.name.toLowerCase());
    } else {
      handleSaveFilter(values.name);
    }
  };

  const handleSaveFilter = async (filterName) => {
    setModalLoading(true);
    let myFilters = { filters: { ...filters } };
    filterName = filterName.toLowerCase();
    const newFilters = userFilters.filter((filter) => filter.name.toLowerCase() !== filterName);
    myFilters.name = filterName.toLowerCase();
    myFilters.checkedColumns = checkedColumns;
    newFilters.push(myFilters);

    try {
      await userFiltersService.updateFilters(newFilters);
      onUpdate(newFilters);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteFilter = async (filterName) => {
    setModalLoading(true);
    const newFilters = userFilters.filter((filter) => filter.name !== filterName);
    try {
      await userFiltersService.updateFilters(newFilters);
      onUpdate(newFilters);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <div>
      <Button className="export-btn" type="primary" ghost onClick={() => setUserFiltersModal(true)} icon={<SaveOutlined />}>
        User Filters
      </Button>{' '}
      <Modal visible={userFiltersModalIsOpen} onOk={() => setUserFiltersModal(false)} onCancel={() => setUserFiltersModal(false)}>
        <Spin spinning={modalLoading}>
          <Form
            onFinish={handleSubmit}
            onFinishFailed={({ errorFields }) => {
              form.scrollToField(errorFields[0].name);
            }}
          >
            <Row gutter={32}>
              {/* <Form.Item
              label="Filter Name:"
              rules={[
                {
                  required: true,
                  whitespace: true
                }
              ]}
            >
              <br></br>
              <AutoComplete
                width={200}
                options={allFilters}
                onChange={(e) => {
                  autoCompleteResult(e);
                }}
                placeholder="input here"
              />
            </Form.Item> */}
              <Col>
                <Form.Item
                  label="Filter name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please type filter name!'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item className="modal_buttons">
                  <Button type="primary" htmlType="submit" className="myButton">
                    Save Filters
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {userFilters && userFilters.length > 0 ? (
            <Collapse accordion>
              <Panel header="Saved filters" key="1">
                {userFilters.map((filter, index) => (
                  <p key={index}>
                    {filter.name}
                    <Popconfirm
                      onConfirm={() => {
                        handleDeleteFilter(filter.name);
                      }}
                      onCancel={() => {}}
                      title={<p>Are you sure that you want to delete {filter.name} filter?</p>}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        style={{
                          float: 'right',
                          color: '#ff5600',
                          cursor: 'pointer',
                          marginTop: '3px'
                        }}
                      />
                    </Popconfirm>
                  </p>
                ))}
              </Panel>
            </Collapse>
          ) : null}{' '}
        </Spin>
      </Modal>
    </div>
  );
};
