import './layout.css';

import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import Media from 'react-media';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { SwitchRoutes } from '../routes/SwitchRoutes';
import { getAllowedRoutes } from '../routes';
import { useLocation } from 'react-router-dom';
import { isHeaderAndSidebarHidden } from './utils/hiddenSidebar';
import { auth } from '../services/auth';
import { ENV } from '../config';

const smartlookScript =
  "window.smartlook||(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c); })(document); smartlook('init', 'b481adbed7c509992e0dc7c83f973c5b4473194f', { region: 'eu' });";

export const AppLayout = () => {
  const smartlookScriptRef = useRef(null);
  useEffect(() => {
    const initSmartlook = async () => {
      const user = await auth.getUserProfile();
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `${smartlookScript} smartlook('identify', ${user.id}, {"name":"${user.first_name} ${user.last_name}", "email": "${user.email}"});`;

      document.body.appendChild(script);
      smartlookScriptRef.current = script;
    };
    if (ENV === 'prod') {
      initSmartlook();
    }

    return () => {
      if (smartlookScriptRef.current) {
        document.body.removeChild(smartlookScriptRef.current);
      }
    };
  }, []);

  const location = useLocation();
  const isSidebarVisible = !isHeaderAndSidebarHidden(location);
  return (
    <Layout style={{ minHeight: '100vh', zIndex: '2' }}>
      {isSidebarVisible && <Media query="(max-width: 599px)">{(matches) => <Sidebar isSmallScreen={matches} />}</Media>}
      <Layout>
        {isSidebarVisible && <Header />}
        <Layout.Content style={{ margin: isSidebarVisible ? '20px' : 0 }}>
          <div style={{ padding: isSidebarVisible ? 24 : 0, background: '#fff', minHeight: 360 }}>
            <SwitchRoutes routes={getAllowedRoutes()} />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
