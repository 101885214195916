import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const IdentityStatus = {
  Active: 'Active',
  Paused: 'Paused'
};

export const IdentityType = {
  Fixed: 'Fixed',
  Normal: 'Normal',
  Replacement: 'Replacement'
};

class IdentityService {
  async getAll({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/identities/${query}` });
  }

  async getPaged({ currentPage, pageSize, filters, searchValue, sorting, cancelToken }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      type: filters.type,
      status: filters.status,
      search: searchValue,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({
      url: `/api/identities/${query}`,
      cancelToken
    });
  }

  async getPagedDomains({ id, currentPage, pageSize, cancelToken }) {
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize })
    });
    return await callApi({
      url: `/api/identities/${id}/domains/${query}`,
      cancelToken
    });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const identityService = new IdentityService();
