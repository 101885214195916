import React from 'react';
import { Select, Tooltip } from 'antd';

import { timeZones } from '../pages/companies/utils/timeZones';
import { API_BASE_URL } from '../config';
import { SetupTypes } from '../services/media-campaigns';

const { Option } = Select;

export const generateOptions = (options, labelKey, valueKey, alternativeLabelKey) => {
  return options?.map((option) => {
    const valueOpt = option[valueKey];
    const labelOpt = option[labelKey];
    const alternativeLabel = alternativeLabelKey && option[alternativeLabelKey] ? ` (${option[alternativeLabelKey]})` : '';

    const searchForSelect = String(valueOpt) + String(labelOpt) + String(alternativeLabel);

    return (
      <Option key={valueOpt} value={valueOpt} data-searchvalue={searchForSelect}>
        {`${labelOpt}${alternativeLabel}`}
      </Option>
    );
  });
};

export const generateTimeZoneOptions = () => {
  return timeZones.map((zone, index) => {
    return (
      <Option key={index} value={zone.value} /*title={zone.label}*/ data-searchvalue={zone.label}>
        {zone.label}
      </Option>
    );
  });
};

export const generateUserOptions = (allUsers) => {
  return allUsers.map((user) => {
    const display = `${user.first_name} ${user.last_name}`;
    return (
      <Option key={user.id} value={user.id} data-searchvalue={display + user.id}>
        {display}
      </Option>
    );
  });
};

export const generateCountriesOptions = (allCountries, useCodeForValue = false) => {
  const noImage = ['bl', 'bq', 'mf', 'sx', 'xk', 'cw'];
  return allCountries.map((country) => {
    let img;
    if (noImage.includes(country.code2.toLowerCase())) {
      img = null;
    } else {
      img = <img width="16px" alt={country.code2} src={`${API_BASE_URL}${country.img}`} style={{ marginRight: '5px' }} />;
    }
    return (
      <Option key={country.id} value={useCodeForValue ? country.code2 : country.id} data-searchvalue={country.name}>
        <Tooltip title={country.name}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {img} {country.name}
          </div>
        </Tooltip>
      </Option>
    );
  });
};

export const generateSelectOptionsWithIdValues = (options) => {
  return options.map((option) => {
    const name = option.name
      ? option.name
      : option.title
      ? option.title
      : option.channel
      ? option.channel
      : option.email
      ? option.email
      : option.domain_name
      ? option.domain_name
      : '';
    return (
      <Option key={option.id} value={option.id} data-searchvalue={name + option.id}>
        {name}
      </Option>
    );
  });
};

export const generateCampaignPatternTypeSelectOptions = (options) => {
  return options.map((option) => {
    const { id, name } = option;
    const disabled = id === SetupTypes.json;

    return (
      <Option key={id} value={id} data-searchvalue={name + id} disabled={disabled}>
        {name}
      </Option>
    );
  });
};

export const generateOptionsFromObject = (options, transform = (s) => s) => {
  const transformedOptions = Object.values(options).map((value) => {
    const name = transform(value);

    return {
      id: value,
      name
    };
  });

  return generateSelectOptionsWithIdValues(transformedOptions);
};

export const generateOptionsFromObjectKeyValue = (object) => {
  const transformedOptions = Object.entries(object).map(([id, name]) => ({
    id,
    name
  }));

  return generateSelectOptionsWithIdValues(transformedOptions);
};

export const generateVirtualizedSelectOptions = ({ options, labelKey = 'name', valueKey = 'id' }) => {
  return options.map((option) => {
    return {
      label: option[labelKey],
      value: option[valueKey]
    };
  });
};

export const generateVirtualizedSelectOptionsFromValueArr = (options) => {
  return options.map((option) => {
    return {
      label: option,
      value: option
    };
  });
};

export const snakeCaseToProperCase = (value) => {
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export const filterOptionsByLabelAndValue = (input, option) => {
  const label = option?.label ?? '';
  const value = option?.value ?? '';
  const labelValue = `${label}${value}`.toLowerCase();

  return labelValue.includes(input.toLowerCase());
};

export const getActivityOptions = () => {
  return (
    <>
      <Select.Option key={0} value={true}>
        Active
      </Select.Option>
      <Select.Option key={1} value={false}>
        Inactive
      </Select.Option>
    </>
  );
};

// Will need this when we decide to add exclude to all filters in stats
// export const generateSelectOptionsPropFromObject = (optionsObj, transform = (s) => s) => {
//   return Object.values(optionsObj).map((value) => {
//     const label = transform(value);

//     return {
//       label,
//       value
//     };
//   });
// };

// Will need this when we decide to add exclude to all filters in stats
// export const generateSelectOptionsProp = (options, labelName = 'name', valueName = 'id') => {
//   return options.map((option) => {
//     return {
//       label: option[labelName],
//       value: option[valueName]
//     };
//   });
// };
