import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { callApi } from '../../../services/api';
import { BOOL_OPTIONS, FieldType, urgentPanelService } from '../../../services/urgentPanel';
import { openSuccessNotification } from '../../../utils/notifications';
import { generateOptions, generateSelectOptionsWithIdValues } from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';

const generateInput = (key, item, dataForSelect) => {
  switch (item.type) {
    case FieldType.BOOL:
      return (
        <>
          <Form.Item label={item.frontend_name} name={key}>
            <Select clearIcon children={generateSelectOptionsWithIdValues(BOOL_OPTIONS)} />
          </Form.Item>
        </>
      );
    case FieldType.DATE:
      return (
        <>
          <Form.Item label={item.frontend_name} name={key}>
            <DatePicker />
          </Form.Item>
        </>
      );
    case FieldType.STRING:
      return (
        <>
          <Form.Item label={item.frontend_name} name={key}>
            <Input />
          </Form.Item>
        </>
      );
    case FieldType.ID:
      const label = item.fields.label;
      const value = item.fields.value;

      return (
        <Form.Item label={item.frontend_name} name={key} valuePropName="selectValue">
          <Select
            showSearch
            clearIcon
            optionFilterProp="data-searchvalue"
            children={generateOptions(dataForSelect || item.options, label, value)}
            style={{ width: '100%', minWidth: '200px' }}
          ></Select>
        </Form.Item>
      );
    case FieldType.NUMBER:
      return (
        <Form.Item label={item.frontend_name} name={key}>
          <InputNumber />
        </Form.Item>
      );
    case FieldType.CHOICE_FIELD:
      return (
        <Form.Item label={item.frontend_name} name={key}>
          <Select children={generateSelectOptionsWithIdValues(item.options)} style={{ width: '100%', minWidth: '200px' }}></Select>
        </Form.Item>
      );

    default:
  }
};
export const UrgentPanelUpdate = ({ data, onCancel }) => {
  const [optionValues, setOptionValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const getData = async (element, key) => {
    return {
      [[key, element.backend_name].join('__')]: await callApi({
        url: `${element.url}?fields=${Object.values(element.fields).join(',')}`
      })
    };
  };
  useEffect(() => {
    const fetchAll = async (element, key) => {
      const promises = [];
      Object.entries(data).forEach(([key, value]) => {
        value.fields.forEach((field) => {
          if (field.url) {
            promises.push(getData(field, key));
          }
        });
      });
      try {
        setLoading(true);
        const result = await Promise.all(promises);
        setOptionValues(result.reduce((r, c) => Object.assign(r, c), {}));
      } finally {
        setLoading(false);
      }
    };
    fetchAll();
  }, [data]);

  const generateModelsBlocks = () => {
    return Object.entries(data)
      .map(([key, value]) => {
        return (
          <>
            <Card title={key}>
              {value.fields.map((element, index) => {
                const fullName = [key, element.backend_name].join('__');
                return (
                  <Col span={24}>
                    <Form.Item label="Edit this field" name={`${fullName}__shouldUpdateThisField`} valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    {generateInput(fullName, element, optionValues[fullName])}
                    {index !== value.fields.length - 1 && <Divider />}
                  </Col>
                );
              })}
            </Card>
          </>
        );
      })
      .flat(1);
  };

  const confirmBulkUpdate = () => {
    const formData = form.getFieldsValue();
    let generatedMessage = [];
    Object.entries(data).forEach(([key, value]) => {
      const fields = value.fields
        .filter((el) => {
          const shouldUpdate = formData[[key, el.backend_name, 'shouldUpdateThisField'].join('__')];
          return shouldUpdate;
        })
        .map((el) => el.frontend_name);
      if (fields.length > 0) {
        generatedMessage.push(<Divider />);
        generatedMessage.push(
          <>
            <p>Fields which will be edit: {fields.join('->')}</p>
            <p>
              Ids of {key}: {value.ids.join(', ')}
            </p>
            <p>Count {value.ids.length}</p>
          </>
        );
      }
    });
    const message = <p>Are you sure you want to update: {generatedMessage}</p>;
    Modal.confirm({
      maskClosable: true,
      title: message,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleUpdate,
      onCancel() {}
    });
  };

  const handleUpdate = async () => {
    const formData = form.getFieldsValue();
    const dataForUpdate = {};

    Object.entries(data).forEach(([key, value]) => {
      let fieldsForUpdate = {};
      value.fields.forEach((field) => {
        const shouldUpdate = formData[[key, field.backend_name, 'shouldUpdateThisField'].join('__')];
        if (shouldUpdate) {
          let value = formData[[key, field.backend_name].join('__')];
          if (typeof value === 'object' && value.hasOwnProperty('_isAMomentObject') && value._isAMomentObject) {
            value = value.format('YYYY-MM-DD');
          }

          fieldsForUpdate = { ...fieldsForUpdate, [field.backend_name]: formData[[key, field.backend_name].join('__')] };
        }
      });
      dataForUpdate[key] = {
        ids: value.ids,
        data: fieldsForUpdate
      };
    });
    try {
      setLoading(true);
      await urgentPanelService.update({ data: dataForUpdate });
      openSuccessNotification({ message: 'Successfully updated.' });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const displayUpdate = Object.keys(data).length !== 0;

  return (
    <Row gutter={24} justify="center">
      <Col span={24}>
        <Row gutter={24}>
          <Col span={24}>
            <Button type="danger" onClick={onCancel}>
              <CloseOutlined />
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
      <br />
      <Col span={24}>
        <Spin spinning={loading}>
          <Form form={form}>
            {generateModelsBlocks()}
            {displayUpdate && (
              <>
                <br />
                <Form.Item>
                  <Button ghost type="primary" onClick={confirmBulkUpdate} block>
                    Update Fields
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};
