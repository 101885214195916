import React from 'react';
import { Col, Row, Select } from 'antd';
import { SearchBar } from '../../../components/SearchBar';

import styles from '../Identities.module.css';
import { IdentityStatus, IdentityType } from '../../../services/identity';

const { Option } = Select;

export const Header = ({ filters, searchValue, onFilter, onSearch }) => {
  return (
    <>
      <Row gutter={32} className={styles.paddingBetweenRows}>
        <Col span={6}>
          <Select
            placeholder={'Select type'}
            style={{ width: '100%' }}
            value={filters.type}
            onChange={(value) => onFilter({ ...filters, type: value })}
            allowClear
          >
            <Option value={IdentityType.Fixed}>Fixed</Option>
            <Option value={IdentityType.Normal}>Normal</Option>
            <Option value={IdentityType.Replacement}>Replacement</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            placeholder={'Select status'}
            style={{ width: '100%' }}
            value={filters.status}
            onChange={(value) => onFilter({ ...filters, status: value })}
            allowClear
          >
            <Option value={IdentityStatus.Active}>Active</Option>
            <Option value={IdentityStatus.Paused}>Paused</Option>
          </Select>
        </Col>
        <Col span={6}>
          <SearchBar value={searchValue} onChange={(e) => onSearch(e.target.value)} />
        </Col>
      </Row>
    </>
  );
};
