import React, { useState, useEffect } from 'react';

import { Heading } from '../Heading';
import { Table } from './Table';
import { AddOrEditTargetingList } from './AddOrEditTargetingList';

import { listsService, ListTypes } from '../../../../services/lists';

import { useDebounce, useFetch } from '../../../../utils/hooks';

import { SortDirection } from '../../../../services/statistic';

const listType = ListTypes.targeting_lists;
const globalList = true;

export const TargetingTab = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadTargetingLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      globalList,
      fields:
        'id,img,name,creator_details,date_created,targeted_campaigns,targeting_patterns_details,targeting_info,assigned,exclude,private',
      filters,
      sorting
    });

  const [
    {
      data: { results: targetingLists, count: totalRows },
      isFetching
    },
    getTargetingLists
  ] = useFetch(loadTargetingLists);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getTargetingLists();
  }, [debouncedSearchValue, currentPage, getTargetingLists, filters, sorting]);

  const handleDelete = () => {
    if (targetingLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getTargetingLists();
  };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="Targeting"
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        targetingLists={targetingLists}
        loading={isFetching}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        listType={listType}
        sorting={sorting}
        onClone={getTargetingLists}
        onEdit={getTargetingLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />

      <AddOrEditTargetingList
        visibleModal={visibleAddModal}
        listType={listType}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getTargetingLists}
      />
    </div>
  );
};
