import React from 'react';
import { Select } from 'antd';

import styles from '../../lists/Lists.module.css';

const { Option } = Select;

export const getSelectOptionsWithIdValues = (options) => {
  return options.map((option) => {
    const { id, name, title, channel } = option;
    const optionText = name ? name : title ? title : channel;

    return (
      <Option key={id} value={id} data-searchvalue={optionText + id}>
        {optionText}
      </Option>
    );
  });
};

export const getDomainSelectOptions = (options) => {
  return options.map((option) => {
    const { id, domain_name } = option;

    return (
      <Option key={id} value={id} data-searchvalue={domain_name + id}>
        {domain_name}
      </Option>
    );
  });
};

export const getPrefixSelectOptions = (options) => {
  return options.map((option) => (
    <Option key={option} value={option} data-searchvalue={option}>
      {option}
    </Option>
  ));
};

export const getSelectOptionsWithExclude = (options) => {
  return options.map((option) => {
    const { id, name, exclude, img } = option;

    return (
      <Option
        key={id}
        value={id}
        data-searchvalue={name + id}
        style={{
          display: 'flex'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            <div
              style={{
                width: '16px',
                height: '16px',
                marginRight: '5px',
                borderRadius: '5px',
                background: exclude ? 'rgb(219, 64, 64)' : 'green'
              }}
            ></div>
          }
          {img ? (
            <span className={styles.selectImgWrapper}>
              <img src={img} alt={id} />
            </span>
          ) : null}
          {name}
        </div>
      </Option>
    );
  });
};
