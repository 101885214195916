import { Form, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { OnOffSwitch } from '../../../../components/OnOffSwitch';
import { Select } from '../../../../components/Select';
import { Spinner } from '../../../../components/Spinner';
import { listsService } from '../../../../services/lists';
import { FilterRule, mediaCampaignsService } from '../../../../services/media-campaigns';
import { useEffectTrigger, useForm } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { getSelectOptionsWithIdValues } from '../../utils/options';
import styles from './Tabs.module.css';
import { getExternalUrls, getMediaCampaigns, getSubidList, getSubidLists, useCustomQuery } from '../../../../utils/hooks/queries';
import { withRefetching } from '../../../../components/SelectWithSideLoader';
import { getSelectOptionsWithExtendedTooltip } from '../../../lists/utils/options';

const SelectFieldNames = {
  subidLists: 'subidLists',
  subidFilterRule: 'subidFilterRule',
  subidExternalUrl: 'subidExternalUrl',
  subidMediaCampaign: 'subidMediaCampaign',
  ipLists: 'ipLists',
  ipWhitelists: 'ipWhitelists',
  ipFilterRule: 'ipFilterRule',
  ipExternalUrl: 'ipExternalUrl',
  ipMediaCampaign: 'ipMediaCampaign'
};

const SelectWithRefetching = withRefetching(Select);

export const Filters = ({ form: antdForm, initialData, loadingTabsData, onFormChange, isClone }) => {
  const [subidSwitch, setSubidSwitch] = useState(false);
  const [ipSwitch, setIpSwitch] = useState(false);
  const [subidFilterRule, setSubidFilterRule] = useState('');
  const [ipFilterRule, setIpFilterRule] = useState('');

  const [allIpLists, setAllIpLists] = useState([]);
  const [allIpListsLoading, setAllIpListsLoading] = useState(false);

  const [allFilterRules, setAllFilterRules] = useState([]);
  const [allFilterRulesLoading, setAllFilterRulesLoading] = useState(false);

  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();

  const [form] = useForm(activateFormChangedTrigger, antdForm);

  const {
    isLoading: allSubidListsLoading,
    isFetching: allSubidListsRefetching,
    data: allSubidLists
  } = useCustomQuery(getSubidLists, { fields: 'id,name,exclude' });

  const {
    isLoading: allExternalUrlsLoading,
    isFetching: allExternalUrlsRefetching,
    data: allExternalUrls
  } = useCustomQuery(getExternalUrls);

  const {
    isLoading: allMediaCampaignsLoading,
    isFetching: allMediaCampaignsRefetching,
    data: allMediaCampaigns
  } = useCustomQuery(getMediaCampaigns, undefined, [], {
    enabled: subidFilterRule === FilterRule.InternalRedirect || ipFilterRule === FilterRule.InternalRedirect
  });

  const loading = loadingTabsData;
  const initialValues = useMemo(() => {
    if (!initialData) {
      const defaultIpLists = allIpLists.filter((el) => el.is_default).map((el) => el.id);
      const defaultIpWhitelists = allIpLists.filter((el) => el.is_default_whitelist).map((el) => el.id);
      return {
        ipSwitch: defaultIpLists.length > 0,
        ipFilterRule: FilterRule[404],
        ipLists: defaultIpLists,
        ipWhitelists: defaultIpWhitelists
      };
    }

    const { subid, ip } = initialData;

    let subidSwitch = undefined;
    let subidLists = undefined;
    let subidFilterRule = undefined;
    let subidExternalUrl = undefined;
    let subidMediaCampaign = undefined;
    let subidId = undefined;

    let ipSwitch = undefined;
    let ipLists = undefined;
    let ipWhitelists = undefined;
    let ipFilterRule = undefined;
    let ipExternalUrl = undefined;
    let ipMediaCampaign = undefined;
    let ipId = undefined;

    if (subid.length) {
      const { active, subid_lists, filter_rule, external_url, internal_campaign, id } = subid[0];

      subidSwitch = active;
      subidLists = subid_lists || undefined;
      subidFilterRule = filter_rule || undefined;
      subidExternalUrl = external_url || undefined;
      subidMediaCampaign = internal_campaign || undefined;
      subidId = isClone ? undefined : id;
    }

    if (ip.length) {
      const { active, ip_lists, ip_whitelists, filter_rule, external_url, internal_campaign, id } = ip[0];

      ipSwitch = active;
      ipLists = ip_lists || undefined;
      ipWhitelists = ip_whitelists || undefined;
      ipFilterRule = filter_rule || undefined;
      ipExternalUrl = external_url || undefined;
      ipMediaCampaign = internal_campaign || undefined;
      ipId = isClone ? undefined : id;
    }

    return {
      subidSwitch,
      subidLists,
      subidFilterRule,
      subidExternalUrl,
      subidMediaCampaign,
      ipSwitch,
      ipLists,
      ipWhitelists,
      ipFilterRule,
      ipExternalUrl,
      ipMediaCampaign,
      subidId,
      ipId
    };
  }, [initialData, allIpLists, isClone]);

  useEffect(() => form.resetFields(), [form, initialValues]);

  // Next two useEffects remove validation message and red border from fields when switches turn off (fields become disabled)
  useEffect(() => {
    if (!subidSwitch) {
      form.validateFields([
        SelectFieldNames.subidLists,
        SelectFieldNames.subidFilterRule,
        SelectFieldNames.subidExternalUrl,
        SelectFieldNames.subidMediaCampaign
      ]);
    }
  }, [form, subidSwitch]);

  useEffect(() => {
    if (!ipSwitch) {
      form.validateFields([
        SelectFieldNames.ipLists,
        SelectFieldNames.ipFilterRule,
        SelectFieldNames.ipExternalUrl,
        SelectFieldNames.ipMediaCampaign
      ]);
    }
  }, [form, ipSwitch]);

  useEffect(() => {
    const { subidSwitch, subidFilterRule, ipSwitch, ipFilterRule } = form.getFieldsValue(true);

    setSubidSwitch(subidSwitch);
    setSubidFilterRule(subidFilterRule);
    setIpSwitch(ipSwitch);
    setIpFilterRule(ipFilterRule);
  }, [form, formChangedTrigger]);

  useEffect(() => {
    let didCancel = false;

    const getFilterRules = async () => {
      setAllFilterRulesLoading(true);
      try {
        const filtersRules = await mediaCampaignsService.getFilterRules();

        if (!didCancel) {
          setAllFilterRules(filtersRules);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllFilterRulesLoading(false);
      }
    };
    const getIpLists = async () => {
      setAllIpListsLoading(true);
      try {
        const ip = await listsService.getIpLists();
        if (!didCancel) {
          setAllIpLists(ip);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllIpListsLoading(false);
      }
    };

    getFilterRules();
    getIpLists();
    return () => {
      didCancel = true;
    };
  }, []);

  const renderAdditionalSelect = (filterSwitch, filterRule, externalUrlFieldName, mediaCampaignFieldName) => {
    if (filterRule === FilterRule.RedirectUrl) {
      return (
        <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
          <Form.Item
            name={externalUrlFieldName}
            rules={[
              {
                required: filterSwitch,
                message: 'Please select an external URL!'
              }
            ]}
          >
            <SelectWithRefetching
              loading={allExternalUrlsLoading}
              refetching={allExternalUrlsRefetching}
              placeholder="Select External Url"
              optionFilterProp="data-searchvalue"
              disabled={!filterSwitch}
              showSearch
            >
              {getSelectOptionsWithIdValues(allExternalUrls)}
            </SelectWithRefetching>
          </Form.Item>
        </div>
      );
    } else if (filterRule === FilterRule.InternalRedirect) {
      return (
        <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
          <Form.Item
            name={mediaCampaignFieldName}
            rules={[
              {
                required: filterSwitch,
                message: 'Please select a media campaign!'
              }
            ]}
          >
            <SelectWithRefetching
              loading={allMediaCampaignsLoading}
              refetching={allMediaCampaignsRefetching}
              placeholder="Select Media Campaign"
              optionFilterProp="data-searchvalue"
              disabled={!filterSwitch}
              showSearch
            >
              {getSelectOptionsWithIdValues(allMediaCampaigns)}
            </SelectWithRefetching>
          </Form.Item>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Form
      form={form}
      name="filtersForm"
      initialValues={initialValues}
      onValuesChange={() => {
        onFormChange(true);
        activateFormChangedTrigger();
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.filtersContentWrapper}>
          <div className={styles.filtersRowWrapper}>
            <div className={styles.filtersRowFields}>
              <div className={styles.filtersName}>Source Blacklist</div>
              <Form.Item name="subidId" hidden>
                <Input />
              </Form.Item>
              <div>
                <Form.Item name="subidSwitch" valuePropName="checked">
                  <OnOffSwitch />
                </Form.Item>
              </div>

              <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                <Form.Item
                  name={SelectFieldNames.subidLists}
                  rules={[
                    {
                      required: !!subidSwitch,
                      message: 'Please select a list!'
                    }
                  ]}
                >
                  <SelectWithRefetching
                    loading={allSubidListsLoading}
                    refetching={allSubidListsRefetching}
                    placeholder="Select SUBID List"
                    optionFilterProp="data-searchvalue"
                    disabled={!subidSwitch}
                    showSearch
                    allowClear
                    mode="multiple"
                  >
                    {getSelectOptionsWithExtendedTooltip(allSubidLists, 'subids_list', true, getSubidList)}
                  </SelectWithRefetching>
                </Form.Item>
              </div>

              <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                <Form.Item
                  name={SelectFieldNames.subidFilterRule}
                  rules={[
                    {
                      required: !!subidSwitch,
                      message: 'Please select a rule!'
                    }
                  ]}
                >
                  <Select loading={allFilterRulesLoading} placeholder="Select Filter Rule" disabled={!subidSwitch} allowClear>
                    {getSelectOptionsWithIdValues(allFilterRules)}
                  </Select>
                </Form.Item>
              </div>

              {renderAdditionalSelect(subidSwitch, subidFilterRule, SelectFieldNames.subidExternalUrl, SelectFieldNames.subidMediaCampaign)}
            </div>

            <div className={styles.filtersDescription}>
              If user’s SUBID matches with SUBID/Source from the list, will be routed to Filter Rule
            </div>
          </div>

          <div className={styles.filtersRowWrapper}>
            <div className={styles.filtersRowFields}>
              <div className={styles.filtersName}>IP Blacklist</div>
              <Form.Item name="ipId" hidden>
                <Input />
              </Form.Item>
              <div>
                <Form.Item name="ipSwitch" valuePropName="checked">
                  <OnOffSwitch />
                </Form.Item>
              </div>
              <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                <Form.Item
                  name={SelectFieldNames.ipLists}
                  rules={[
                    {
                      required: !!ipSwitch,
                      message: 'Please select a lists!'
                    }
                  ]}
                >
                  <Select
                    loading={allIpListsLoading}
                    placeholder="Select IP Lists"
                    optionFilterProp="data-searchvalue"
                    disabled={!ipSwitch}
                    showSearch
                    allowClear
                    mode="multiple"
                  >
                    {getSelectOptionsWithIdValues(allIpLists)}
                  </Select>
                </Form.Item>
              </div>

              <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                <Form.Item
                  name={SelectFieldNames.ipFilterRule}
                  rules={[
                    {
                      required: !!ipSwitch,
                      message: 'Please select a rule!'
                    }
                  ]}
                >
                  <Select loading={allFilterRulesLoading} placeholder="Select Filter Rule" disabled={!ipSwitch} allowClear>
                    {getSelectOptionsWithIdValues(allFilterRules)}
                  </Select>
                </Form.Item>
              </div>

              {renderAdditionalSelect(ipSwitch, ipFilterRule, SelectFieldNames.ipExternalUrl, SelectFieldNames.ipMediaCampaign)}
            </div>
            <div className={styles.filtersRowFields}>
              <div style={{ width: '10%' }}></div>
              <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                <Form.Item name={SelectFieldNames.ipWhitelists} label="Exception">
                  <Select
                    loading={allIpListsLoading}
                    placeholder="Select IP Whitelists"
                    optionFilterProp="data-searchvalue"
                    disabled={!ipSwitch}
                    showSearch
                    allowClear
                    mode="multiple"
                  >
                    {getSelectOptionsWithIdValues(allIpLists)}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className={styles.filtersDescription}>
              If user’s IP matches with IP from the list, will be routed to Filter Rule except if it matches one of Whitelists
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
