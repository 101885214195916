import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table as AntTable, Pagination } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dayjs from 'dayjs';

import { ActionsTableCol } from './ActionsTableCol';
// import { StatusTableCol } from './StatusTableCol';
// import { CampaignNameCol } from '../CampaignNameCol';

import styles from './Show.module.css';
import {
  id,
  type,
  title,
  channel,
  company,
  status,
  campaignUrl,
  dateAndTimeCreated,
  dateAndTimeEdited
} from '../../../../services/media-campaigns';
import { TableTitle } from '../../../../components/TableTitle';

export const Table = ({
  campaigns,
  checkedColumns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  rowSelection,
  sorting,
  onPaginationChange,
  onActivate,
  onPause,
  onSuspend,
  onDelete,
  onSortingClick
}) => {
  const [tableColumns, setTableColumns] = useState([]);

  const history = useHistory();

  const dataColumns = useMemo(() => {
    return [
      {
        title: (
          <TableTitle
            text={id.title}
            value={id.sortingValue}
            order={sorting.find((s) => s.key === id.sortingValue)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: id.key,
        dataIndex: 'id',
        align: 'center',
        width: 100
      },
      {
        title: type.title,
        key: type.key,
        dataIndex: 'type',
        align: 'center',
        width: 100
      },
      {
        title: (
          <TableTitle
            text={title.title}
            value={title.sortingValue}
            order={sorting.find((s) => s.key === title.sortingValue)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: title.key,
        dataIndex: 'title',
        width: 200
      },
      {
        title: (
          <TableTitle
            text={channel.title}
            value={channel.sortingValue}
            order={sorting.find((s) => s.key === channel.sortingValue)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: channel.key,
        width: 200,
        className: styles.channelColumn,
        render: ({ id, channel }) => {
          return <div onClick={() => history.push(`/media-campaigns/${id}`)}>{channel}</div>;
        }
      },
      {
        title: company.title,
        key: company.key,
        width: 200,
        className: styles.companyColumn,
        render: ({ company_details }) => {
          const { id, name } = company_details;
          return <div onClick={() => history.push(`/companies/${id}`)}>{name}</div>;
        }
      },
      {
        title: status.title,
        key: status.key,
        dataIndex: 'status',
        align: 'center',
        width: 150
      },
      {
        title: campaignUrl.title,
        key: campaignUrl.key,
        className: styles.campaignUrlColumn,
        width: 600,
        render: ({ final_url }) => {
          return (
            <div>
              <div>{final_url}</div>
              <span>
                <CopyToClipboard text={final_url}>
                  <CopyTwoTone style={{ display: final_url ? 'default' : 'none' }} />
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
      {
        title: (
          <TableTitle
            text={dateAndTimeCreated.title}
            value={dateAndTimeCreated.sortingValue}
            order={sorting.find((s) => s.key === dateAndTimeCreated.sortingValue)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: dateAndTimeCreated.key,
        dataIndex: 'date_created',
        align: 'center',
        width: 150,
        render: (date_created) => {
          return dayjs(date_created).format('DD.MM.YYYY. - HH:mm:ss');
        }
      },
      {
        title: (
          <TableTitle
            text={dateAndTimeEdited.title}
            value={dateAndTimeEdited.sortingValue}
            order={sorting.find((s) => s.key === dateAndTimeEdited.sortingValue)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: dateAndTimeEdited.key,
        dataIndex: 'date_edited',
        align: 'center',
        width: 150,
        render: (date_edited) => {
          return dayjs(date_edited).format('DD.MM.YYYY. - HH:mm:ss');
        }
      }
    ];
  }, [history, sorting, onSortingClick]);

  const actionsColumn = useMemo(() => {
    return {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 200,
      render: ({ id, title, status }) => {
        return (
          <ActionsTableCol
            id={id}
            title={title}
            status={status}
            onActivate={onActivate}
            onPause={onPause}
            onSuspend={onSuspend}
            onDelete={onDelete}
          />
        );
      }
    };
  }, [onActivate, onPause, onSuspend, onDelete]);

  useEffect(() => {
    const checkedColumnKeys = Object.keys(checkedColumns).filter((key) => checkedColumns[key]);

    const updatedColumns = dataColumns.filter((column) => checkedColumnKeys.includes(String(column.key)));

    updatedColumns.push(actionsColumn);

    setTableColumns(updatedColumns);
  }, [checkedColumns, dataColumns, actionsColumn]);

  return (
    <div className={styles.campaignsTable}>
      <AntTable
        columns={tableColumns}
        dataSource={campaigns}
        loading={loadingCampaigns}
        pagination={false}
        rowKey={({ id }) => id}
        rowSelection={rowSelection}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={['10', '20', '30']}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          );
        }}
      />
    </div>
  );
};
