import { accountsService } from '../services/accounts';
import { listsService } from '../services/lists';
import { mediaCampaignsService } from '../services/media-campaigns';
import { productsService } from '../services/products';
import { landingPagesService } from '../services/landingPages';
import moment from 'moment';
import { repeatPeriodTypes } from '../pages/media-campaigns/utils/enums';

export const targetingFetch = async () => {
  const promises = [
    accountsService.getAll(),
    listsService.getCountryAndCountryLists(),
    listsService.getOSAndOSLists(),
    listsService.getBrowserAndBrowserLists(),
    listsService.getSubidLists({ fields: 'id,name,subids_list' }),
    listsService.getDeviceTypeLists(),
    mediaCampaignsService.getCampaignPatternTypes(),
    productsService.getAll({ fields: 'id,title,product_lps' }),
    landingPagesService.getAll(),
    mediaCampaignsService.getExternalUrl()
  ];

  return await Promise.all(promises);
};

export const S2S_TYPE = {
  Percent: 'percent',
  ThresholdCumulative: 'threshold_cumulative',
  ThresholdAbsolute: 'threshold_absolute'
};

export const DEFAULT_S2S_ADVANCED_VALUE = {
  s2s: 100,
  s2s_type: S2S_TYPE.Percent,
  is_s2s_advanced: false,
  pixels: {
    pl_s2s_percent: 100,
    p1_s2s_percent: 100,
    p2_s2s_percent: 100,
    p3_s2s_percent: 100,
    p4_s2s_percent: 100,
    p5_s2s_percent: 100,
    p6_s2s_percent: 100,
    p7_s2s_percent: 100,
    p8_s2s_percent: 100
  }
};

export const DEFAULT_AUTOMATION_RULES_VALUE = {
  isActive: true,
  condition: {},
  desired: {}
};

export const transformTargetingData = (values, isClone) => {
  const { id, name, assigned, privateList, patternFormList } = values;

  const transformedData = {
    global_list: false,
    assigned: assigned || [],
    targeting_patterns_details: patternFormList
      ? patternFormList
          .map((pattern, index) => {
            const {
              patternId,
              origin,
              countryOrCountryList,
              osOrOSList,
              browserOrBrowserList,
              subidList,
              deviceTypeList,
              showScheduler,
              scheduledRange,
              repeatEveryNumber,
              repeatEveryPeriod,
              repeatOnDays,
              schedulerEnd,
              patternSetupFormList
            } = pattern;

            if (origin) {
              return null; // (*)
            }

            const patternDetails = {
              country: countryOrCountryList,
              os: osOrOSList,
              browser: browserOrBrowserList,
              subid_list: subidList || null,
              device_type_list: deviceTypeList,
              order: index + 1,
              schedule_start: null,
              targeting_pattern_setups_details: patternSetupFormList
                ? patternSetupFormList.map((patternSetup) => {
                    const {
                      patternSetupId,
                      type,
                      percentage,
                      optimiseBySource,
                      s2sExtended,
                      dynamicPixelOptimisation,
                      automationRules,
                      s2s: dynamicPixelOptimisationS2S
                    } = patternSetup;
                    const { s2s: s2s_value, s2s_type, is_s2s_advanced, pixels } = s2sExtended;
                    const {
                      pl_s2s_percent,
                      p1_s2s_percent,
                      p2_s2s_percent,
                      p3_s2s_percent,
                      p4_s2s_percent,
                      p5_s2s_percent,
                      p6_s2s_percent,
                      p7_s2s_percent,
                      p8_s2s_percent
                    } = pixels;
                    let { product, landingPage, externalUrl } = patternSetup;

                    if (type === 'Regular') {
                      externalUrl = null;
                    } else if (type === 'DDL' || type === 'JSON') {
                      landingPage = null;
                      externalUrl = null;
                    } else {
                      product = null;
                      landingPage = null;
                    }

                    const s2sType = !!dynamicPixelOptimisation || s2s_type === S2S_TYPE.Percent ? S2S_TYPE.Percent : s2s_type;
                    const s2sValue = !!dynamicPixelOptimisation ? dynamicPixelOptimisationS2S : s2s_value;
                    const isS2sAdvanced = !!dynamicPixelOptimisation ? false : is_s2s_advanced;

                    const s2s_object = {
                      s2s_percent: s2sType === S2S_TYPE.Percent ? s2sValue : 0,
                      s2s_threshold: isS2sAdvanced
                        ? 0 // when advanced s2s is on, s2s_threshold must be 0 or it won't work in pixel processor
                        : s2sType === S2S_TYPE.ThresholdCumulative || s2sType === S2S_TYPE.ThresholdAbsolute
                        ? s2sValue
                        : 0,
                      is_s2s_threshold_cumulative: s2sType !== S2S_TYPE.ThresholdAbsolute
                    };

                    const automation_rules = automationRules.map((rule) => ({ ...rule, is_active: rule.isActive }));

                    const patternSetupDetails = {
                      type,
                      product,
                      is_s2s_advanced: isS2sAdvanced,
                      lp: landingPage,
                      pl_s2s_percent,
                      p1_s2s_percent,
                      p2_s2s_percent,
                      p3_s2s_percent,
                      p4_s2s_percent,
                      p5_s2s_percent,
                      p6_s2s_percent,
                      p7_s2s_percent,
                      p8_s2s_percent,
                      external_url: externalUrl,
                      percent: percentage,
                      optimise_by_source: !!dynamicPixelOptimisation ? false : optimiseBySource,
                      dynamic_pixel_optimisation: !!dynamicPixelOptimisation,
                      automation_rules,
                      ...s2s_object
                    };

                    if (patternSetupId && !isClone) {
                      patternSetupDetails.id = patternSetupId;
                    }

                    return patternSetupDetails;
                  })
                : []
            };

            if (patternId && !isClone) {
              patternDetails.id = patternId;
            }

            if (showScheduler) {
              scheduledRange[0].milliseconds(0);
              scheduledRange[1].milliseconds(0); // NOTE: antd sets some random milliseconds when picking values so here we reset them to zero
              patternDetails.schedule_start = moment(scheduledRange[0]).utc(true).format('YYYY-MM-DDTHH:mm:ss');
              patternDetails.schedule_duration = (scheduledRange[1] - scheduledRange[0]) / 1000;
              patternDetails.schedule_repeat_every = repeatEveryNumber;
              patternDetails.schedule_repeat_end = repeatEveryNumber ? moment(schedulerEnd).format('YYYY-MM-DD') : '2030-01-01';
              patternDetails.schedule_repeat_type = repeatEveryNumber ? repeatEveryPeriod : repeatPeriodTypes.DAY;

              if (repeatEveryNumber && repeatOnDays?.length) {
                patternDetails.schedule_repeat_days = repeatOnDays;
              }
            }

            return patternDetails;
          })
          .filter((pattern) => !!pattern) // Remove null values, which are set for gpss patterns - see (*)
      : []
  };

  if (name) {
    transformedData.name = name;
    transformedData.global_list = true;
  }

  if (id && !isClone) {
    transformedData.id = id;
  }

  if (assigned !== undefined) {
    transformedData.assigned = assigned;
  }

  if (privateList !== undefined) {
    transformedData.private = privateList;
  }

  return transformedData;
};

export const targetingInitialValues = (listData, isClone) => {
  const { id, assigned, targeting_patterns_details } = listData;
  let { name } = listData;

  name = isClone ? name + ' - clone' : name;
  const privateList = listData.private;

  const patternFormList = targeting_patterns_details.map((pattern) => {
    const {
      id,
      country,
      os,
      browser,
      subid_list,
      device_type_list,
      schedule_start,
      schedule_duration,
      schedule_repeat_every,
      schedule_repeat_type,
      schedule_repeat_days,
      schedule_repeat_end,
      origin,
      targeting_pattern_setups_details
    } = pattern;

    const countryOrCountryList = country;
    const osOrOSList = os;
    const browserOrBrowserList = browser;
    const subidList = subid_list || undefined;
    const deviceTypeList = device_type_list;
    const showScheduler = !!schedule_start;
    let scheduledRange;
    let repeatEveryNumber = 0;
    let repeatEveryPeriod = repeatPeriodTypes.DAY;
    let repeatOnDays;
    let schedulerEnd = moment('2030-01-01');

    if (showScheduler) {
      const timezoneOffset = -new Date().getTimezoneOffset() / 60;
      const scheduleTo = moment(schedule_start).add(schedule_duration, 's').utc().utcOffset(timezoneOffset, true).format();
      scheduledRange = [moment(schedule_start).utc().utcOffset(timezoneOffset, true), moment(scheduleTo)];
      repeatEveryNumber = schedule_repeat_every;
      repeatEveryPeriod = schedule_repeat_type;
      repeatOnDays = schedule_repeat_days;
      schedulerEnd = moment(schedule_repeat_end);
    }

    const patternSetupFormList = targeting_pattern_setups_details.map((patternSetup) => {
      const {
        id,
        type,
        lp,
        external_url,
        s2s_percent,
        s2s_threshold,
        is_s2s_threshold_cumulative,
        is_s2s_advanced,
        percent,
        optimise_by_source,
        pl_s2s_percent,
        p1_s2s_percent,
        p2_s2s_percent,
        p3_s2s_percent,
        p4_s2s_percent,
        p5_s2s_percent,
        p6_s2s_percent,
        p7_s2s_percent,
        p8_s2s_percent,
        gpss_rule,
        dynamic_pixel_optimisation,
        automation_rules
      } = patternSetup;
      let { product } = patternSetup;
      product = product || undefined;
      const landingPage = lp || undefined;
      const externalUrl = external_url || undefined;
      const s2s = s2s_percent || s2s_threshold || 0;
      const percentage = percent;
      const optimiseBySource = optimise_by_source;
      const gpssRule = gpss_rule ?? '-';
      let patternSetupValues = {
        type,
        product,
        landingPage,
        s2sExtended: {
          pixels: {
            pl_s2s_percent: pl_s2s_percent || 100,
            p1_s2s_percent: p1_s2s_percent || 100,
            p2_s2s_percent: p2_s2s_percent || 100,
            p3_s2s_percent: p3_s2s_percent || 100,
            p4_s2s_percent: p4_s2s_percent || 100,
            p5_s2s_percent: p5_s2s_percent || 100,
            p6_s2s_percent: p6_s2s_percent || 100,
            p7_s2s_percent: p7_s2s_percent || 100,
            p8_s2s_percent: p8_s2s_percent || 100
          },
          s2s_type: s2s_threshold
            ? is_s2s_threshold_cumulative
              ? S2S_TYPE.ThresholdCumulative
              : S2S_TYPE.ThresholdAbsolute
            : S2S_TYPE.Percent,
          is_s2s_advanced,
          s2s
        },
        externalUrl,
        dynamicPixelOptimisation: dynamic_pixel_optimisation,
        automationRules: automation_rules.map((rule) => ({ ...rule, isActive: rule.is_active })),
        s2s, // s2s field when dynamicPixelOptimisation enabled
        percentage,
        optimiseBySource,
        gpssRule
      };

      if (!isClone) {
        patternSetupValues.patternSetupId = id;
      }

      return patternSetupValues;
    });

    let patternValues = {
      countryOrCountryList,
      osOrOSList,
      browserOrBrowserList,
      subidList,
      deviceTypeList,
      showScheduler,
      scheduledRange,
      repeatEveryNumber,
      repeatEveryPeriod,
      repeatOnDays,
      schedulerEnd,
      origin,
      patternSetupFormList
    };

    if (!isClone) {
      patternValues.patternId = id;
    }

    return patternValues;
  });

  let targetingInitialValues = {
    name,
    privateList,
    assigned,
    patternFormList
  };

  if (!isClone) {
    targetingInitialValues.id = id;
  }

  return targetingInitialValues;
};
