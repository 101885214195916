import React from 'react';

import { ActionsTableCol } from './ActionsTableCol';
import { TableCommon } from '../TableCommon';

export const Table = ({
  allCountries,
  allAccounts,
  countryLists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  loader,
  sorting,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const renderSelectedItems = ({ countries_details }) => {
    return (
      countries_details
        .map((country) => {
          return country.code2;
        })
        .join(', ') || '-'
    );
  };

  const renderActions = (record) => {
    return (
      <ActionsTableCol
        allCountries={allCountries}
        allAccounts={allAccounts}
        countryList={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        loader={loader}
      />
    );
  };

  return (
    <TableCommon
      lists={countryLists}
      loading={loading}
      totalRows={totalRows}
      currentPage={currentPage}
      pageSize={pageSize}
      itemsTitle="List of Countries"
      sorting={sorting}
      onPaginationChange={onPaginationChange}
      renderSelectedItems={renderSelectedItems}
      renderActions={renderActions}
      onSortingClick={onSortingClick}
    />
  );
};
