import { ImportOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';

import styles from './ImportPopover.module.css';

export const ImportPopover = ({ onImportClick }) => {
  return (
    <Popover placement="top" content="Import from Campaign" trigger="hover">
      <div className={styles.importBtnWrapper} onClick={onImportClick}>
        <ImportOutlined />
      </div>
    </Popover>
  );
};
