import { Button, Checkbox, Form, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { usePromise } from 'react-use';
import { confirmClose } from '../../../utils/confirmClose';
import { showApiErrors } from '../../../utils/showApiErrors';
import { landingPagesService } from '../../../services/landingPages';
import styles from './CreatePrelanderCampaignModal.module.css';
import { withCopyIcon } from '../../media-campaigns/utils/withCopyIcon';
import { mediaCampaignsService } from '../../../services/media-campaigns';
import { Select } from '../../../components/Select';
import { generateSelectOptionsWithIdValues } from '../../../utils/options';

const InputWithCopyIcon = withCopyIcon(Input);

export const CreatePrelanderCampaignModal = ({ modalVisible, lpId, onCancel }) => {
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [campaignUrl, setCampaignUrl] = useState('');

  const [identities, setIdentities] = useState([]);
  const [areIdentitiesLoading, setAreIdentitiesLoading] = useState(false);

  const [useRedirect, setUseRedirect] = useState(false);
  const [redirectDomains, setRedirectDomains] = useState([]);
  const [areRedirectDomainsLoading, setAreRedirectDomainsLoading] = useState(false);

  const [form] = Form.useForm();

  const mounted = usePromise();

  useEffect(() => {
    const getIdentities = async () => {
      try {
        setAreIdentitiesLoading(true);
        setIdentities(await mounted(mediaCampaignsService.getIdentities()));
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAreIdentitiesLoading(false);
      }
    };

    const getRedirectDomains = async () => {
      try {
        setAreRedirectDomainsLoading(true);
        setRedirectDomains(await mounted(mediaCampaignsService.getDomains()));
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAreRedirectDomainsLoading(false);
      }
    };

    getIdentities();
    getRedirectDomains();
  }, [mounted]);

  const handleCancel = () => {
    wasFormChanged ? confirmClose(() => onCancel()) : onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const { campaign_url: campaignUrlResponse } = await landingPagesService.createPrelanderCampaign(lpId, values);
      setCampaignUrl(campaignUrlResponse);
      setWasFormChanged(false);
      form.setFieldsValue({ campaignUrl: campaignUrlResponse });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      form.resetFields();
      setWasFormChanged(false);
      setCampaignUrl('');
    }
  }, [form, modalVisible]);

  return (
    <Modal
      title="Create prelander"
      visible={modalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          style={{ display: campaignUrl ? 'none' : 'inline-block' }}
          onClick={() => form.submit()}
        >
          Create
        </Button>
      ]}
      width={400}
    >
      <Form form={form} className={styles.form} layout="vertical" onFinish={handleSubmit} onValuesChange={() => setWasFormChanged(true)}>
        {campaignUrl ? (
          <Row>
            <Form.Item name="campaignUrl" label="Campaign URL">
              <InputWithCopyIcon placeholder="Campaign URL" text={campaignUrl} />
            </Form.Item>
          </Row>
        ) : (
          <>
            <Row>
              <Form.Item name="product_title" label="Product title" rules={[{ required: true, message: 'Please type title!' }]}>
                <Input placeholder="Type product title" />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item name="product_link" label="Product link" rules={[{ required: true, message: 'Please type product link!' }]}>
                <Input placeholder="Type product link" />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item name="identity" label="Identity" rules={[{ required: true, message: 'Please select an identity!' }]}>
                <Select placeholder="Identity" optionFilterProp="data-searchvalue" showSearch loading={areIdentitiesLoading}>
                  {generateSelectOptionsWithIdValues(identities)}
                </Select>
              </Form.Item>
            </Row>

            <Row>
              <Checkbox
                checked={useRedirect}
                onChange={(event) => {
                  const value = event.target.checked;
                  setUseRedirect(value);
                }}
              >
                Use Redirect
              </Checkbox>
            </Row>

            {useRedirect && (
              <Row style={{ marginTop: '10px' }}>
                <Form.Item
                  name="redirect_domain"
                  label="Redirect domain"
                  rules={[{ required: true, message: 'Please select a redirect domain!' }]}
                >
                  <Select placeholder="Redirect domain" optionFilterProp="data-searchvalue" showSearch loading={areRedirectDomainsLoading}>
                    {generateSelectOptionsWithIdValues(redirectDomains)}
                  </Select>
                </Form.Item>
              </Row>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};
