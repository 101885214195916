import React from 'react';
import { Table as AntTable, Pagination, Spin } from 'antd';
import { TableTitle } from '../../../components/TableTitle';
import moment from 'moment';

import { ActionsTableCol } from './ActionsTableCol';
import { getName, getTitle, getUserFullName } from '../../../utils/functions';
import { GPSSStatusTitle, GPSSStatusValue } from '../../../services/gpss';

export const Table = ({
  rulesLoading,
  rules,
  countries,
  countriesLoading,
  countriesRefetching,
  totalItems,
  currentPage,
  pageSize,
  sorting,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const timezoneOffset = -new Date().getTimezoneOffset() / 60;
  const columns = [
    {
      title: <TableTitle text="Id" value="id" order={sorting.find((s) => s.key === 'id')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'id',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        };
      }
    },
    {
      title: <TableTitle text="Name" value="name" order={sorting.find((s) => s.key === 'name')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'name'
    },
    {
      title: (
        <TableTitle
          text="Start date (UTC-0)"
          value="start_date"
          order={sorting.find((s) => s.key === 'start_date')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'startDate',
      dataIndex: 'start_date',
      align: 'left',
      render: (start_date) => (start_date ? moment(start_date).utc().utcOffset(timezoneOffset, true).format('DD.MM.YYYY. - HH:mm:ss') : '')
    },
    {
      title: (
        <TableTitle
          text="End date (UTC-0)"
          value="end_date"
          order={sorting.find((s) => s.key === 'end_date')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'endDate',
      dataIndex: 'end_date',
      align: 'center',
      width: 150,
      render: (end_date) => (end_date ? moment(end_date).utc().utcOffset(timezoneOffset, true).format('DD.MM.YYYY. - HH:mm:ss') : '')
    },
    {
      title: (
        <TableTitle
          text="Device Type"
          value="device_type_list"
          order={sorting.find((s) => s.key === 'device_type_list')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'device_type_list',
      dataIndex: 'device_type_list_details',
      render: (device_type_list_details) => getName(device_type_list_details)
    },
    {
      title: (
        <TableTitle
          text="Product"
          value="product"
          order={sorting.find((s) => s.key === 'product')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'product',
      dataIndex: 'product_details',
      render: (product_details) => getTitle(product_details)
    },
    {
      title: <TableTitle text="LP" value="lp" order={sorting.find((s) => s.key === 'lp')?.value} onSortingClick={onSortingClick} />,
      key: 'lp',
      dataIndex: 'lp_details',
      render: (lp_details) => getTitle(lp_details)
    },
    {
      title: (
        <TableTitle
          text="Country"
          value="country"
          order={sorting.find((s) => s.key === 'country')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'country',
      dataIndex: 'country_details',
      render: (country_details) => getName(country_details)
    },
    {
      title: <TableTitle text="OS" value="os" order={sorting.find((s) => s.key === 'os')?.value} onSortingClick={onSortingClick} />,
      key: 'os',
      dataIndex: 'os_details',
      render: (os_details) => getName(os_details)
    },
    {
      title: (
        <TableTitle
          text="Browser"
          value="browser"
          order={sorting.find((s) => s.key === 'browser')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'browser',
      dataIndex: 'browser_details',
      render: (browser_details) => getName(browser_details)
    },
    {
      title: (
        <TableTitle
          text="Creator"
          value="creator"
          order={sorting.find((s) => s.key === 'creator')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'creator',
      dataIndex: 'creator_details',
      render: (creator_details) => getUserFullName(creator_details)
    },
    {
      title: (
        <TableTitle
          text="S2S"
          value="s2s_percent"
          order={sorting.find((s) => s.key === 's2s_percent')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 's2s_percent',
      render: (s2s_percent) => `${s2s_percent}%`
    },
    {
      title: (
        <TableTitle
          text="Date And Time Created"
          value="date_created"
          order={sorting.find((s) => s.key === 'date_created')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'dateAndTimeCreated',
      dataIndex: 'date_created',
      align: 'left',
      render: (date_created) => moment(date_created).format('DD.MM.YYYY. - HH:mm:ss')
    },
    {
      title: (
        <TableTitle
          text="Date and Time Edited"
          value="date_edited"
          order={sorting.find((s) => s.key === 'date_edited')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      key: 'dateAndTimeEdited',
      dataIndex: 'date_edited',
      align: 'center',
      width: 150,
      render: (date_edited) => {
        return moment(date_edited).format('DD.MM.YYYY. - HH:mm:ss');
      }
    },
    {
      title: (
        <TableTitle text="Status" value="status" order={sorting.find((s) => s.key === 'status')?.value} onSortingClick={onSortingClick} />
      ),
      dataIndex: 'status',
      render: (status) => {
        return <span style={{ whiteSpace: 'nowrap' }}>{GPSSStatusTitle[status]}</span>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (rule) => {
        return rule.status === GPSSStatusValue.Completed ? (
          <ActionsTableCol
            rule={rule}
            countries={countries}
            countriesLoading={countriesLoading}
            countriesRefetching={countriesRefetching}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ) : (
          <Spin size="small" />
        );
      }
    }
  ];

  return (
    <div>
      <AntTable
        loading={rulesLoading}
        dataSource={rules}
        style={{ marginTop: '3%' }}
        rowKey={({ id }) => id}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
