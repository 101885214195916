import { Row, Col, Form, InputNumber } from 'antd';
import React from 'react';

export const PixelLimit = () => {
  return (
    <div>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item label="Lead Limit" name="pl_limit">
            <InputNumber min={0} max={1} placeholder="Lead limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 1 Limit" name="p1_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 1 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 2 Limit" name="p2_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 2 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={8}>
          <Form.Item label="Pixel 3 Limit" name="p3_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 3 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 4 Limit" name="p4_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 4 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 5 Limit" name="p5_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 5 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={8}>
          <Form.Item label="Pixel 6 Limit" name="p6_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 6 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 7 Limit" name="p7_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 7 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 8 Limit" name="p8_limit">
            <InputNumber min={0} max={1} placeholder="Pixel 8 Limit" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
