import { Modal, Select, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from '../Lists.module.css';
import { getUserFullName } from '../../../utils/functions';
import { useCustomQuery } from '../../../utils/hooks/queries';

const { Option } = Select;
export const CustomTooltip = ({ option, title, exclude, onVisibleChange }) => (
  <Tooltip title={title} mouseEnterDelay={0.5} onVisibleChange={onVisibleChange}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {exclude ? <ExcludeComponent option={option} /> : <ImgComponent option={option} />}
      </div>

      {option.listed ? (
        <Tooltip title="Listed" className={styles.listedTooltip}>
          <img src="/images/warning_icon_circle.png" alt="Listed" width={20} />
        </Tooltip>
      ) : null}
    </div>
  </Tooltip>
);

export const getSelectOptionsWithTooltip = (options, exclude = false) =>
  options.map((option) => {
    return (
      <Option key={option.id} value={option.id} data-searchvalue={option.name + option.id}>
        <CustomTooltip option={option} title={option.name} exclude={exclude}></CustomTooltip>
      </Option>
    );
  });

export const ExpendedCustomTooltip = ({ option, typeOfList, exclude, queryHookCallback }) => {
  const [shouldThereBeMore, setShouldThereBeMore] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [tooltipList, setTooltipList] = useState();
  const [dataForModal, setDataForModal] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const optionId = String(option.id).replace('list:', '');

  const {
    isFetching: listRefetching,
    isLoading: listLoading,
    data: list
  } = useCustomQuery(queryHookCallback, optionId, [], { enabled: tooltipVisible });

  useEffect(() => {
    setTooltipList(
      typeOfList && list[typeOfList]
        ? list[typeOfList].split(',').map((el, index) => {
            if (index < 10) {
              return <div key={index}>{el}</div>;
            } else {
              if (index === 10) {
                setShouldThereBeMore(true);
              }
              return null;
            }
          })
        : list.name
    );
  }, [list, typeOfList]);

  const tooltipTitle = (
    <>
      {listLoading || listRefetching ? (
        <Spin size="small" />
      ) : (
        <>
          {tooltipList}
          {shouldThereBeMore && (
            <div
              onClick={() => {
                setVisibility(true);
                setDataForModal(list[typeOfList].split(','));
              }}
            >
              <a>Show more</a>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <Modal title="List" visible={visibility} onCancel={() => setVisibility(false)} footer={null}>
        {dataForModal.map((el, index) => {
          return <div key={index}>{el}</div>;
        })}
      </Modal>
      <CustomTooltip option={option} title={tooltipTitle} exclude={exclude} onVisibleChange={setTooltipVisible} />
    </>
  );
};

export const getSelectOptionsWithExtendedTooltip = (options, typeOfList = null, exclude = false, queryHookCallback = () => {}) =>
  options.map((option) => {
    return (
      <Option key={option.id} value={option.id} data-searchvalue={option.name + option.id}>
        <ExpendedCustomTooltip
          typeOfList={typeOfList}
          option={option}
          exclude={exclude}
          queryHookCallback={queryHookCallback}
        ></ExpendedCustomTooltip>
      </Option>
    );
  });

export const ExcludeComponent = ({ option }) => (
  <>
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '5px',
        background: option.exclude ? 'rgb(219, 64, 64)' : 'green',
        display: 'inline-block'
      }}
    ></div>
    {option.img && (
      <span style={{ marginLeft: 5 }} className={styles.selectImgWrapper}>
        <img src={option.img} alt={option.id} />
      </span>
    )}
    <span style={{ marginLeft: option.img ? 0 : 5 }}>{option.name}</span>
  </>
);

export const ImgComponent = ({ option }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    {option.img ? (
      <span className={styles.selectImgWrapper}>
        <img src={option.img} alt={option.id} />
      </span>
    ) : null}
    {option.name}
  </div>
);

export const getAccountsSelectOptions = (accounts) =>
  accounts.map((acc) => {
    const accountName = getUserFullName(acc);
    return (
      <Option key={acc.id} value={acc.id} data-searchvalue={accountName + acc.id.toString()}>
        {accountName}
      </Option>
    );
  });
