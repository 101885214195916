import React from 'react';

import { ListTypes } from '../../../../services/lists';
import { AddOrEditListModal } from '../AddOrEditListModal';
import { MultipleItemsSelect } from '../MultipleItemsSelect';

export const AddOrEditSubidListModal = ({
  visibleModal,
  allAccounts,
  subidList,
  isClone,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  allAccountsLoading
}) => {
  const fieldName = 'subids';
  const fieldNameRead = 'subids_json';

  const renderItemsSelect = (form) => (
    <MultipleItemsSelect form={form} title="SUBIDs" name={fieldName} message="Please type SUBID/s!" pasteHandler={true} />
  );

  return (
    <AddOrEditListModal
      visibleModal={visibleModal}
      allAccounts={allAccounts}
      onCloseModal={onCloseModal}
      onClone={onClone}
      onEdit={onEdit}
      onAdd={onAdd}
      list={subidList}
      listType={ListTypes.subid_lists}
      itemsFieldName={fieldName}
      itemsFieldNameRead={fieldNameRead}
      title="SUBID List"
      nameFieldTitle="SUBID List Name"
      renderItemsSelect={renderItemsSelect}
      isClone={isClone}
      areAccountsLoading={allAccountsLoading}
    />
  );
};
