import { Switch } from 'antd';
import React from 'react';

import styles from './SwitchWithLabelOnRight.module.css';

export const SwitchWithLabelOnRight = ({ checked, label, onChange }) => {
  return (
    <div className={styles.switchWrapper}>
      <Switch checked={checked} onChange={onChange} />
      <span>{label}</span>
    </div>
  );
};
