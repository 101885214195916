import { Modal, Popover } from 'antd';
import React, { useState } from 'react';
import { leadSourcesService } from '../../../services/lead-sources';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { AddOrEditLeadSource } from './AddOrEditLeadSource';

import styles from './ActionsTableCol.module.css';

export const ActionsTableCol = ({ leadSource, onEdit, onDelete }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  const handleDelete = async () => {
    try {
      await leadSourcesService.delete(leadSource.id);
      openSuccessNotification({ message: 'Successfully deleted lead source!' });
      onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${leadSource.name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDelete,
      onCancel() {}
    });
  };

  return (
    <div className={styles.actionsWrapper}>
      <Popover content="Edit" trigger="hover">
        <div className={styles.action} onClick={handleOpenModal}>
          <img src="/images/edit_icon.svg" alt="Edit" width="18px" />
        </div>
      </Popover>

      <Popover content="Delete" trigger="hover">
        <div className={styles.action} onClick={confirmDelete}>
          <img src="/images/delete_icon.png" alt="Delete" width="21px" />
        </div>
      </Popover>

      <AddOrEditLeadSource
        modalVisible={modalVisible}
        leadSource={leadSource}
        onClose={handleCloseModal}
        onEdit={() => {
          onEdit();
          handleCloseModal();
        }}
      />
    </div>
  );
};
