import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';

import { getSelectOptionsWithTooltip } from '../pages/lists/utils/options';
import { removeDuplicateCountriesHelper } from '../utils/functions';
import { isAny } from '../utils/pricesValidator';
import styles from './DynamicPriceFields.module.css';
import { Select } from './Select';

const countDecimals = (value) => {
  if (typeof value === 'string') {
    if (Math.floor(value).toString() === value) {
      return 0;
    }
    return value.split('.')[1].length || 0;
  } else {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split('.')[1].length || 0;
  }
};

export const DynamicPriceFields = ({
  allCountries,
  allOSs,
  allBrowsers,
  allDeviceTypes,
  listName,
  disabled,
  isListForm,
  areCountriesLoading,
  areOSsLoading,
  areBrowsersLoading,
  areDeviceTypesLoading,
  isAdvanced,
  form,
  isRevShare
}) => {
  const handleCountryChange = (value, index) => {
    if (isAny(value)) {
      const list = [...form.getFieldValue(listName)];
      list[index] = { os: 1, browser: 1, country: 1, price: list[index].price };
      form.setFieldsValue({
        [listName]: list
      });
    }
  };

  const removeDuplicateCountries = (selectedOptionData, rowIndex) => {
    const {
      children: {
        props: {
          option: { id, listed }
        }
      }
    } = selectedOptionData;

    if (listed && !isAdvanced) {
      removeDuplicateCountriesHelper({ form, rowIndex, formFieldName: listName, country: id });
    }
  };
  const spanSize = isAdvanced ? 5 : 11;
  return (
    <Form.List name={listName} shouldUpdate>
      {(fields, { add, remove }) => {
        return (
          <>
            {isListForm ? (
              <span className={styles.paddingBottom15}>Price Lists</span>
            ) : (
              <Row gutter={24} className={styles.paddingBottom15}>
                <Col span={spanSize}>Country</Col>
                <Col span={4} style={{ display: isAdvanced ? 'block' : 'none' }}>
                  OS
                </Col>
                <Col span={4} style={{ display: isAdvanced ? 'block' : 'none' }}>
                  Browser
                </Col>
                <Col span={4} style={{ display: isAdvanced ? 'block' : 'none' }}>
                  Device type
                </Col>
                <Col span={spanSize}>{isRevShare ? 'Revshare%' : 'Price'}</Col>
              </Row>
            )}
            {fields.map((field, index) => (
              <Row gutter={24} key={index}>
                <div style={{ display: 'none' }}>
                  <Form.Item {...field} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']}>
                    <Input />
                  </Form.Item>
                </div>

                <Col span={spanSize}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'country']}
                    fieldKey={[field.fieldKey, 'country']}
                    rules={[{ required: true, message: 'Please select country!' }]}
                  >
                    <Select
                      placeholder="Select country"
                      dropdownClassName={styles.selectDropdownWithImg}
                      optionFilterProp="data-searchvalue"
                      mode="multiple"
                      showSearch
                      loading={areCountriesLoading}
                      onChange={(value) => handleCountryChange(value, index)}
                      onSelect={(_, optionData) => removeDuplicateCountries(optionData, field.name)}
                    >
                      {getSelectOptionsWithTooltip(allCountries)}
                    </Select>
                  </Form.Item>
                </Col>
                {isAdvanced ? (
                  <>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'os']}
                        fieldKey={[field.fieldKey, 'os']}
                        rules={[{ required: true, message: 'Please select OS!' }]}
                        hidden={!isAdvanced}
                      >
                        <Select
                          placeholder="Select OS"
                          dropdownClassName={styles.selectDropdownWithImg}
                          optionFilterProp="data-searchvalue"
                          showSearch
                          loading={areOSsLoading}
                        >
                          {getSelectOptionsWithTooltip(allOSs)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'browser']}
                        fieldKey={[field.fieldKey, 'browser']}
                        rules={[{ required: true, message: 'Please select browser!' }]}
                      >
                        <Select
                          placeholder="Select browser"
                          dropdownClassName={styles.selectDropdownWithImg}
                          optionFilterProp="data-searchvalue"
                          showSearch
                          loading={areBrowsersLoading}
                        >
                          {getSelectOptionsWithTooltip(allBrowsers)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'device_type']}
                        fieldKey={[field.fieldKey, 'device_type']}
                        rules={[{ required: true, message: 'Please select device type!' }]}
                      >
                        <Select
                          placeholder="Select device type"
                          optionFilterProp="data-searchvalue"
                          showSearch
                          loading={areDeviceTypesLoading}
                        >
                          {getSelectOptionsWithTooltip(allDeviceTypes)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
                <Col span={spanSize}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'price']}
                    fieldKey={[field.fieldKey, 'price']}
                    rules={[
                      {
                        required: true,
                        validator: (rule, value) => {
                          if (value !== undefined && value !== null && value !== '' && !isNaN(value)) {
                            let decimals = countDecimals(value);
                            if (value >= 1000 || decimals > 5) {
                              return Promise.reject('Please enter valid price (max 1000 & only to 5 decimals)');
                            }
                          } else {
                            return Promise.reject('Enter valid price');
                          }
                          return Promise.resolve();
                        },
                        whitespace: true
                      }
                    ]}
                  >
                    <InputNumber placeholder={`${isRevShare ? 'Revshare%' : 'Price'}`} min={0} className={styles.width100} />
                  </Form.Item>
                </Col>
                <Col span={2} style={{ display: disabled ? 'none' : 'flex' }}>
                  <img
                    src="/images/delete_icon.png"
                    alt="Delete"
                    width="21px"
                    height="21px"
                    onClick={() => remove(field.name)}
                    className={styles.deleteAligned}
                  />
                </Col>
              </Row>
            ))}
            <Row gutter={24} style={{ display: disabled ? 'none' : 'block' }}>
              <Col span={24}>
                <Form.Item>
                  <Button ghost type="primary" onClick={() => add({ country: [], browser: 1, os: 1, price: 0 })} block>
                    <PlusOutlined /> Add field
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};
