import React, { useState, useEffect } from 'react';

import { Heading } from '../Heading';
import { Table } from './Table';
import { AddOrEditIpAddressListModal } from './AddOrEditIpAddressListModal';

import { listsService, ListTypes } from '../../../../services/lists';
import { accountsService } from '../../../../services/accounts';
import { useDebounce, useFetch } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { SortDirection } from '../../../../services/statistic';

// import styles from '../../Lists.module.css';

const listType = ListTypes.ip_lists;

export const IpAddressesTab = () => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };

    getAccounts();
  }, []);

  const loadIpAddressLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      fields: 'id,img,name,creator_details,date_created,ips_lists,ips_json,ips,assigned,exclude,private',
      filters,
      sorting
    });

  const [
    {
      data: { results: ipAddressLists, count: totalRows },
      isFetching
    },
    getIpAddressLists
  ] = useFetch(loadIpAddressLists);

  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getIpAddressLists();
  }, [debouncedSearchValue, currentPage, getIpAddressLists, filters, sorting]);

  const handleDelete = () => {
    if (ipAddressLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getIpAddressLists();
  };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="Ip Address"
        allAccounts={allAccounts}
        areAccountsLoading={allAccountsLoading}
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        allAccounts={allAccounts}
        ipAddressLists={ipAddressLists}
        loading={loading}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        allAccountsLoading={allAccountsLoading}
        sorting={sorting}
        onClone={getIpAddressLists}
        onEdit={getIpAddressLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />

      <AddOrEditIpAddressListModal
        visibleModal={visibleAddModal}
        allAccounts={allAccounts}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getIpAddressLists}
        allAccountsLoading={allAccountsLoading}
      />
    </div>
  );
};
