import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, AutoComplete, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { showApiErrors } from '../../../utils/showApiErrors';
import { landingPagesService } from '../../../services/landingPages';

import styles from './Header.module.css';

export const Header = ({ filters, onFilterChange, onSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const [allLandingPageTitles, setAllLandingPageTitles] = useState([]);

  const transformLandingPages = useCallback((landingPageTitles) => {
    return landingPageTitles.map((lpTitle, index) => ({
      key: index,
      label: lpTitle,
      value: lpTitle
    }));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const landingPages = await landingPagesService.getAll({ fields: 'title' });
        const landingPageTitleArr = _.uniq(landingPages.map((lp) => lp.title));
        setAllLandingPageTitles(transformLandingPages(landingPageTitleArr));
      } catch (e) {
        showApiErrors(e);
      }
    })();
  }, [transformLandingPages]);

  return (
    <>
      <Row gutter={32}>
        <Col>
          <AutoComplete
            placeholder="Search by ID or Title"
            options={allLandingPageTitles}
            value={searchValue}
            style={{ width: 400 }}
            filterOption={true} // This should be true by default, but it's not - filtering won't work if this isn't set explicitly
            onChange={(value) => {
              setSearchValue(value);
              onSearch && onSearch(value);
            }}
          />
        </Col>

        <Col>
          <Link to="/editor">
            <Button type="primary" size="default" style={{ marginLeft: 0 }}>
              <PlusOutlined /> Add New Landing Page
            </Button>
          </Link>
        </Col>
      </Row>

      <Row gutter={32} className={styles.filterCheckboxRow}>
        <Col>
          <Checkbox
            checked={!!filters.showLpsWithObsoleteUploadedImage}
            onChange={(e) => onFilterChange({ ...filters, showLpsWithObsoleteUploadedImage: e.target.checked })}
          >
            Show LPs with obsolete uploaded image
          </Checkbox>
        </Col>
      </Row>
    </>
  );
};
