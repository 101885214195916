import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

import { AddOrEdit } from './AddOrEdit';

export const Header = ({ onAdd }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <>
      <Row>
        <h1>Company Tags</h1>
      </Row>

      <Row gutter={32}>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleOpenModal}>
            Add New Company tag
          </Button>
        </Col>
      </Row>

      <AddOrEdit
        modalVisible={modalVisible}
        onClose={handleCloseModal}
        onAdd={() => {
          onAdd();
          handleCloseModal();
        }}
      />
    </>
  );
};
