import React from 'react';
import { Popover } from 'antd';

import styles from '../UserNotifications.module.css';
import { useHistory } from 'react-router';
import { confirmDeleteUserNotificationModal } from '../../../utils/user-notifications';

export const ActionsTableCol = ({
  userNotification,
  setNotificationId,
  setCloneModalVisible,
  setDetailsModalVisible,
  onDelete
}) => {
  const history = useHistory();

  return (
    <div className={styles.actions}>
      <Popover content="See details" trigger="hover">
        <div
          onClick={(e) => {
            setNotificationId(userNotification.id);
            e.stopPropagation();
            setDetailsModalVisible(true);
          }}
        >
          <img
            src="/images/preview_icon.png"
            alt="Preview"
            width="19px"
          />
        </div>
      </Popover>
      <Popover content="Edit" trigger="hover">
        <div
          onClick={(e) => {
            setNotificationId(userNotification.id);
            e.stopPropagation();
            if (userNotification.action !== 'Deleted') {
              history.push(`/user-notifications/${userNotification.id}`);
            }
          }}
        >
          <img
            style={userNotification.action === 'Deleted' ? { filter: 'grayscale(100%)' } : {}}
            src="/images/edit_icon.svg"
            alt="Edit"
            width="19px"
          />
        </div>
      </Popover>
      <Popover content="Clone" trigger="hover">
        <div
          onClick={(e) => {
            setNotificationId(userNotification.id);
            e.stopPropagation();
            setCloneModalVisible(true);
          }}
        >
          <img src="/images/copy.png" alt="Copy" width="19px" />
        </div>
      </Popover >
      <Popover
        content="Delete"
        trigger="hover"
        onClick={(e) => {
          e.stopPropagation();
          if (userNotification.action !== 'Deleted') {
            confirmDeleteUserNotificationModal(userNotification, onDelete);
          }
        }}
      >
        <div>
          <img
            style={userNotification.action === 'Deleted' ? { filter: 'grayscale(100%)' } : {}}
            src="/images/delete_icon.png"
            alt="Delete"
            width="22px"
          />
        </div>
      </Popover>
    </div >
  );
};
