import React, { useState } from 'react';
import { Header } from './Header';
import { status_codes } from './status_codes';
import { openSuccessNotification } from '../../utils/notifications';
import { s2sService } from '../../services/s2s';
import { showApiErrors } from '../../utils/showApiErrors';

export const S2STest = () => {
  const [loading, setLoading] = useState(false);
  const [pixelStatus, setPixelStatus] = useState([]);

  const handleFilters = async (filters) => {
    setLoading(true);
    try {
      let country = filters.country ? filters.country : '';
      const res = await s2sService.runS2STest(filters.channel, {
        test_link: filters.url,
        user_agent_string: filters.user_agent,
        country
      });
      const pixel_status = [];
      openSuccessNotification({ message: res.message, duration: 8 });
      res.data.forEach(function (val) {
        const status = status_codes.find((status) => {
          return status.code === val.s2s_call_response_code.toString();
        });
        pixel_status.push(
          <>
            <code>
              S2S Response Code:{' '}
              <span style={{ color: val.s2s_call_response_code >= 200 && val.s2s_call_response_code < 300 ? '#36e02e' : '#ff0000' }}>
                {val.s2s_call_response_code} ( {status ? status.phrase : null})
              </span>
              <br />
            </code>
            <code>
              S2S Type: <span style={{ color: '#ff0000' }}>{val.s2s_type}</span>
              <br />
            </code>
            <code>
              S2S Link: <span style={{ color: '#ff0000' }}>{val.s2s_link}</span>
              <br />
            </code>
            <code>
              Response: <span style={{ color: '#ff0000' }}>{val.s2s_call_response_text}</span>
              <br />
            </code>
            <br />
          </>
        );
      });
      setPixelStatus(pixel_status);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setPixelStatus([]);
      showApiErrors(error);
    }
  };
  return (
    <div>
      <h1>S2S Test</h1>
      <Header
        onSubmit={(e) => {
          handleFilters(e);
        }}
        loading={loading}
      />
      <div style={{ marginTop: '30px' }}>
        {' '}
        <h2>Pixel response:</h2>
        <div
          style={{
            background: '#3B4050',
            width: '100%',
            color: '#fff',
            fontSize: '12px',
            lineHeight: '2',
            minHeight: '200px',
            borderRadius: '10px'
          }}
        >
          {pixelStatus ? (
            <div
              style={{
                textAlign: 'left',
                wordBreak: 'break-all',
                padding: '20px'
              }}
            >
              {pixelStatus}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
