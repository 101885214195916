import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';
import { handleOrdering } from '../utils/functions';

class ExternalURLsServices {
  async getExternalURLsDataPaged({ currentPage, pageSize, cancelToken, searchValue, sorting }) {
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      search: searchValue,
      ordering: ordering && ordering.join(','),
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      only_non_company_traffic_back: true
    });
    return await callApi({ url: `/api/external_urls/${query}`, cancelToken });
  }

  async addExternalURL(data) {
    return await callApi({
      url: '/api/external_urls/',
      method: 'POST',
      data
    });
  }

  async editExternalURL(id, data) {
    return await callApi({
      url: `/api/external_urls/${id}/`,
      method: 'PUT',
      data
    });
  }

  async deleteExternalURL(id) {
    return await callApi({
      url: `/api/external_urls/${id}/`,
      method: 'DELETE'
    });
  }
}

export const externalURLsServices = new ExternalURLsServices();
