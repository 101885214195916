import React, { useEffect, useState } from 'react';

import { accountsService } from '../../../../services/accounts';
import { deviceTypesService } from '../../../../services/device-types';
import { listsService, ListTypes } from '../../../../services/lists';
import { SortDirection } from '../../../../services/statistic';
import { useDebounce, useFetch } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { Heading } from '../Heading';
import { AddOrEditDeviceTypeListModal } from './AddOrEditDeviceTypeListModal';
import { Table } from './Table';

// import styles from '../../Lists.module.css';

const listType = ListTypes.device_type_lists;

export const DeviceTypesTab = () => {
  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [allDeviceTypesLoading, setAllDeviceTypesLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };
    const getDeviceTypes = async () => {
      setAllDeviceTypesLoading(true);
      try {
        const deviceTypes = await deviceTypesService.getAll();
        setAllDeviceTypes(deviceTypes);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllDeviceTypesLoading(false);
      }
    };
    getAccounts();
    getDeviceTypes();
  }, []);

  const loadDeviceTypeLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      filters,
      sorting
    });

  const [
    {
      data: { results: deviceTypeLists, count: totalRows },
      isFetching
    },
    getDeviceTypeLists
  ] = useFetch(loadDeviceTypeLists);

  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getDeviceTypeLists();
  }, [debouncedSearchValue, currentPage, getDeviceTypeLists, filters, sorting]);

  const handleDelete = () => {
    if (deviceTypeLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getDeviceTypeLists();
  };

  const loader = { allAccounts: allAccountsLoading, allDeviceTypes: allDeviceTypesLoading };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="Device Types"
        allAccounts={allAccounts}
        areAccountsLoading={allAccountsLoading}
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        allDeviceTypes={allDeviceTypes}
        allAccounts={allAccounts}
        deviceTypeLists={deviceTypeLists}
        loading={loading}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loader={loader}
        sorting={sorting}
        onClone={getDeviceTypeLists}
        onEdit={getDeviceTypeLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />

      <AddOrEditDeviceTypeListModal
        visibleModal={visibleAddModal}
        allDeviceTypes={allDeviceTypes}
        allAccounts={allAccounts}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getDeviceTypeLists}
        loader={loader}
      />
    </div>
  );
};
