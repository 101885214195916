import React from 'react';

import { AddOrEditCountryListModal } from './AddOrEditCountryListModal';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { ListTypes, ListTypesPlural } from '../../../../services/lists';

export const ActionsTableCol = ({ allCountries, allAccounts, countryList, onClone, onEdit, onDelete, loader }) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.country_lists}
      contentForCopy={countryList[ListTypesPlural.country_lists]}
      allAccounts={allAccounts}
      list={countryList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => (
        <AddOrEditCountryListModal {...commonProps} allCountries={allCountries} countryList={countryList} loader={loader} />
      )}
    />
  );
};
