import React, { useState } from 'react';
import styles from './CustomPixelInput.module.css';
import { Switch, Select, InputNumber } from 'antd';
import { PerPixelModal } from './PerPixelModal';
import { S2S_TYPE } from '../utils/targeting';
import { TagListWithEditModal } from './TagListWithEditModal';

const { Option } = Select;

export const CustomPixelInput = ({ value, onChange }) => {
  const { pixels, s2s, s2s_type, is_s2s_advanced } = value;
  const [modalVisible, setModalVisible] = useState(false);
  const getPixelOptions = (pixels) => {
    // generates pixel option names
    return Object.entries(pixels).map(([key, value]) => ({
      label: `${key.substring(0, 2)}-${value}%`,
      value: key + value
    }));
  };

  return (
    <>
      <div className={styles.customPixelContainer}>
        <div className={styles.customPixelAdvanced}>
          <span className={styles.label}>Advanced</span>
          <Switch checked={is_s2s_advanced} onChange={(is_s2s_advanced) => onChange({ ...value, is_s2s_advanced })} />
        </div>
        {is_s2s_advanced ? (
          <div className={styles.s2sColWrapper}>
            <span className={styles.label} style={{ marginLeft: '20%' }}>
              S2S Configuration
            </span>
            <TagListWithEditModal
              tagLabels={getPixelOptions(pixels).map(({ label }) => label)}
              onModalOpen={() => setModalVisible(true)}
              style={{ width: '100%' }}
            />
          </div>
        ) : (
          <div className={styles.s2sColWrapper}>
            <span className={styles.label} style={{ marginLeft: '20%' }}>
              S2S Configuration
            </span>
            <div className={styles.s2sCol}>
              <div className={styles.s2sColType}>
                <Select value={s2s_type} onChange={(s2s_type) => onChange({ ...value, s2s_type, s2s: 0 })}>
                  <Option value={S2S_TYPE.Percent}>Percent</Option>
                  <Option value={S2S_TYPE.ThresholdCumulative}>Cumulative Threshold</Option>
                  <Option value={S2S_TYPE.ThresholdAbsolute}>Absolute Threshold</Option>
                </Select>
              </div>
              <div className={styles.s2sColValue}>
                <InputNumber
                  style={{ width: '100%' }}
                  value={s2s}
                  onChange={(s2s) => onChange({ ...value, s2s })}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}${s2s_type === S2S_TYPE.Percent ? '%' : ''}`}
                  step={s2s_type === S2S_TYPE.Percent ? 1 : 0.001}
                  parser={(value) => value.replace(s2s_type === S2S_TYPE.Percent ? '%' : '', '')}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {modalVisible && (
        <PerPixelModal
          visible={modalVisible}
          setVisible={setModalVisible}
          value={pixels}
          onChange={(pixels) => onChange({ ...value, pixels })}
        />
      )}
    </>
  );
};
