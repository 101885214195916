import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const GPSSStatusValue = {
  ToBeApplied: 'to_be_applied',
  Applying: 'applying',
  ToBeDeleted: 'to_be_deleted',
  Deleting: 'deleting',
  Completed: 'completed'
};

export const GPSSStatusTitle = {
  [GPSSStatusValue.ToBeApplied]: 'To be applied',
  [GPSSStatusValue.Applying]: 'Applying',
  [GPSSStatusValue.ToBeDeleted]: 'To be deleted',
  [GPSSStatusValue.Deleting]: 'Deleting',
  [GPSSStatusValue.Completed]: 'Completed'
};

class GPSSService {
  async getAll({ fields = 'id' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/gpss/${query}` });
  }

  async getPaged({ fields, currentPage, pageSize, filters, searchValue, sorting, cancelToken }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      fields,
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      product__in: filters.product && filters.product.join(','),
      country__in: filters.country && filters.country.join(','),
      search: searchValue,
      ordering: ordering && ordering.join(',')
    });

    return await callApi({
      url: `/api/gpss/${query}`,
      cancelToken
    });
  }

  async add(data) {
    return await callApi({ url: '/api/gpss/', method: 'POST', data });
  }

  async delete(id) {
    return await callApi({ url: `/api/gpss/${id}`, method: 'DELETE' });
  }

  async edit(data, id) {
    return await callApi({ url: `/api/gpss/${id}/`, method: 'PATCH', data });
  }

  async getById({ id, fields }) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/gpss/${id}${query}` });
  }
}

export const gpssService = new GPSSService();
