import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { Form, Input, Button, Popover, Checkbox, Row, Col } from 'antd';
import { Select } from '../../../../components/Select';
import { PlusOutlined } from '@ant-design/icons';

import { Spinner } from '../../../../components/Spinner';

import { getSelectOptionsWithIdValues } from '../../utils/options';

import { mediaCampaignsService } from '../../../../services/media-campaigns';

import { showApiErrors } from '../../../../utils/showApiErrors';

import styles from './Tabs.module.css';
import { openWarningNotification } from '../../../../utils/notifications';
import { s2sMacros } from '../../../../services/s2s';
import { generateMacrosContent } from '../../../../utils/functions';
import { Pricing } from './Pricing';

export const S2sAndPricing = ({
  form,
  initialData,
  pricingData,
  loadingTabsData,
  isCpmOrCpc,
  hasNoPrice,
  companyS2sUrl,
  onFormChange,
  isRevShare
}) => {
  const [currentUrlInput, setCurrentUrlInput] = useState();
  const [allS2sTypes, setAllS2sTypes] = useState([]);
  const [allS2sTypesLoading, setAllS2sTypesLoading] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(0);

  const [whereTheCursorWas, setWhereTheCursorWas] = useState({});
  const [disabledRadio, setDisabledRadio] = useState(false);

  const mount = useRef(true);

  const loading = loadingTabsData;

  const handleMacrosClick = (macroContent) => {
    const oldValue = form.getFieldsValue().s2sFormList[currentUrlInput]['s2sUrl'] || '';
    const cursorWasAt = whereTheCursorWas[currentUrlInput];
    if (cursorWasAt !== undefined) {
      setWhereTheCursorWas((prevState) => {
        let newState = { ...prevState };
        newState[currentUrlInput] += macroContent.length;
        return newState;
      });
    }
    let newValue =
      cursorWasAt !== undefined
        ? oldValue.substring(0, cursorWasAt) + macroContent + oldValue.substring(cursorWasAt, oldValue.length)
        : oldValue + macroContent;
    form.setFields([{ name: ['s2sFormList', currentUrlInput, 's2sUrl'], value: newValue }]);
  };

  const initialValues = useMemo(() => {
    if (!initialData || !initialData.length) {
      if (isRevShare) {
        return { s2sFormList: [{ id: undefined, isSpentPixel: true, s2sType: undefined, s2sUrl: '', priceList: null }] };
      }
      if (isCpmOrCpc) {
        return { pricingCpmCpc: { ...pricingData } };
      }
      return {};
    }
    setNumberOfItems(initialData.length);
    const s2sFormList = initialData.map((elem) => {
      const { id, is_spent_pixel, s2s_type, s2s_url, price_list } = elem;

      const formatedPriceList = price_list?.list_prices?.map((elem) => {
        // NOTE: we must transform here instead of Pricing component because form values in this component won't be updated until some row is changed in Pricing component. That way for rev share campaign if nothing is changed in pricing and we submit the form we would send to backend 0 (price field) instead of old percent (rev_share_percent field)
        const { id, country, price, rev_share_percent, os, browser, device_type } = elem;

        return {
          id,
          country,
          os,
          browser,
          price: isRevShare ? rev_share_percent : price,
          device_type
        };
      });

      return {
        id,
        isSpentPixel: is_spent_pixel,
        s2sType: s2s_type,
        s2sUrl: s2s_url,
        priceList: price_list ? { ...price_list, list_prices: formatedPriceList } : null
      };
    });

    return {
      s2sFormList,
      pricingCpmCpc: { ...pricingData }
    };
  }, [initialData, isRevShare, pricingData, isCpmOrCpc]);

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  useEffect(() => {
    setAllS2sTypesLoading(true);
    let didCancel = false;

    const fetchData = async () => {
      try {
        const s2sTypes = await mediaCampaignsService.getS2sTypes();

        if (!didCancel) {
          setAllS2sTypes(s2sTypes);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllS2sTypesLoading(false);
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
  }, []);

  const clearRadio = useCallback(() => {
    const { s2sFormList } = form.getFieldsValue();

    if (s2sFormList) {
      const filteredValues = s2sFormList.map((value) => {
        if (value) {
          return {
            ...value,
            isSpentPixel: false
          };
        }

        return value;
      });

      form.setFieldsValue({ s2sFormList: filteredValues });
    }
  }, [form]);

  useEffect(() => {
    if (isCpmOrCpc || (!isRevShare && hasNoPrice)) {
      setDisabledRadio(true);
      clearRadio();
    } else {
      setDisabledRadio(false);
    }
  }, [isCpmOrCpc, hasNoPrice, clearRadio, isRevShare, form]);

  useEffect(() => {
    if (mount.current) {
      mount.current = false;
      return;
    }

    if (companyS2sUrl) {
      const s2sFormList = (form.getFieldValue('s2sFormList') || []).map((el) => ({ ...el, s2sUrl: companyS2sUrl }));
      form.setFieldsValue({
        s2sFormList: s2sFormList
      });

      openWarningNotification({ message: "Check S2s tab, it's changed!" });
    } else {
      form.setFieldsValue({ s2sFormList: undefined });
      openWarningNotification({ message: 'S2s tab is emptied!' });
    }
  }, [form, companyS2sUrl]);

  const onRemove = (remove, name) => {
    setWhereTheCursorWas((prevState) => {
      let newState = { ...prevState };
      delete newState[name];
      return newState;
    });
    setNumberOfItems((i) => i - 1);
    const hadSpentPixel = form.getFieldValue(['s2sFormList', name].isSpentPixel);
    remove(name);
    if (hadSpentPixel) {
      if (isRevShare) {
        const formList = [...form.getFieldValue('s2sFormList')];
        formList[0] = { ...formList[0], isSpentPixel: true };
        form.setFieldsValue({
          s2sFormList: formList
        });
      }
    }
  };

  const displayRemove = useMemo(() => {
    return !isRevShare || (isRevShare && numberOfItems > 1);
  }, [numberOfItems, isRevShare]);

  return (
    <Form form={form} name="s2sForm" initialValues={initialValues} onValuesChange={() => onFormChange(true)}>
      {loading ? (
        <Spinner />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h2>S2s</h2>
            <Form.List name="s2sFormList" shouldUpdate>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <div className={styles.s2sHeading}>
                      <div></div>
                      <div>S2S Type</div>
                      <div>S2S URL</div>
                      <div></div>
                    </div>
                    {fields.map((field, index) => (
                      <Row style={{ width: '100%' }} gutter={[16, 16]} key={index}>
                        <Col span={24}>
                          <div key={field.key} className={styles.s2sDynamicFieldsWrapper}>
                            <div style={{ display: 'none' }}>
                              <Form.Item {...field} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']}>
                                <Input />
                              </Form.Item>
                            </div>
                            <div className={styles.s2sRadio}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'isSpentPixel']}
                                fieldKey={[field.fieldKey, 'isSpentPixel']}
                                valuePropName="checked"
                              >
                                <Checkbox name={field.key} disabled={disabledRadio} />
                              </Form.Item>
                            </div>
                            <div className={styles.s2sDynamicField}>
                              <Form.Item
                                {...field}
                                name={[field.name, 's2sType']}
                                fieldKey={[field.fieldKey, 's2sType']}
                                rules={[{ required: true, message: 'Please select a type!' }]}
                              >
                                <Select
                                  placeholder="Select type"
                                  optionFilterProp="data-searchvalue"
                                  allowClear
                                  showSearch
                                  loading={allS2sTypesLoading}
                                >
                                  {getSelectOptionsWithIdValues(allS2sTypes)}
                                </Select>
                              </Form.Item>
                            </div>

                            <div className={styles.s2sDynamicField}>
                              <Form.Item
                                {...field}
                                name={[field.name, 's2sUrl']}
                                fieldKey={[field.fieldKey, 's2sUrl']}
                                onBlur={(e) => {
                                  setWhereTheCursorWas((prevState) => {
                                    let newState = { ...prevState };
                                    newState[field.name] = e.target.selectionStart;
                                    return newState;
                                  });
                                }}
                              >
                                <Input
                                  placeholder="Enter URL"
                                  type="url"
                                  addonAfter={
                                    <Popover
                                      title="System Macros"
                                      trigger="click"
                                      content={generateMacrosContent(s2sMacros, handleMacrosClick)}
                                    >
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setCurrentUrlInput(field.name);
                                        }}
                                      >
                                        Macros
                                      </span>
                                    </Popover>
                                  }
                                />
                              </Form.Item>
                            </div>

                            {displayRemove ? (
                              <div className={styles.s2sRemoveDynamicFields}>
                                <img
                                  src="/images/delete_icon.png"
                                  alt="Delete"
                                  width="21px"
                                  onClick={() => {
                                    onRemove(remove, field.name);
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col span={24}>
                          {!isCpmOrCpc && (
                            <div>
                              <Form.Item name={[field.name, 'priceList']} fieldKey={[field.fieldKey, 'priceList']}>
                                {form.getFieldValue(['s2sFormList', field.name, 'isSpentPixel']) && (
                                  <Pricing loadingTabsData={loadingTabsData} noPricesDisabled={true} isRevShare={isRevShare} />
                                )}
                              </Form.Item>
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                    <div className={`${styles.s2sAddDynamicFields} ${styles.formRemoveBottomMargin}`} style={{ width: '100%' }}>
                      <Form.Item>
                        <Button
                          ghost
                          type="primary"
                          onClick={() => {
                            add({ s2sUrl: '', isSpentPixel: false });
                            setNumberOfItems((i) => i + 1);
                          }}
                          block
                        >
                          <PlusOutlined /> Add new s2s
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                );
              }}
            </Form.List>
          </Col>
          {isCpmOrCpc && (
            <Col span={24}>
              <h2>Pricing</h2>
              <Form.Item name="pricingCpmCpc">
                <Pricing loadingTabsData={loadingTabsData} />
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
    </Form>
  );
};
