import React from 'react';
import { Popover, Button, Checkbox, Row, Col, Collapse } from 'antd';
import { useState } from 'react';
import { groupBy } from 'lodash';

const { Panel } = Collapse;

export const renderRows = ({ columnsPerRow = 3, columnsData, checkedColumns, onChange }) => {
  let rows = [];
  for (let i = 0; i < columnsData.length; i += columnsPerRow) {
    const rowCols = [];
    for (let j = i; j < i + columnsPerRow; j++) {
      const column = columnsData[j];
      if (column) {
        const col = (
          <Col span={8} key={column.value}>
            <Checkbox
              value={column.value}
              checked={!!checkedColumns[column.value]}
              onChange={({ target }) => onChange({ ...checkedColumns, [target.value]: target.checked })}
            >
              {column.text}
            </Checkbox>
          </Col>
        );
        rowCols.push(col);
      }
    }
    const row = (
      <Row style={{ marginBottom: '10px' }} key={i}>
        {rowCols}
      </Row>
    );
    rows.push(row);
  }
  return rows;
};

export const renderCollapseRows = ({
  columnsPerRow,
  panelHeaderTitles,
  defaultActivePanelKeys = [],
  allColumns,
  checkedColumns,
  onChange
}) => {
  const groupedColumns = groupBy(allColumns, 'group');
  const panels = Object.values(panelHeaderTitles).map((title) => (
    <Panel header={title} key={title}>
      {renderRows({ columnsPerRow, columnsData: groupedColumns[title] || [], checkedColumns, onChange })}
    </Panel>
  ));

  return <Collapse defaultActiveKey={defaultActivePanelKeys}>{panels}</Collapse>;
};

export const ColumnsGroup = ({
  wrapperHeight = '100%',
  popoverPlacement = 'bottomRight',
  showUpdateButton,
  renderColumnRows,
  onUpdate
}) => {
  const renderColumns = () => {
    return (
      <div
        style={{
          width: '800px',
          height: wrapperHeight,
          overflowY: 'auto'
        }}
      >
        {renderColumnRows()}
        <br />
        {!showUpdateButton ? null : (
          <Row style={{ position: 'sticky', right: 0, bottom: 0, justifyContent: 'flex-end', backgroundColor: '#fff' }}>
            <Button
              onClick={() => {
                setColumnsVisibility(false);
                onUpdate();
              }}
              type="primary"
            >
              Update
            </Button>
          </Row>
        )}
      </div>
    );
  };

  const [columnsVisibility, setColumnsVisibility] = useState(false);

  return (
    <Popover
      placement={popoverPlacement}
      trigger="click"
      visible={columnsVisibility}
      onVisibleChange={() => setColumnsVisibility((prevState) => !prevState)}
      content={renderColumns()}
    >
      <Button type="primary" ghost style={{ width: '100%' }}>
        Columns
      </Button>
    </Popover>
  );
};
