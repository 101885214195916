import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Select } from '../../../../components/Select';
import { getSelectOptionsWithIdValues } from '../../utils/options';

import styles from './Vertical.module.css';

export const SearchAndFilter = ({
  searchValue,
  companies,
  identities,
  companiesLoading,
  identitiesLoading,
  filters,
  onSearch,
  onFilter
}) => {
  return (
    <div className={styles.searchAndFilter}>
      <div className={styles.searchInput}>
        <Input placeholder="Search" value={searchValue} allowClear suffix={<SearchOutlined />} onChange={(e) => onSearch(e.target.value)} />
      </div>

      <div className={styles.filterSelect}>
        <Select
          allowClear={true}
          placeholder="Select company"
          value={filters?.company}
          showSearch
          onChange={(value) => onFilter((oldFilters) => ({ ...oldFilters, company: value }))}
          optionFilterProp="data-searchvalue"
          loading={companiesLoading}
        >
          {getSelectOptionsWithIdValues(companies)}
        </Select>
      </div>

      <div className={styles.filterSelect}>
        <Select
          allowClear={true}
          placeholder="Select identity"
          value={filters?.identity}
          showSearch
          onChange={(value) => onFilter((oldFilters) => ({ ...oldFilters, identity: value }))}
          optionFilterProp="data-searchvalue"
          loading={identitiesLoading}
        >
          {getSelectOptionsWithIdValues(identities)}
        </Select>
      </div>
    </div>
  );
};
