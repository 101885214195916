import { callApi } from './api';
import { getQueryString } from '../utils/query';

class FraudReportsServices {
  async getCSV(queryParams) {
    const { dateRange, trafficCompany, campaigns, campaignColumns, additionalColumns, sources } = queryParams;

    const query = getQueryString({
      date_from: dateRange[0].format('YYYY-MM-DD'),
      date_to: dateRange[1].format('YYYY-MM-DD'),
      adv_company_id: trafficCompany,
      campaign_ids: campaigns && campaigns.join(','),
      campaign_columns: campaignColumns && campaignColumns.join(','),
      additional_columns: additionalColumns && additionalColumns.join(','),
      sources: sources && sources.join(',')
    });
    return await callApi({ url: `/api/channels/fraud_report/${query}` });
  }
}

export const fraudReportsServices = new FraudReportsServices();
