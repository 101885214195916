import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin, Table, Tree } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { urgentPanelService } from '../../../services/urgentPanel';
import { openWarningNotification } from '../../../utils/notifications';
import { DropBox } from './DropBox';
import { SideBarItem } from './SideBarItem';

const BACKEND_FIELD_FOR_EDITABLE_ROW = '__editable_row__';
const CUSTOM_FIELDS = ['__expandable__', '__index__', '__key__', '__unique_key__', '__editable_row__'];
const LIMIT = 100;

const createColumns = (el, fieldsDataWithFullNameAsKey) => {
  let title = '';
  if (fieldsDataWithFullNameAsKey[el]) {
    title = fieldsDataWithFullNameAsKey[el].frontend_name;
  } else {
    const key = el.split('__');
    key.pop();
    const keyName = key.join('__');
    const length = el.length;
    const field = fieldsDataWithFullNameAsKey[keyName];

    if (field) {
      if (field.fields) {
        const label = field.fields.label;

        if (el.substring(length - 2, length) === field.fields.value) {
          title = `${field.frontend_name} Id`;
        } else {
          title = `${field.frontend_name} ${label.charAt(0).toUpperCase() + label.slice(1)}`;
        }
      } else {
        title = fieldsDataWithFullNameAsKey[keyName].frontend_name;
      }
    } else {
      title = 'Id';
    }
  }
  return { title, dataIndex: el, key: el };
};

const subFormatDataAndAddNewKeys = ({ parents, uniqueKeysParents, keyOfParents, element, parentName, index, curr }) => {
  const { [BACKEND_FIELD_FOR_EDITABLE_ROW]: editableRow, ...omitElement } = element; // BACKEND_FIELD_FOR_EDITABLE_ROW is field on backend which define if it is editable
  Object.entries(omitElement).forEach(([key, value]) => {
    let fullUniqueKeys = [...uniqueKeysParents, `${key}__${index}`];
    let fullUniqueKeysJoined = fullUniqueKeys.join('__');
    let fullName = [...parents, key];
    let fullNameJoined = fullName.join('__');
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      subFormatDataAndAddNewKeys({
        parents: fullName,
        uniqueKeysParents: fullUniqueKeys,
        element: value,
        parentName: fullNameJoined,
        keyOfParents: [...keyOfParents, key, index],
        index,
        curr
      });
    } else if (Array.isArray(value)) {
      curr[fullNameJoined] = formatDataAndAddNewKeys({
        parents: fullName,
        uniqueKeysParents: fullUniqueKeys,
        arr: value,
        parentName: fullNameJoined,
        keyOfParents: [...keyOfParents, key, index]
      });
      const tempKeyOfParents = [...keyOfParents];
      if (tempKeyOfParents.length > 2) {
        tempKeyOfParents.pop(); //Remove index as we dont need it
      }
      curr.__expandable__ = true;
      curr.__index__ = index;
      curr.__key__ = tempKeyOfParents.join('__');
      curr.__unique_key__ = fullUniqueKeysJoined;
      curr.__editable_row__ = editableRow;
    } else {
      const tempKeyOfParents = [...keyOfParents];
      if (tempKeyOfParents.length > 2) {
        tempKeyOfParents.pop(); //Remove index as we dont need it
      }
      let parsedValue = value;
      if (typeof parsedValue === 'boolean') {
        parsedValue = value.toString();
      }
      curr[fullNameJoined] = parsedValue;
      curr.__index__ = index;
      curr.__key__ = tempKeyOfParents.join('__');
      curr.__unique_key__ = fullUniqueKeysJoined;
      curr.__editable_row__ = editableRow;
    }
  });
  return curr;
};

const formatDataAndAddNewKeys = ({ parents, uniqueKeysParents, keyOfParents, arr, parentName }) => {
  const newArr = [];
  arr.forEach((element, index) => {
    newArr.push(subFormatDataAndAddNewKeys({ parents, uniqueKeysParents, keyOfParents, element, parentName, index, curr: {} }));
  });
  return newArr;
};

const onExpandTable = ({ expand, record, setter, tableKey }) => {
  if (expand) {
    setter((prev) => ({ ...prev, [tableKey]: [...(prev[tableKey] ?? []), record.__unique_key__] }));
  } else {
    setter((prev) => ({
      ...prev,
      [tableKey]: prev[tableKey].filter((el) => el !== record.__unique_key__)
    }));
  }
};

const onSelectAllTable = ({ selected, tableKey, selectedRows, setter }) => {
  if (!selected) {
    setter((prevState) => {
      return {
        ...prevState,
        [tableKey]: []
      };
    });
  } else {
    if (selectedRows.length > 0) {
      setter((prev) => ({ ...prev, [tableKey]: selectedRows.map((el) => el.__unique_key__) }));
    }
  }
};

const onSelectOneRowTable = ({ selected, tableKey, selectedRows, record, setter }) => {
  if (!selected) {
    setter((prevState) => ({
      ...prevState,
      [tableKey]: prevState[tableKey].filter((el) => el !== record.__unique_key__)
    }));
  } else {
    if (selectedRows.length > 0) {
      setter((prev) => ({ ...prev, [tableKey]: [...(prev[tableKey] ?? []), record.__unique_key__] }));
    }
  }
};
const createEmptyTable = () => <Table />;
const createTable = ({
  columns,
  dataSource,
  subTables,
  expandedRowKeysArr,
  expandedRowKeysSetter,
  tableKey,
  selectedRowKeysArr,
  selectRowKeysSetter
}) => {
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={[...dataSource]}
      key={(record) => record.__unique_key__}
      rowKey={(record) => record.__unique_key__}
      expandable={{
        expandedRowRender: (record, index) => <div key={record.__unique_key__}>{subTables && subTables[index]}</div>,
        rowExpandable: (record) => record.__expandable__,
        expandedRowKeys: expandedRowKeysArr ?? [],
        onExpand: (expand, record) => onExpandTable({ expand, record, setter: expandedRowKeysSetter, tableKey })
      }}
      rowSelection={{
        selectedRowKeys: selectedRowKeysArr ?? [],
        onSelectAll: (selected, selectedRows, changeRow) =>
          onSelectAllTable({ selected, selectedRows, tableKey, setter: selectRowKeysSetter }),
        onSelect: (record, selected, selectedRows, nativeEvent) =>
          onSelectOneRowTable({ record, selected, selectedRows, tableKey, setter: selectRowKeysSetter }),
        getCheckboxProps: (record) => {
          return {
            disabled: !record.__editable_row__
          };
        }
      }}
    />
  );
};

export const UrgentPanelList = ({
  onEdit,
  fieldsData,
  loading,
  onLoadingCampaigns,
  onFinishedLoadingCampaigns,
  fieldsForEdit,
  boxes,
  onFilterValueChange,
  onRemoveItem,
  onDropItem,
  filters
}) => {
  const [mediaCampaigns, setMediaCampaigns] = useState([]);
  // Currently selected rows, key of object is unique_key and value is (unique_key + __ + index)
  const [selectedRowKeys, setSelectedRowKeys] = useState({});
  // Currently expanded rows, key of object is unique_key and value is (unique_key + __ + index)
  const [expandedRowKeys, setExpandedRowKeys] = useState({});
  // All row keys
  const [allRowKeys, setAllRowKeys] = useState({});
  // All row keys with values
  const [allRowKeysAndValues, setAllRowKeysAndValues] = useState({});
  const [mediaCampaignsSize, setMediaCampaignsSize] = useState(0);

  const showedAllResults = useMemo(() => mediaCampaigns.length === mediaCampaignsSize, [mediaCampaigns, mediaCampaignsSize]);

  const selectedAll = useMemo(
    () => Object.values(selectedRowKeys).reduce((a, b) => a + b.length, 0) === Object.values(allRowKeys).reduce((a, b) => a + b.length, 0),
    [allRowKeys, selectedRowKeys]
  );
  const expandedAll = useMemo(
    () => Object.values(expandedRowKeys).reduce((a, b) => a + b.length, 0) === Object.values(allRowKeys).reduce((a, b) => a + b.length, 0),
    [allRowKeys, expandedRowKeys]
  );
  const [table, setTable] = useState();

  // Converting tree to object data with path sa key
  const fieldsToObject = useCallback((current, name, object) => {
    if (current.children) {
      current.children.forEach((el) => {
        let newName = el.backend_name;
        if (name !== '') {
          newName = name + '__' + el.backend_name;
        }
        fieldsToObject(el, newName, object);
      });
    }
    object[name] = current;
    return { ...object };
  }, []);

  // Generating Tree for current Node (recursive)
  const generateTreeForField = (current, backendName, frontendName) => {
    const children = [];
    if (current.children) {
      current.children.forEach((el) => {
        let newNameBackend = [el.backend_name];
        let newNameFrontend = [el.frontend_name];
        if (backendName !== '') {
          newNameBackend = backendName.concat(el.backend_name);
          newNameFrontend = frontendName.concat(el.frontend_name);
        }
        children.push(generateTreeForField(el, newNameBackend, newNameFrontend));
        return (
          <Tree.TreeNode
            value={newNameBackend}
            title={<SideBarItem title={current.frontend_name} fullName={newNameFrontend} value={newNameBackend.join('__')} {...current} />}
            key={newNameBackend.join('__')}
            isLeaf={true}
            selectable={true}
          />
        );
      });
    }
    if (children.length > 0) {
      return (
        <Tree.TreeNode
          value={backendName.join('__')}
          fullName={frontendName}
          title={current.frontend_name}
          key={backendName.join('__')}
          selectable={false}
          children={children}
        />
      );
    } else {
      return (
        <Tree.TreeNode
          value={backendName.join('__')}
          title={<SideBarItem title={current.frontend_name} fullName={frontendName} value={backendName.join('__')} {...current} />}
          key={backendName.join('__')}
          selectable={true}
        />
      );
    }
  };

  // Generating array of objects using fieldToObject
  const fieldsDataWithFullNameAsKey = useMemo(
    () => fieldsData.map((el) => fieldsToObject(el, el.backend_name, {})).reduce((r, c) => Object.assign(r, c), {}),
    [fieldsData, fieldsToObject]
  );

  const getMediaCampaigns = async (fields, filters, limit) => {
    try {
      onLoadingCampaigns();
      const data = await urgentPanelService.getAll({ fields, filters, limit });
      setMediaCampaigns(data.data);
      setMediaCampaignsSize(data.size);
    } finally {
      onFinishedLoadingCampaigns();
    }
  };

  const handleSearch = (limit) => {
    setSelectedRowKeys({});
    setExpandedRowKeys({});
    const fields = boxes.searchBox.items.map((el) => el.value);
    const filters = boxes.filterBox.items
      .filter((el) => el.value && (el.suffix === '' || el.suffix))
      .map((el) => {
        //  Because some values are array type we must join them for GET request
        return `${el.value + el.suffix}=${Array.isArray(el.filterValue) ? el.filterValue.join(',') : el.filterValue}`;
      });
    if (fields.length > 0) {
      getMediaCampaigns(fields, filters, limit);
    } else {
      openWarningNotification({ message: 'Please select search fields.' });
    }
  };
  const handleEdit = () => {
    const obj = {};
    let selectedObjects = [];
    // Creating selectedObjects as array then flat it, merged (based on keys) allRowKeysAndValues and selectedRowKeys, as allRowKeysAndValues has values and selectedRowKeys only keys
    Object.entries(selectedRowKeys).forEach(([selectedKey, selectedKeysWithIndex]) => {
      const current = allRowKeysAndValues[selectedKey];
      selectedObjects.push(selectedKeysWithIndex.map((el) => current[el]));
    });
    selectedObjects = selectedObjects.flat(1);
    fieldsForEdit.forEach((item) => {
      // Has ID
      let keysSubstring = '';
      if (item.fields) {
        keysSubstring = item.value.split('__').slice(0, -2).join('__');
      } else {
        keysSubstring = item.value.split('__').slice(0, -1).join('__');
      }

      let key = '';
      let name = '';
      if (!keysSubstring) {
        key = item.backend_name;
        name = 'root';
      } else {
        key = keysSubstring;
        name = keysSubstring;
      }
      let currObjects = [];
      if (name === 'root' && !item.fields) {
        currObjects = selectedObjects.filter((el) => el.hasOwnProperty('id'));
      } else {
        currObjects = selectedObjects.filter((el) => el.hasOwnProperty(key + '__id'));
      }
      currObjects.forEach((el) => {
        const id = name === 'root' ? el['id'] : el[key + '__id'];
        if (obj[name] && obj[name]['ids']) {
          obj[name]['ids'] = Array.from(new Set([...obj[name]['ids'], id]));
        } else {
          obj[name] = { ...obj[name], ids: [id] };
        }
        if (obj[name] && obj[name]['fields']) {
          const itemAlreadyExists = obj[name].fields.find((el) => el.value === item.value);
          if (!itemAlreadyExists) {
            obj[name]['fields'].push(item);
          }
        } else {
          obj[name] = { ...obj[name], fields: [item] };
        }
      });
    });
    if (Object.keys(obj).length > 0) {
      onEdit(obj);
    } else {
      openWarningNotification({ message: 'There are no fields to edit.' });
    }
  };

  const tableCreator = useCallback(
    ({ arr, used, rowKeys, rowKeysAndValues }) => {
      const columns = [];
      const tables = {};
      if (!arr.length) {
        return { table: createEmptyTable(), rowKeys: {}, rowKeysAndValues: {} };
      }

      const key = arr[0].__key__; // All elements have same key
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        rowKeys[key] = [...(rowKeys[key] || []), element.__unique_key__];
        rowKeysAndValues[key] = { ...rowKeysAndValues[key], ...{ [element.__unique_key__]: element } };
        Object.entries(element).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            var {
              table,
              rowKeys: rowKeysFromLowerLvl,
              rowKeysAndValues: rowKeysAndValuesFromLowerLvl
            } = tableCreator({
              arr: value,
              used: [...CUSTOM_FIELDS],
              rowKeys,
              rowKeysAndValues
            });
            // Replace in current
            rowKeys[value[0].__key__] = rowKeysFromLowerLvl[value[0].__key__];
            rowKeysAndValues[value[0].__key__] = rowKeysAndValuesFromLowerLvl[value[0].__key__];

            if (!tables[i]) {
              tables[i] = [];
            }
            tables[i] = [...tables[i], table];
          } else {
            if (!used.includes(key)) {
              columns.push(createColumns(key, fieldsDataWithFullNameAsKey));
              used.push(key);
            }
          }
        });
      }
      return {
        table: createTable({
          columns,
          dataSource: arr,
          subTables: tables,
          expandedRowKeysArr: expandedRowKeys[key],
          expandedRowKeysSetter: setExpandedRowKeys,
          tableKey: key,
          selectedRowKeysArr: selectedRowKeys[key],
          selectRowKeysSetter: setSelectedRowKeys
        }),
        rowKeysAndValues: { ...rowKeysAndValues },
        rowKeys: { ...rowKeys }
      };
    },
    [fieldsDataWithFullNameAsKey, expandedRowKeys, selectedRowKeys]
  );

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelectedRowKeys({});
    } else {
      setSelectedRowKeys(allRowKeys);
    }
  };

  const handleExpandAll = () => {
    if (expandedAll) {
      setExpandedRowKeys({});
    } else {
      setExpandedRowKeys(allRowKeys);
    }
  };

  const displayTable = mediaCampaigns.length > 0;

  useEffect(() => {
    if (mediaCampaigns.length > 0) {
      const parsedData = formatDataAndAddNewKeys({
        parents: [],
        uniqueKeysParents: [],
        keyOfParents: [],
        arr: mediaCampaigns,
        parentName: ''
      });
      if (parsedData.length > 0) {
        const { table, rowKeys, rowKeysAndValues } = tableCreator({
          arr: parsedData,
          used: [...CUSTOM_FIELDS],
          rowKeys: {},
          rowKeysAndValues: {}
        });
        setAllRowKeys(rowKeys);
        setAllRowKeysAndValues(rowKeysAndValues);
        setTable(table);
      }
    }
  }, [mediaCampaigns, tableCreator]);

  return (
    <Spin spinning={loading}>
      <Row gutter={24}>
        <Col span={5}>
          <Tree showLine={true} switcherIcon={<DownOutlined />}>
            {fieldsData.map((el) => generateTreeForField(el, [el.backend_name], [el.frontend_name]))}
          </Tree>
        </Col>
        <Col span={19}>
          {Object.entries(boxes).map(([key, value]) => {
            const { accepts, items, name } = value;
            return (
              <div key={key}>
                <Row style={{ width: '100%' }}>
                  <Col style={{ width: '100%' }}>
                    <DropBox
                      accept={accepts}
                      items={items}
                      onDrop={(item) => {
                        onDropItem(key, item);
                      }}
                      boxKey={key}
                      onRemoveItem={onRemoveItem}
                      name={name}
                      onFilterValueChange={onFilterValueChange}
                      filterValues={boxes.filterBox.items}
                      filters={filters}
                    />
                  </Col>
                </Row>
                <br />
              </div>
            );
          })}
        </Col>
      </Row>
      <br />
      <Row gutter={24} justify="space-between">
        <Col span={8}>
          <Button type="primary" onClick={() => handleSearch(LIMIT)}>
            Search
          </Button>
        </Col>
        {displayTable && (
          <Col span={8}>
            <Button type="primary" onClick={handleEdit} disabled={!showedAllResults}>
              Edit
            </Button>
          </Col>
        )}
      </Row>
      {displayTable ? (
        <>
          <br />
          <Row>
            <Col span={8}>
              <Button type="ghost" onClick={handleExpandAll}>
                {expandedAll ? 'Unexpand all' : 'Expand all'}
              </Button>
            </Col>
            <Col span={8}>
              <Button type="ghost" onClick={handleSelectAll}>
                {selectedAll ? 'Deselect all' : 'Select all'}
              </Button>
            </Col>
          </Row>
          <br />
          <p>**Some rows may not be possible to edit as they are part of global list</p>
          {table}
          <Divider>
            Total number of results: <b>{mediaCampaignsSize}</b>
          </Divider>
          {mediaCampaignsSize > LIMIT ? (
            <Button type="ghost" onClick={() => handleSearch(undefined)} disabled={showedAllResults}>
              Show all
            </Button>
          ) : null}
        </>
      ) : null}
    </Spin>
  );
};
