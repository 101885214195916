import { Upload, Button, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';
import { groupCountriesWithSamePrices } from '../../../utils/products';
import { productsService } from '../../../services/products';
import { showApiErrors } from '../../../utils/showApiErrors';

export const ImportPrices = ({ onPricesImported }) => {
  const [fileList, setFileList] = useState([]);

  const handleUpload = async (e) => {
    if (fileList.length > 0) {
      e.persist();
      const formData = new FormData();
      formData.append('file', fileList[0], fileList[0].name);

      try {
        const { data, message } = await productsService.importPrices(formData);

        if (!!data) {
          const { formatedPriceGroupsData } = groupCountriesWithSamePrices(data);
          onPricesImported(formatedPriceGroupsData);

          openSuccessNotification({ message: 'Price list successfully imported!' });
        } else {
          openErrorNotification({ message: message || 'Could not import a price list!' });
        }
      } catch (err) {
        showApiErrors(err);
      } finally {
        e.target.value = null;
        setFileList([]);
      }
    }
  };

  const props = {
    multiple: false,
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };

  return (
    <>
      <Col>
        <Upload {...props}>
          <Button type="dashed" style={{ width: 150 }}>
            <UploadOutlined /> Import data
          </Button>
        </Upload>
      </Col>
      <Col>
        <Button type="primary" onClick={handleUpload} disabled={fileList.length === 0} style={{ width: 118 }}>
          Start Upload
        </Button>
      </Col>
    </>
  );
};
