import React from 'react';

import { AddOrEditOSListModal } from './AddOrEditOSListModal';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { ListTypes, ListTypesPlural } from '../../../../services/lists';

export const ActionsTableCol = ({ allOSs, allAccounts, osList, onClone, onEdit, onDelete, loader }) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.os_lists}
      contentForCopy={osList[ListTypesPlural.os_lists]}
      allAccounts={allAccounts}
      list={osList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => <AddOrEditOSListModal {...commonProps} allOSs={allOSs} osList={osList} loader={loader} />}
    />
  );
};
