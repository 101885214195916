import React from 'react';
import dayjs from 'dayjs';

import styles from '../Lists.module.css';

const setCreator = (creator) => {
  const name = `${creator?.first_name} ${creator?.last_name}`;
  return name || 'Unknown creator';
};

export const ListNameTableCol = ({ listName, creator, dateCreated }) => {
  return (
    <div>
      <div>{listName}</div>

      <div className={styles.creatorAndDateCreated}>
        Created by {setCreator(creator)} on {dayjs(dateCreated).format('DD.MM.YYYY')}
      </div>
    </div>
  );
};
