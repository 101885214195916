import { Button, Col, Form, Input, Modal, Row, DatePicker, InputNumber } from 'antd';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { confirmClose } from '../../../utils/confirmClose';
import { useBeforeUnload } from 'react-use';
import { gpssService } from '../../../services/gpss';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';

import {
  getBrowsers,
  getDeviceTypeList,
  getDeviceTypeLists,
  getLandingPages,
  getOSs,
  getProducts,
  useCustomQuery
} from '../../../utils/hooks/queries';
import moment from 'moment';
import { withRefetching } from '../../../components/SelectWithSideLoader';
import { getSelectOptionsWithTooltip, getSelectOptionsWithExtendedTooltip } from '../../lists/utils/options';
import { generateCountriesOptions, generateSelectOptionsWithIdValues } from '../../../utils/options';
import { Select } from '../../../components/Select';
import { LpPreview, PreviewLpSelect } from '../../../components/PreviewLpSelect';
import { landingPagesService } from '../../../services/landingPages';
import { companiesService } from '../../../services/companies';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import styles from './AddOrEditRule.module.css';

const SelectWithRefetching = withRefetching(Select);

export const AddOrEditRule = ({
  modalVisible,
  rule,
  countries,
  countriesLoading,
  countriesRefetching,
  onClose,
  isClone,
  onAdd,
  onClone,
  onEdit
}) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  const utcOffset = -new Date().getTimezoneOffset() / 60;
  const [date, setDate] = useState({
    dateRange: {
      from: moment()?.utcOffset(utcOffset, true),
      to: moment()?.utcOffset(utcOffset, true)
    }
  });

  const action = !isClone && rule ? 'Edit' : isClone && rule ? 'Clone' : 'Add';

  const initialValues = useMemo(() => {
    if (!rule) {
      return { s2s_percent: 100 };
    }

    return {
      ...rule,
      dateRange: [moment(rule.start_date).utc().utcOffset(utcOffset, true), moment(rule.end_date).utc().utcOffset(utcOffset, true)]
    };
  }, [rule, utcOffset]);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onClose) : onClose();
  };

  const resetFields = useCallback(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form]);

  useBeforeUnload(wasFormChanged);

  useEffect(() => {
    resetFields();
  }, [resetFields, modalVisible]);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    (async () => {
      try {
        setCompaniesLoading(true);
        const companies = await companiesService.getAll({ fields: 'id,name' });
        setAllCompanies(companies);
      } catch (err) {
        showApiErrors(err);
      } finally {
        setCompaniesLoading(false);
      }
    })();
  }, []);

  const {
    isFetching: allBrowsersRefetching,
    isLoading: allBrowsersLoading,

    data: allBrowsers
  } = useCustomQuery(getBrowsers);
  const filteredBrowsers = useMemo(() => {
    const indexOfAny = allBrowsers.findIndex(({ id }) => id === 1);
    const allBrowsersCopy = [...allBrowsers];
    allBrowsersCopy.splice(indexOfAny, 1);
    return allBrowsersCopy;
  }, [allBrowsers]);

  const {
    isFetching: allOSRefetching,
    isLoading: allOSLoading,

    data: allOS
  } = useCustomQuery(getOSs);
  const filteredOSs = useMemo(() => {
    const indexOfAny = allOS.findIndex(({ id }) => id === 1);
    const allOSCopy = [...allOS];
    allOSCopy.splice(indexOfAny, 1);
    return allOSCopy;
  }, [allOS]);

  const {
    isLoading: allDeviceTypeListsLoading,
    isFetching: allDeviceTypeListsRefetching,
    data: allDeviceTypeLists
  } = useCustomQuery(getDeviceTypeLists, { fields: 'id' });

  const { isLoading: allProductsLoading, isFetching: allProductsRefetching, data: allProducts } = useCustomQuery(getProducts);

  const { isLoading: allLandingPagesLoading, data: allLandingPages } = useCustomQuery(getLandingPages, {
    fields: 'id,title,date_edited,products'
  });

  const handleSubmit = async (values) => {
    const { country, os, browser, product, lp, dateRange } = values;

    const nowUTC = moment().utc().utcOffset(utcOffset, true);
    const endDateUTC = dateRange[1].utc(true);
    if (nowUTC > endDateUTC) {
      openErrorNotification({ message: 'End date is in past. Select future date & time for end date, please.' });
      return;
    }

    const updatedValues = {
      ...values,
      country: country ?? null,
      os: os ?? null,
      browser: browser ?? null,
      product: product ?? null,
      lp: lp ?? null,
      start_date: dateRange[0].utc(true),
      end_date: endDateUTC
    };

    try {
      setSubmitting(true);
      if (rule?.id && !isClone) {
        await gpssService.edit(updatedValues, rule.id);
        openSuccessNotification({ message: 'Successfully edited gpss rule!' });
        onEdit();
      } else {
        await gpssService.add(updatedValues);
        openSuccessNotification({ message: `Successfully ${isClone ? 'cloned' : 'added'}  gpss rule!` });
        isClone ? onClone() : onAdd();
      }
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={`${action} GPSS Rule`}
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          {action}
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12} className={styles.companiesCol}>
              <Form.Item name="companies" label="Companies" valuePropName="selectValue">
                <VirtualizedSelect
                  options={companiesService.generateOptions(allCompanies)}
                  placeholder={'Select companies'}
                  loading={companiesLoading}
                  selectResetInput={true}
                  multi={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={16}>
              <Form.Item
                name={['dateRange']}
                label="Date & time (UTC-0)"
                rules={[
                  { required: true, message: 'Please pick date & time range' },
                  {
                    validator: async (_, value) => {
                      if (value?.[0]?.isSame(value?.[1], 'second')) {
                        throw new Error('Can not select same start and end date!');
                      }
                    }
                  }
                ]}
              >
                <RangePicker
                  showTime
                  placeholder={['Start date & time', 'End date & time']}
                  format="YYYY-MM-DD HH:mm:ss"
                  disabledDate={(current) => current < moment().startOf('day')}
                  onChange={(momentDates, dates) => {
                    setDate(() => ({
                      ...date,
                      dateRange: {
                        from: momentDates?.[0]?.utc(true),
                        to: momentDates?.[1]?.utc(true)
                      }
                    }));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="s2s_percent" label="S2S% decrease by" rules={[{ required: true, message: 'Please input S2S% decrease!' }]}>
                <InputNumber
                  min={1}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  precision={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="country" label="Countries">
                <SelectWithRefetching
                  placeholder="Select Country"
                  optionFilterProp="data-searchvalue"
                  showSearch
                  loading={countriesLoading}
                  refetching={countriesRefetching}
                  allowClear
                >
                  {generateCountriesOptions(countries)}
                </SelectWithRefetching>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="os" label="OS">
                <SelectWithRefetching
                  placeholder="Select OS"
                  optionFilterProp="data-searchvalue"
                  showSearch
                  loading={allOSLoading}
                  refetching={allOSRefetching}
                  allowClear
                >
                  {getSelectOptionsWithTooltip(filteredOSs)}
                </SelectWithRefetching>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="browser" fieldKey="browser" label="Browser">
                <SelectWithRefetching
                  placeholder="Select Browser"
                  optionFilterProp="data-searchvalue"
                  showSearch
                  loading={allBrowsersLoading}
                  refetching={allBrowsersRefetching}
                  allowClear
                >
                  {getSelectOptionsWithTooltip(filteredBrowsers)}
                </SelectWithRefetching>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="device_type_list" label="Device type">
                <SelectWithRefetching
                  placeholder="Select Device Type List"
                  optionFilterProp="data-searchvalue"
                  showSearch
                  allowClear
                  loading={allDeviceTypeListsLoading}
                  refetching={allDeviceTypeListsRefetching}
                >
                  {getSelectOptionsWithExtendedTooltip(allDeviceTypeLists, 'device_type', true, getDeviceTypeList)}
                </SelectWithRefetching>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="product" label="Product">
                {/* Antd select instead of virtualized select so we can show values that are not in select options - good for clone */}
                <SelectWithRefetching
                  placeholder="Select Product"
                  showSearch
                  allowClear
                  loading={allProductsLoading}
                  refetching={allProductsRefetching}
                  optionFilterProp="data-searchvalue"
                >
                  {generateSelectOptionsWithIdValues(allProducts)}
                </SelectWithRefetching>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="lp" label="Landing Page" valuePropName="selectValue">
                <PreviewLpSelect
                  loading={allLandingPagesLoading}
                  options={landingPagesService.generateOptions(allLandingPages)}
                  renderOptionComponent={(option) => <LpPreview option={option} />}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};
