import { callApi } from './api';
import { getQueryString } from '../utils/query';

class BrowsersService {
  async getAll({ fields = 'name,id,img' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/browsers/${query}` });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };

  generateOptionsWithNameAsValue = (arr) => {
    const namesWithPotentialDuplicates = arr.map((option) => option.name);
    const names = namesWithPotentialDuplicates.filter((value, index) => namesWithPotentialDuplicates.indexOf(value) === index);
    return names.map((name) => {
      return {
        label: name,
        value: name
      };
    });
  };
}

export const browsersService = new BrowsersService();
