import React, { useState, useEffect } from 'react';

import { Heading } from '../Heading';
import { Table } from './Table';
import { AddOrEditBrowserListModal } from './AddOrEditBrowserListModal';

import { browsersService } from '../../../../services/browsers';
import { listsService, ListTypes } from '../../../../services/lists';
import { accountsService } from '../../../../services/accounts';
import { useDebounce, useFetch } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { SortDirection } from '../../../../services/statistic';

// import styles from '../../Lists.module.css';

const listType = ListTypes.browser_lists;

export const BrowsersTab = () => {
  const [allBrowsers, setAllBrowsers] = useState([]);
  const [allBrowsersLoading, setAllBrowsersLoading] = useState(false);

  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };
    const getBrowsers = async () => {
      setAllBrowsersLoading(true);
      try {
        const browsers = await browsersService.getAll({ fields: 'id,name,img' });
        setAllBrowsers(browsers);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllBrowsersLoading(false);
      }
    };
    getAccounts();
    getBrowsers();
  }, []);

  const loadBrowserLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      fields: 'id,img,name,creator_details,date_created,browsers_list,browsers_details,browsers,assigned,exclude,private',
      filters,
      sorting
    });

  const [
    {
      data: { results: browserLists, count: totalRows },
      isFetching
    },
    getBrowserLists
  ] = useFetch(loadBrowserLists);

  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getBrowserLists();
  }, [debouncedSearchValue, currentPage, getBrowserLists, filters, sorting]);

  const handleDelete = () => {
    if (browserLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getBrowserLists();
  };

  const loader = { allAccounts: allAccountsLoading, allBrowsers: allBrowsersLoading };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="Browser"
        allAccounts={allAccounts}
        areAccountsLoading={allAccountsLoading}
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        allBrowsers={allBrowsers}
        allAccounts={allAccounts}
        browserLists={browserLists}
        loading={loading}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loader={loader}
        sorting={sorting}
        onClone={getBrowserLists}
        onEdit={getBrowserLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />

      <AddOrEditBrowserListModal
        visibleModal={visibleAddModal}
        allBrowsers={allBrowsers}
        allAccounts={allAccounts}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getBrowserLists}
        loader={loader}
      />
    </div>
  );
};
