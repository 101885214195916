import React from 'react';

import { ActionsTableCol } from './ActionsTableCol';
import { TableCommon } from '../TableCommon';

export const Table = ({
  allCountries,
  allAccounts,
  allOSs,
  allBrowsers,
  allDeviceTypes,
  priceLists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  loader,
  sorting,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const renderSelectedItems = ({ list_prices }) => {
    return (
      list_prices
        .map((list) => {
          return list.country_details.regex;
        })
        .join(', ') || '-'
    );
  };

  const renderActions = (record) => {
    return (
      <ActionsTableCol
        allCountries={allCountries}
        allAccounts={allAccounts}
        allOSs={allOSs}
        allBrowsers={allBrowsers}
        allDeviceTypes={allDeviceTypes}
        priceList={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        loader={loader}
      />
    );
  };

  return (
    <TableCommon
      lists={priceLists}
      loading={loading}
      totalRows={totalRows}
      currentPage={currentPage}
      pageSize={pageSize}
      itemsTitle="List of Countries"
      sorting={sorting}
      onPaginationChange={onPaginationChange}
      renderSelectedItems={renderSelectedItems}
      renderActions={renderActions}
      onSortingClick={onSortingClick}
    />
  );
};
