import { LineChartOutlined, LoginOutlined, StopOutlined } from '@ant-design/icons';
import { Form, Modal, Pagination, Popconfirm, Popover, Spin, Table as AntTable, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { usePromise } from 'react-use';
import dayjs from 'dayjs';

import { auth } from '../../../services/auth';
import { companiesService, STATUS, STATUS_LABEL } from '../../../services/companies';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { AddOrEditCompany } from './AddOrEditCompany';
import { Link } from 'react-router-dom';
import { SuspendModalContent } from './SuspendModalContent';
import { TableTitle } from '../../../components/TableTitle';

const TitleValue = {
  Id: 'id',
  Name: 'name',
  DateCreated: 'date_created',
  DateEdited: 'date_edited'
};

export const Table = ({
  companies,
  loading,
  users,
  loader,
  countries,
  onDelete,
  totalItems,
  currentPage,
  pageSize,
  categoriesLoading,
  allPublisherCategories,
  allAdvertiserCategories,
  sorting,
  onPaginationChange,
  onEdit,
  onReject,
  onSuspend,
  onSortingClick
}) => {
  const [thisUserId, setThisUserId] = useState();
  const [loadingThisUserId, setLoadingThisUserId] = useState(false);

  const [suspendedForm] = Form.useForm();

  const isAdmin = auth.getPermissions() === true;

  const mounted = usePromise();

  const resetSuspendFormFields = () => suspendedForm.resetFields();

  const handleSuspend = async (id, values) => {
    try {
      await companiesService.suspend(id, values);
      openSuccessNotification({ message: 'Company has been suspended', duration: 8 });
      onSuspend();
    } catch (e) {
      showApiErrors(e);
    } finally {
      resetSuspendFormFields();
    }
  };

  const loginAsPublisher = async (id) => {
    try {
      const data = await companiesService.loginAsPublisher(id);
      openSuccessNotification({ message: data.message, duration: 8 });
      window.location.href = data.data;
    } catch (e) {
      showApiErrors(e);
    } finally {
    }
  };

  const loginAsAdvertiser = async (id) => {
    try {
      const data = await companiesService.loginAsAdvertiser(id);
      openSuccessNotification({ message: data.message, duration: 8 });
      window.location.href = data.data;
    } catch (e) {
      showApiErrors(e);
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingThisUserId(true);
        const user = await mounted(auth.getUserProfile());
        setThisUserId(user.id);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingThisUserId(false);
      }
    })();
  }, [mounted]);

  const confirmSuspendCampaigns = (id, name) => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to suspend ${name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      content: <SuspendModalContent form={suspendedForm} onSubmit={(values) => handleSuspend(id, values)} />,
      onOk: () => suspendedForm.submit(),
      onCancel: resetSuspendFormFields
    });
  };

  const columns = [
    {
      title: (
        <TableTitle
          text="Id"
          value={TitleValue.Id}
          order={sorting.find((s) => s.key === TitleValue.Id)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'id',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        };
      }
    },
    {
      title: (
        <TableTitle
          text="Name"
          value={TitleValue.Name}
          order={sorting.find((s) => s.key === TitleValue.Name)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'name'
    },
    {
      title: 'Tags',
      render: ({ tags }) => {
        return tags.map((tag, index) => (
          <Tag color="default" key={index}>
            {tag.name}
          </Tag>
        ));
      }
    },

    {
      title: 'Categories',
      render: ({ categories }) => {
        return categories.map(({ category: categoryId }, index) => (
          <Tag color="default" key={index}>
            {/* TODO: Backend should return category name in categories data */}
            {[...allPublisherCategories, ...allAdvertiserCategories].find(({ id }) => categoryId === id)?.name}{' '}
          </Tag>
        ));
      }
    },
    { title: 'Type', dataIndex: 'type' },
    {
      title: (
        <TableTitle
          text="Date Created"
          value={TitleValue.DateCreated}
          order={sorting.find((s) => s.key === TitleValue.DateCreated)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_created',
      render: (dateCreated) => dayjs(dateCreated).format('DD.MM.YYYY. - HH:mm:ss')
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: (_, { status }) => {
        return status === STATUS.ACTIVE ? STATUS_LABEL.Active : status;
      }
    },
    {
      title: 'Actions',
      key: 'operation',
      align: 'center',
      width: 100,
      render: (
        _,
        {
          id,
          name,
          status,
          categories,
          assigned: trafficAssigned,
          product_users: productAssigned,
          is_partner: isPartner,
          is_ssp_platform: isSspPlatform,
          has_traffic
        },
        index
      ) => {
        let allManagers = new Set();
        categories.forEach((category, index) => {
          let am = category.account_manager;
          let asm = [...category.account_sub_managers];
          allManagers.add(am);
          asm.forEach((a) => allManagers.add(a));
        });

        const isAssignedToThisUser =
          allManagers.has(thisUserId) ||
          trafficAssigned.some((userId) => userId === thisUserId) ||
          productAssigned.some((userId) => userId === thisUserId);
        const canViewAllCompanies = auth.hasPermission('view_all_companies');
        const commonCheck = status === STATUS.ACTIVE && (isAdmin || isAssignedToThisUser || canViewAllCompanies);

        const showPublisherLoginActionBtn = commonCheck && isPartner;
        const showAdvertiserLoginActionBtn = commonCheck && isSspPlatform;

        let statsParam = `${status === STATUS.ACTIVE && has_traffic ? `adv_company_id=${id}` : ''}`;

        return (
          <Spin spinning={loadingThisUserId}>
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {showPublisherLoginActionBtn && (
                <Popover content="Login as Publisher" trigger="hover" onClick={() => loginAsPublisher(id)}>
                  <span style={{ width: 19, height: 19, marginTop: 2, cursor: 'pointer' }}>
                    <LoginOutlined style={{ fontSize: 19, color: '#1890ff' }} />
                  </span>
                </Popover>
              )}

              {showAdvertiserLoginActionBtn && (
                <Popover content="Login as Advertiser" trigger="hover" onClick={() => loginAsAdvertiser(id)}>
                  <span style={{ width: 19, height: 19, margin: '2px 0 0 7px', cursor: 'pointer' }}>
                    <LoginOutlined style={{ fontSize: 19, color: '#16A24A' }} />
                  </span>
                </Popover>
              )}

              {statsParam && (
                <Popover content="Stats" trigger="hover">
                  <span style={{ width: 19, height: 19, margin: '2px 0 0 7px', cursor: 'pointer' }}>
                    <Link to={`/stubs/channel_stats/?${statsParam}`}>
                      <LineChartOutlined style={{ fontSize: 19, color: '#1890ff' }} />
                    </Link>
                  </span>
                </Popover>
              )}

              {status !== STATUS.SUSPENDED && (
                <Popover content="Suspend" trigger="hover">
                  <span
                    style={{ width: 19, height: 19, margin: '2px 0 0 7px', cursor: 'pointer' }}
                    onClick={() => confirmSuspendCampaigns(id, name)}
                  >
                    <StopOutlined style={{ fontSize: 19, color: '#F84747' }} />
                  </span>
                </Popover>
              )}

              <Popover content="Edit" trigger="hover">
                <span style={{ margin: '0 0 1px 7px', cursor: 'pointer' }} onClick={() => openModal(index)}>
                  <img src="/images/edit_icon.svg" alt="Edit" width="19px" />
                </span>
              </Popover>

              <Popconfirm
                onConfirm={() => onDelete(id)}
                onCancel={() => {}}
                title="Are you sure that you want to delete this company?"
                okText="Yes"
                cancelText="No"
              >
                <Popover content="Delete" trigger="hover">
                  <span style={{ marginLeft: 5, cursor: 'pointer' }}>
                    <img src="images/delete_icon.png" alt="Delete" width="22px" />{' '}
                  </span>
                </Popover>
              </Popconfirm>
            </div>
          </Spin>
        );
      }
    }
  ];
  const [isApprove] = useState(false);
  const [currEditIndex, setCurrEditIndex] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const hideModal = () => {
    setModalVisible(false);
    setCurrEditIndex(-1);
  };
  const showModal = () => setModalVisible(true);
  const openModal = (index) => {
    setCurrEditIndex(index);
    showModal();
  };

  return (
    <div>
      <AddOrEditCompany
        onCancel={hideModal}
        onSubmitSuccess={() => {
          hideModal();
          onEdit();
        }}
        loader={loader}
        countries={countries}
        users={users}
        company={companies[currEditIndex]}
        onFormChanged={setWasFormChanged}
        wasFormChanged={wasFormChanged}
        isShown={modalVisible}
        isApprove={isApprove}
        categoriesLoading={categoriesLoading}
        publisherCategories={allPublisherCategories}
        advertiserCategories={allAdvertiserCategories}
      />
      <AntTable
        loading={loading}
        rowKey={({ id }) => id}
        dataSource={companies}
        columns={columns}
        pagination={false}
        onRow={(data, index) => ({
          onClick: () => {
            openModal(index);
          }
        })}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
