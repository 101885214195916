import { Card, DatePicker, Input, InputNumber, Popover, Select, Spin, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';

import { callApi } from '../../../services/api';
import { BOOL_OPTIONS, FieldType, TYPES } from '../../../services/urgentPanel';
import { generateOptions, generateSelectOptionsWithIdValues } from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from './DropBox.module.css';

export const DropBox = ({ boxKey, accept, items, onDrop, onRemoveItem, name, onFilterValueChange, filterValues, filters }) => {
  const [, drop] = useDrop({
    accept: accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [dataStorage, setDataStorage] = useState({});
  const [components, setComponents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const generateComponent = (item, itemIndex) => {
      if (!dataStorage[item.value] && item.url && item.fields) {
        getData(item);
      }
      const filterOptions = item.operators.map((el) => filters[el]);
      const suffixValue = items[itemIndex].suffix;
      const filterSuffixSelect = (
        <Select
          children={generateSelectOptionsWithIdValues(filterOptions)}
          onChange={(value) => onFilterValueChange({ itemIndex, value, isSuffix: true })}
          value={suffixValue}
        />
      );

      let filterInput = <></>;

      const optionValue = dataStorage[item.value] || item.options || [];
      const isRange = filterValues[itemIndex].suffix === filters.range.id;
      const isContains = filterValues[itemIndex].suffix === filters.icontains.id;
      const filterValue = items[itemIndex].filterValue;
      switch (item.type) {
        case FieldType.DATE:
          filterInput = (
            <DatePicker
              value={moment(filterValue)}
              onChange={(value) => onFilterValueChange({ itemIndex, value: value.format('YYYY-MM-DD'), isSuffix: false })}
            />
          );
          break;
        case FieldType.BOOL:
          filterInput = (
            <Select
              clearIcon
              onChange={(value) => onFilterValueChange({ itemIndex, value, isSuffix: false })}
              children={generateSelectOptionsWithIdValues(BOOL_OPTIONS)}
              value={filterValue}
            />
          );
          break;

        case FieldType.STRING:
          filterInput = isContains ? (
            <Input value={filterValue} onChange={(e) => onFilterValueChange({ itemIndex, value: e.target.value, isSuffix: false })} />
          ) : (
            <Select
              clearIcon
              onChange={(value) => onFilterValueChange({ itemIndex, value, isSuffix: false })}
              mode="tags"
              value={filterValue}
            />
          );

          break;
        case FieldType.ID:
          const label = item.fields.label;
          const value = item.fields.value;
          filterInput = (
            <Select
              showSearch
              clearIcon
              optionFilterProp="data-searchvalue"
              onChange={(value) => onFilterValueChange({ itemIndex, value, isSuffix: false })}
              children={generateOptions(optionValue, label, value)}
              mode="multiple"
              value={filterValue}
            />
          );

          break;
        case FieldType.NUMBER:
          const firstValue = Array.isArray(filterValue) ? filterValue[0] : undefined || undefined;
          const secondValue = Array.isArray(filterValue) ? filterValue[1] : undefined || undefined;

          filterInput = isRange ? (
            <>
              <span>
                <i>From</i>
              </span>
              &nbsp;
              <InputNumber
                value={firstValue}
                onChange={(value) => onFilterValueChange({ itemIndex, value: [value, secondValue], isSuffix: false })}
                style={{ minWidth: '75px' }}
              />
              &nbsp;
              <span>
                <i>To</i>
              </span>
              &nbsp;
              <InputNumber
                value={secondValue}
                onChange={(value) => onFilterValueChange({ itemIndex, value: [firstValue, value], isSuffix: false })}
                style={{ minWidth: '75px' }}
              />
            </>
          ) : (
            <InputNumber value={filterValue} onChange={(value) => onFilterValueChange({ itemIndex, value, isSuffix: false })} />
          );

          break;
        case FieldType.CHOICE_FIELD:
          filterInput = (
            <Select
              children={generateSelectOptionsWithIdValues(optionValue)}
              onChange={(value) => onFilterValueChange({ itemIndex, value, isSuffix: false })}
              mode="multiple"
              value={filterValue}
            />
          );

          break;

        default:
          filterInput = <></>;
          break;
      }
      const name = item.fullName;
      const nameLength = item.fullName.length;
      const displayName = nameLength > 1 ? `${name[0]} > ... > ${name[nameLength - 1]}` : name[0];
      return (
        <div className={styles.row}>
          <div>
            <Popover content={item.fullName.join(' > ')} trigger="hover">
              <span>{displayName}</span>
            </Popover>
          </div>
          <div>{filterSuffixSelect}</div>
          <div>{filterInput}</div>
          <div onClick={() => onRemoveItem(boxKey, itemIndex)}>
            <Popover content="Remove" trigger="hover">
              <span style={{ marginLeft: 5, cursor: 'pointer' }}>
                <img src="images/delete_icon.png" alt="Delete" width="17px" />
              </span>
            </Popover>
          </div>
        </div>
      );
    };
    setComponents(
      items.map((item, itemIndex) =>
        name === TYPES.FILTER ? (
          <div key={itemIndex}>{generateComponent(item, itemIndex)}</div>
        ) : (
          <Tag
            key={item.value}
            closable
            value={item.value}
            onClose={() => onRemoveItem(boxKey, itemIndex)}
            style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}
          >
            {item.fullName.join(' > ')}
          </Tag>
        )
      )
    );
  }, [items, dataStorage, boxKey, onRemoveItem, name, onFilterValueChange, filterValues, filters]);

  const getData = async (item) => {
    setLoading(true);
    try {
      const data = await callApi({ url: `${item.url}?fields=${Object.values(item.fields).join(',')}` });
      setDataStorage((prev) => ({ ...prev, [item.value]: data }));
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={name.toUpperCase()} className={styles.card}>
        <div ref={drop} style={{ width: '100%' }}>
          {components}
        </div>
      </Card>
    </Spin>
  );
};
