import React from 'react';

import { ListTypes } from '../../../../services/lists';
import { AddOrEditListModal } from '../AddOrEditListModal';
import { MultipleItemsSelect } from '../MultipleItemsSelect';

export const AddOrEditCountryListModal = ({
  visibleModal,
  allCountries,
  allAccounts,
  countryList,
  isClone,
  onClone,
  onCloseModal,
  onEdit,
  onAdd,
  loader
}) => {
  const fieldName = 'countries';

  const renderItemsSelect = () => (
    <MultipleItemsSelect
      loading={loader.allCountries}
      title="List of Countries"
      name={fieldName}
      message="Please choose country/ies!"
      allItems={allCountries}
    />
  );

  return (
    <AddOrEditListModal
      visibleModal={visibleModal}
      allAccounts={allAccounts}
      onCloseModal={onCloseModal}
      onClone={onClone}
      onEdit={onEdit}
      onAdd={onAdd}
      list={countryList}
      listType={ListTypes.country_lists}
      itemsFieldName={fieldName}
      title="Country List"
      nameFieldTitle="Country List Name"
      renderItemsSelect={renderItemsSelect}
      isClone={isClone}
      areAccountsLoading={loader.allAccounts}
    />
  );
};
