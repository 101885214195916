import { LoadingOutlined } from '@ant-design/icons';
import { Pagination, Table as AntTable } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { LpPreviewPopover } from '../../../components/PreviewLpSelect';
import { TableTitle } from '../../../components/TableTitle';

import { ActionsTableCol } from './ActionsTableCol';
import styles from './Table.module.css';

const TitleValue = {
  Id: 'id',
  Title: 'title',
  Tags: 'tags',
  DateCreated: 'date_created',
  DateEdited: 'date_edited'
};

export const Table = ({
  landingPages,
  loading,
  users,
  usersLoading,
  totalItems,
  currentPage,
  pageSize,
  sorting,
  onPaginationChange,
  onClone,
  onUpload,
  onDelete,
  onSortingClick
}) => {
  const columns = [
    {
      title: (
        <TableTitle
          text="Id"
          value={TitleValue.Id}
          order={sorting.find((s) => s.key === TitleValue.Id)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'id'
    },
    {
      title: (
        <TableTitle
          text="Title"
          value={TitleValue.Title}
          order={sorting.find((s) => s.key === TitleValue.Title)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'title',
      width: 350,
      render: (title, { lp_preview_url }) => {
        return (
          <a className={styles.previewLink} href={lp_preview_url} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        );
      }
    },
    {
      title: 'Uploaded Image',
      dataIndex: 'uploaded_image_url',
      align: 'center',
      width: 250,
      render: (uploadedImage) => (uploadedImage ? <img src={uploadedImage} alt="Uploaded" height={50} /> : '-')
    },
    {
      title: 'Screenshot',
      key: 'autogenerated_screenshot',
      align: 'center',
      width: 150,
      render: ({ id, date_edited: dateEdited }) => {
        const screenshot = `/api/lps/${id}/get-ss-file/?date_edited=${dateEdited}`;
        const imgDimensions = { width: 250, height: 170 };

        return <LpPreviewPopover screenshot={screenshot} imgDimensions={imgDimensions} eyeImgWidth={21} />;
      }
    },
    {
      title: (
        <TableTitle
          text="Tags"
          value={TitleValue.Tags}
          order={sorting.find((s) => s.key === TitleValue.Tags)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'tags',
      width: 250,
      render: (tags) => tags.split(',').join(', ')
    },
    {
      title: (
        <TableTitle
          text="Date and Time Created"
          value={TitleValue.DateCreated}
          order={sorting.find((s) => s.key === TitleValue.DateCreated)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_created',
      width: 250,
      render: (date) => dayjs(date).format('DD.MM.YYYY. - HH:mm:ss')
    },
    {
      title: (
        <TableTitle
          text="Date and Time Edited"
          value={TitleValue.DateEdited}
          order={sorting.find((s) => s.key === TitleValue.DateEdited)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_edited',
      width: 250,
      render: (date) => (date ? dayjs(date).format('DD.MM.YYYY. - HH:mm:ss') : '/')
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      width: 150,
      render: (user_id) => {
        if (usersLoading) {
          return <LoadingOutlined />;
        }
        if (user_id !== null) {
          const user = users.find((u) => u.id === user_id);
          return user ? `${user.first_name} ${user.last_name}` : user_id;
        }
        return null;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100
    },
    {
      title: 'Actions',
      width: 200,
      key: 'operation',
      align: 'center',
      render: ({ id, lp_preview_url: previewUrl }) => (
        <ActionsTableCol id={id} previewUrl={previewUrl} onClone={onClone} onUpload={onUpload} onDelete={onDelete} />
      )
    }
  ];

  return (
    <div>
      <AntTable
        style={{ marginTop: '3%' }}
        loading={loading}
        rowKey={({ id }) => id}
        dataSource={landingPages}
        columns={columns}
        pagination={false}
        scroll={{ x: 1500, scrollToFirstRowOnChange: true }}
        // bordered
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
