import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select, DatePicker, Checkbox } from 'antd';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import styles from '../UserNotifications.module.css';
import moment from 'moment';

import { companiesService } from '../../../services/companies';
import { accountsService } from '../../../services/accounts';
import { userNotificationsService } from '../../../services/user-notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { ColumnsGroup, renderRows } from '../../../components/ColumnsGroup';
import { allTableColumns } from '../../../utils/notifications';

const rangeData = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(30, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
};
const { RangePicker } = DatePicker;
const { Option } = Select;

export const NotificationsFilters = ({
  filters,
  setFilters,
  onFilter,
  createdDateRange,
  setCreatedDateRange,
  setEditedDateRange,
  users,
  usersLoading,
  companies,
  companiesLoading,
  checkedColumns,
  onColumnsChange
}) => {
  const [modules, setModules] = useState([]);
  const [modulesLoading, setModulesLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setModulesLoading(true);
      try {
        const modules = await userNotificationsService.getPermissions();
        setModules(modules);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setModulesLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Row gutter={16} style={{ marginTop: '10px' }}>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Date created range </span>
          <div style={{ width: '100%' }}>
            <RangePicker
              format="DD.MM.YYYY"
              ranges={rangeData}
              onChange={(momentDates, dates) => {
                if (momentDates) {
                  let createdDate = { from: momentDates[0], to: momentDates[1] };
                  setCreatedDateRange(createdDate);
                } else {
                  let createdDate = { from: null, to: null };
                  setCreatedDateRange(createdDate);
                }
              }}
              defaultValue={[moment(createdDateRange.from), moment(createdDateRange.to)]}
            />
          </div>
        </Col>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Date edited range </span>
          <div style={{ width: '100%' }}>
            <RangePicker
              format="DD.MM.YYYY"
              ranges={rangeData}
              onChange={(momentDates, dates) => {
                if (momentDates) {
                  let editedDate = { from: momentDates[0], to: momentDates[1] };
                  setEditedDateRange(editedDate);
                } else {
                  let editedDate = { from: null, to: null };
                  setEditedDateRange(editedDate);
                }
              }}
            />
          </div>
        </Col>
        <Col span={6} className={styles.columnsGroupCol} style={{ display: 'flex', alignItems: 'end' }}>
          <ColumnsGroup renderColumnRows={() => renderRows({ columnsData: allTableColumns, checkedColumns, onChange: onColumnsChange })} />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '10px' }}>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Status</span>
          <Select
            placeholder={'Select status'}
            mode="multiple"
            style={{ width: '100%' }}
            value={filters.action__in}
            onChange={(value) => setFilters({ ...filters, action__in: value })}
            allowClear
          >
            <Option value="Created">Created</Option>
            <Option value="Edited">Edited</Option>
            <Option value="Deleted">Deleted</Option>
          </Select>
        </Col>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Company</span>
          <VirtualizedSelect
            placeholder={'Select company'}
            multi={true}
            style={{ width: '100%' }}
            loading={companiesLoading}
            selectValue={filters.company__in}
            onChange={(value) => setFilters({ ...filters, company__in: value })}
            options={companiesService.generateOptions(companies)}
          />
        </Col>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Creator</span>
          <VirtualizedSelect
            placeholder={'Select creator'}
            multi={true}
            style={{ width: '100%' }}
            loading={usersLoading}
            selectValue={filters.user__in}
            onChange={(value) => setFilters({ ...filters, user__in: value })}
            options={accountsService.generateOptions(users)}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '10px' }}>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Type</span>
          <Select
            placeholder={'Select type'}
            mode="multiple"
            style={{ width: '100%' }}
            value={filters.type}
            onChange={(value) => setFilters({ ...filters, type: value })}
            allowClear
          >
            <Option value="Broadcast">Broadcast</Option>
            <Option value="Notification Bar">Notification Bar</Option>
          </Select>
        </Col>
        <Col span={6}>
          <span className={styles.spanMarginBottom}>Module</span>
          <VirtualizedSelect
            placeholder={'Select module'}
            multi={true}
            style={{ width: '100%' }}
            loading={modulesLoading}
            selectValue={filters.module__in}
            onChange={(value) => setFilters({ ...filters, module__in: value })}
            options={userNotificationsService.generateModuleOptions(modules)}
          />
        </Col>
        <Col className={styles.checkedColumn} span={6}>
          <Checkbox
            checked={filters.onlyPending}
            onChange={(e) => {
              setFilters({
                ...filters,
                onlyPending: e.target.checked
              });
            }}
          >
            Show only pending notifications
          </Checkbox>
        </Col>
        <Col className={styles.btnCol} span={6}>
          <Button className="add-price-btn" type="primary" onClick={onFilter}>
            Filter notifications
          </Button>
        </Col>
      </Row>
    </>
  );
};
