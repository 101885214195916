import React from 'react';

import { AddOrEditBrowserListModal } from './AddOrEditBrowserListModal';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { ListTypes, ListTypesPlural } from '../../../../services/lists';

export const ActionsTableCol = ({ allBrowsers, allAccounts, browserList, onClone, onEdit, onDelete, loader }) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.browser_lists}
      contentForCopy={browserList[ListTypesPlural.browser_lists]}
      allAccounts={allAccounts}
      list={browserList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => (
        <AddOrEditBrowserListModal {...commonProps} allBrowsers={allBrowsers} browserList={browserList} loader={loader} />
      )}
    />
  );
};
