import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Button, Form } from 'antd';

import { PricesForm } from '../../../../components/PricesForm';

import { listsService, ListTypes } from '../../../../services/lists';
import { openErrorNotification, openSuccessNotification } from '../../../../utils/notifications';
import { showApiErrors } from '../../../../utils/showApiErrors';

import styles from '../../Lists.module.css';
import { confirmClose } from '../../../../utils/confirmClose';
import { useBeforeUnload } from '../../../../utils/hooks/useBeforeUnload';
import { priceListValidator } from '../../../../utils/pricesValidator';
import { useEffectTrigger, useForm } from '../../../../utils/hooks';
import { breakGroupedCountries, groupCountriesWithSamePrices, markListedCountries } from '../../../../utils/functions';
import { useDeepCompareEffect } from 'use-deep-compare';

const listName = 'listPrices';

export const AddOrEditPriceListModal = ({
  visibleModal,
  allCountries,
  allAccounts,
  allOSs,
  allBrowsers,
  allDeviceTypes,
  isClone,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  list,
  loader
}) => {
  const [loading, setLoading] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [groupedPriceData, setGroupedPriceData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countriesAndIds, setCountriesAndIds] = useState([]);

  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();

  const [importedList, setImportedList] = useState(null);
  const [form] = useForm(activateFormChangedTrigger);
  const listType = ListTypes.price_lists;

  const initialValues = useMemo(() => {
    if (!list && !importedList) {
      return undefined;
    }

    const listPricesArr = importedList ? importedList.list_prices : list.list_prices;
    const name = list ? (isClone ? list.name + ' - clone' : list.name) : undefined;
    const global = list ? list.global_list : undefined;
    const assigned = list ? list.assigned : undefined;
    const privateList = list ? list.private : undefined;
    const formatedListPrices = listPricesArr.map((lp) => {
      let priceObj = {
        country: lp.country,
        price: lp.price,
        browser: lp.browser,
        os: lp.os,
        device_type: lp.device_type
      };

      if (!isClone) {
        priceObj.id = lp.id;
      }

      return priceObj;
    });

    const { groupedData, countriesAndIds } = groupCountriesWithSamePrices(formatedListPrices);
    const listPrices = groupedData;

    setGroupedPriceData(groupedData);
    setCountriesAndIds(countriesAndIds);
    return { name, global, assigned, privateList, listPrices };
  }, [list, importedList, isClone]);

  useBeforeUnload(wasFormChanged);

  useDeepCompareEffect(() => {
    if (groupedPriceData) {
      markListedCountries({
        incomingValue: groupedPriceData,
        countryList,
        countryListSetter: setCountryList
      });
    }
  }, [countryList, groupedPriceData]);

  useEffect(() => {
    setCountryList(allCountries);
  }, [allCountries]);

  useEffect(() => {
    setImportedList(null);
    setWasFormChanged(false);
  }, [visibleModal]);

  useEffect(() => {
    if (visibleModal) {
      form.resetFields();
    }
  }, [form, visibleModal]);

  useEffect(() => {
    form.resetFields([listName]);
  }, [form, importedList]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const { name, listPrices, privateList, assigned } = values;

    const breakedPriceList = breakGroupedCountries(listPrices, countriesAndIds);
    if (breakedPriceList) {
      if (!priceListValidator(breakedPriceList.map(({ price, id, ...others }) => others))) {
        setLoading(false);
        return;
      }
    } else {
      openErrorNotification({ message: 'Please enter at least one price!' });
    }

    const formData = {
      name,
      list_prices: breakedPriceList,
      global_list: true,
      private: !!privateList,
      assigned
    };

    if (list && !isClone) {
      try {
        await listsService.editList(list.id, formData, listType);
        onCloseModal();
        openSuccessNotification({ message: 'List successfully edited!' });
        await onEdit();
        setWasFormChanged(false);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const { id } = await listsService.addList(formData, listType);
        onCloseModal();
        openSuccessNotification({ message: `List successfully ${isClone ? 'cloned' : 'created'}!` });
        isClone ? await onClone(id) : await onAdd(id);
        setWasFormChanged(false);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const type = list && isClone ? 'Clone' : list ? 'Edit' : 'Add';

  const onCancel = () => {
    if (wasFormChanged) {
      confirmClose(() => {
        onCloseModal();
      });
    } else {
      onCloseModal();
    }
  };

  return (
    <Modal
      title={type + ' Price List'}
      visible={visibleModal}
      wrapClassName={styles.addOrEditListModal}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {(list && isClone ? 'Clone' : list ? 'Edit' : 'Save') + ' List'}
        </Button>
      ]}
      width={600}
    >
      <div className={styles.modalFormWrapper}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
          onValuesChange={(_, allValues) => {
            setWasFormChanged(true);
            setGroupedPriceData(allValues[listName]);
            activateFormChangedTrigger();
          }}
        >
          <PricesForm
            form={form}
            allAccounts={allAccounts}
            allCountries={countryList}
            allOSs={allOSs}
            allBrowsers={allBrowsers}
            allDeviceTypes={allDeviceTypes}
            list={list}
            listName={listName}
            isListForm={true}
            onImport={setImportedList}
            loader={loader}
            formChangedTrigger={formChangedTrigger}
          />
        </Form>
      </div>
    </Modal>
  );
};
