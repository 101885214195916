import React from 'react';
import { Modal, Input } from 'antd';
import { userNotificationsService } from '../services/user-notifications';
import { openSuccessNotification } from './notifications';
import { showApiErrors } from './showApiErrors';

export const confirmDeleteUserNotificationModal = (userNotification, onDelete) => {
  const { title } = userNotification;

  Modal.confirm({
    title: `Are you sure that you want to delete notification ${title}?`,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => handleDeleteUserNotification(userNotification, onDelete),
    onCancel: () => { }
  });
};

export const addLinkToDescriptionUserNotificationModal = (initialFormValues, setInitialFormValues, setLinkToDescription) => {
  let linkText = '';
  let linkUrl = '';

  const setLinkText = (inputLinkTextValue) => {
    linkText = inputLinkTextValue.target.value;
  };

  const setLinkUrl = (inputLinkUrlValue) => {
    linkUrl = inputLinkUrlValue.target.value;
  };

  Modal.confirm({
    title: 'Add link to your description. Please input link url and text.',
    okText: 'Done',
    cancelText: 'Cancel',
    content: (
      <>
        <div style={{ marginTop: '10px' }}>
          <label for="text">Link text</label>
          <Input style={{ marginBottom: '10px' }} id="text" placeholder="Input link text" onChange={setLinkText} />
          <label for="url">URL</label>
          <Input id="url" placeholder="Input link url" onChange={setLinkUrl} />
        </div>
      </>
    ),
    onOk: () =>
      handleAddLinkToDescriptionUserNotification(initialFormValues, setInitialFormValues, setLinkToDescription, linkText, linkUrl),
    onCancel: () => { }
  });
};

export const handleAddLinkToDescriptionUserNotification = (
  initialFormValues,
  setInitialFormValues,
  setLinkToDescription,
  linkText,
  linkUrl
) => {
  // const tempValue = `<a href="${linkUrl}" target="_blank">${linkText}</a>`;
  let tempValue = '';
  if (!linkUrl.toLowerCase().includes('http')) {
    tempValue = `[${linkText}](https://${linkUrl})`;
  } else {
    tempValue = `[${linkText}](${linkUrl})`;
  }

  let initialTempFormValues = { ...initialFormValues };
  initialTempFormValues.description = initialFormValues.description ? initialFormValues.description + ' ' + tempValue : tempValue;
  setInitialFormValues(initialTempFormValues);
  setLinkToDescription(initialTempFormValues);
};

export const handleDeleteUserNotification = async (userNotification, onDelete) => {
  const { id, title } = userNotification;
  try {
    await userNotificationsService.deleteUserNotification(id);
    openSuccessNotification({ message: `Successfully deleted notification ${title}!`, duration: 8 });
    onDelete();
  } catch (e) {
    showApiErrors(e);
  }
};
