import { notification } from 'antd';

const columnTitlesAndKeys = {
  id: {
    title: 'Id',
    key: 'id',
    sortingValue: 'id'
  },
  title: {
    title: 'Title',
    key: 'title',
    sortingValue: 'title'
  },
  type: {
    title: 'Type',
    key: 'type',
    sortingValue: ''
  },
  icon: {
    title: 'Icon',
    key: 'icon',
    sortingValue: ''
  },
  description: {
    title: 'Description',
    key: 'description',
    sortingValue: 'description'
  },
  company: {
    title: 'Company',
    key: 'company',
    sortingValue: ''
  },
  module: {
    title: 'Module',
    key: 'module',
    sortingValue: ''
  },
  startDate: {
    title: 'Scheduled for',
    key: 'startDate',
    sortingValue: 'start_date'
  },
  endDate: {
    title: 'End Date',
    key: 'endDate',
    sortingValue: 'end_date'
  },
  status: {
    title: 'Status',
    key: 'status',
    sortingValue: ''
  },
  creator: {
    title: 'Creator',
    key: 'creator',
    sortingValue: ''
  },
  editor: {
    title: 'Editor',
    key: 'editor',
    sortingValue: ''
  },
  dateCreated: {
    title: 'Date Created',
    key: 'dateCreated',
    sortingValue: 'date_created'
  },
  dateEdited: {
    title: 'Date Edited',
    key: 'dateEdited',
    sortingValue: 'date_edited'
  }
};

export const {
  id,
  title,
  description,
  icon,
  type,
  company,
  module: publisherModule,
  creator,
  dateCreated,
  editor,
  dateEdited,
  startDate,
  endDate,
  status
} = columnTitlesAndKeys;

export const allTableColumns = [
  {
    value: id.key,
    text: id.title
  },
  {
    value: title.key,
    text: title.title
  },
  {
    value: type.key,
    text: type.title
  },
  {
    value: icon.key,
    text: icon.title
  },
  {
    value: description.key,
    text: description.title
  },
  {
    value: company.key,
    text: company.title
  },
  {
    value: publisherModule.key,
    text: publisherModule.title
  },
  {
    value: startDate.key,
    text: startDate.title
  },
  {
    value: endDate.key,
    text: endDate.title
  },
  {
    value: status.key,
    text: status.title
  },
  {
    value: creator.key,
    text: creator.title
  },
  {
    value: editor.key,
    text: editor.title
  },
  {
    value: dateCreated.key,
    text: dateCreated.title
  },
  {
    value: dateEdited.key,
    text: dateEdited.title
  }
];

export const openNotificationWithIcon = (type, message, description, duration = 3) => {
  notification[type]({ message, description, duration });
};

export const openErrorNotification = ({ message, description, duration }) => {
  openNotificationWithIcon('error', message, description, duration);
};

export const openSuccessNotification = ({ message, description, duration }) => {
  openNotificationWithIcon('success', message, description, duration);
};

export const openWarningNotification = ({ message, description, duration }) => {
  openNotificationWithIcon('warning', message, description, duration);
};

export const openInfoNotification = ({ message, description, duration }) => {
  openNotificationWithIcon('info', message, description, duration);
};
