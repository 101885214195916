import { callApi } from './api';

class UserFiltersService {
  userId = '';
  async getFilters() {
    const user = await callApi({ url: '/api/accounts/?fields=id,filters' });
    this.userId = user[0].id;
    const userFilters = JSON.parse(user[0].filters || null) || [];
    return userFilters;
  }
  async updateFilters(data) {
    data = JSON.stringify(data);
    return await callApi({ url: `/api/accounts/${this.userId}/`, method: 'PATCH', data: { filters: data } });
  }
  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.name
      };
    });
  };
}

export const userFiltersService = new UserFiltersService();
