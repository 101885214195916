import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PauseOutlined,
  PlusOutlined,
  StopOutlined
} from '@ant-design/icons';
import { Input, Button, Divider, Modal, Form, Select, Col, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { VirtualizedSelect } from '../../../../components/VirtualizedSelect';
import { companiesService } from '../../../../services/companies';
import { SearchBar } from '../../../../components/SearchBar';
import { activateCampaign, deleteCampaign, pauseCampaign, suspendCampaigns } from '../../utils/functions';

import styles from './Show.module.css';
import { openErrorNotification, openSuccessNotification } from '../../../../utils/notifications';
import { allTableColumns, mediaCampaignsService } from '../../../../services/media-campaigns';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { ColumnsGroup, renderRows } from '../../../../components/ColumnsGroup';
import { SuspendModalContent } from './SuspendModalContent';
import { generateUserOptions } from '../../../../utils/options';
import { accountsService } from '../../../../services/accounts';
import { FilterSwitch } from '../../../../components/FilterSwitch';
import { identityService } from '../../../../services/identity';

export const Header = ({
  searchValue,
  companies,
  identities,
  companiesLoading,
  identitiesLoading,
  filters,
  utmCampaign,
  liveCampaigns,
  selectedRowKeys,
  selectedRowTitles,
  checkedColumns,
  onColumnsChange,
  onFilter,
  onSearch,
  onActivate,
  onPause,
  onSuspend,
  onCopyInfo,
  onDelete,
  onUtmCampaignChange
}) => {
  const [loadingCampaignInfo, setLoadingCampaignInfo] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const history = useHistory();

  const [suspendedForm] = Form.useForm();

  const haveSelectedRows = selectedRowKeys.length;

  const openCreatePage = () => {
    history.push('/media-campaigns/new');
  };

  // TODO: This should call backend with list of ids
  const actionOnMultipleCampaigns = (actionCallback, onAction, data = null) => {
    selectedRowKeys.forEach((key, index) => actionCallback(key, selectedRowTitles[index], onAction, data));
  };

  const handleSuspendedConfirm = async (values) => {
    try {
      await suspendCampaigns(selectedRowKeys, values);
      openSuccessNotification({ message: `Successfully suspended campaigns with ids: ${selectedRowKeys.join(', ')} ` });
      onSuspend();
    } catch (e) {
      showApiErrors(e);
    } finally {
      suspendedForm.resetFields();
    }
  };

  const confirmActivateCampaigns = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to activate ${selectedRowTitles}?`.replaceAll(',', ', '),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => actionOnMultipleCampaigns(activateCampaign, onActivate),
      onCancel() {}
    });
  };

  const confirmPauseCampaigns = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to pause ${selectedRowTitles}?`.replaceAll(',', ', '),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => actionOnMultipleCampaigns(pauseCampaign, onPause),
      onCancel() {}
    });
  };

  const confirmSuspendCampaigns = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to suspend ${selectedRowTitles}?`.replaceAll(',', ', '),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      content: <SuspendModalContent form={suspendedForm} onSubmit={handleSuspendedConfirm} />,
      onOk: () => suspendedForm.submit(),
      onCancel: () => suspendedForm.resetFields()
    });
  };

  const confirmDeleteCampaigns = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${selectedRowTitles}?`.replaceAll(',', ', '),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => actionOnMultipleCampaigns(deleteCampaign, onDelete),
      onCancel() {}
    });
  };

  const handleCopy = async () => {
    if (haveSelectedRows) {
      try {
        setLoadingCampaignInfo(true);

        const campaignsInfoPromiseArr = selectedRowKeys.map((key) => mediaCampaignsService.getCampaignsInfo(key));
        const campaignsInfo = await Promise.all(campaignsInfoPromiseArr);

        navigator.clipboard.writeText(campaignsInfo.join('\n--------------------\n'));

        openSuccessNotification({ message: 'Successfully copied to clipboard!' });
        onCopyInfo();
      } catch (e) {
        showApiErrors(e);
        openErrorNotification({ message: 'Failed to copy to clipboard!' });
      } finally {
        setLoadingCampaignInfo(false);
      }
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        setUsersLoading(true);
        const users = await accountsService.getAll();
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    };
    getUsers();
  }, []);

  return (
    <div>
      <h1>Media Campaigns</h1>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={3}>
              <ColumnsGroup
                renderColumnRows={() => renderRows({ columnsData: allTableColumns, checkedColumns, onChange: onColumnsChange })}
              />
            </Col>

            <Col span={4}>
              <SearchBar value={searchValue} onChange={(e) => onSearch(e.target.value)} />
            </Col>
            <Col span={4}>
              <Input placeholder="utm_campaign" value={utmCampaign} onChange={(e) => onUtmCampaignChange(e.target.value)} />
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={openCreatePage}>
                <PlusOutlined />
                Create Campaign
              </Button>
            </Col>

            <Col span={9}>
              <Row justify="end">
                <Col style={{ alignSelf: 'center' }}>{liveCampaigns} live campaigns</Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Select
                placeholder="Select media buyer"
                mode="multiple"
                onChange={(value) => onFilter((oldFilters) => ({ ...oldFilters, creators: value }))}
                allowClear
                loading={usersLoading}
                style={{ width: '100%' }}
                optionFilterProp="data-searchvalue"
              >
                {generateUserOptions(users)}
              </Select>
            </Col>

            <Col span={4}>
              <VirtualizedSelect
                options={companiesService.generateOptions(companies)}
                onChange={(value) => onFilter((oldFilters) => ({ ...oldFilters, company: value }))}
                selectValue={filters?.company}
                placeholder={'Select company'}
                loading={companiesLoading}
              />
            </Col>

            <Col span={4}>
              <VirtualizedSelect
                options={identityService.generateOptions(identities)}
                onChange={(value) => onFilter((oldFilters) => ({ ...oldFilters, identity: value }))}
                selectValue={filters?.identity}
                placeholder={'Select identity'}
                loading={identitiesLoading}
              />
            </Col>

            <Col span={4} style={{ alignSelf: 'center' }}>
              <FilterSwitch
                label={
                  <div>
                    For suspend{' '}
                    <Tooltip title="Filter web push campaigns older than 15 days with less than 1000 impressions in last 15 days.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                // checked={false}
                onChange={(value) => onFilter((oldFilters) => ({ ...oldFilters, forSuspend: value }))}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col>
              <Button className={styles.headerActivateAction} disabled={!haveSelectedRows} onClick={confirmActivateCampaigns}>
                <CaretRightOutlined />
                Activate
              </Button>
            </Col>
            <Col>
              <Button className={styles.headerPauseAction} disabled={!haveSelectedRows} onClick={confirmPauseCampaigns}>
                <PauseOutlined />
                Pause
              </Button>
            </Col>
            <Col>
              <Button className={styles.headerPauseAction} disabled={!haveSelectedRows} onClick={confirmSuspendCampaigns}>
                <StopOutlined />
                Suspend
              </Button>
            </Col>
            <Col>
              <Button
                className={styles.headerCopyInfoAction}
                disabled={!haveSelectedRows}
                loading={loadingCampaignInfo}
                onClick={handleCopy}
              >
                <CopyOutlined />
                Copy Info
              </Button>
            </Col>
            <Col>
              <Button className={styles.headerDeleteAction} disabled={!haveSelectedRows} onClick={confirmDeleteCampaigns}>
                <DeleteOutlined />
                Delete
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};
