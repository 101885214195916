export const initialHtml = '<!-- HTML -->\n<h1>Landing Page Starter</h1>';

export const initialCss = `/* CSS */
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

h1 {
	font-weight: 100;
}

h3 {
	font-weight: 500;
}`;

export const initialJs = '// JavaScript';

export const initialHead = `<!-- Content injected in <head> tag -->
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width" />
<title></title>
`;
