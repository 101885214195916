import { callApi } from './api';
import { getQueryString } from '../utils/query';

export const DASH_RANGE_TYPE = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  THIS_DAY_LAST_MONTH: 'this_day_last_month'
};

class DashboardsService {
  async getSalesDashboard({ rangeType, isHeader }) {
    const query = getQueryString({ range_type: rangeType, is_header: isHeader });
    return await callApi({ url: `/api/sales_dashboards/${query}` });
  }

  async getAdminDashboard({ rangeType, isHeader }) {
    const query = getQueryString({ range_type: rangeType, is_header: isHeader });
    return await callApi({ url: `/api/admin_dashboards/${query}` });
  }
}

export const dashboardsService = new DashboardsService();
