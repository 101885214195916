import React from 'react';

export const Logo = () => {
  return (
    <div className="logo">
      <img src="/images/logo.svg" alt="Logo" />
      <span>Analytics 2.1</span>
    </div>
  );
};
