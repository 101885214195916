import React, { useMemo, useEffect, useState } from 'react';
import { Form, Input, Checkbox, Row, Col } from 'antd';
import { Select } from '../../../../components/Select';
import { Spinner } from '../../../../components/Spinner';

import { withPadlockIcon } from '../../utils/withPadlockIcon';
import { withCopyIcon } from '../../utils/withCopyIcon';
import { getDomainSelectOptions, getSelectOptionsWithIdValues } from '../../utils/options';

import { mediaCampaignsService } from '../../../../services/media-campaigns';

import { showApiErrors } from '../../../../utils/showApiErrors';

import { useEffectTrigger, useForm } from '../../../../utils/hooks';
import { countriesService } from '../../../../services/countries';
import { filterOptionsByLabelAndValue } from '../../../../utils/options';

const InputWithPadlockIcon = withPadlockIcon(Input);
const InputWithCopyIcon = withCopyIcon(Input);
const SelectWithPadlockIcon = withPadlockIcon(Select, 'suffixIcon');

export const Redirect = ({
  redirectForm: redirectAntdForm,
  redirectInitialData,
  magicCheckerForm: magicCheckerAntdForm,
  magicCheckerInitialData,
  loadingTabsData,
  isClone,
  onFormChange
}) => {
  const [useRedirect, setUseRedirect] = useState(false);
  const [redirectCampaignUrl, setRedirectCampaignUrl] = useState('');
  const [allRedirectDomains, setAllRedirectDomains] = useState([]);
  const [allRedirectDomainsLoading, setAllRedirectDomainsLoading] = useState(false);

  const [useMagicChecker, setUseMagicChecker] = useState(false);
  const [magicCheckerCampaignUrl, setMagicCheckerCampaignUrl] = useState('');
  const [allMagicCheckerDomains, setAllMagicCheckerDomains] = useState([]);
  const [allMagicCheckerDomainsLoading, setAllMagicCheckerDomainsLoading] = useState(false);
  const [allMagicCheckerBrowsers, setAllMagicCheckerBrowsers] = useState([]);
  const [allMagicCheckerBrowsersLoading, setAllMagicCheckerBrowsersLoading] = useState(false);
  const [allMagicCheckerDevices, setAllMagicCheckerDevices] = useState([]);
  const [allMagicCheckerDevicesLoading, setAllMagicCheckerDevicesLoading] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [allCountriesLoading, setAllCountriesLoading] = useState(false);
  const [wasMcCampaignCreated, setWasMcCampaignCreated] = useState(false);

  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();

  const loading = loadingTabsData;
  const isEdit = (redirectInitialData || magicCheckerInitialData) && !isClone;

  const [redirectForm] = useForm(activateFormChangedTrigger, redirectAntdForm);
  const [magicCheckerForm] = useForm(activateFormChangedTrigger, magicCheckerAntdForm);

  const redirectInitialValues = useMemo(() => {
    if (!redirectInitialData) {
      return undefined;
    }

    const { use_redirect, redirect_domain, uri, camp_redirect_url } = redirectInitialData;
    const useRedirect = use_redirect;
    const domain = redirect_domain || undefined;
    const redirectCampaignUrl = (use_redirect && isEdit && camp_redirect_url) || undefined;

    setRedirectCampaignUrl(redirectCampaignUrl);

    return {
      useRedirect,
      domain,
      uri,
      redirectCampaignUrl
    };
  }, [redirectInitialData, isEdit]);

  const magicCheckerInitialValues = useMemo(() => {
    if (!magicCheckerInitialData) {
      return { magicCheckerCampaignType: 'proxy' };
    }

    const {
      use_magic_checker,
      magic_checker_campaign_type,
      magic_checker_domain,
      camp_safe_page_url,
      safe_page_append_params,
      magic_countries,
      magic_browsers,
      magic_device_os,
      camp_promo_page_url,
      camp_magic_checker_url,
      magic_checker_external_id
    } = magicCheckerInitialData;
    const useMagicChecker = use_magic_checker;
    const magicCheckerCampaignType = magic_checker_campaign_type || undefined;
    const domain = magic_checker_domain || undefined;
    const safePageUrl = camp_safe_page_url;
    const safePageAppendParams = safe_page_append_params;
    const countries = (magic_countries || []).map((c) => c.toUpperCase());
    const browsers = magic_browsers || [];
    const devices = magic_device_os || [];
    const promoPageUrl = camp_promo_page_url;
    const magicCheckerCampaignUrl = (use_magic_checker && isEdit && camp_magic_checker_url) || undefined;

    setMagicCheckerCampaignUrl(magicCheckerCampaignUrl);
    setWasMcCampaignCreated(!!magic_checker_external_id);

    return {
      useMagicChecker,
      magicCheckerCampaignType,
      domain,
      safePageUrl,
      safePageAppendParams,
      countries,
      devices,
      browsers,
      promoPageUrl,
      magicCheckerCampaignUrl
    };
  }, [magicCheckerInitialData, isEdit]);

  useEffect(() => redirectForm.resetFields(), [redirectForm, redirectInitialValues]);

  useEffect(() => magicCheckerForm.resetFields(), [magicCheckerForm, magicCheckerInitialValues]);

  useEffect(() => {
    const { useRedirect } = redirectForm.getFieldsValue(true);
    setUseRedirect(useRedirect);
    const { useMagicChecker } = magicCheckerForm.getFieldsValue(true);
    setUseMagicChecker(useMagicChecker);
  }, [redirectForm, magicCheckerForm, formChangedTrigger]);

  useEffect(() => {
    redirectForm.validateFields();
  }, [redirectForm, useRedirect]);

  useEffect(() => {
    magicCheckerForm.validateFields();
  }, [magicCheckerForm, useMagicChecker]);

  useEffect(() => {
    if (!isEdit) {
      if (useRedirect) {
        redirectForm.setFieldsValue({ uri: '/{{6}}/' });
      } else {
        redirectForm.setFieldsValue({ uri: undefined });
      }
    }
  }, [redirectForm, isEdit, useRedirect]);

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      try {
        setAllRedirectDomainsLoading(true);
        const domains = await mediaCampaignsService.getDomains();

        if (!didCancel) {
          setAllRedirectDomains(domains);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllRedirectDomainsLoading(false);
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    let didCancel = false;

    const fetchMagicCheckerDomains = async () => {
      try {
        setAllMagicCheckerDomainsLoading(true);
        const domains = await mediaCampaignsService.getMagicCheckerDomains();

        if (!didCancel) {
          setAllMagicCheckerDomains(domains);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllMagicCheckerDomainsLoading(false);
      }
    };

    const fetchCountries = async () => {
      try {
        setAllCountriesLoading(true);
        const countries = await countriesService.getAll();

        if (!didCancel) {
          setAllCountries(countries);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllCountriesLoading(false);
      }
    };

    const fetchMagicCheckerBrowsers = async () => {
      try {
        setAllMagicCheckerBrowsersLoading(true);
        const browsers = await mediaCampaignsService.getMagicCheckerBrowsers();

        if (!didCancel) {
          setAllMagicCheckerBrowsers(browsers);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllMagicCheckerBrowsersLoading(false);
      }
    };

    const fetchMagicCheckerDevices = async () => {
      try {
        setAllMagicCheckerDevicesLoading(true);
        const devices = await mediaCampaignsService.getMagicCheckerDevices();

        if (!didCancel) {
          setAllMagicCheckerDevices(devices);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllMagicCheckerDevicesLoading(false);
      }
    };

    fetchMagicCheckerDomains();
    fetchCountries();
    fetchMagicCheckerBrowsers();
    fetchMagicCheckerDevices();
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <>
      <Form
        form={redirectForm}
        name="redirectForm"
        initialValues={redirectInitialValues}
        onValuesChange={() => {
          onFormChange(true);
          activateFormChangedTrigger();
        }}
        layout="vertical"
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item name="useRedirect" valuePropName="checked">
                  <Checkbox>Use Redirect</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={6}>
                <Form.Item
                  name="domain"
                  label="Domain"
                  rules={[
                    {
                      required: !!useRedirect,
                      message: 'Please select a domain!'
                    }
                  ]}
                >
                  <SelectWithPadlockIcon
                    placeholder="Domain"
                    disabled={!useRedirect}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                    isEdit={isEdit}
                    loading={allRedirectDomainsLoading}
                  >
                    {getDomainSelectOptions(allRedirectDomains)}
                  </SelectWithPadlockIcon>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="uri"
                  label="URI"
                  rules={[
                    {
                      required: !!useRedirect,
                      message: 'Please enter an URI!'
                    }
                  ]}
                >
                  <InputWithPadlockIcon placeholder="URI" disabled={!useRedirect} isEdit={isEdit} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="redirectCampaignUrl" label="Redirect Campaign URL">
                  <InputWithCopyIcon placeholder="Redirect Campaign URL" text={redirectCampaignUrl} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <Form
        form={magicCheckerForm}
        name="magicCheckerForm"
        initialValues={magicCheckerInitialValues}
        onValuesChange={() => {
          onFormChange(true);
          activateFormChangedTrigger();
        }}
        layout="vertical"
      >
        {!loading && (
          <>
            <Row gutter={32} style={{ marginTop: '30px' }}>
              <Col span={24}>
                <Form.Item name="useMagicChecker" valuePropName="checked">
                  <Checkbox>Use MagicChecker</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item
                  name="magicCheckerCampaignType"
                  label="MagicChecker Campaign Type"
                  rules={[
                    {
                      required: !!useMagicChecker,
                      message: 'Please select a campaign type!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Campaign Type"
                    disabled={!useMagicChecker || (isEdit && wasMcCampaignCreated)}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                  >
                    {getSelectOptionsWithIdValues([
                      { id: 'proxy', name: 'Proxy' },
                      { id: 'redirect', name: 'Redirect' }
                    ])}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="domain"
                  label="MagicChecker Domain"
                  rules={[
                    {
                      required: !!useMagicChecker,
                      message: 'Please select a domain!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Domain"
                    disabled={!useMagicChecker || (isEdit && wasMcCampaignCreated)}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                    loading={allMagicCheckerDomainsLoading}
                  >
                    {getSelectOptionsWithIdValues(allMagicCheckerDomains)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={16}>
                <Form.Item
                  name="safePageUrl"
                  label="Safe Page URL"
                  rules={[
                    {
                      required: !!useMagicChecker,
                      message: 'Please input a Safe Page URL!'
                    }
                  ]}
                >
                  <Input placeholder="Safe Page URL" disabled={!useMagicChecker}></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Append Params To Safe Page" name="safePageAppendParams" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item name="countries" label="Countries">
                  <Select
                    placeholder="Countries"
                    disabled={!useMagicChecker}
                    showSearch
                    allowClear
                    loading={allCountriesLoading}
                    options={countriesService.generateOptions(allCountries.filter(({ id }) => id !== 1))}
                    filterOption={filterOptionsByLabelAndValue}
                    mode="multiple"
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="devices" label="Devices">
                  <Select
                    placeholder="Devices"
                    disabled={!useMagicChecker}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                    loading={allMagicCheckerDevicesLoading}
                    mode="multiple"
                  >
                    {getSelectOptionsWithIdValues(allMagicCheckerDevices)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="browsers" label="Browsers">
                  <Select
                    placeholder="Browsers"
                    disabled={!useMagicChecker}
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                    loading={allMagicCheckerBrowsersLoading}
                    mode="multiple"
                  >
                    {getSelectOptionsWithIdValues(allMagicCheckerBrowsers)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={16}>
                <Form.Item name="magicCheckerCampaignUrl" label="MagicChecker Campaign URL">
                  <InputWithCopyIcon placeholder="MagicChecker Campaign URL" text={magicCheckerCampaignUrl} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
};
