import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { SearchBar } from '../../../components/SearchBar';
import { productsService } from '../../../services/products';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from '../GPSS.module.css';
import { AddOrEditRule } from './AddOrEditRule';
import { useMount } from 'react-use';
import { filterOptionsByLabelAndValue, generateCountriesOptions } from '../../../utils/options';

export const Header = ({ filters, searchValue, countries, countriesLoading, countriesRefetching, onFilter, onSearch, onAdd }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  useMount(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchValue = urlSearchParams.get('search');
    onSearch(searchValue);
  });

  useEffect(() => {
    (async () => {
      setProductsLoading(true);
      try {
        const product = await productsService.getAll();
        setProducts(product);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Row gutter={32} className={styles.paddingBetweenRows}>
        <Col span={6}>
          <Select
            key="product"
            placeholder={'Select product'}
            mode="multiple"
            style={{ width: '100%' }}
            options={productsService.generateOptions(products)}
            loading={productsLoading}
            value={filters.product}
            onChange={(value) => onFilter({ ...filters, product: value })}
            filterOption={filterOptionsByLabelAndValue}
            allowClear
            showSearch
          />
        </Col>
        <Col span={6}>
          <Select
            key="country"
            placeholder={'Select country'}
            mode="multiple"
            style={{ width: '100%' }}
            loading={countriesLoading}
            value={filters.country}
            onChange={(value) => onFilter({ ...filters, country: value })}
            optionFilterProp="data-searchvalue"
            allowClear
            showSearch
          >
            {generateCountriesOptions(countries, true)}
          </Select>
        </Col>
        <Col span={6}>
          <SearchBar value={searchValue} onChange={(e) => onSearch(e.target.value)} />
        </Col>
        <Col className={styles.btnCol} span={4}>
          <Button icon={<PlusOutlined />} className="add-price-btn" type="primary" onClick={handleOpenModal}>
            Add New Rule
          </Button>
        </Col>
      </Row>
      <AddOrEditRule
        modalVisible={modalVisible}
        countries={countries}
        countriesLoading={countriesLoading}
        countriesRefetching={countriesRefetching}
        onClose={handleCloseModal}
        onAdd={() => {
          onAdd();
          handleCloseModal();
        }}
      />
    </>
  );
};
