import React from 'react';
import styles from './Block.module.css';
import { Skeleton } from 'antd';
export const Block = ({ currentValue, compareValue, label, color, loading }) => {
  return (
    <>
      <span style={{ color: color }} className={styles.mainTxt}>
        {loading ? (
          <Skeleton.Avatar shape={'circle'} active={true} block={false} size="small" />
        ) : (
          `${currentValue}  ${compareValue !== undefined ? `/ ${compareValue}` : ''}`
        )}
      </span>
      <span className={styles.subTxt}>{label}</span>
    </>
  );
};
