import React from 'react';
import { useDrag } from 'react-dnd';

import { ACCEPTS_ALL } from '../../../services/urgentPanel';

export const SideBarItem = (props) => {
  const { title, ...item } = { ...props };
  const [{ opacity }, drag] = useDrag({
    type: ACCEPTS_ALL,
    item: item,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} style={{ opacity }}>
      {title}
    </div>
  );
};
