import React from 'react';
import { Tooltip } from 'antd';
export const generateApiKey = () => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let count = Math.floor(Math.random() * (25 - 20 + 1) + 20);
  for (var i = 0; i < count; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const tooltipLabel = (label, info) => {
  return (
    <Tooltip title={info}>
      <span>{label}</span>
    </Tooltip>
  );
};
