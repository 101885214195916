export const linkTimeToLiveOptions = [
  { label: '1 min', value: '60' },
  { label: '15 min', value: '900' },
  { label: '30 min', value: '1800' },
  { label: '1 h', value: '3600' },
  { label: '3 h', value: '10800' },
  { label: '6 h', value: '21600' },
  { label: '12 h', value: '43200' },
  { label: '1 day', value: '86400' },
  { label: '3 days', value: '259200' },
  { label: '7 days', value: '604800' },
  { label: '30 days', value: '2592000' },
  { label: '90 days', value: '7776000' },
  { label: '180 days', value: '15552000' },
  { label: '1 year', value: '31536000' },
  { label: '10 years', value: '315360000' }
];
