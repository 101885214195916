import { Button, Checkbox, Form, Input, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ImageUploader from 'react-images-upload';

import { Select } from '../../../components/Select';
import { listsService } from '../../../services/lists';
import { confirmClose } from '../../../utils/confirmClose';
import { useBeforeUnload } from '../../../utils/hooks/useBeforeUnload';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from '../Lists.module.css';
import { getAccountsSelectOptions } from '../utils/options';

export const AddOrEditListModal = ({
  visibleModal,
  allAccounts,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  list,
  listType,
  itemsFieldName,
  itemsFieldNameRead,
  title,
  nameFieldTitle,
  renderItemsSelect,
  isClone,
  areAccountsLoading
}) => {
  const [loading, setLoading] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);
  const [imageData, setImageData] = useState({
    image: null,
    imageChanged: false
  });

  const [form] = Form.useForm();

  const [wasFormChanged, setWasFormChanged] = useState(false);
  const initialValues = useMemo(() => {
    if (!list) {
      return undefined;
    }

    const name = isClone ? list.name + ' - clone' : list.name;
    const chosenItems = itemsFieldNameRead ? list[itemsFieldNameRead] : list[itemsFieldName];
    const exclude = list.exclude;
    const assigned = list.assigned;
    const privateField = list.private;

    return { name, [itemsFieldName]: chosenItems, exclude, assigned, privateField };
  }, [list, itemsFieldName, itemsFieldNameRead, isClone]);

  const resetFields = useCallback(() => {
    form.resetFields();
    setImageRemoved(false);
    setImageData({
      image: null,
      imageChanged: false
    });
    setWasFormChanged(false);
  }, [form]);

  useBeforeUnload(wasFormChanged);

  useEffect(() => {
    resetFields();
  }, [resetFields, visibleModal]);

  const handleImageDrop = (image) => {
    setImageData({
      image: image[0],
      imageChanged: true
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    const { name, exclude, privateField, assigned } = values;
    const { image, imageChanged } = imageData;

    formData.append('name', name);

    formData.append('exclude', !!exclude);

    formData.append('private', !!privateField);

    if (itemsFieldNameRead) {
      const chosenItems = values[itemsFieldName].join('\r\n');
      formData.append(itemsFieldName, chosenItems);
    } else {
      values[itemsFieldName].forEach((value) => {
        formData.append(itemsFieldName, value);
      });
    }

    if (assigned) {
      assigned.forEach((id) => {
        formData.append('assigned', id);
      });
    }

    if (isClone && !imageChanged && !imageRemoved && list.img) {
      const image = await listsService.downloadImage(list.img);
      formData.append('img', image, 'clone_img');
    } else if (imageChanged || imageRemoved) {
      if (image && image.length !== 0) {
        formData.append('img', image, image.name);
      } else {
        formData.append('img', '');
      }
    }

    if (list && !isClone) {
      try {
        await listsService.editList(list.id, formData, listType);
        onCloseModal();
        openSuccessNotification({ message: 'List successfully edited!' });
        setWasFormChanged(false);
        await onEdit();
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const { id } = await listsService.addList(formData, listType);
        onCloseModal();
        openSuccessNotification({ message: `List successfully ${isClone ? 'cloned' : 'created'}!` });
        isClone ? await onClone(id) : await onAdd(id);
        setWasFormChanged(false);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const type = list && isClone ? 'Clone' : list ? 'Edit' : 'Add';

  const handleClose = () => {
    wasFormChanged ? confirmClose(() => onCloseModal()) : onCloseModal();
  };

  return (
    <Modal
      title={type + ` ${title}`}
      visible={visibleModal}
      wrapClassName={styles.addOrEditListModal}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {(list && isClone ? 'Clone' : list ? 'Edit' : 'Save') + ' List'}
        </Button>
      ]}
      width={600}
    >
      <div className={styles.modalFormWrapper}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={(values) => {
            onSubmit(values);
          }}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
          onValuesChange={() => setWasFormChanged(true)}
        >
          <div className={styles.nameAndCheckboxFieldsWrapper}>
            <div className={`${styles.widthModal50}`}>
              <span>{nameFieldTitle}</span>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter list name!'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className={`${styles.checkboxFiledsWrapper} ${styles.widthModal50}`}>
              <div>
                <Form.Item name="privateField" valuePropName="checked">
                  <Checkbox>Private</Checkbox>
                </Form.Item>
              </div>

              <div>
                <Form.Item name="exclude" valuePropName="checked">
                  <Checkbox>Exclude</Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className={styles.marginBotModal}>
            <span>Assigned</span> <br />
            <Form.Item name="assigned">
              <Select mode="multiple" allowClear optionFilterProp="data-searchvalue" loading={areAccountsLoading}>
                {getAccountsSelectOptions(allAccounts)}
              </Select>
            </Form.Item>
          </div>
          {renderItemsSelect(form)}
          {!itemsFieldNameRead ? (
            <div>
              <ImageUploader
                className={styles.imageUploaderModal}
                buttonText="Choose image"
                imgExtension={['.jpg', '.jpeg', '.png']}
                singleImage
                withLabel={false}
                onChange={handleImageDrop}
              />

              {imageData.image ? (
                <div className={styles.imagePreviewModal}>
                  <img src={URL.createObjectURL(imageData.image)} alt="Preview" />
                </div>
              ) : list && list.img && !imageRemoved ? (
                <div className={styles.imagePreviewModal}>
                  <img src={list.img} alt="Preview" />
                </div>
              ) : null}

              {imageData.image || (list && list.img && !imageRemoved) ? (
                <div
                  className={styles.removeImageButton}
                  onClick={() => {
                    setImageRemoved(true);
                    setImageData({
                      image: null,
                      imageChanged: false
                    });
                  }}
                >
                  <img src="/images/delete_icon.png" alt="Remove" width={30} style={{ cursor: 'pointer' }} />
                </div>
              ) : null}
            </div>
          ) : null}
        </Form>
      </div>
    </Modal>
  );
};
