import React from 'react';

import { AddOrEditSubidListModal } from './AddOrEditSubidListModal';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { ListTypes, ListTypesPlural } from '../../../../services/lists';

export const ActionsTableCol = ({ allAccounts, subidList, onClone, onEdit, onDelete, allAccountsLoading }) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.subid_lists}
      contentForCopy={subidList[ListTypesPlural.subid_lists]}
      allAccounts={allAccounts}
      list={subidList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => (
        <AddOrEditSubidListModal {...commonProps} subidList={subidList} allAccountsLoading={allAccountsLoading} />
      )}
    />
  );
};
