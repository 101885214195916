import React from 'react';

import { AddOrEditPriceListModal } from './AddOrEditPriceListModal';
import { ActionsTableColCommon } from '../ActionsTableColCommon';
import { ListTypes } from '../../../../services/lists';

export const ActionsTableCol = ({
  allCountries,
  allAccounts,
  allDeviceTypes,
  allOSs,
  allBrowsers,
  priceList,
  onClone,
  onEdit,
  onDelete,
  loader
}) => {
  return (
    <ActionsTableColCommon
      listType={ListTypes.price_lists}
      allAccounts={allAccounts}
      list={priceList}
      onClone={onClone}
      onEdit={onEdit}
      onDelete={onDelete}
      renderModal={(commonProps) => (
        <AddOrEditPriceListModal
          {...commonProps}
          allCountries={allCountries}
          allDeviceTypes={allDeviceTypes}
          list={priceList}
          loader={loader}
          allBrowsers={allBrowsers}
          allOSs={allOSs}
        />
      )}
    />
  );
};
