import React from 'react';

import { ActionsTableCol } from './ActionsTableCol';
import { TableCommon } from '../TableCommon';

export const Table = ({
  allAccounts,
  ipAddressLists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  allAccountsLoading,
  sorting,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const renderSelectedItems = ({ ips_json }) => {
    const ipsToShow = ips_json.slice(0, 5).toString().split(',').join(', '); // showing first 5 ips only
    return ips_json.length > 5 ? `${ipsToShow}, ...` : ipsToShow;
  };

  const renderActions = (record) => {
    return (
      <ActionsTableCol
        allAccounts={allAccounts}
        ipAddressList={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        allAccountsLoading={allAccountsLoading}
      />
    );
  };

  return (
    <TableCommon
      lists={ipAddressLists}
      loading={loading}
      totalRows={totalRows}
      currentPage={currentPage}
      pageSize={pageSize}
      itemsTitle="List of IP Addresses"
      sorting={sorting}
      onPaginationChange={onPaginationChange}
      renderSelectedItems={renderSelectedItems}
      renderActions={renderActions}
      onSortingClick={onSortingClick}
    />
  );
};
