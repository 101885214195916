import React from 'react';

import { ListTypes } from '../../../../services/lists';
import { AddOrEditListModal } from '../AddOrEditListModal';
import { MultipleItemsSelect } from '../MultipleItemsSelect';

export const AddOrEditOSListModal = ({
  visibleModal,
  allOSs,
  allAccounts,
  osList,
  isClone,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  loader
}) => {
  const fieldName = 'operating_systems';

  const renderItemsSelect = () => (
    <MultipleItemsSelect loading={loader.allOSs} title="List of OSs" name={fieldName} message="Please choose OS/s!" allItems={allOSs} />
  );

  return (
    <AddOrEditListModal
      visibleModal={visibleModal}
      allAccounts={allAccounts}
      onCloseModal={onCloseModal}
      onClone={onClone}
      onEdit={onEdit}
      onAdd={onAdd}
      list={osList}
      listType={ListTypes.os_lists}
      itemsFieldName={fieldName}
      title="OS List"
      nameFieldTitle="OS List Name"
      renderItemsSelect={renderItemsSelect}
      isClone={isClone}
      areAccountsLoading={loader.allAccounts}
    />
  );
};
