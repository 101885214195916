import React from 'react';

import { ActionsTableCol } from './ActionsTableCol';
import { TableCommon } from '../TableCommon';

export const Table = ({
  targetingLists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  listType,
  sorting,
  onClone,
  onEdit,
  onDelete,
  onPaginationChange,
  onSortingClick
}) => {
  const renderSelectedItems = ({ targeting_patterns_details }) => targeting_patterns_details.length;

  const renderActions = (record) => {
    return <ActionsTableCol targetingList={record} listType={listType} onClone={onClone} onEdit={onEdit} onDelete={onDelete} />;
  };

  return (
    <TableCommon
      lists={targetingLists}
      loading={loading}
      totalRows={totalRows}
      currentPage={currentPage}
      pageSize={pageSize}
      itemsTitle="Number of Patterns"
      sorting={sorting}
      onPaginationChange={onPaginationChange}
      renderSelectedItems={renderSelectedItems}
      renderActions={renderActions}
      onSortingClick={onSortingClick}
    />
  );
};
