import React from 'react';

import { ActionsTableCol } from './ActionsTableCol';
import { TableCommon } from '../TableCommon';

export const Table = ({
  allBrowsers,
  allAccounts,
  browserLists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  loader,
  sorting,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const renderSelectedItems = ({ browsers_list }) => browsers_list;

  const renderActions = (record) => {
    return (
      <ActionsTableCol
        allBrowsers={allBrowsers}
        allAccounts={allAccounts}
        browserList={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        loader={loader}
      />
    );
  };

  return (
    <TableCommon
      lists={browserLists}
      loading={loading}
      totalRows={totalRows}
      currentPage={currentPage}
      pageSize={pageSize}
      itemsTitle="List of Browsers"
      sorting={sorting}
      onPaginationChange={onPaginationChange}
      renderSelectedItems={renderSelectedItems}
      renderActions={renderActions}
      onSortingClick={onSortingClick}
    />
  );
};
