import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Form, Input, Row, Table, Tabs } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { Spinner } from '../../../components/Spinner';
import { companiesService } from '../../../services/companies';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from './CompanyDiary.module.css';

const { Panel } = Collapse;
const { TextArea } = Input;

export const CompanyDiary = ({ companyId, onAction }) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const [form] = Form.useForm();

  useEffect(() => {
    if (companyId) {
      (async () => {
        try {
          setLoading(true);
          const companyDiary = await companiesService.getCompanyDiary({ id: companyId });
          setData(companyDiary);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [companyId]);

  useEffect(() => {
    form.resetFields();
  }, [form, data]);

  const handleTabChange = (key) => {
    wasFormChanged
      ? confirmClose(() => {
          setCurrentActiveTab(key);
          setWasFormChanged(false);
          form.resetFields();
        })
      : setCurrentActiveTab(key);
  };

  const handleSubmit = async (year, quartal) => {
    const generalInfoQuestions = form.getFieldValue([year, 'generalInfo', quartal]);
    const detailInfoQuestions = form.getFieldValue([year, 'detailInfo', quartal]);
    const diaryId = form.getFieldValue([year, quartal, 'diaryId']);
    const diary = {
      general_info: generalInfoQuestions.map((el) => ({ question: el.question, answer: el.answer })),
      detail_info: detailInfoQuestions.map((el) => ({ question: el.question, answer: el.answer }))
    };
    try {
      await companiesService.updateCompanyDiary({ id: diaryId, data: diary });
      openSuccessNotification({ message: `Succesfully saved year: ${year}, quartal ${quartal}.` });
      onAction();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const mergedDataWithSameQuartal = useMemo(() => {
    const formatedData = {};

    const compare = (a, b) => {
      if (a.year > b.year) {
        return -1;
      }
      if (a.year < b.year) {
        return 1;
      }
      return 0;
    };

    if (data) {
      data.sort(compare).forEach((element) => {
        let generalInfo = undefined;
        let detailInfo = undefined;
        let stats = undefined;
        try {
          generalInfo = JSON.parse(element.general_info);
        } catch {
          generalInfo = element.general_info;
        }
        try {
          detailInfo = JSON.parse(element.detail_info);
        } catch {
          detailInfo = element.detail_info;
        }
        try {
          stats = JSON.parse(element.stats);
        } catch {
          stats = element.stats;
        }

        formatedData[element.year] = {
          ...(formatedData[element.year] || {}),
          [element.quarter]: {
            generalInfo: generalInfo,
            detailInfo: detailInfo,
            stats: stats,
            diaryId: element.id,
            editor: element.editor_details ? `${element.editor_details.first_name} ${element.editor_details.last_name}` : 'No editor'
          }
        };
      });
    }
    return formatedData;
  }, [data]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderStats = (stats) => {
    const columns = [
      {
        title: 'Month',
        dataIndex: 'month',
        key: 'month'
      },
      {
        title: 'Spent',
        dataIndex: 'spent',
        key: 'spent'
      },
      {
        title: 'Earned',
        dataIndex: 'earned',
        key: 'earned'
      },
      {
        title: 'Profit',
        dataIndex: 'profit',
        key: 'profit'
      },
      {
        title: 'ROI',
        dataIndex: 'roi',
        key: 'roi'
      }
    ];
    const statsArray = [];
    Object.entries(stats || {}).forEach(([month, object], index) => {
      statsArray.push({ month: capitalizeFirstLetter(month), index, ...object });
    });

    return <Table columns={columns} dataSource={statsArray} rowKey={({ index }) => index} pagination={false} className={styles.table} />;
  };

  const renderQuestion = (year, keyName, quartal, question, index) => {
    return (
      <div className={styles.question} key={index}>
        <Form.Item name={[year, keyName, quartal, index, 'question']} initialValue={question.question}>
          <h4>{question.question}</h4>
        </Form.Item>
        <Form.Item name={[year, keyName, quartal, index, 'answer']} initialValue={question.answer}>
          <TextArea />
        </Form.Item>
      </div>
    );
  };

  const renderQuestions = (year, keyName, quartal, questions) =>
    questions.map((question, index) => renderQuestion(year, keyName, quartal, question, index));

  const renderQuartal = (year, quartal, infoObjects) => (
    <Row gutter={[16, 16]} key={quartal}>
      <Form.Item name={[year, quartal, 'diaryId']} hidden={true} initialValue={infoObjects.diaryId}>
        <Input />
      </Form.Item>
      <Col span={12}>
        <Card className={styles.card} title="General Info">
          {renderQuestions(year, 'generalInfo', quartal, infoObjects.generalInfo)}
        </Card>
      </Col>
      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card className={styles.card} title="Overview">
              {renderStats(infoObjects.stats)}
            </Card>
          </Col>
          <Col span={24}>
            <Card className={styles.card} title="Upcoming Activities">
              {renderQuestions(year, 'detailInfo', quartal, infoObjects.detailInfo)}
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const renderYear = (year, quartalObject) => (
    <Panel header={year} key={year} className={styles.panel}>
      <Tabs
        onTabClick={(key) => {
          handleTabChange(key);
        }}
        activeKey={currentActiveTab}
      >
        {Object.entries(quartalObject).map(([quartal, infoObjects]) => {
          return (
            <Tabs.TabPane tab={`Quartal ${quartal}`} key={quartal} forceRender={true}>
              {renderQuartal(year, quartal, infoObjects)}
              <Col span={24}>
                <Row style={{ justifyContent: 'space-beetween', paddingTop: '20px' }}>
                  <Col span={12}>
                    <h4>Last edited by: {infoObjects.editor}</h4>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      size="large"
                      icon={<SaveOutlined />}
                      onClick={() => handleSubmit(year, quartal)}
                      className={styles.buttonLeft}
                    >
                      Save Q{quartal}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </Panel>
  );

  const renderDiary = () => {
    if (loading) {
      return <Spinner />;
    }
    if (!data) {
      return <>Something went wrong.</>;
    }
    // `mergedDataWithSameQuartal` has years as keys and Object as value. This Object has 4 keys (Q1, Q2, Q3, Q4)
    //  value of each has 3 keys (2 infos and 1 stats) infos is array of objects with questions and answers
    return (
      <Form
        form={form}
        onFinish={(e) => {
          handleSubmit(e);
        }}
        onFieldsChange={() => {
          setWasFormChanged(true);
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Collapse defaultActiveKey={[moment().year()]} className={styles.years}>
              {Object.entries(mergedDataWithSameQuartal).map(([year, quartalObject]) => renderYear(year, quartalObject))}
            </Collapse>
          </Col>
        </Row>
      </Form>
    );
  };

  return renderDiary();
};
