import React, { useMemo, useEffect, useState } from 'react';
import { useEffectTrigger, useForm } from '../../../utils/hooks';
import { accountsService } from '../../../services/accounts';
import { showApiErrors } from '../../../utils/showApiErrors';
import { Button, Form, Input, InputNumber, Popover, Select } from 'antd';
import { Spinner } from '../../../components/Spinner';
import { OnOffSwitch } from '../../../components/OnOffSwitch';
import { generateUserOptions } from '../../../utils/options';
import { PlusOutlined } from '@ant-design/icons';
import { productsService } from '../../../services/products';
import styles from './ProductRules.module.css';
import dayjs from 'dayjs';

const RuleMetric = {
  Revenue: 'revenue',
  Spent: 'spent',
  Leads: 'leads',
  Impressions: 'impressions',
  Profit: 'profit'
};

export const ProductRules = ({
  form,
  loadingTabsData,
  rulesNotifiedAt,
  productStartRuleMetrics,
  productStartSwitches,
  onProductStartSwitchesChanged,
  onProductStartRuleMetricsChanged
}) => {
  const [allRuleMetrics, setAllRuleMetrics] = useState([]);
  const [allRuleMetricsLoading, setAllRuleMetricsLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  const [allUsersLoading, setAllUsersLoading] = useState(false);

  const loading = loadingTabsData;

  useEffect(() => form.resetFields(), [form]);

  useEffect(() => {
    let didCancel = false;

    const fetchRuleMetrics = async () => {
      try {
        setAllRuleMetricsLoading(true);
        const ruleMetrics = await productsService.getRuleMetrics();

        if (!didCancel) {
          setAllRuleMetrics(ruleMetrics);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllRuleMetricsLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        setAllUsersLoading(true);
        const users = await accountsService.getAll();
        if (!didCancel) {
          setAllUsers(users);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllUsersLoading(false);
      }
    };

    fetchRuleMetrics();
    fetchUsers();

    return () => {
      didCancel = true;
    };
  }, []);

  const { Option } = Select;
  const getSelectOptionsWithIdValues = (options) => {
    return options.map((option) => {
      const { id, name, title, channel } = option;
      const optionText = name ? name : title ? title : channel;

      return (
        <Option key={id} value={id} data-searchvalue={optionText + id}>
          {optionText}
        </Option>
      );
    });
  };

  const updateProductStartProperties = () => {
    const rulesData = [...form.getFieldValue(['productStartFormList'])];
    let tempStartSwitches = {};
    let tempRuleMetrics = {};

    rulesData.forEach((elem, index) => {
      if (elem) {
        const { productStartSwitch, productStartRuleMetric } = elem;

        tempStartSwitches[index] = productStartSwitch;
        tempRuleMetrics[index] = productStartRuleMetric;
      }
    });
    onProductStartSwitchesChanged(tempStartSwitches);
    onProductStartRuleMetricsChanged(tempRuleMetrics);
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.formRemoveBottomMargin}>
          <div className={styles.ruleHeading}>
            <div className={styles.ruleHeadingText}>
              Product Start <span>- Don’t miss product start.</span>
            </div>
          </div>
          <Form.List name="productStartFormList" form={form} shouldUpdate>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key} className={styles.ruleDynamicFieldsWrapper}>
                      <div style={{ display: 'none' }}>
                        <Form.Item {...field} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']}>
                          <Input />
                        </Form.Item>
                      </div>

                      {rulesNotifiedAt[index] ? (
                        <>
                          <div className={styles.minContentWidth}>{dayjs(rulesNotifiedAt[index]).format('DD.MM.YYYY.\nHH:mm:ss')}</div>
                          <div>-</div>
                        </>
                      ) : (
                        <div className={styles.ruleStartSwitch}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'productStartSwitch']}
                            fieldKey={[field.fieldKey, 'productStartSwitch']}
                            valuePropName="checked"
                          >
                            <OnOffSwitch
                              onChange={(value) => {
                                const tempProductStartSwitches = { ...productStartSwitches };
                                tempProductStartSwitches[index] = value;
                                onProductStartSwitchesChanged(tempProductStartSwitches);
                              }}
                            />
                          </Form.Item>
                        </div>
                      )}

                      <div className={styles.ruleAlertTextContent}>Alert</div>

                      <div className={`${styles.formItemWrapper3} ${styles.noMarginField}`}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'assignedUsers']}
                          fieldKey={[field.fieldKey, 'assignedUsers']}
                          rules={[
                            {
                              required: productStartSwitches[index] && !rulesNotifiedAt[index],
                              message: 'Please select at least one user!'
                            }
                          ]}
                        >
                          <Select
                            placeholder="Select at least one user"
                            optionFilterProp="data-searchvalue"
                            disabled={!productStartSwitches[index] || rulesNotifiedAt[index]}
                            showSearch
                            loading={allUsersLoading}
                            mode="multiple"
                          >
                            {generateUserOptions(allUsers)}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className={styles.ruleTextContent}>when product</div>

                      <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'productStartRuleMetric']}
                          fieldKey={[field.fieldKey, 'productStartRuleMetric']}
                          rules={[
                            {
                              required: productStartSwitches[index] && !rulesNotifiedAt[index],
                              message: 'Please select a rule metric!'
                            }
                          ]}
                        >
                          <Select
                            onChange={(value) => {
                              const tempProductRuleMetrics = { ...productStartRuleMetrics };
                              tempProductRuleMetrics[index] = value;
                              onProductStartRuleMetricsChanged(tempProductRuleMetrics);
                            }}
                            placeholder="Select one option"
                            optionFilterProp="data-searchvalue"
                            disabled={!productStartSwitches[index] || rulesNotifiedAt[index]}
                            showSearch
                            loading={allRuleMetricsLoading}
                          >
                            {getSelectOptionsWithIdValues(allRuleMetrics)}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className={styles.ruleTextContent}>is more than</div>

                      <div className={`${styles.ruleNumberInput} ${styles.noMarginField}`}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'productStartNumber']}
                          fieldKey={[field.fieldKey, 'productStartNumber']}
                          rules={[
                            {
                              required: productStartSwitches[index] && !rulesNotifiedAt[index],
                              message: 'Please enter a number!'
                            }
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter a number"
                            min={productStartRuleMetrics[index] === RuleMetric.Profit ? -Infinity : 1}
                            step={1}
                            disabled={!productStartSwitches[index] || rulesNotifiedAt[index]}
                          />
                        </Form.Item>
                      </div>

                      <div className={styles.switchWithImg}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'productStartSlackSwitch']}
                          fieldKey={[field.fieldKey, 'productStartSlackSwitch']}
                          valuePropName="checked"
                        >
                          <OnOffSwitch disabled={!productStartSwitches[index] || rulesNotifiedAt[index]} />
                        </Form.Item>

                        <div>
                          <img src="/images/slack_logo.png" alt="Slack logo" height="34px" />
                        </div>
                      </div>

                      <div className={styles.switchWithImg}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'productStartGmailSwitch']}
                          fieldKey={[field.fieldKey, 'productStartGmailSwitch']}
                          valuePropName="checked"
                        >
                          <OnOffSwitch disabled={!productStartSwitches[index] || rulesNotifiedAt[index]} />
                        </Form.Item>

                        <div>
                          <img src="/images/gmail_logo.png" alt="Gmail logo" height="34px" />
                        </div>
                      </div>

                      <div className={styles.actionsWrapper}>
                        <Popover content="Clone" trigger="hover">
                          <div className={styles.action}>
                            <img
                              src="/images/copy.png"
                              alt="Clone"
                              width="19px"
                              onClick={() => {
                                add({
                                  ...form.getFieldValue(['productStartFormList', index]),
                                  id: undefined,
                                  productStartNumber: undefined
                                });
                                updateProductStartProperties();
                              }}
                            />
                          </div>
                        </Popover>
                        {!rulesNotifiedAt[index] && (
                          <Popover content="Delete" trigger="hover">
                            <div
                              className={styles.action}
                              onClick={() => {
                                remove(field.name);
                                updateProductStartProperties();
                              }}
                            >
                              <img src="/images/delete_icon.png" alt="Delete" width="21px" />
                            </div>
                          </Popover>
                        )}
                      </div>
                    </div>
                  ))}

                  <div className={styles.ruleAddDynamicFields}>
                    <Form.Item>
                      <Button ghost type="primary" onClick={() => add()} block>
                        <PlusOutlined /> Add new Alert
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      )}
    </div>
  );
};
