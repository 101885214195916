import { callApi } from './api';
import { getQueryString } from '../utils/query';

export const s2sMacros = [
  {
    value: '{CONVERSION_ID}',
    desc: ''
  },
  {
    value: '{SOURCE}',
    desc: ''
  },
  {
    value: '{COUNTRY_CODE}',
    desc: ''
  },
  {
    value: '{LEAD_PRICE}',
    desc: ''
  },
  {
    value: '{COINIS_CLICK_ID}',
    desc: ''
  },
  {
    value: '{PIXEL_TIMESTAMP}',
    desc: ''
  },
  {
    value: '{OS}',
    desc: ''
  },
  {
    value: '{OS_VERSION}',
    desc: ''
  },
  {
    value: '{BROWSER}',
    desc: ''
  },
  {
    value: '{BROWSER_VERSION}',
    desc: ''
  },
  {
    value: '{DEVICE_TYPE}',
    desc: ''
  },
  {
    value: '{SOURCE_1}',
    desc: ''
  },
  {
    value: '{SOURCE_2}',
    desc: ''
  },
  {
    value: '{SOURCE_3}',
    desc: ''
  },
  {
    value: '{SOURCE_4}',
    desc: ''
  },
  {
    value: '{SOURCE_5}',
    desc: ''
  },
  {
    value: '{SOURCE_6}',
    desc: ''
  },
  {
    value: '{SOURCE_7}',
    desc: ''
  },
  {
    value: '{SOURCE_8}',
    desc: ''
  },
  {
    value: '{SOURCE_9}',
    desc: ''
  },
  {
    value: '{HOST}',
    desc: ''
  },
  {
    value: '{REMOTE_ADDR}',
    desc: ''
  },
  {
    value: '{IM_REMOTE_ADDR}',
    desc: ''
  },
  {
    value: '{IM_TIMESTAMP}',
    desc: ''
  },
  {
    value: '{IM_USER_AGENT}',
    desc: ''
  }
];

class S2SService {
  async getExportUrl({ filters }) {
    const query = getQueryString({
      start_date: filters.dateRange.from.format('YYYY-MM-DD'),
      end_date: filters.dateRange.to.format('YYYY-MM-DD'),
      campaigns: filters.campaigns && filters.campaigns.join(','),
      companies: filters.companies && filters.companies.join(','),
      fired_pixel_type: filters.pixel || null
    });
    return `/api/channels/export/s2s_log/${query}`;
  }

  async runS2STest(id, data) {
    return await callApi({ url: `/api/media_campaigns/${id}/s2s_test/`, method: 'POST', data });
  }
}
export const s2sService = new S2SService();
