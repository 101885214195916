import React, { useState } from 'react';
import { LockTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const withPadlockIcon =
  (WrappedItem, suffixPropName = 'suffix') =>
  (props) => {
    const [disabledLocal, setDisabledLocal] = useState(true);
    const { disabled, isEdit, ...itemProps } = props;

    const displayIcon = isEdit && (disabled || disabledLocal);
    const disabledItem = disabled || (isEdit && disabledLocal);

    const handleIconClick = () => {
      if (!disabled) {
        setDisabledLocal(false);
      }
    };

    const suffixProp = {
      [suffixPropName]: (
        <Tooltip title="Unlock to Edit">
          <LockTwoTone style={{ display: displayIcon ? 'default' : 'none' }} onClick={handleIconClick} />
        </Tooltip>
      )
    };

    return <WrappedItem {...itemProps} {...suffixProp} disabled={disabledItem} />;
  };
