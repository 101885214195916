import { openErrorNotification } from './notifications';

export const isAny = (val) => {
  return val === 1;
};

export const calcWeight = (val) => {
  return val === 1 ? 0 : 1; // NOTE: Any is weight 0 else is 1
};

const hasSameBrowser = (a, b) => {
  return a.browser === b.browser;
};

const hasSameCountry = (a, b) => {
  return a.country === b.country;
};

const hasSameOS = (a, b) => {
  return a.os === b.os;
};

const hasSameDt = (a, b) => {
  return a.dt === b.dt;
};

const calcWeightOfRow = (row) => {
  return calcWeight(row.os) + calcWeight(row.browser) + calcWeight(row.country) + calcWeight(row.dt);
};

const hasSameWeight = (row1, row2) => {
  const row1Weight = calcWeightOfRow(row1);
  const row2Weight = calcWeightOfRow(row2);
  return row1Weight === row2Weight;
};

const neitherIsAny = (a, b) => {
  return !isAny(a) && !isAny(b);
};
const elementsAreSameOrOneIsAny = (row1, row2) => {
  if (neitherIsAny(row1.os, row2.os)) {
    if (!hasSameOS(row1, row2)) {
      return false;
    }
  }
  if (neitherIsAny(row1.browser, row2.browser)) {
    if (!hasSameBrowser(row1, row2)) {
      return false;
    }
  }
  if (neitherIsAny(row1.dt, row2.dt)) {
    if (!hasSameDt(row1, row2)) {
      return false;
    }
  }
  if (neitherIsAny(row1.country, row2.country)) {
    if (!hasSameCountry(row1, row2)) {
      return false;
    }
  }

  return true;
};

const areTheyDuplicates = (row1, row2) => {
  return row1.country === row2.country && row1.os === row2.os && row1.browser === row2.browser && row1.dt === row2.dt;
};

const checkForConflict = (row1, row2) => {
  // NOTE: To have conflict rows must be same weight, and elements must be same or one must be Any. Exactly same rows are handled before call to this function
  const sameWeight = hasSameWeight(row1, row2);
  const elementsWithoutAnyAreSame = elementsAreSameOrOneIsAny(row1, row2);

  return sameWeight && elementsWithoutAnyAreSame;
};

export const priceListValidator = (list) => {
  const osBrowserList = [];
  list.forEach((el) => {
    osBrowserList.push({ os: el.os, browser: el.browser, dt: el.device_type, country: el.country });
  });
  for (let i = 0; i < osBrowserList.length - 1; i++) {
    let row1 = osBrowserList[i];
    for (let j = i + 1; j < osBrowserList.length; j++) {
      let row2 = osBrowserList[j];
      if (areTheyDuplicates(row1, row2)) {
        openErrorNotification({ message: 'There are duplicates' });
        return false;
      }
      if (checkForConflict(row1, row2)) {
        const isThereCandidateToSolveConflict = osBrowserList.find((el, index) => {
          if (index !== i && index !== j && calcWeightOfRow(el) > calcWeightOfRow(row1)) {
            // NOTE: Conflict solver should be row which has same value in column as one from two rows, and has bigger weight
            const osVal = hasSameOS(el, row1) || hasSameOS(el, row2);
            const browserVal = hasSameBrowser(el, row1) || hasSameBrowser(el, row2);
            const dtVal = hasSameDt(el, row1) || hasSameDt(el, row2);
            const countryVal = hasSameCountry(el, row1) || hasSameCountry(el, row2);
            return osVal && browserVal && dtVal && countryVal;
          }
          return false;
        });

        if (!isThereCandidateToSolveConflict) {
          openErrorNotification({ message: 'You must change your list, because they are multiple of them with same matching score.' });
          return false;
        }
      }
    }
  }
  return true;
};
