import { Input } from 'antd';
import React from 'react';

export const SearchBar = ({ id, name, placeholder, width, value, onChange }) => {
  return (
    <div style={{ width: width || '100%' }}>
      <Input.Search
        id={id || 'search'}
        name={name || 'search'}
        placeholder={placeholder || 'Search'}
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
