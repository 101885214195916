import React from 'react';
import { CustomDivider } from './CustomDivider';

export const TitleWithDivider = ({ title }) => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <span style={{ whiteSpace: 'nowrap', marginRight: 20 }}>{title}</span>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <CustomDivider margin="0" />
      </div>
    </div>
  );
};
