import React, { useState } from 'react';
import { Col, Select, Row } from 'antd';
import { productsService } from '../../../services/products';
import { countriesService } from '../../../services/countries';
import { accountsService } from '../../../services/accounts';
import { landingPagesService } from '../../../services/landingPages';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { browsersService } from '../../../services/browsers';
import { companiesService } from '../../../services/companies';
import { mediaCampaignsService } from '../../../services/media-campaigns';
import styles from '../ChartView.module.css';
import { userFiltersService } from '../../../services/user-filters';
import { osService } from '../../../services/os';
import { generateOptionsFromObject } from '../../../utils/options';
import { PublisherModules } from '../../../utils/enums';
import { firstLetterUpperCase } from '../../../utils/functions';
import { FilterName } from '../../../services/statistic';

export const Filters = ({
  products,
  companies,
  users,
  lps,
  os,
  productCompany,
  browsers,
  channels,
  countries,
  filters,
  userFilters,
  onClose,
  onChange,
  showClose,
  loaders
}) => {
  const [userSavedFilter, setUserSavedFilter] = useState();

  const allMetrics = [
    {
      label: 'Impressions',
      value: 'im'
    },
    {
      label: 'Redirect Impressions',
      value: 'ri'
    },
    {
      label: 'Downloads',
      value: 'dw'
    },
    {
      label: 'Leads',
      value: 'pl'
    },
    {
      label: 'PX 1',
      value: 'p1'
    },
    {
      label: 'PX 2',
      value: 'p2'
    },
    {
      label: 'PX 3',
      value: 'p3'
    },
    {
      label: 'PX 4',
      value: 'p4'
    },
    {
      label: 'PX 5',
      value: 'p5'
    },
    {
      label: 'PX 6',
      value: 'p6'
    },
    {
      label: 'PX 7',
      value: 'p7'
    },
    {
      label: 'PX 8',
      value: 'p8'
    },
    {
      label: 'PX 1 Spam',
      value: 'p1_spam'
    },
    {
      label: 'PX 1 Not Spam',
      value: 'p1_not_spam'
    },
    {
      label: 'Spent',
      value: 'spent'
    },
    {
      label: 'Earned',
      value: 'earned'
    },
    {
      label: 'CPM',
      value: 'cpm'
    },
    {
      label: 'ROI',
      value: 'roi'
    },
    {
      label: 'Profit',
      value: 'profit'
    },
    {
      label: 'CR',
      value: 'cr'
    },
    {
      label: 'Partner CR',
      value: 'pcr'
    },
    {
      label: 'Partner CR PX8',
      value: 'pcrf8'
    },
    {
      label: 'RPM',
      value: 'rpm'
    },
    {
      label: 'Fired',
      value: 'pf'
    },
    {
      label: 'PF 1',
      value: 'f1'
    },
    {
      label: 'PF 2',
      value: 'f2'
    },
    {
      label: 'PF 3',
      value: 'f3'
    },
    {
      label: 'PF 4',
      value: 'f4'
    },
    {
      label: 'PF 5',
      value: 'f5'
    },
    {
      label: 'PF 6',
      value: 'f6'
    },
    {
      label: 'PF 7',
      value: 'f7'
    },
    {
      label: 'PF 8',
      value: 'f8'
    }
  ];
  const setSavedFilter = (name) => {
    const selectedUserFilter = userFilters.find((el) => el.name === name);
    onChange({ ...selectedUserFilter.filters, metrics: filters.metrics });
  };
  return (
    <div className={styles.filtersWrapper}>
      <div>
        <Row style={{ justifyContent: 'flex-end' }}>
          {showClose ? (
            <img style={{ cursor: 'pointer' }} src="/images/delete_icon.png" alt="Delete" width="21px" onClick={() => onClose()} />
          ) : (
            ''
          )}
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Metrics</span>
            <VirtualizedSelect
              options={allMetrics}
              onChange={(value) => {
                onChange({ ...filters, metrics: value });
              }}
              multi={true}
              selectValue={filters.metrics}
              placeholder={'Select metric'}
              loading={false}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Traffic Companies</span>
            <VirtualizedSelect
              options={companiesService.generateOptions(companies)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.TrafficCompanies]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.TrafficCompanies]}
              placeholder={'Select traffic companies'}
              loading={loaders.companies}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Product Companies</span>
            <VirtualizedSelect
              options={companiesService.generateOptions(productCompany)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.ProductCompanies]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.ProductCompanies]}
              placeholder={'Select product companies'}
              loading={loaders.productCompany}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Channels</span>
            <VirtualizedSelect
              options={mediaCampaignsService.generateOptions(channels)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.Channels]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.Channels]}
              placeholder={'Select channels'}
              loading={loaders.channels}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Products</span>
            <VirtualizedSelect
              options={productsService.generateOptions(products)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.Products]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.Products]}
              placeholder={'Select products'}
              loading={loaders.products}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Countries</span>
            <VirtualizedSelect
              options={countriesService.generateOptions(countries)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.Countries]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.Countries]}
              placeholder={'Select countries'}
              loading={loaders.countries}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Account Managers</span>
            <VirtualizedSelect
              options={accountsService.generateOptions(users)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.AccountManagers]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.AccountManagers]}
              placeholder={'Select managers'}
              loading={loaders.users}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Landing Pages</span>
            <VirtualizedSelect
              options={landingPagesService.generateOptions(lps)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.LPs]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.LPs]}
              placeholder={'Select lps'}
              loading={loaders.lps}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Browsers</span>
            <VirtualizedSelect
              options={browsersService.generateOptionsWithNameAsValue(browsers)}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.Browsers]: value });
              }}
              multi={true}
              selectValue={filters[FilterName.Browsers]}
              placeholder={'Select browsers'}
              loading={loaders.browsers}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>OSs</span>
            <Select
              onChange={(value) => {
                onChange({ ...filters, [FilterName.OSs]: value });
              }}
              value={filters[FilterName.OSs]}
              placeholder={'Select OSs'}
              mode="tags"
              style={{ width: '100%' }}
              options={osService.generateOptionsForFilters(os)}
              loading={loaders.os}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>OS Versions</span>
            <Select
              style={{ width: '100%' }}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.OSVersions]: value });
              }}
              mode="tags"
              value={filters[FilterName.OSVersions]}
              placeholder={'Type OS Versions'}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3} className={`${styles.bgColor}`}>
            <span className={styles.spanMarginBottom}>Sources</span>
            <Select
              mode="tags"
              onChange={(value) => {
                onChange({ ...filters, [FilterName.Sources]: value });
              }}
              style={{ width: '100%', height: '36px', borderRadius: '4px', border: '1px solid #ccc', borderCollapse: 'separate' }}
              value={filters[FilterName.Sources]}
            >
              {null}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Filters</span>
            <VirtualizedSelect
              multi={false}
              selectValue={userSavedFilter}
              onChange={(value) => {
                setUserSavedFilter(value);
                if (value) {
                  setSavedFilter(value);
                }
              }}
              options={userFiltersService.generateOptions(userFilters)}
              style={{ width: '100%' }}
              loading={loaders.userFilters}
            >
              {null}
            </VirtualizedSelect>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
            <span className={styles.spanMarginBottom}>Publisher Modules</span>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              onChange={(value) => {
                onChange({ ...filters, [FilterName.PublisherModules]: value });
              }}
              value={filters[FilterName.PublisherModules]}
              placeholder={'Select publisher modules'}
              allowClear
            >
              {generateOptionsFromObject(PublisherModules, firstLetterUpperCase)}
            </Select>
          </Col>
        </Row>
      </div>
    </div>
  );
};
