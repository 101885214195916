import React, { useState } from 'react';
import { QuestionCircleFilled, SettingOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { auth } from '../services/auth';
import { getAllowedRoutes } from '../routes';
import { Logo } from '../components/Logo';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const generateSidebarMenu = (routes, parentPath = '') => {
  return routes.map((route) => {
    if (route.skipRender) {
      return null;
    }
    const path = route.linkPath || route.path;

    if (route.children) {
      return route.children.length > 0 ? (
        <Menu.SubMenu
          key={path}
          title={
            <span>
              {route.icon}
              <span>{route.title}</span>
            </span>
          }
        >
          {generateSidebarMenu(route.children, path)}
        </Menu.SubMenu>
      ) : null;
    }

    const fullPath = parentPath + path;
    return (
      <Menu.Item key={fullPath}>
        <Link to={fullPath}>
          {!parentPath && route.icon}
          <span>{route.title}</span>
        </Link>
      </Menu.Item>
    );
  });
};

export const Sidebar = ({ isSmallScreen }) => {
  const location = useLocation();
  const wasCollapsed = localStorage.getItem('collapse');
  const [collapsed, setCollapsed] = useState(wasCollapsed ? wasCollapsed === 'true' : window.innerWidth < 1400);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    localStorage.setItem('collapse', collapsed);
  };

  const additionalProps = useMemo(() => {
    return isSmallScreen ? { breakpoint: 'lg', collapsedWidth: '0' } : null;
  }, [isSmallScreen]);

  const showItServiceLink = auth.hasPermission('view_itdesk');
  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse} {...additionalProps}>
      <Logo />

      <hr />

      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        {generateSidebarMenu(getAllowedRoutes())}
      </Menu>

      {showItServiceLink && (
        <>
          <hr />
          <Menu theme="dark" mode="inline">
            <Menu.Item icon={<QuestionCircleFilled />} key="0">
              <a href="https://coinis.atlassian.net/servicedesk/customer/portal/2" target="_blank" rel="noopener noreferrer">
                IT service desk
              </a>
            </Menu.Item>
            <Menu.Item icon={<SettingOutlined />} key="1">
              <a href="https://lp.webbcrew.com" target="_blank" rel="noopener noreferrer">
                LP Generator
              </a>
            </Menu.Item>
          </Menu>
        </>
      )}
    </Layout.Sider>
  );
};
