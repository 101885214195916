import { Form, Modal, Select, Button, InputNumber, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { repeatPeriodTypeMessage, repeatPeriodTypes } from '../pages/media-campaigns/utils/enums';

import styles from './SetupRepeatModal.module.css';

const days = [
  { label: 'Monday', value: 'Mo' },
  { label: 'Tuesday', value: 'Tu' },
  { label: 'Wednesday', value: 'We' },
  { label: 'Thursday', value: 'Th' },
  { label: 'Friday', value: 'Fr' },
  { label: 'Saturday', value: 'Sa' },
  { label: 'Sunday', value: 'Su' }
];

const repeatPeriodOptions = () => {
  const options = [];

  // In this case value is an object key, and label is a value of that key
  for (const value of Object.values(repeatPeriodTypes)) {
    options.push({
      label: repeatPeriodTypeMessage[value],
      value
    });
  }

  return options;
};

export const defaultModalFields = (patternIndex) => {
  return [
    {
      name: ['patternFormList', patternIndex, 'repeatEveryNumber'],
      value: 0
    },
    {
      name: ['patternFormList', patternIndex, 'repeatEveryPeriod'],
      value: repeatPeriodTypes.DAY
    },
    {
      name: ['patternFormList', patternIndex, 'repeatOnDays'],
      value: undefined
    },
    {
      name: ['patternFormList', patternIndex, 'schedulerEnd'],
      value: moment('2030-01-01')
    }
  ];
};

export const SetupRepeatModal = ({ initialRepeatEveryNumber, initialRepeatEveryPeriod, field, patternIndex, modalVisible, onClose }) => {
  const [repeatEveryNumber, setRepeatEveryNumber] = useState(0);
  const [repeatEveryPeriod, setRepeatEveryPeriod] = useState(repeatPeriodTypes.DAY);

  useEffect(() => {
    if (initialRepeatEveryNumber && initialRepeatEveryPeriod) {
      setRepeatEveryNumber(initialRepeatEveryNumber);
      setRepeatEveryPeriod(initialRepeatEveryPeriod);
    }
  }, [initialRepeatEveryNumber, initialRepeatEveryPeriod]);

  return (
    <Modal
      title={`Setup Repeat (Pattern ${patternIndex + 1})`}
      visible={modalVisible}
      wrapClassName={styles.setupRepeatModal}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Close
        </Button>
      ]}
    >
      <>
        <div className={styles.repeatEveryContentWrapper} style={{ marginBottom: repeatEveryNumber ? 30 : 0 }}>
          <span className={`${styles.textSpan} ${styles.repeatEveryText}`}>Repeat every</span>

          <div className={styles.repeatEveryNumberWrapper}>
            <Form.Item {...field} name={[field.name, 'repeatEveryNumber']} fieldKey={[field.fieldKey, 'repeatEveryNumber']}>
              <InputNumber min={0} onChange={setRepeatEveryNumber} />
            </Form.Item>
          </div>

          <div className={styles.repeatEveryPeriodWrapper}>
            <Form.Item {...field} name={[field.name, 'repeatEveryPeriod']} fieldKey={[field.fieldKey, 'repeatEveryPeriod']}>
              <Select placeholder="Period" options={repeatPeriodOptions()} onChange={setRepeatEveryPeriod} />
            </Form.Item>
          </div>
        </div>

        {repeatEveryNumber ? (
          <>
            {repeatEveryPeriod === repeatPeriodTypes.WEEK ? (
              <div className={styles.repeatOnContentWrapper}>
                <span className={`${styles.textSpan} ${styles.repeatOnText}`}>Repeat on</span>

                <div className={styles.dayCheckboxesWrapper}>
                  <Form.Item {...field} name={[field.name, 'repeatOnDays']} fieldKey={[field.fieldKey, 'repeatOnDays']}>
                    <Checkbox.Group options={days} />
                  </Form.Item>
                </div>
              </div>
            ) : null}

            <div className={styles.endsOnContentWrapper}>
              <span className={`${styles.textSpan} ${styles.endsOnText}`}>Ends on</span>

              <div>
                <Form.Item {...field} name={[field.name, 'schedulerEnd']} fieldKey={[field.fieldKey, 'schedulerEnd']}>
                  <DatePicker placeholder="Scheduler End" />
                </Form.Item>
              </div>
            </div>
          </>
        ) : null}
      </>
    </Modal>
  );
};
