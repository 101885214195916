import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { companyTagsService } from '../../../services/company-tags';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { useBeforeUnload } from '../../../utils/hooks';

export const AddOrEdit = ({ modalVisible, companyTag, onClose, onAdd, onEdit }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const action = companyTag ? 'Edit' : 'Add';

  const initialValues = useMemo(() => {
    if (!companyTag) {
      return {};
    }

    return companyTag;
  }, [companyTag]);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onClose) : onClose();
  };

  const resetFields = useCallback(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form]);

  useBeforeUnload(wasFormChanged);

  useEffect(() => {
    resetFields();
  }, [resetFields, modalVisible]);

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      if (companyTag?.id) {
        await companyTagsService.edit(values, companyTag.id);
        openSuccessNotification({ message: 'Successfully edited company tag!' });
        onEdit();
      } else {
        await companyTagsService.add(values);
        openSuccessNotification({ message: 'Successfully added company tag!' });
        onAdd();
      }
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={`${action} Company Tag`}
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          {action}
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};
