import React, { useEffect, useState } from 'react';
import { Form, Input, Checkbox, Switch, Row } from 'antd';

import { PricesImportExport } from './PricesImportExport';
import { DynamicPriceFields } from './DynamicPriceFields';

import { getAccountsSelectOptions } from '../pages/lists/utils/options';

import styles from './PricesForm.module.css';
import { Select } from './Select';
import { isAny } from '../utils/pricesValidator';

export const PricesForm = ({
  form,
  allAccounts,
  allCountries,
  allOSs,
  allBrowsers,
  allDeviceTypes,
  list,
  listName,
  disabled,
  isListForm,
  loader,
  onImport,
  formChangedTrigger,
  isRevShare
}) => {
  const [isAdvanced, setAdvanced] = useState(false);
  const [isAdvancedDisabled, setAdvancedDisabled] = useState(false);

  useEffect(() => {
    const formList = form.getFieldValue(listName) || list?.list_prices;
    const foundIt = !!formList?.find((el) => !isAny(el.os) || !isAny(el.browser));
    setAdvancedDisabled(foundIt);
  }, [formChangedTrigger, form, list, listName]);

  useEffect(() => {
    const formList = form.getFieldValue(listName) || list?.list_prices;
    const foundIt = !!formList?.find((el) => !isAny(el.os) || !isAny(el.browser));
    setAdvanced(foundIt);
  }, [form, list, listName]);

  return (
    <div>
      <Row style={{ marginBottom: '30px' }}>
        Advanced &nbsp; <Switch disabled={isAdvancedDisabled} checked={isAdvanced} onChange={(value) => setAdvanced(value)} />
      </Row>
      {isListForm ? (
        <div className={styles.nameAndPrivateFieldWrapper}>
          <div className={styles.widthModal50}>
            <span>Price List Name</span>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter list name!'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={styles.widthModal50 + ' ' + styles.privateAndImportExport}>
            <div className={list ? styles.widthModal50 + ' ' + styles.privateFieldWrapper : styles.privateFieldWrapper}>
              <Form.Item name="privateList" valuePropName="checked">
                <Checkbox disabled={disabled}>Private</Checkbox>
              </Form.Item>
            </div>

            <div className={styles.widthModal50}>
              <PricesImportExport list={list} isListForm={isListForm} onImport={onImport} />
            </div>
          </div>
        </div>
      ) : null}
      {isListForm ? (
        <div className={isListForm ? styles.marginBotModal : styles.formItemWrapper4}>
          <span>Assigned</span> <br />
          <Form.Item name="assigned">
            <Select mode="multiple" allowClear optionFilterProp="data-searchvalue" disabled={disabled} loading={loader.allAccounts}>
              {getAccountsSelectOptions(allAccounts)}
            </Select>
          </Form.Item>
        </div>
      ) : null}
      {!isListForm ? (
        <div className={disabled ? styles.disabledPricesForm : ''}>
          <DynamicPriceFields
            allCountries={allCountries}
            allBrowsers={allBrowsers}
            allOSs={allOSs}
            allDeviceTypes={allDeviceTypes}
            listName={listName}
            disabled={disabled}
            areCountriesLoading={loader.allCountries}
            areBrowsersLoading={loader.allBrowsers}
            areDeviceTypesLoading={loader.allDeviceTypes}
            allOSsLoading={loader.allOSs}
            onAdvancedChange={setAdvanced}
            isAdvanced={isAdvanced}
            form={form}
            isRevShare={isRevShare}
          />
        </div>
      ) : (
        <DynamicPriceFields
          allCountries={allCountries}
          allBrowsers={allBrowsers}
          allOSs={allOSs}
          allDeviceTypes={allDeviceTypes}
          listName={listName}
          isListForm={isListForm}
          areCountriesLoading={loader.allCountries}
          areBrowsersLoading={loader.allBrowsers}
          allOSsLoading={loader.allOSs}
          areDeviceTypesLoading={loader.allDeviceTypes}
          onAdvancedChange={setAdvanced}
          isAdvanced={isAdvanced}
          form={form}
        />
      )}
    </div>
  );
};
