import React from 'react';

import { ListTypes } from '../../../../services/lists';
import { AddOrEditListModal } from '../AddOrEditListModal';
import { MultipleItemsSelect } from '../MultipleItemsSelect';

export const AddOrEditIpAddressListModal = ({
  visibleModal,
  allAccounts,
  ipAddressList,
  isClone,
  onCloseModal,
  onClone,
  onEdit,
  onAdd,
  allAccountsLoading
}) => {
  const fieldName = 'ips';
  const fieldNameRead = 'ips_json';

  const renderItemsSelect = (form) => (
    <MultipleItemsSelect form={form} title="IP Addresses" name={fieldName} message="Please type IP Address/es!" pasteHandler={true} />
  );

  return (
    <AddOrEditListModal
      visibleModal={visibleModal}
      allAccounts={allAccounts}
      onCloseModal={onCloseModal}
      onClone={onClone}
      onEdit={onEdit}
      onAdd={onAdd}
      list={ipAddressList}
      listType={ListTypes.ip_lists}
      itemsFieldName={fieldName}
      itemsFieldNameRead={fieldNameRead}
      title="IP Address List"
      nameFieldTitle="IP Address List Name"
      renderItemsSelect={renderItemsSelect}
      isClone={isClone}
      areAccountsLoading={allAccountsLoading}
    />
  );
};
