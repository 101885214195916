import React, { useState } from 'react';

import { LiveCampaigns } from './LiveCampaigns';
import { SearchAndFilter } from './SearchAndFilter';
import { Table } from './Table';

import styles from './Vertical.module.css';

export const VerticalMenu = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  searchValue,
  companies,
  identities,
  companiesLoading,
  identitiesLoading,
  filters,
  urlId,
  onPaginationChange,
  onSearch,
  onFilter
}) => {
  const [shrinkMenu, setShrinkMenu] = useState(false);

  const menuClasses = () => {
    return shrinkMenu ? styles.verticalMenu + ' ' + styles.verticalMenuShrink : styles.verticalMenu;
  };

  return (
    <div className={menuClasses()}>
      <LiveCampaigns liveCampaigns={totalRows} shrinkMenu={shrinkMenu} onShrinkClicked={() => setShrinkMenu((oldValue) => !oldValue)} />

      {!shrinkMenu ? (
        <SearchAndFilter
          searchValue={searchValue}
          companies={companies}
          identities={identities}
          identitiesLoading={identitiesLoading}
          companiesLoading={companiesLoading}
          filters={filters}
          onSearch={onSearch}
          onFilter={onFilter}
        />
      ) : null}

      <Table
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={loadingCampaigns}
        shrinkMenu={shrinkMenu}
        urlId={urlId}
        onPaginationChange={onPaginationChange}
      />
    </div>
  );
};
