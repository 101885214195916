import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { confirmClose } from '../../../utils/confirmClose';

import styles from './CustomColumnModal.module.css';

const relatedEvents = [
  {
    label: 'Impressions',
    value: 'im'
  },
  {
    label: 'Redirect Impressions',
    value: 'ri'
  },
  {
    label: 'Downloads',
    value: 'dw'
  },
  {
    label: 'Leads',
    value: 'pl'
  },
  {
    label: 'Fired',
    value: 'pf'
  },
  {
    label: 'PX 1',
    value: 'p1'
  },
  {
    label: 'PX 2',
    value: 'p2'
  },
  {
    label: 'PX 3',
    value: 'p3'
  },
  {
    label: 'PX 4',
    value: 'p4'
  },
  {
    label: 'PX 5',
    value: 'p5'
  },
  {
    label: 'PX 6',
    value: 'p6'
  },
  {
    label: 'PX 7',
    value: 'p7'
  },
  {
    label: 'PX 8',
    value: 'p8'
  },
  {
    label: 'PF 1',
    value: 'f1'
  },
  {
    label: 'PF 2',
    value: 'f2'
  },
  {
    label: 'PF 3',
    value: 'f3'
  },
  {
    label: 'PF 4',
    value: 'f4'
  },
  {
    label: 'PF 5',
    value: 'f5'
  },
  {
    label: 'PF 6',
    value: 'f6'
  },
  {
    label: 'PF 7',
    value: 'f7'
  },
  {
    label: 'PF 8',
    value: 'f8'
  }
];

const metrics = [
  { label: 'Spent', value: 'spent' },
  { label: 'Earned', value: 'earned' }
];

const comparationSigns = [
  { label: 'Less than (<)', value: '__lt' },
  { label: 'Less than or equal to (<=)', value: '__lte' },
  { label: 'Equal to (=)', value: '__eq' },
  { label: 'Not equal to (!=)', value: '__neq' },
  { label: 'Greater than (>)', value: '__gt' },
  { label: 'Greater than or equal to (>=)', value: '__gte' }
];

export const CustomColumnModal = ({ visibleModal, onCancel: closeModal, onColumnCreate }) => {
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [form] = Form.useForm();

  const handleClose = () => {
    wasFormChanged ? confirmClose(() => closeModal()) : closeModal();
  };

  const handleCreateColumn = (values) => {
    const { columnName, relatedEvent, customColumnMetrics } = values;
    const columnValue = columnName.toLowerCase().replaceAll(' ', '_');

    const metricsAndValues = customColumnMetrics.map((column) => {
      const { metric, comparationSign, numericValue } = column;

      return {
        metric,
        comparationSign,
        numericValue
      };
    });

    const newColumn = {
      text: columnName,
      value: columnValue,
      relatedEvent,
      metricsAndValues
    };

    onColumnCreate(newColumn);
    closeModal();
  };

  useEffect(() => {
    if (visibleModal) {
      form.resetFields();
      setWasFormChanged(false);
    }
  }, [visibleModal, form]);

  return (
    <Modal
      title="Create Custom Column"
      visible={visibleModal}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Create column
        </Button>
      ]}
      width={800}
    >
      <Form
        form={form}
        onFinish={handleCreateColumn}
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <Row gutter={32}>
          <Col span={16}>
            <Form.Item name="columnName" rules={[{ required: true, message: 'Please input column label!' }]}>
              <Input placeholder="Column label" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="relatedEvent" rules={[{ required: true, message: 'Please select related event!' }]}>
              <Select options={relatedEvents} placeholder="Select related event" />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ marginTop: 0 }} />

        <Form.List name="customColumnMetrics">
          {(fields, { add, remove }) => {
            return (
              <div className={styles.formListContent}>
                {fields.map((field, index) => (
                  <Row gutter={32} key={index}>
                    <Col span={7}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'metric']}
                        fieldKey={[field.fieldKey, 'metric']}
                        rules={[{ required: true, message: 'Please select metric!' }]}
                      >
                        <Select options={metrics} placeholder="Select metric" />
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'comparationSign']}
                        fieldKey={[field.fieldKey, 'comparationSign']}
                        rules={[{ required: true, message: 'Please select comparation sign!' }]}
                      >
                        <Select options={comparationSigns} placeholder="Select comparation sign" />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        {...field}
                        name={[field.fieldKey, 'numericValue']}
                        fieldKey={[field.fieldKey, 'numericValue']}
                        rules={[{ required: true, message: 'Please input value!' }]}
                      >
                        <InputNumber placeholder="Numeric value" />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <div className={styles.deleteRow} onClick={() => remove(field.name)}>
                        <img src="/images/delete_icon.png" alt="Delete" width="21px" />
                      </div>
                    </Col>
                  </Row>
                ))}
                <Button ghost type="primary" onClick={() => add()} block>
                  <PlusOutlined /> Add new metric
                </Button>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};
