import React, { useEffect, useMemo, useState } from 'react';
import { InputNumber, Modal } from 'antd';
import styles from './PerPixelModal.module.css';

export const PerPixelModal = ({ visible, setVisible, value: pixelValues, onChange }) => {
  const [modalValues, setModalValues] = useState({});

  useEffect(() => {
    setModalValues(pixelValues);
  }, [pixelValues]);

  const currentItemPixels = useMemo(() => {
    if (modalValues) {
      return modalValues ? Object.entries(modalValues) : [];
    }
    return [];
  }, [modalValues]);

  const handleConfirm = () => {
    onChange({ ...pixelValues, ...modalValues });
    setVisible(false);
  };

  return (
    <Modal
      title="Pixel Percentage Configuration"
      okText="Confirm"
      cancelText="Discard"
      visible={visible}
      width={350}
      onCancel={() => {
        setVisible(false);
        setModalValues({});
      }}
      onOk={handleConfirm}
    >
      <div className={styles.pixelContent}>
        {currentItemPixels.map(([key, value]) => {
          return (
            <div key={key} className={styles.perPixelRow}>
              <div className={styles.perPixelLabel}>{key}</div>
              <div className={styles.perPixelValue}>
                <InputNumber
                  value={value}
                  min={0}
                  max={100}
                  onChange={(value) => setModalValues((prevValues) => ({ ...prevValues, [key]: value }))}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
