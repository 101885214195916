import { Checkbox, Col, Divider, Form, Radio, Row, Space } from 'antd';
import React, { useState } from 'react';

// We will need commented parts in future!
const RadioValues = {
  WebmastersFraud: 'webmasters_fraud',
  WebmastersLowConversionAlert: 'webmasters_low_conversion_alert',
  MediaBuying: 'media_buying'
  // AffiliateProgramsFraud: 'affiliate_programs_fraud',
  // AffiliateProgramsLowConversionAlert: 'affiliate_programs_low_conversion_alert'
};

const initialValues = {
  notify_on_mail: false,
  campaign_pub_module: RadioValues.publisher
};

export const SuspendModalContent = ({ form, onSubmit }) => {
  const [shouldSendMail, setShouldSendMail] = useState(false);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      onValuesChange={(changedValues) => {
        const { notify_on_mail: sendMail } = changedValues;

        if (sendMail !== undefined) {
          setShouldSendMail(sendMail);
        }
      }}
    >
      <Divider />

      <Row>
        <Col>
          <Form.Item name="notify_on_mail" valuePropName="checked">
            <Checkbox>Send Mail</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      {shouldSendMail && (
        <Row>
          <Col>
            <Form.Item name="campaign_pub_module">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={RadioValues.WebmastersFraud}>Webmasters - Fraud</Radio>
                  <Radio value={RadioValues.WebmastersLowConversionAlert}>Webmasters Low Conversion - Alert</Radio>
                  <Radio value={RadioValues.MediaBuying}>Media Buying</Radio>
                  {/* <Radio value={RadioValues.AffiliateProgramsFraud}>Affiliate Programs - Fraud</Radio> */}
                  {/* <Radio value={RadioValues.AffiliateProgramsLowConversionAlert}>Affiliate Programs Low Conversion - Alert</Radio> */}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};
