import { callApi } from './api';
import { getQueryString } from '../utils/query';
class CountriesService {
  async getAll({ fields = 'id,name,code2,img' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/countries/${query}` });
  }

  generateOptions = (arr) => {
    return arr.map((country) => {
      return {
        label: country.name,
        value: country.code2
      };
    });
  };
  /*
  CountryOptionRenderer = ({
    focusedOption,
    focusedOptionIndex,
    focusOption,
    key,
    labelKey,
    option,
    options,
    selectValue,
    style,
    valueArray,
    valueKey
  }) => {
    const flagImageUrl = `${API_BASE_URL}${option.code.toLowerCase()}`;

   const classNames = [styles.countryOption];
    if (option === focusedOption) {
      classNames.push(styles.countryOptionFocused);
    }
    if (valueArray.indexOf(option) >= 0) {
      classNames.push(styles.countryOptionSelected);
    }

    return (
      <div key={key} onClick={() => selectValue(option)} onMouseEnter={() => focusOption(option)} style={style}>
        <label>{option.name}</label>
        <img alt="a" src={flagImageUrl} />
      </div>
    );
  };*/
}

export const countriesService = new CountriesService();
