export const PublisherModules = {
  offerwall: 'offerwall',
  publisher: 'publisher'
};

export const PriceRadioValues = {
  noPrice: 'no_price',
  local: 'local',
  global: 'global'
};
