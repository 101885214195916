import { Row, Col, Form, Input } from 'antd';
import React from 'react';

export const PixelDescriptions = () => {
  return (
    <div>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item label="Lead Description" name="lead_description">
            <Input placeholder="Lead description" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 1 Description" name="p1_description">
            <Input placeholder="Pixel 1 Description" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 2 Description" name="p2_description">
            <Input placeholder="Pixel 2 Description" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={8}>
          <Form.Item label="Pixel 3 Description" name="p3_description">
            <Input placeholder="Pixel 3 Description" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 4 Description" name="p4_description">
            <Input placeholder="Pixel 4 Description" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 5 Description" name="p5_description">
            <Input placeholder="Pixel 5 Description" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={8}>
          <Form.Item label="Pixel 6 Description" name="p6_description">
            <Input placeholder="Pixel 6 Description" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 7 Description" name="p7_description">
            <Input placeholder="Pixel 7 Description" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Pixel 8 Description" name="p8_description">
            <Input placeholder="Pixel 8 Description" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
