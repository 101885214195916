import React from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';

import styles from '../ExternalURLs.module.css';

export const Heading = ({ searchValue, onSearch, onCreateExternalURL }) => {
  return (
    <div>
      <h1>External URLs</h1>

      <div className={styles.headingFields}>
        <div className={styles.searchInput}>
          <Input
            placeholder="Search"
            value={searchValue}
            allowClear
            suffix={<SearchOutlined />}
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>

        <div>
          <Button type="primary" onClick={onCreateExternalURL}>
            <PlusOutlined />
            Add External URL
          </Button>
        </div>
      </div>
    </div>
  );
};
