import { callApi } from './api';

export const FieldType = {
  ID: 'foreign_field',
  NUMBER: 'number',
  STRING: 'string',
  CHOICE_FIELD: 'choice_field',
  DATE: 'date',
  BOOL: 'bool'
};

export const TYPES = {
  FILTER: 'filter',
  COLUMNS: 'columns'
};

export const BOOL_OPTIONS = [
  { id: 'true', name: 'True' },
  { id: 'false', name: 'False' }
];

export const ACCEPTS_ALL = 'ALL';

export const MULTIPLE_VALUES_FILTER_SUFFIX = '__in';

class UrgentPanelService {
  async update({ data }) {
    return await callApi({ url: '/api/urgent_panel/update/?model_name=MediaCampaign', method: 'POST', data });
  }

  async getAll({ fields, filters, limit }) {
    const limitParam = limit ? `limit=${limit}` : '';
    return await callApi({
      url: `/api/urgent_panel/filtered/?model_name=MediaCampaign&${filters.join('&')}&fields=${fields.join(',')}&${limitParam}`
    });
  }

  async getTree() {
    return await callApi({ url: '/api/urgent_panel/model_fields/?model_name=MediaCampaign' });
  }
}

export const urgentPanelService = new UrgentPanelService();
