import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, InputNumber, Button, Popover, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { removeDuplicateCountriesHelper } from '../../../utils/functions';

import { getSelectOptionsWithTooltip } from '../../lists/utils/options';

import styles from './ProductPricesFormList.module.css';

const inlineStyles = {
  paddingLeft: {
    paddingLeft: 0
  },
  paddingRight: {
    paddingRight: 0
  },
  margin: {
    margin: 0
  },
  cursorPointer: {
    cursor: 'pointer'
  }
};

export const ProductPricesFormList = ({ form, pixelType, countriesList, countriesLoading }) => {
  const [updatedCountryList, setUpdatedCountryList] = useState([]);

  useEffect(() => {
    setUpdatedCountryList(countriesList);
  }, [countriesList]);

  const removeDuplicateCountries = (selectedOptionData, rowIndex) => {
    const {
      children: {
        props: {
          option: { id, listed }
        }
      }
    } = selectedOptionData;

    if (listed) {
      removeDuplicateCountriesHelper({ form, rowIndex, formFieldName: pixelType, country: id });
    }
  };

  return (
    <Form.List name={pixelType} shouldUpdate>
      {(fields, { add, remove }) => {
        return (
          <>
            <Row gutter={16} className={styles.headerRow} style={{ ...inlineStyles.margin, marginBottom: 16 }}>
              <Col span={11} style={inlineStyles.paddingLeft}>
                <div>Country</div>
              </Col>
              <Col span={6}>
                <div>Spent</div>
              </Col>
              <Col span={6}>
                <div>Earned</div>
              </Col>
              <Col span={1} style={inlineStyles.paddingRight}>
                <div></div>
              </Col>
            </Row>

            {fields.map((field) => (
              <div className={styles.rowWrapper} key={field.fieldKey}>
                <Row gutter={16} style={inlineStyles.margin}>
                  <Form.Item name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']} hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item name={[field.name, 'pixel_type']} fieldKey={[field.fieldKey, 'pixel_type']} hidden>
                    <Input />
                  </Form.Item>

                  <Col span={11} style={inlineStyles.paddingLeft}>
                    <Form.Item
                      name={[field.name, 'country']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select country!'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select country"
                        optionFilterProp="data-searchvalue"
                        mode="multiple"
                        showSearch
                        allowClear
                        loading={countriesLoading}
                        onSelect={(_, optionData) => removeDuplicateCountries(optionData, field.name)}
                      >
                        {getSelectOptionsWithTooltip(updatedCountryList)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item name={[field.name, 'spent']}>
                      <InputNumber min={0} formatter={(value) => `$ ${value}`} parser={(value) => value.replace('$ ', '')} />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item name={[field.name, 'earned']}>
                      <InputNumber min={0} formatter={(value) => `$ ${value}`} parser={(value) => value.replace('$ ', '')} />
                    </Form.Item>
                  </Col>

                  <Col span={1} style={inlineStyles.paddingRight}>
                    <Popover content="Delete">
                      <img
                        src="/images/delete_icon.png"
                        alt="Delete"
                        width="21px"
                        height="21px"
                        style={inlineStyles.cursorPointer}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Popover>
                  </Col>
                </Row>
              </div>
            ))}

            <Button
              ghost
              type="primary"
              onClick={() => {
                add({
                  pixel_type: pixelType,
                  spent: 0,
                  earned: 0
                });
              }}
              block
            >
              <PlusOutlined /> Add product price
            </Button>
          </>
        );
      }}
    </Form.List>
  );
};
