import React from 'react';
import { Table as AntTable, Pagination } from 'antd';

import { ListNameTableCol } from './ListNameTableCol';

import styles from '../Lists.module.css';
import { TableTitle } from '../../../components/TableTitle';

export const TableCommon = ({
  lists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  sorting,
  onPaginationChange,
  renderActions,
  itemsTitle,
  renderSelectedItems,
  onSortingClick
}) => {
  const columns = [
    {
      title: (
        <TableTitle text="List Name" value="name" order={sorting.find((s) => s.key === 'name')?.value} onSortingClick={onSortingClick} />
      ),
      key: 'name',
      render: ({ name, creator_details, date_created }) => {
        return <ListNameTableCol listName={name} creator={creator_details} dateCreated={date_created} />;
      }
    },
    {
      title: itemsTitle,
      key: 'items_list',
      render: (record) => renderSelectedItems(record)
    },
    // {
    //   title: 'Used in Campaigns',
    //   key: 'campaigns',
    //   render: ({used_in}) => {
    //     return used_in.join(', ') || '-';
    //   }
    // },
    {
      title: 'Actions',
      align: 'center',
      render: (record) => renderActions(record)
    }
  ];

  return (
    <div className={styles.listsTable}>
      <AntTable
        columns={columns}
        dataSource={lists}
        loading={loading}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
