import React, { useEffect, useState } from 'react';

import { companyTagsService } from '../../services/company-tags';
import { useFetch } from '../../utils/hooks';
import { Header } from './components/Header';
import { Table } from './components/Table';

export const CompanyTags = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);

  const loadCompanyTags = (cancelToken) => companyTagsService.getPaged({ currentPage, pageSize, cancelToken });

  const [
    {
      data: { results: companyTags, count: totalItems },
      isFetching
    },
    getCompanyTags
  ] = useFetch(loadCompanyTags);

  useEffect(() => {
    getCompanyTags();
  }, [currentPage, getCompanyTags]);

  return (
    <div>
      <Header onAdd={getCompanyTags} />

      <Table
        data={companyTags}
        loading={isFetching}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        onPaginationChange={(page) => setCurrentPage(page)}
        onEdit={getCompanyTags}
        onDelete={getCompanyTags}
      />
    </div>
  );
};
