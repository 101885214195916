import React, { useMemo, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Button, Popover } from 'antd';
import { Select } from '../../../../components/Select';
import { PlusOutlined } from '@ant-design/icons';

import { Spinner } from '../../../../components/Spinner';
import { OnOffSwitch } from '../../../../components/OnOffSwitch';
import { ImportPopover } from '../../../../components/ImportPopover';

import { getSelectOptionsWithIdValues } from '../../utils/options';

import { mediaCampaignsService } from '../../../../services/media-campaigns';

import { showApiErrors } from '../../../../utils/showApiErrors';

import styles from './Tabs.module.css';
import { ImportFromCampaignModal } from '../../../../components/ImportFromCampaignModal';
import { MediaCampaignTabKeys } from '.';
import { useEffectTrigger, useForm } from '../../../../utils/hooks';
import { accountsService } from '../../../../services/accounts';
import { generateUserOptions } from '../../../../utils/options';
import dayjs from 'dayjs';

const RuleMetric = {
  Revenue: 'revenue',
  Spent: 'spent',
  Leads: 'leads',
  Impressions: 'impressions',
  Profit: 'profit'
};

export const Rules = ({ form: antdForm, initialData, loadingTabsData, isClone, onFormChange }) => {
  const [campaignStartSwitches, setCampaignStartSwitches] = useState({});
  const [campaignStartRuleMetrics, setCampaignStartRuleMetrics] = useState({});

  const [allRuleMetrics, setAllRuleMetrics] = useState([]);
  const [allRuleMetricsLoading, setAllRuleMetricsLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  const [allUsersLoading, setAllUsersLoading] = useState(false);

  const [rulesData, setRulesData] = useState([]);
  const [rulesNotifiedAt, setRulesNotifiedAt] = useState({});

  const [importModalVisible, setImportModalVisible] = useState(false);

  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();

  const [form] = useForm(activateFormChangedTrigger, antdForm);

  const loading = loadingTabsData;

  const initialValues = useMemo(() => {
    if (!rulesData.length && !initialData) {
      return {
        campaignStartFormList: [
          {
            campaignStartSwitch: false,
            campaignStartRuleMetric: RuleMetric.Impressions,
            campaignStartNumber: 1000,
            campaignStartSlackSwitch: true
          }
        ]
      };
    }

    const notifiedAt = {};

    const campaignStartFormList = rulesData.map((elem, index) => {
      const { id, active, rule_data, assigned_users, notified_at } = elem;

      const { rule_metric, number, notify_on_slack, notify_on_mail } = rule_data;

      notifiedAt[index] = isClone ? null : notified_at;

      return {
        id: isClone ? undefined : id,
        campaignStartSwitch: active,
        assignedUsers: assigned_users,
        campaignStartRuleMetric: rule_metric,
        campaignStartNumber: number,
        campaignStartSlackSwitch: notify_on_slack,
        campaignStartGmailSwitch: notify_on_mail
      };
    });

    setRulesNotifiedAt(notifiedAt);

    return {
      campaignStartFormList
    };
  }, [rulesData, initialData, isClone]);

  useEffect(() => {
    if (initialData) {
      setRulesData(initialData.campaign_start);
    }
  }, [initialData]);

  useEffect(() => form.resetFields(), [form, rulesData, initialValues]);

  useEffect(() => {
    const rulesData = [...form.getFieldValue('campaignStartFormList')];

    let tempStartSwitches = {};
    let tempRuleMetrics = {};

    rulesData.forEach((elem, index) => {
      if (elem) {
        const { campaignStartSwitch, campaignStartRuleMetric } = elem;

        tempStartSwitches[index] = !!campaignStartSwitch;
        tempRuleMetrics[index] = campaignStartRuleMetric;
      }
    });

    setCampaignStartSwitches(tempStartSwitches);
    setCampaignStartRuleMetrics(tempRuleMetrics);
  }, [form, formChangedTrigger]);

  useEffect(() => {
    let didCancel = false;

    const fetchRuleMetrics = async () => {
      try {
        setAllRuleMetricsLoading(true);
        const ruleMetrics = await mediaCampaignsService.getRuleMetrics();

        if (!didCancel) {
          setAllRuleMetrics(ruleMetrics);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllRuleMetricsLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        setAllUsersLoading(true);
        const users = await accountsService.getAll();
        if (!didCancel) {
          setAllUsers(users);
        }
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllUsersLoading(false);
      }
    };

    fetchRuleMetrics();
    fetchUsers();

    return () => {
      didCancel = true;
    };
  }, []);

  const openImportModal = () => setImportModalVisible(true);
  const closeImportModal = () => setImportModalVisible(false);

  return (
    <div>
      <Form
        form={form}
        name="rulesForm"
        initialValues={initialValues}
        onValuesChange={() => {
          onFormChange(true);
          activateFormChangedTrigger();
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className={styles.formRemoveBottomMargin}>
              <div className={styles.ruleHeading}>
                <div className={styles.ruleHeadingText}>
                  Campaign Start <span>- Don’t miss campaign start.</span>
                </div>

                <ImportPopover onImportClick={openImportModal} />
                {importModalVisible && (
                  <ImportFromCampaignModal
                    tabKey={MediaCampaignTabKeys.rules}
                    modalVisible={importModalVisible}
                    onImportData={setRulesData}
                    onClose={closeImportModal}
                  />
                )}
              </div>

              <Form.List name="campaignStartFormList" shouldUpdate>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key} className={styles.ruleDynamicFieldsWrapper}>
                          <div style={{ display: 'none' }}>
                            <Form.Item {...field} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']}>
                              <Input />
                            </Form.Item>
                          </div>

                          {rulesNotifiedAt[index] ? (
                            <>
                              <div className={styles.minContentWidth}>{dayjs(rulesNotifiedAt[index]).format('DD.MM.YYYY.\nHH:mm:ss')}</div>
                              <div>-</div>
                            </>
                          ) : (
                            <div className={styles.ruleStartSwitch}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'campaignStartSwitch']}
                                fieldKey={[field.fieldKey, 'campaignStartSwitch']}
                                valuePropName="checked"
                              >
                                <OnOffSwitch />
                              </Form.Item>
                            </div>
                          )}

                          <div className={styles.ruleAlertTextContent}>Alert</div>

                          <div className={`${styles.formItemWrapper3} ${styles.noMarginField}`}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'assignedUsers']}
                              fieldKey={[field.fieldKey, 'assignedUsers']}
                              rules={[
                                {
                                  required: campaignStartSwitches[index] && !rulesNotifiedAt[index],
                                  message: 'Please select at least one user!'
                                }
                              ]}
                            >
                              <Select
                                placeholder="Select at least one user"
                                optionFilterProp="data-searchvalue"
                                disabled={!campaignStartSwitches[index] || rulesNotifiedAt[index]}
                                showSearch
                                loading={allUsersLoading}
                                mode="multiple"
                              >
                                {generateUserOptions(allUsers)}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className={styles.ruleTextContent}>when campaign</div>

                          <div className={`${styles.formItemWrapper4} ${styles.noMarginField}`}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'campaignStartRuleMetric']}
                              fieldKey={[field.fieldKey, 'campaignStartRuleMetric']}
                              rules={[
                                {
                                  required: campaignStartSwitches[index] && !rulesNotifiedAt[index],
                                  message: 'Please select a rule metric!'
                                }
                              ]}
                            >
                              <Select
                                placeholder="Select one option"
                                optionFilterProp="data-searchvalue"
                                disabled={!campaignStartSwitches[index] || rulesNotifiedAt[index]}
                                showSearch
                                loading={allRuleMetricsLoading}
                              >
                                {getSelectOptionsWithIdValues(allRuleMetrics)}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className={styles.ruleTextContent}>is more than</div>

                          <div className={`${styles.ruleNumberInput} ${styles.noMarginField}`}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'campaignStartNumber']}
                              fieldKey={[field.fieldKey, 'campaignStartNumber']}
                              rules={[
                                {
                                  required: campaignStartSwitches[index] && !rulesNotifiedAt[index],
                                  message: 'Please enter a number!'
                                }
                              ]}
                            >
                              <InputNumber
                                placeholder="Enter a number"
                                min={campaignStartRuleMetrics[index] === RuleMetric.Profit ? -Infinity : 1}
                                step={1}
                                disabled={!campaignStartSwitches[index] || rulesNotifiedAt[index]}
                              />
                            </Form.Item>
                          </div>

                          <div className={styles.switchWithImg}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'campaignStartSlackSwitch']}
                              fieldKey={[field.fieldKey, 'campaignStartSlackSwitch']}
                              valuePropName="checked"
                            >
                              <OnOffSwitch disabled={!campaignStartSwitches[index] || rulesNotifiedAt[index]} />
                            </Form.Item>

                            <div>
                              <img src="/images/slack_logo.png" alt="Slack logo" height="34px" />
                            </div>
                          </div>

                          <div className={styles.switchWithImg}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'campaignStartGmailSwitch']}
                              fieldKey={[field.fieldKey, 'campaignStartGmailSwitch']}
                              valuePropName="checked"
                            >
                              <OnOffSwitch disabled={!campaignStartSwitches[index] || rulesNotifiedAt[index]} />
                            </Form.Item>

                            <div>
                              <img src="/images/gmail_logo.png" alt="Gmail logo" height="34px" />
                            </div>
                          </div>

                          <div className={styles.actionsWrapper}>
                            <Popover content="Clone" trigger="hover">
                              <div className={styles.action}>
                                <img
                                  src="/images/copy.png"
                                  alt="Clone"
                                  width="19px"
                                  onClick={() =>
                                    add({
                                      ...form.getFieldValue(['campaignStartFormList', index]),
                                      id: undefined,
                                      campaignStartNumber: undefined
                                    })
                                  }
                                />
                              </div>
                            </Popover>
                            {!rulesNotifiedAt[index] && (
                              <Popover content="Delete" trigger="hover">
                                <div className={styles.action} onClick={() => remove(field.name)}>
                                  <img src="/images/delete_icon.png" alt="Delete" width="21px" />
                                </div>
                              </Popover>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className={styles.ruleAddDynamicFields}>
                        <Form.Item>
                          <Button ghost type="primary" onClick={() => add()} block>
                            <PlusOutlined /> Add new Alert
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  );
                }}
              </Form.List>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
