import React from 'react';
import { Table as AntTable, Pagination } from 'antd';

export const DomainsInfoTable = ({ loading, domains, totalItems, currentPage, pageSize, onPaginationChange }) => {
  const columns = [
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      width: 'auto'
    },
    {
      title: 'Is Live',
      key: 'is_live',
      dataIndex: 'is_live',
      width: 'auto',
      render: (is_live) => (is_live ? 'True' : 'False')
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 'auto'
    }
  ];

  return (
    <div>
      <AntTable
        loading={loading}
        dataSource={domains}
        style={{ width: 'auto' }}
        rowKey={({ id }) => id}
        columns={columns}
        pagination={false}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
