import { Checkbox, Col, DatePicker, Row, Select } from 'antd';
import React, { useMemo } from 'react';
import { withRefetching } from '../../../../components/SelectWithSideLoader';
import {
  getCountriesAndCountriesLists,
  getCountryList,
  getSubidList,
  getSubidLists,
  useCustomQuery
} from '../../../../utils/hooks/queries';
import { generateCountriesOptions } from '../../../../utils/options';
import { getSelectOptionsWithExtendedTooltip } from '../../../lists/utils/options';
import moment from 'moment';

import styles from './ActivityHeader.module.css';
import { SearchBar } from '../../../../components/SearchBar';

const { RangePicker } = DatePicker;
const { OptGroup } = Select;
const SelectWithRefetching = withRefetching(Select);

export const ActivityHeader = ({ filters, searchValue, onFilterChange, onSearch }) => {
  const {
    isFetching: allCountryAndCountryListsRefetching,
    isLoading: allCountryAndCountryListsLoading,
    data: allCountryAndCountryLists
  } = useCustomQuery(getCountriesAndCountriesLists);

  const {
    isLoading: allSubidListsLoading,
    isFetching: allSubidListsRefetching,
    data: allSubidLists
  } = useCustomQuery(getSubidLists, { fields: 'id,name,exclude' });

  const [countryLists, countries] = useMemo(() => {
    const countryLists = allCountryAndCountryLists.results
      ? allCountryAndCountryLists.results.slice(0, allCountryAndCountryLists.lists_count)
      : [];

    const countries = allCountryAndCountryLists.results
      ? allCountryAndCountryLists.results.slice(allCountryAndCountryLists.lists_count)
      : [];

    return [countryLists, countries];
  }, [allCountryAndCountryLists]);

  return (
    <>
      <Row gutter={32} className={styles.filterRow}>
        <Col span={6}>
          <div className={styles.filterLabel}>Date range</div>
          <RangePicker
            className={styles.filterRangePicker}
            format="DD.MM.YYYY"
            ranges={{
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 2 Days': [moment().subtract(1, 'days'), moment()],
              'Last 3 Days': [moment().subtract(2, 'days'), moment()],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(30, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
            }}
            onChange={(momentDates) => {
              onFilterChange({
                ...filters,
                startDate: momentDates ? momentDates[0] : null,
                endDate: momentDates ? momentDates[1] : null
              });
            }}
            value={[filters.startDate, filters.endDate]}
            style={{ width: '100%' }}
          />
        </Col>

        <Col span={6}>
          <Checkbox
            className={styles.filterCheckbox}
            checked={!!filters.showAllChanges}
            onChange={(e) => {
              const showAllChanges = e.target.checked;
              onFilterChange({
                ...filters,
                showAllChanges,
                showTargetingChanges: showAllChanges || !!filters.showTargetingChanges,
                showPricingChanges: showAllChanges || !!filters.showPricingChanges,
                showAutomationRulesChanges: showAllChanges || !!filters.showAutomationRulesChanges
              });
            }}
          >
            Show all changes
          </Checkbox>
        </Col>

        <Col span={6}>
          <Checkbox
            className={styles.filterCheckbox}
            checked={!!filters.showTargetingChanges}
            onChange={(e) => {
              const showTargetingChanges = e.target.checked;
              onFilterChange({ ...filters, showTargetingChanges, showAllChanges: showTargetingChanges ? !!filters.showAllChanges : false });
            }}
          >
            Show targeting changes
          </Checkbox>
        </Col>

        <Col span={6}>
          <Checkbox
            className={styles.filterCheckbox}
            checked={!!filters.showPricingChanges}
            onChange={(e) => {
              const showPricingChanges = e.target.checked;
              onFilterChange({ ...filters, showPricingChanges, showAllChanges: showPricingChanges ? !!filters.showAllChanges : false });
            }}
          >
            Show pricing changes
          </Checkbox>
        </Col>
      </Row>

      <Row gutter={32} className={styles.filterRow}>
        <Col span={6}>
          <div className={styles.filterLabel}>Country/Country list</div>
          <SelectWithRefetching
            className={styles.filterSelect}
            placeholder="Select country or country list"
            dropdownClassName={styles.selectDropdownWithImg}
            optionFilterProp="data-searchvalue"
            showSearch
            allowClear
            loading={allCountryAndCountryListsLoading}
            refetching={allCountryAndCountryListsRefetching}
            value={filters.countryOrCountryList}
            onChange={(countryOrCountryList) => onFilterChange({ ...filters, countryOrCountryList })}
          >
            <OptGroup key="countryLists" label="Country Lists">
              {getSelectOptionsWithExtendedTooltip(countryLists, 'countries_list', false, getCountryList)}
            </OptGroup>

            <OptGroup key="countries" label="Countries">
              {generateCountriesOptions(countries)}
            </OptGroup>
          </SelectWithRefetching>
        </Col>

        <Col span={6}>
          <div className={styles.filterLabel}>Source list</div>
          <SelectWithRefetching
            className={styles.filterSelect}
            placeholder="Select source list"
            dropdownClassName={styles.selectDropdownWithImg}
            optionFilterProp="data-searchvalue"
            showSearch
            allowClear
            loading={allSubidListsLoading}
            refetching={allSubidListsRefetching}
            value={filters.sourceList}
            onChange={(sourceList) => onFilterChange({ ...filters, sourceList })}
          >
            {getSelectOptionsWithExtendedTooltip(allSubidLists, 'subids_list', true, getSubidList)}
          </SelectWithRefetching>
        </Col>

        <Col span={6}>
          <div className={styles.filterLabel}>Search</div>
          <SearchBar value={searchValue} onChange={(e) => onSearch(e.target.value)} />
        </Col>

        <Col span={6}>
          <Checkbox
            className={styles.filterCheckbox}
            checked={!!filters.showAutomationRulesChanges}
            onChange={(e) => {
              const showAutomationRulesChanges = e.target.checked;
              onFilterChange({
                ...filters,
                showAutomationRulesChanges,
                showAllChanges: showAutomationRulesChanges ? !!filters.showAllChanges : false
              });
            }}
          >
            Show A.R. changes
          </Checkbox>
        </Col>
      </Row>
    </>
  );
};
