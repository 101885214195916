import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button } from 'antd';
import { countriesService } from '../../services/countries';
import { mediaCampaignsService } from '../../services/media-campaigns';
import { userAgentsService } from '../../services/user-agent';
import { VirtualizedSelect } from '../../components/VirtualizedSelect';
import { PlusOutlined } from '@ant-design/icons';
import { showApiErrors } from '../../utils/showApiErrors';
export const Header = ({ onSubmit, loading }) => {
  const [countries, setCountries] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(false);

  const [userAgents, setUserAgents] = useState([]);
  const [userAgentsLoading, setUserAgentsLoading] = useState(false);

  const [channels, setChannels] = useState([]);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    const getChannels = async () => {
      try {
        setChannelsLoading(true);
        const channels = await mediaCampaignsService.getCampaigns();
        setChannels(channels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setChannelsLoading(false);
      }
    };
    const getCountries = async () => {
      try {
        setCountriesLoading(true);
        const countries = await countriesService.getAll({ fields: 'id,name,code2,img' });
        setCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCountriesLoading(false);
      }
    };
    const getBrowsers = async () => {
      try {
        setUserAgentsLoading(true);
        const userAgent = await userAgentsService.getAll();
        setUserAgents(userAgent.data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUserAgentsLoading(false);
      }
    };

    getChannels();
    getBrowsers();
    getCountries();
  }, []);

  return (
    <div>
      <Form
        form={form}
        onFinish={(e) => {
          onSubmit(e);
        }}
        layout="vertical"
      >
        <Form.Item
          name="url"
          label="Test Link"
          rules={[
            {
              required: true,
              message: 'Please enter url!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={10} lg={10} xl={8} xxl={8}>
            <Form.Item
              name="channel"
              label="Channel"
              rules={[
                {
                  required: true,
                  message: 'Please select channel!'
                }
              ]}
              valuePropName="selectValue"
            >
              <VirtualizedSelect
                options={mediaCampaignsService.generateOptions(channels)}
                placeholder={'Select channels'}
                loading={channelsLoading}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10} lg={10} xl={8} xxl={8}>
            <Form.Item
              name="user_agent"
              label="User agent"
              rules={[
                {
                  required: true,
                  message: 'Please select user agent!'
                }
              ]}
              valuePropName="selectValue"
            >
              <VirtualizedSelect
                options={userAgentsService.generateOptions(userAgents)}
                placeholder={'Select user agent'}
                loading={userAgentsLoading}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10} lg={10} xl={8} xxl={8}>
            <Form.Item name="country" label="Country" valuePropName="selectValue">
              <VirtualizedSelect
                options={countriesService.generateOptions(countries)}
                placeholder={'Select country'}
                loading={countriesLoading}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }} gutter={32}>
          <Col>
            <Button type="danger" onClick={() => form.resetFields()}>
              Clear All Fields
            </Button>
          </Col>
          <Col>
            <Form.Item className="modal_buttons">
              <Button type="primary" htmlType="submit" className="myButton" loading={loading}>
                <PlusOutlined /> Fire test fallback
              </Button>
            </Form.Item>{' '}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
