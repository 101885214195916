import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { userNotificationsService } from '../../../services/user-notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { useHistory } from 'react-router';
import styles from './NotificationPreviewModal.module.css';
import ShowMoreText from 'react-show-more-text';
import dayjs from 'dayjs';
import { LogTable } from './LogTable';

export const NotificationPreviewModal = ({
  modalVisible,
  notificationId,
  onClose,
  platformUsers
}) => {

  const [notification, setNotification] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  const history = useHistory();

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    (async () => {
      if (modalVisible) {
        try {
          const userNotif = await userNotificationsService.getById({ id: notificationId });
          setNotification(userNotif);
        } catch (e) {
          showApiErrors(e);
        }
      }
    })();
  }, [modalVisible, notificationId]);

  const getNotificationCreator = (creatorId) => {
    if (platformUsers) {
      let kreator = platformUsers.filter((element) => element.id === creatorId);
      if (kreator[0]) {
        return kreator[0].first_name + ' ' + kreator[0].last_name;
      }
    }
  };

  return (
    <Modal
      centered
      className={styles.previewModal}
      closable={false}
      visible={modalVisible}
      title="Notification details"
      okText="Edit details"
      onOk={() => {
        handleCancel();
        history.push(`/user-notifications/${notificationId}`);
      }}
      okButtonProps={{ disabled: notification.action === 'Deleted' }}
      cancelText="Back to notifications"
      onCancel={handleCancel}
    >
      <h3>Notification name</h3>
      <p>{notification.title}</p>
      <h3>Description</h3>
      <ShowMoreText
        lines={5}
        more="Show more"
        less="Show less"
        className={styles.desc}
        anchorClass="show-more-less-clickable"
        onClick={() => setIsExpanded(!isExpanded)}
        truncatedEndingComponent={'... '}
      >
        {notification.description}
      </ShowMoreText>
      <h3>Icon & type</h3>
      <div className={styles.type}>
        {notification.icon && <img alt='icon' src={notification.icon} />}
        <span>{notification.type}</span>
      </div>
      <h3>Creator</h3>
      <p>{getNotificationCreator(notification.creator)}</p>
      <h3>Date created</h3>
      <p>{dayjs(notification.date_created).format('DD.MM.YYYY. - HH:mm:ss')}</p>
      <h3>Edit Log</h3>
      <LogTable notificationId={notificationId} getNotificationCreator={getNotificationCreator} />
    </Modal>
  );
};
