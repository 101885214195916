import React, { useState } from 'react';
import { Table as AntTable, Pagination } from 'antd';
import { TableTitle } from '../../../components/TableTitle';
import { IdentityInfoModal } from './IdentityInfoModal';

export const Table = ({
  loading,
  identities,
  totalItems,
  currentPage,
  pageSize,
  sorting,
  onPaginationChange,
  onSortingClick,
  onAction
}) => {
  const [currIndex, setCurrIndex] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setCurrIndex(-1);
    setModalVisible(false);
  };

  const openModal = (index) => {
    setCurrIndex(index);
    setModalVisible(true);
  };

  const columns = [
    {
      title: <TableTitle text="Id" value="id" order={sorting.find((s) => s.key === 'id')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'id',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        };
      }
    },
    {
      title: <TableTitle text="Name" value="name" order={sorting.find((s) => s.key === 'name')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'name'
    },
    {
      title: <TableTitle text="Type" value="type" order={sorting.find((s) => s.key === 'type')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'type'
    },
    {
      title: (
        <TableTitle text="Status" value="status" order={sorting.find((s) => s.key === 'status')?.value} onSortingClick={onSortingClick} />
      ),
      dataIndex: 'status'
    },
    {
      title: (
        <TableTitle
          text="Total domains count"
          value="active_domains_num"
          order={sorting.find((s) => s.key === 'active_domains_num')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'active_domains_num'
    }
  ];

  return (
    <div>
      <AntTable
        loading={loading}
        dataSource={identities}
        style={{ marginTop: '3%' }}
        rowKey={({ id }) => id}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        onRow={(_, index) => ({
          onClick: () => openModal(index)
        })}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
      {currIndex !== -1 && (
        <IdentityInfoModal onAction={onAction} info={identities[currIndex]} modalVisible={modalVisible} onCloseModal={closeModal} />
      )}
    </div>
  );
};
