import { callApi } from './api';

class AuthService {
  userPermissions = { isAdmin: false, permissions: [] };
  user = null;

  async loadPermissions() {
    const data = await callApi({ url: '/api/accounts/get_user_permissions/' });

    const { is_admin: isAdmin, permissions } = data.results;
    this.userPermissions = { isAdmin, permissions };
    return data;
  }

  async getUserProfile() {
    if (this.user) {
      return this.user;
    } else {
      this.user = (await callApi({ url: '/api/accounts/' }))[0];
      return this.user;
    }
  }

  getPermissions() {
    if (this.userPermissions.isAdmin) {
      return true;
    }

    return this.userPermissions.permissions;
  }

  hasPermission(permission) {
    return !permission || this.userPermissions.isAdmin || this.userPermissions.permissions.includes(permission);
  }
}

export const auth = new AuthService();
