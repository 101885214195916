import React, { useEffect, useState } from 'react';
import { useEffectTrigger } from '../../../../utils/hooks';

import { Header } from './Header';
import { Table } from './Table';

const tempChecked = {
  id: true,
  channel: true,
  company: true,
  campaignUrl: true,
  dateAndTimeCreated: true,
  dateAndTimeEdited: true
};

export const ShowMediaCampaigns = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  companies,
  identities,
  companiesLoading,
  identitiesLoading,
  filters,
  searchValue,
  utmCampaign,
  sorting,
  onPaginationChange,
  onFilter,
  onSearch,
  onUtmCampaignChange,
  onActivate,
  onPause,
  onSuspend,
  onDelete,
  onSortingClick
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowTitles, setSelectedRowTitles] = useState([]);
  const [copyInfoTrigger, activateCopyInfoTrigger] = useEffectTrigger();
  const [checkedColumns, setCheckedColumns] = useState(tempChecked);

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRowTitles([]);
  }, [campaigns, copyInfoTrigger]);

  const tableRowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, records) => {
      const titles = records.map(({ title }) => title);

      setSelectedRowKeys(rowKeys);
      setSelectedRowTitles(titles);
    }
  };

  return (
    <div>
      <Header
        searchValue={searchValue}
        companies={companies}
        identities={identities}
        identitiesLoading={identitiesLoading}
        companiesLoading={companiesLoading}
        filters={filters}
        utmCampaign={utmCampaign}
        liveCampaigns={totalRows}
        selectedRowKeys={selectedRowKeys}
        selectedRowTitles={selectedRowTitles}
        checkedColumns={checkedColumns}
        onColumnsChange={setCheckedColumns}
        onFilter={onFilter}
        onSearch={onSearch}
        onActivate={onActivate}
        onPause={onPause}
        onSuspend={onSuspend}
        onCopyInfo={activateCopyInfoTrigger}
        onDelete={onDelete}
        onUtmCampaignChange={onUtmCampaignChange}
      />

      <Table
        campaigns={campaigns}
        checkedColumns={checkedColumns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={loadingCampaigns}
        rowSelection={tableRowSelection}
        sorting={sorting}
        onPaginationChange={onPaginationChange}
        onActivate={onActivate}
        onPause={onPause}
        onSuspend={onSuspend}
        onDelete={onDelete}
        onSortingClick={onSortingClick}
      />
    </div>
  );
};
