import React from 'react';
import { Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import styles from '../Lists.module.css';
import { SearchBar } from '../../../components/SearchBar';
import { getAccountsSelectOptions } from '../utils/options';
import { getAccounts, useCustomQuery } from '../../../utils/hooks/queries';
import { withRefetching } from '../../../components/SelectWithSideLoader';

const SelectWithRefetching = withRefetching(Select);

export const Heading = ({ searchValue, listType, filters, onFilter, onSearch, onCreateListClicked }) => {
  const { isLoading: allAccountsLoading, isFetching: allAccountsRefetching, data: allAccounts } = useCustomQuery(getAccounts);

  return (
    <div className={styles.headingActions}>
      <div>
        <SearchBar id={`search${listType.replace(/\s/g, '')}`} value={searchValue} width={400} onChange={(e) => onSearch(e.target.value)} />
      </div>

      <div>
        <SelectWithRefetching
          className={styles.assignedFilter}
          placeholder="Select assigned"
          allowClear
          showSearch
          optionFilterProp="data-searchvalue"
          loading={allAccountsLoading}
          refetching={allAccountsRefetching}
          onChange={(assigned) => {
            onFilter({ ...filters, assigned });
          }}
        >
          {getAccountsSelectOptions(allAccounts)}
        </SelectWithRefetching>
      </div>

      <div>
        <Button type="primary" onClick={onCreateListClicked}>
          <PlusOutlined />
          Create {listType} List
        </Button>
      </div>
    </div>
  );
};
