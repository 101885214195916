import { Modal } from 'antd';

const confirm = Modal.confirm;

export const confirmClose = (onOk, onCancel = () => {}) => {
  confirm({
    maskClosable: true,
    title: 'Are you sure you want to leave without saving changes first?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    }
  });
};
