import { CopyTwoTone, UploadOutlined } from '@ant-design/icons';
import { Popconfirm, Popover } from 'antd';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { openSuccessNotification } from '../../../utils/notifications';

import styles from './ActionsTableCol.module.css';
import { UploadImageModal } from './UploadImageModal';

export const ActionsTableCol = ({ id, previewUrl, onClone, onUpload, onDelete }) => {
  const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false);

  const handleOpenUploadImageModal = () => setUploadImageModalVisible(true);
  const handleCloseUploadImageModal = () => setUploadImageModalVisible(false);

  return (
    <div className={styles.actions}>
      <Link to={{ pathname: `/editor/${id}/` }}>
        {/* TODO: tooltip*/}
        <Popover content="Edit" trigger="hover">
          <img src="/images/edit_icon.svg" width="20px" alt="pen" />
        </Popover>
      </Link>

      <Link to={`/stubs/channel_stats/?lp_id=${id}`}>
        {/* TODO: tooltip*/}
        <Popover content="Channel stats for this landing" trigger="hover">
          <img src="/images/table.png" width="20px" alt="table" />
        </Popover>
      </Link>

      <Popconfirm
        onConfirm={() => onClone(id)}
        onCancel={() => {}}
        title="Are you sure that you want to clone this landing page?"
        okText="Yes"
        cancelText="No"
      >
        <a>
          {/* TODO: button instead of link*/}
          {/* TODO: tooltip*/}
          <Popover content="Clone" trigger="hover">
            <img src="/images/copy.png" width="20px" alt="pen" />
          </Popover>
        </a>
      </Popconfirm>

      <Popover content="Copy Preview URL" trigger="hover">
        <div onClick={(e) => e.stopPropagation()}>
          <CopyToClipboard text={previewUrl} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
            <CopyTwoTone style={{ fontSize: 18, marginTop: 4 }} />
          </CopyToClipboard>
        </div>
      </Popover>

      <>
        <Popover content="Upload Image" trigger="hover">
          <div onClick={(e) => e.stopPropagation()}>
            <UploadOutlined style={{ fontSize: 18, marginTop: 4, color: '#188EFB' }} onClick={handleOpenUploadImageModal} />
          </div>
        </Popover>

        <UploadImageModal
          modalVisible={uploadImageModalVisible}
          lpId={id}
          onCancel={handleCloseUploadImageModal}
          onUpload={() => {
            onUpload();
            handleCloseUploadImageModal();
          }}
        />
      </>

      <Popconfirm
        onConfirm={() => onDelete(id)}
        onCancel={() => {}}
        title="Are you sure that you want to delete this landing page?"
        okText="Yes"
        cancelText="No"
      >
        <a>
          {/* TODO: button instead of link*/}
          {/* TODO: tooltip*/}
          <Popover content="Delete" trigger="hover">
            <img src="/images/delete_icon.png" width="22px" alt="delete" />
          </Popover>
        </a>
      </Popconfirm>
    </div>
  );
};
