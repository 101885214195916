import { InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { SearchBar } from '../../../components/SearchBar';
import { generateOptions, generateOptionsFromObjectKeyValue, generateUserOptions, getActivityOptions } from '../../../utils/options';
import { AddOrEditCompany } from './AddOrEditCompany';
import styles from './Header.module.css';
import { AdvancedFiltering } from './AdvancedFiltering';
import { showApiErrors } from '../../../utils/showApiErrors';
import { companyTagsService } from '../../../services/company-tags';
import { STATUS_LABEL } from '../../../services/companies';

const { RangePicker } = DatePicker;

export const Header = ({
  onSearch,
  countries,
  users,
  onCompanyAdded,
  loader,
  searchValue,
  publisherCategories,
  advertiserCategories,
  selectedTags,
  status,
  activity,
  selectedAccountManager,
  selectedBroughtByManagers,
  clearFiltersTrigger,
  categoriesLoading,
  allPublisherCategories,
  allAdvertiserCategories,
  onStatusChange,
  onTagsChange,
  onBroughtByManagersChange,
  onBroughtByDateChange,
  onActivityChange,
  onClearFiltersActivate,
  onPublisherCategoryChange,
  onAdvertiserCategoryChange,
  onAdvancedFiltering,
  onAccountManagerFilter
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);

  useEffect(() => {
    const getTags = async () => {
      try {
        setTagsLoading(true);
        const data = await companyTagsService.getAll();
        setTags(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTagsLoading(false);
      }
    };

    getTags();
  }, []);

  return (
    <>
      <Row gutter={24} className={styles.headerRow}>
        <Col>
          <span className={styles.filterTitle}>Search</span>
          <SearchBar value={searchValue} width={'100%'} onChange={(e) => onSearch(e.target.value)} />
        </Col>

        <Col>
          <span className={styles.filterTitle}>Publisher categories</span>
          <Select
            placeholder="Select publisher categories"
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            value={publisherCategories}
            onChange={onPublisherCategoryChange}
            optionFilterProp="data-searchvalue"
          >
            {generateOptions(allPublisherCategories, 'title', 'id')}
          </Select>
        </Col>

        <Col>
          <span className={styles.filterTitle}>Advertiser categories</span>
          <Select
            placeholder="Select advertiser categories"
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            value={advertiserCategories}
            onChange={onAdvertiserCategoryChange}
            optionFilterProp="data-searchvalue"
          >
            {generateOptions(allAdvertiserCategories, 'title', 'id')}
          </Select>
        </Col>

        <Col>
          <span className={styles.filterTitle}>
            <span>Activity</span>
            <Tooltip
              overlayClassName={styles.activityTooltip}
              title="If company’s Spent or Earned has been over 0 in the past 6 months, or it is created in last 2 months, it is considered an Active company."
            >
              <InfoCircleTwoTone style={{ fontSize: 10, marginLeft: 5, cursor: 'help' }} />
            </Tooltip>
          </span>
          <Select placeholder="Select activity" style={{ width: '100%' }} value={activity} onChange={onActivityChange} allowClear>
            {getActivityOptions()}
          </Select>
        </Col>

        <Col>
          <span className={styles.filterTitle}>Status</span>
          <Select placeholder="Select status" style={{ width: '100%' }} value={status} onChange={onStatusChange} allowClear>
            {generateOptionsFromObjectKeyValue(STATUS_LABEL)}
          </Select>
        </Col>

        <Col>
          <span className={styles.filterTitle}>Tags</span>
          <Select
            placeholder="Select tags"
            style={{ width: '100%' }}
            onChange={onTagsChange}
            allowClear
            mode="multiple"
            loading={tagsLoading}
            optionFilterProp="data-searchvalue"
            value={selectedTags}
          >
            {generateOptions(tags, 'name', 'id')}
          </Select>
        </Col>

        <Col>
          <span className={styles.filterTitle}>Account manager</span>
          <Select
            placeholder="Select account manager"
            style={{ width: '100%' }}
            onChange={onAccountManagerFilter}
            allowClear
            showSearch
            loading={loader.users}
            optionFilterProp="data-searchvalue"
            value={selectedAccountManager}
          >
            {generateUserOptions(users)}
          </Select>
        </Col>

        <Col>
          <span className={styles.filterTitle}>Brought by</span>
          <Select
            placeholder="Select brought by"
            style={{ width: '100%' }}
            mode="multiple"
            onChange={onBroughtByManagersChange}
            allowClear
            showSearch
            loading={loader.users}
            optionFilterProp="data-searchvalue"
            value={selectedBroughtByManagers}
          >
            {generateUserOptions(users)}
          </Select>
        </Col>
        <Col>
          <span className={styles.filterTitle}>Brought by date</span>
          <RangePicker
            format="YYYY-MM-DD"
            ranges={{
              Today: [moment(), moment()],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(30, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
            }}
            onChange={(momentDates, dates) => {
              if (momentDates) {
                let broughtByDate = { from: momentDates[0], to: momentDates[1] };
                onBroughtByDateChange(broughtByDate);
              } else {
                let broughtByDate = { from: null, to: null };
                onBroughtByDateChange(broughtByDate);
              }
            }}
          />
        </Col>
      </Row>

      <Row gutter={24} className={styles.btnsWrapperRow}>
        <Col>
          <Button onClick={onClearFiltersActivate}>Show all companies</Button>
        </Col>

        <Col className={styles.addBtnCol}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
            Add New Company
          </Button>
        </Col>
      </Row>

      <AddOrEditCompany
        onSubmitSuccess={() => {
          hideModal();
          onCompanyAdded();
        }}
        onCancel={hideModal}
        countries={countries}
        users={users}
        onFormChanged={setWasFormChanged}
        wasFormChanged={wasFormChanged}
        isShown={modalVisible}
        loader={loader}
        categoriesLoading={categoriesLoading}
        publisherCategories={allPublisherCategories}
        advertiserCategories={allAdvertiserCategories}
      />

      <AdvancedFiltering
        onAdvancedFiltering={onAdvancedFiltering}
        allAdvertiserCategories={allAdvertiserCategories}
        allPublisherCategories={allPublisherCategories}
        tags={tags}
        clearFiltersTrigger={clearFiltersTrigger}
      />
    </>
  );
};
