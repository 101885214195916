import React, { useState, useEffect } from 'react';
import { Table } from './components/Table';
import { useFetch } from '../../utils/hooks';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserNotificationForm } from './components/UserNotificationForm';
import { CloneNotificationModal } from './components/CloneNotificationModal';
import { showApiErrors } from '../../utils/showApiErrors';
import { NotificationPreviewModal } from './components/NotificationPreviewModal';
import { NotificationsFilters } from './components/NotificationsFilters';

import { companiesService } from '../../services/companies';
import { userNotificationsService } from '../../services/user-notifications';
import { accountsService } from '../../services/accounts';

import styles from './UserNotifications.module.css';
import moment from 'moment';
import { SortDirection } from '../../services/statistic';

const defaultChecked = {
  title: true,
  type: true,
  company: true,
  module: true,
  startDate: true,
  status: true
};

export const UserNotifications = (props) => {
  const urlId = props.match.params.id;

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  const [notificationId, setNotificationId] = useState();
  const [cloneModalVisible, setCloneModalVisible] = useState(false);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'id', value: SortDirection.Descending }]);
  const [checkedColumns, setCheckedColumns] = useState(defaultChecked);

  const [createdDateRange, setCreatedDateRange] = useState({
    from: moment().subtract(7, 'days'),
    to: moment()
  });

  const [editedDateRange, setEditedDateRange] = useState({
    from: null,
    to: null
  });

  const [filters, setFilters] = useState({
    dateRange: {
      from: moment().subtract(7, 'days'),
      to: moment()
    },
    onlyPending: false
  });

  useEffect(() => {
    (async () => {
      setCompaniesLoading(true);
      try {
        const companies = await companiesService.getAll({ fields: 'id,name', assigned_type: 'product' });
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCompaniesLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateRange: {
        from: createdDateRange.from,
        to: createdDateRange.to
      },
      dateEditedRange: {
        from: editedDateRange.from,
        to: editedDateRange.to
      }
    }));
  }, [createdDateRange, editedDateRange]);

  useEffect(() => {
    (async () => {
      setUsersLoading(true);
      try {
        const users = await accountsService.getAll();
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    })();
  }, []);

  const loadNotifications = (cancelToken) =>
    userNotificationsService.getPaged({
      currentPage,
      pageSize,
      filters,
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: test, count: totalItems },
      isFetching
    },
    getNotifications
  ] = useFetch(loadNotifications);

  useEffect(() => {
    getNotifications();
  }, [currentPage, getNotifications, sorting]);

  useEffect(() => setCurrentPage(1), [sorting]);

  return (
    <div>
      {urlId ? (
        <UserNotificationForm urlId={urlId} onAction={getNotifications} isClone={false} />
      ) : (
        <>
          <div className={styles.titleContent}>
            <h1>User Notifications</h1>
            <Button type="primary" onClick={() => history.push('/user-notifications/new')}>
              Add New Notification
            </Button>
          </div>
          <NotificationsFilters
            filters={filters}
            setFilters={setFilters}
            onFilter={() => {
              getNotifications();
              setCurrentPage(1);
            }}
            createdDateRange={createdDateRange}
            setCreatedDateRange={setCreatedDateRange}
            setEditedDateRange={setEditedDateRange}
            users={users}
            usersLoading={usersLoading}
            companies={companies}
            companiesLoading={companiesLoading}
            checkedColumns={checkedColumns}
            onColumnsChange={setCheckedColumns}
          />
          <Table
            loading={isFetching}
            platformUsers={users}
            allCompanies={companies}
            userNotifications={test}
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            sorting={sorting}
            checkedColumns={checkedColumns}
            onPaginationChange={setCurrentPage}
            setNotificationId={setNotificationId}
            setCloneModalVisible={setCloneModalVisible}
            setDetailsModalVisible={setDetailsModalVisible}
            onDelete={getNotifications}
            onSortingClick={setSorting}
          />
          <CloneNotificationModal
            modalVisible={cloneModalVisible}
            notificationId={notificationId}
            onAction={getNotifications}
            onClose={() => setCloneModalVisible(false)}
          />
          <NotificationPreviewModal
            modalVisible={detailsModalVisible}
            notificationId={notificationId}
            onClose={() => setDetailsModalVisible(false)}
            platformUsers={users}
          />
        </>
      )}
    </div>
  );
};
