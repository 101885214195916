import React from 'react';
import { Header } from './Header';
import { s2sService } from '../../services/s2s';
export const S2SLog = () => {
  const handleExport = async (filters) => {
    window.open(await s2sService.getExportUrl({ filters }));
  };
  return (
    <div>
      <h1>S2S Log</h1>
      <Header
        onExport={(values) => {
          handleExport(values);
        }}
        loading={false}
      />
    </div>
  );
};
