import { Form, Modal, Select, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { MediaCampaignTabKeys } from '../pages/media-campaigns/components/media-campaign-tabs';
import { getSelectOptionsWithIdValues } from '../pages/media-campaigns/utils/options';

import { mediaCampaignsService } from '../services/media-campaigns';
import { confirmClose } from '../utils/confirmClose';
import { openWarningNotification } from '../utils/notifications';
import { showApiErrors } from '../utils/showApiErrors';

import styles from './ImportFromCampaignModal.module.css';
import { getMediaCampaigns, useCustomQuery } from '../utils/hooks/queries';
import { withRefetching } from './SelectWithSideLoader';

const SelectWithRefetching = withRefetching(Select);

export const ImportFromCampaignModal = ({ tabKey, modalVisible, onImportData, onClose }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [form] = Form.useForm();

  const { isLoading: allCampaignsLoading, isFetching: allCampaignsRefetching, data: allCampaigns } = useCustomQuery(getMediaCampaigns);

  const titleAndButtonText = `Import ${tabKey === MediaCampaignTabKeys.targeting ? 'Targeting' : 'Rules'} Data`;

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, modalVisible]);

  const onImport = async (channel) => {
    try {
      setDataLoading(true);
      const {
        campaign_targeting: targetingData,
        rules: { campaign_start: rulesData }
      } = await mediaCampaignsService.getFormattedCampaign(channel);

      if (tabKey === MediaCampaignTabKeys.targeting) {
        delete targetingData.id;
        onImportData(targetingData);
      } else {
        const clearedRulesData = rulesData?.map((rule) => {
          const { id, ...otherData } = rule;
          return otherData;
        });

        onImportData(clearedRulesData);

        if (!rulesData.length) {
          openWarningNotification({ message: 'Chosen media campaign rules data is empty' });
        }
      }
    } catch (e) {
      showApiErrors(e);
    } finally {
      setDataLoading(false);
    }
  };

  const handleCancel = () => {
    wasFormChanged ? confirmClose(() => onClose()) : onClose();
  };

  return (
    <Modal
      title={titleAndButtonText}
      visible={modalVisible}
      wrapClassName={styles.importFromCampaignModal}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={dataLoading} onClick={() => form.submit()}>
          {titleAndButtonText}
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={async ({ channel }) => {
          await onImport(channel);
          onClose();
        }}
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <Form.Item
          name="channel"
          rules={[
            {
              required: true,
              message: 'Please select a channel!'
            }
          ]}
        >
          <SelectWithRefetching
            placeholder="Channel"
            loading={allCampaignsLoading}
            refetching={allCampaignsRefetching}
            showSearch
            optionFilterProp="data-searchvalue"
          >
            {getSelectOptionsWithIdValues(allCampaigns)}
          </SelectWithRefetching>
        </Form.Item>
      </Form>
    </Modal>
  );
};
