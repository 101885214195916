import React from 'react';

import { TableCommon } from '../TableCommon';
import { ActionsTableCol } from './ActionsTableCol';

export const Table = ({
  allDeviceTypes,
  allAccounts,
  deviceTypeLists,
  loading,
  totalRows,
  currentPage,
  pageSize,
  loader,
  sorting,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const renderSelectedItems = ({ device_type_list }) => device_type_list;

  const renderActions = (record) => {
    return (
      <ActionsTableCol
        allDeviceTypes={allDeviceTypes}
        allAccounts={allAccounts}
        deviceTypeList={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        loader={loader}
      />
    );
  };

  return (
    <TableCommon
      lists={deviceTypeLists}
      loading={loading}
      totalRows={totalRows}
      currentPage={currentPage}
      pageSize={pageSize}
      itemsTitle="List of Device Types"
      sorting={sorting}
      onPaginationChange={onPaginationChange}
      renderSelectedItems={renderSelectedItems}
      renderActions={renderActions}
      onSortingClick={onSortingClick}
    />
  );
};
