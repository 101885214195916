import React from 'react';
import { Tooltip } from 'antd';

import styles from './CampaignNameCol.module.css';

export const CampaignNameCol = ({ creator, channel, companyDetails, onlyInitials, showCheckIcon }) => {
  const getInitials = () => {
    if (!creator) {
      return 'XX';
    }

    const { first_name, last_name } = creator;
    return `${first_name ? first_name.charAt(0).toUpperCase() : 'X'}${last_name ? last_name.charAt(0).toUpperCase() : 'X'}`;
  };

  const wrapperClasses = () => {
    return onlyInitials ? styles.campaignNameWrapper + ' ' + styles.nameInitialsOnly : styles.campaignNameWrapper;
  };

  const initialsDiv = (
    <div className={styles.nameInitials}>
      {getInitials()}
      {showCheckIcon ? (
        <div className={styles.checkIconWrapper}>
          <img src="/images/check_icon.png" alt="Check icon" />
        </div>
      ) : null}
    </div>
  );

  return (
    <div>
      {onlyInitials ? (
        <div className={wrapperClasses()}>
          <Tooltip placement="right" title={channel + '\n' + companyDetails.name} overlayClassName={styles.nameInitialsTootltip}>
            {/* <div className={styles.nameInitials}>{getInitials()}</div> */}
            {initialsDiv}
          </Tooltip>
        </div>
      ) : (
        <div className={wrapperClasses()}>
          {/* <div className={styles.nameInitials}>{getInitials()}</div> */}
          {initialsDiv}

          <div>
            <div className={styles.channelName}>{channel}</div>
            <div className={styles.companyName}>{companyDetails.name}</div>
          </div>
        </div>
      )}

      {/* {!onlyInitials ? (
        <div>
          <div className={styles.channelName}>{channel}</div>
          <div className={styles.companyName}>{companyDetails.name}</div>
        </div>
      ) : null} */}
    </div>
  );
};
