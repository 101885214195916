import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';

import React, { useMemo, useState } from 'react';
import { leadSourcesService } from '../../../services/lead-sources';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';

import styles from './AddOrEditLeadSource.module.css';

// TODO: Add date picker from-to validation
export const AddOrEditLeadSource = ({ modalVisible, leadSource, onClose, onAdd, onEdit }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const action = leadSource ? 'Edit' : 'Add';

  const initialValues = useMemo(() => {
    if (!leadSource) {
      return {
        website: false
      };
    }

    const { active_from: activeFrom, active_to: activeTo } = leadSource;

    // Error on open date picker when using dayjs
    const active_from = moment(activeFrom);
    const active_to = activeTo ? moment(activeTo) : undefined;

    return { ...leadSource, active_from, active_to };
  }, [leadSource]);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onClose) : onClose();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);

      const { active_from, active_to, website, ...otherValues } = values;

      const fomratedValues = {
        ...otherValues,
        website: !!website,
        active_from: moment(active_from).format('YYYY-MM-DD'),
        active_to: active_to ? moment(active_to).format('YYYY-MM-DD') : null
      };

      if (leadSource?.id) {
        await leadSourcesService.edit(fomratedValues, leadSource.id);
        openSuccessNotification({ message: 'Successfully added lead source!' });
        onEdit();
      } else {
        await leadSourcesService.add(fomratedValues);
        openSuccessNotification({ message: 'Successfully edited lead source!' });
        onAdd();
      }
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={`${action} Lead Source`}
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          {action}
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Website" name="website" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32} className={styles.datePickerRow}>
            <Col span={12}>
              <Form.Item label="Active from" name="active_from" rules={[{ required: true, message: 'Please select date!' }]}>
                <DatePicker placeholder="Active from" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Active to" name="active_to">
                <DatePicker placeholder="Active to" />
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};
