import { callApi } from './api';
import { getQueryString } from '../utils/query';

class AccountsService {
  async getAll({ fields = 'last_name,first_name,id', withoutAdmins } = {}) {
    // NOTE: fields query param doesn't work on backend, returns all fields
    const query = getQueryString({ fields, admin: withoutAdmins !== undefined ? !withoutAdmins : undefined, is_active: true });
    return await callApi({ url: `/api/accounts/all/${query}` });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.first_name + ' ' + option.last_name,
        value: option.id
      };
    });
  };
}

export const accountsService = new AccountsService();
