import React, { useState, useEffect } from 'react';

import { Heading } from '../Heading';
import { Table } from './Table';
import { AddOrEditSubidListModal } from './AddOrEditSubidListModal';

import { listsService, ListTypes } from '../../../../services/lists';
import { accountsService } from '../../../../services/accounts';
import { useDebounce, useFetch } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { SortDirection } from '../../../../services/statistic';

// import styles from '../../Lists.module.css';

const listType = ListTypes.subid_lists;

export const SUBIDsTab = () => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();

        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };

    getAccounts();
  }, []);

  const loadSubidLists = (cancelToken) =>
    listsService.getListsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
      listType,
      fields: 'id,img,name,creator_details,date_created,subids_lists,subids_json,subids,assigned,exclude,private',
      filters,
      sorting
    });

  const [
    {
      data: { results: subidLists, count: totalRows },
      isFetching
    },
    getSubidLists
  ] = useFetch(loadSubidLists);

  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, filters]);

  useEffect(() => {
    getSubidLists();
  }, [debouncedSearchValue, currentPage, getSubidLists, filters, sorting]);

  const handleDelete = () => {
    if (subidLists.length === 1 && !isFetching && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    getSubidLists();
  };

  return (
    <div>
      <Heading
        searchValue={searchValue}
        listType="SUBID"
        allAccounts={allAccounts}
        areAccountsLoading={allAccountsLoading}
        filters={filters}
        onFilter={setFilters}
        onSearch={(search) => setSearchValue(search)}
        onCreateListClicked={() => setVisibleAddModal(true)}
      />

      <Table
        allAccounts={allAccounts}
        subidLists={subidLists}
        loading={loading}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        allAcountsLoading={allAccountsLoading}
        sorting={sorting}
        onClone={getSubidLists}
        onEdit={getSubidLists}
        onDelete={handleDelete}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
      />

      <AddOrEditSubidListModal
        visibleModal={visibleAddModal}
        allAccounts={allAccounts}
        onCloseModal={() => setVisibleAddModal(false)}
        onAdd={getSubidLists}
        allAcountsLoading={allAccountsLoading}
      />
    </div>
  );
};
