import React, { useState, useEffect } from 'react';

import { Header } from './components/Header';
import { Table } from './components/Table';
import { SortDirection } from '../../services/statistic';
import { identityService } from '../../services/identity';
import { useDebounce, useFetch } from '../../utils/hooks';

export const Identities = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState([{ key: 'id', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadIdentities = (cancelToken) =>
    identityService.getPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, filters, sorting, cancelToken });

  const [
    {
      data: { results: identities, count: totalItems },
      isFetching
    },
    getIdentities
  ] = useFetch(loadIdentities);

  useEffect(() => {
    getIdentities();
  }, [currentPage, filters, sorting, debouncedSearchValue, getIdentities]);

  return (
    <div>
      <h1>Identities</h1>
      <Header filters={filters} searchValue={searchValue} onFilter={setFilters} onSearch={setSearchValue} />
      <Table
        loading={isFetching}
        identities={identities}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
        onAction={() => getIdentities()}
      />
    </div>
  );
};
