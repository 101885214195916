import React, { useState, useEffect, useCallback } from 'react';
import { Filters } from './components/Filters';
import { Row, Table, Pagination } from 'antd';
import { SortDirection, statisticServices } from '../../services/statistic';
import { useFetch } from '../../utils/hooks';
import styles from './ZoneStats.module.css';
import moment from 'moment';
import { TableTitle } from '../../components/TableTitle';

const allColumns = [
  { value: 'date', text: 'Date' },
  { value: 'flagged_time', text: 'Flagged time (UTC)' },
  {
    value: 'identity',
    text: 'Identity'
  },
  {
    value: 'number_of_flagged',
    text: 'Number of flagged'
  },
  { value: 'ip', text: 'IP' },
  { value: 'domain', text: 'Domain' },
  {
    value: 'replacement_interval',
    text: 'Replacement interval'
  },
  {
    value: 'identity_rotation_domains',
    text: 'Identity Rotation Domains'
  },
  {
    value: 'testing_platform_code',
    text: 'Testing platform code'
  },
  {
    value: 'description',
    text: 'Description'
  },
  { value: 'spent', text: 'Spent' }
];

export const FlaggingStats = () => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const pageSizeOptions = [20, 40, 60, 80];

  const [sorting, setSorting] = useState([]);

  const refactorTableData = () => {
    if (tableData) {
      const tableDataRefactored = tableData.map((el, index) => {
        const refactor = { ...el };
        refactor.date = moment(refactor.date, 'YYYYMMDD').format('DD.MM.YYYY');
        refactor.flagged_time = moment.utc(refactor.flagged_time).format('DD.MM.YYYY. HH:mm:ss');
        refactor.spent = refactor.spent && '$ ' + refactor.spent.toFixed(2);
        return {
          key: index,
          ...refactor
        };
      });
      return tableDataRefactored;
    } else {
      return [];
    }
  };

  const loadFlaggingStats = (cancelToken) => {
    return statisticServices.getFlaggingStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken });
  };

  const [
    {
      data: { count, results: tableData, total },
      isFetching
    },
    getFlaggingStats
  ] = useFetch(loadFlaggingStats, { results: [], count: 0, total: {} });
  const loading = isFetching;

  useEffect(() => setCurrentPage(1), [selectedFilters.filters, selectedFilters.dateRange, pageSize, sorting]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getFlaggingStats();
    }
  }, [sorting, pageSize, selectedFilters, getFlaggingStats, currentPage]);

  const updateColumns = useCallback(
    (value) => {
      setTableColumns(() => {
        let newTableColumn = [];
        allColumns
          .filter((column) => value.checkedColumns[column['value']])
          .forEach(function ({ text, value }) {
            newTableColumn.push({
              title: (
                <TableTitle text={text} order={sorting.find((s) => s.key === value)?.value} value={value} onSortingClick={setSorting} />
              ),
              dataIndex: value
            });
          });
        return newTableColumn;
      });
    },
    [sorting]
  );

  useEffect(() => {
    if (selectedFilters.filters) {
      updateColumns(selectedFilters);
    }
  }, [sorting, updateColumns, selectedFilters]);

  const setDefaultSorting = (checkedColumns) => {
    if (checkedColumns.flagged_time) {
      setSorting([{ key: 'flagged_time', value: SortDirection.Descending }]);
    } else if (checkedColumns.date) {
      setSorting([{ key: 'date', value: SortDirection.Descending }]);
    } else if (checkedColumns.number_of_flagged) {
      setSorting([{ key: 'number_of_flagged', value: SortDirection.Descending }]);
    } else if (checkedColumns.spent) {
      setSorting([{ key: 'spent', value: SortDirection.Descending }]);
    } else {
      setSorting([]);
    }
  };

  const getFormattedTotalField = (columnName) => {
    if (columnName === 'spent') {
      return total.spent && '$ ' + total.spent.toFixed(2);
    }
    return total[columnName];
  };

  return (
    <div className="Reports">
      <h1>Flagging stats</h1>
      <Row></Row>
      <Filters
        allColumns={allColumns}
        onSubmit={(value) => {
          setDefaultSorting(value.checkedColumns);
          updateColumns(value);
          setSelectedFilters(value);
        }}
        updateLoading={loading}
      />

      <Table
        style={{ marginTop: '2%' }}
        columns={tableColumns}
        dataSource={refactorTableData()}
        rowKey="Id"
        loading={loading}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        summary={() => {
          const sum = total ? (
            <Table.Summary.Row>
              {tableColumns.map((el, index) => (
                <Table.Summary.Cell key={index} index={index}>
                  {getFormattedTotalField(el.dataIndex)}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          ) : null;
          return sum;
        }}
        rowClassName={(record, _) => {
          if (record.profit && record.profit !== 0) {
            let profit = record.profit.split('$').pop();
            return profit < 0 ? styles.negative : null;
          }
        }}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={count ? count : 0}
              current={currentPage}
              onChange={(value) => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(_, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
            />
          );
        }}
      />
    </div>
  );
};
