import 'antd/dist/antd.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';

import { auth } from './services/auth';
import { AppLayout } from './layout';
import { PageNotFound } from './pages/page-not-found';
import { confirmClose } from './utils/confirmClose';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { showApiErrors } from './utils/showApiErrors';
import { clearCSRFCookies, getCookieValue, setDummyCookies } from './utils/functions';
import { callApi } from './services/api';
import { IAM_URL } from './config';
import { openErrorNotification } from './utils/notifications';

export function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      clearCSRFCookies(); // iam causes issues with csrf (multiple tokens) so this is a simple workaround
      setDummyCookies(); // when no cookies we get a CORS error for some reason!!
      await callApi({ baseURL: IAM_URL, url: '/api/auth/login/' }); // Call IAM so it sets CSRF token if missing
      if (!getCookieValue('csrftoken')) {
        openErrorNotification({ message: 'CSRF token missing!' });
        return; // prevent app to load if no csrf token
      }

      await auth.loadPermissions();
      setLoading(false);
    }
    load();
  }, []);

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => showApiErrors(error)
    })
  });

  return isLoading ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Spin size="large" />
    </div>
  ) : (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          confirmClose(
            () => callback(true),
            () => callback(false)
          );
        }}
      >
        <Switch>
          <Route
            exact
            path="/page-not-found"
            render={(props) => {
              const { state } = props.location;
              const from = state && state.from;
              return <PageNotFound from={from} />;
            }}
          />
          <Route path="/" component={AppLayout} />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
