export const repeatPeriodTypes = {
  DAY: 'day',
  WEEK: 'week'
};

export const repeatPeriodTypeMessage = {
  [repeatPeriodTypes.DAY]: 'Day',
  [repeatPeriodTypes.WEEK]: 'Week'
};

export const LinkGeneratedMethod = {
  Standard: 'standard_method',
  LinkGetParam: 'link_param_method'
};
