import React, { useState } from 'react';
import { Popover, Spin } from 'antd';
import { ImportOutlined, ExportOutlined } from '@ant-design/icons';

import { listsService } from '../services/lists';
import { openErrorNotification, openSuccessNotification } from '../utils/notifications';
import { showApiErrors } from '../utils/showApiErrors';

import styles from './PricesImportExport.module.css';

export const PricesImportExport = ({ disabled, list, isListForm, onImport }) => {
  const [loadingImport, setLoadingImport] = useState(false);

  const id = list ? list.id : null;

  const importPriceList = async (e) => {
    setLoadingImport(true);
    e.persist();
    const formData = new FormData();
    formData.append('file', e.target.files[0], e.target.files[0].name);

    try {
      const res = await listsService.importPriceList(formData);
      const { data } = res;
      if (!!data) {
        onImport(data);
        openSuccessNotification({ message: 'Price list successfully imported!' });
      } else {
        openErrorNotification({ message: res.message || 'Could not import a price list!' });
      }
    } catch (err) {
      showApiErrors(err);
    } finally {
      e.target.value = null;
      setLoadingImport(false);
    }
  };
  const iconStyle = disabled ? { color: '#F2F2F4' } : {};
  const borderStyle = disabled ? { borderColor: '#F2F2F4' } : {};

  return loadingImport ? (
    <div className={list ? styles.spinnerEditModal : styles.spinnerCreateModal}>
      <Spin />
    </div>
  ) : (
    <div
      className={isListForm ? styles.importExportWrapperInLists : styles.importExportWrapperInMCs}
      style={{ justifyContent: list ? 'space-around' : 'flex-start' }}
    >
      <Popover placement="top" content="Import" trigger="hover">
        <input type="file" id={`importPrices + ${id}`} hidden onChange={importPriceList} disabled={disabled} />
        <label htmlFor={`importPrices + ${id}`}>
          <div className={styles.importBtnWrapper} style={borderStyle}>
            <ImportOutlined style={iconStyle} />
          </div>
        </label>
      </Popover>

      {list ? (
        <Popover placement="top" content="Export" trigger="hover">
          <div
            className={styles.exportBtnWrapper}
            onClick={() => {
              return !disabled && window.open(listsService.exportPriceListUrl(id));
            }}
            style={borderStyle}
          >
            <ExportOutlined style={iconStyle} />
          </div>
        </Popover>
      ) : null}
    </div>
  );
};
