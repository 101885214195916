import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchBar } from '../../../components/SearchBar';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { browsersService } from '../../../services/browsers';
import { companiesService } from '../../../services/companies';
import { countriesService } from '../../../services/countries';
import { osService } from '../../../services/os';
import { ProductStatus } from '../../../services/products';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from '../Products.module.css';

const { Option } = Select;

export const Header = ({ filters, searchValue, onFilter, onSearch }) => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [os, setOS] = useState([]);
  const [osLoading, setOSloading] = useState(false);

  const [browser, setBrowser] = useState([]);
  const [browserLoading, setBrowserLoading] = useState(false);

  const [country, setCountry] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);

  const history = useHistory();

  const openCreatePage = () => {
    history.push('/products/new');
  };

  useEffect(() => {
    (async () => {
      try {
        setCompaniesLoading(true);
        const companies = await companiesService.getAll({ fields: 'id,name' });
        setAllCompanies(companies);
      } catch (err) {
        showApiErrors(err);
      } finally {
        setCompaniesLoading(false);
      }
    })();
    (async () => {
      setOSloading(true);
      try {
        const os = await osService.getAll();
        setOS(os);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setOSloading(false);
      }
    })();

    (async () => {
      setBrowserLoading(true);
      try {
        const browser = await browsersService.getAll();
        setBrowser(browser);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setBrowserLoading(false);
      }
    })();
    (async () => {
      try {
        setCountryLoading(true);
        const countries = await countriesService.getAll({ fields: 'id,name,code2,img' });
        setCountry(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCountryLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Row gutter={32} className={styles.paddingBetweenRows}>
        <Col span={6}>
          <VirtualizedSelect
            options={companiesService.generateOptions(allCompanies)}
            onChange={(value) => onFilter({ ...filters, company: value })}
            selectValue={filters.company}
            placeholder={'Select company'}
            loading={companiesLoading}
          />
        </Col>
        <Col span={6}>
          <Select
            placeholder={'Select OS'}
            mode="multiple"
            style={{ width: '100%' }}
            options={osService.generateOptions(os)}
            loading={osLoading}
            value={filters.os}
            onChange={(value) => onFilter({ ...filters, os: value })}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Select
            placeholder={'Select browser'}
            mode="multiple"
            style={{ width: '100%' }}
            options={browsersService.generateOptions(browser)}
            loading={browserLoading}
            value={filters.browser}
            onChange={(value) => onFilter({ ...filters, browser: value })}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Select
            placeholder={'Select country'}
            mode="multiple"
            style={{ width: '100%' }}
            options={countriesService.generateOptions(country)}
            loading={countryLoading}
            value={filters.country}
            onChange={(value) => onFilter({ ...filters, country: value })}
            allowClear
          />
        </Col>
        <Col span={6}>
          <Select
            placeholder={'Select status'}
            style={{ width: '100%' }}
            value={filters.status}
            onChange={(value) => onFilter({ ...filters, status: value })}
            allowClear
          >
            <Option value={ProductStatus.Active}>Active</Option>
            <Option value={ProductStatus.Inactive}>Inactive</Option>
          </Select>
        </Col>
        <Col span={6}>
          <SearchBar value={searchValue} onChange={(e) => onSearch(e.target.value)} />
        </Col>
        <Col className={styles.btnCol} span={4}>
          <Button className="add-price-btn" type="primary" onClick={openCreatePage}>
            <PlusOutlined /> Add New Product
          </Button>
        </Col>
      </Row>
    </>
  );
};
