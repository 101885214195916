import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Tabs, Spin } from 'antd';
import _ from 'lodash';

import { ImportPrices } from './ImportPrices';
import { DownloadOutlined } from '@ant-design/icons';
import { PixelType, productsService } from '../../../services/products';
import { ProductPricesFormList } from './ProductPricesFormList';

const { TabPane } = Tabs;

export const ProductPrices = ({ form, product, countryLists, countriesLoading, onPriceTabChange, markImportedCountries }) => {
  const [importedPrices, setImportedPrices] = useState({});

  useEffect(() => {
    if (!_.isEmpty(importedPrices)) {
      form.setFieldsValue(importedPrices);
    }
  }, [form, importedPrices]);

  return (
    <div>
      <Spin spinning={countriesLoading}>
        <Row gutter={32}>
          <ImportPrices
            onPricesImported={(prices) => {
              setImportedPrices(prices);
              markImportedCountries(prices);
            }}
          />
        </Row>

        {product && (
          <Row gutter={32} style={{ marginTop: '2%' }}>
            <Col>
              <Button
                type="dashed"
                style={{ width: 150 }}
                onClick={() => {
                  return window.open(productsService.getPricesCsvUrl(product.id));
                }}
              >
                <DownloadOutlined />
                Export prices
              </Button>
            </Col>
          </Row>
        )}

        <Tabs defaultActiveKey={PixelType.pl} style={{ marginTop: '2%' }} onChange={onPriceTabChange}>
          <TabPane tab="Lead" key={PixelType.pl} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.pl}
              countriesList={countryLists.pl}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 1" key={PixelType.p1} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p1}
              countriesList={countryLists.p1}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 2" key={PixelType.p2} forceReder>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p2}
              countriesList={countryLists.p2}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 3" key={PixelType.p3} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p3}
              countriesList={countryLists.p3}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 4" key={PixelType.p4} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p4}
              countriesList={countryLists.p4}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 5" key={PixelType.p5} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p5}
              countriesList={countryLists.p5}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 6" key={PixelType.p6} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p6}
              countriesList={countryLists.p6}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 7" key={PixelType.p7} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p7}
              countriesList={countryLists.p7}
              countriesLoading={countriesLoading}
            />
          </TabPane>

          <TabPane tab="Pixel 8" key={PixelType.p8} forceRender>
            <ProductPricesFormList
              form={form}
              pixelType={PixelType.p8}
              countriesList={countryLists.p8}
              countriesLoading={countriesLoading}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};
