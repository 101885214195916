import { getQueryString } from '../utils/query';
import { callApi } from './api';

class UserAgentsService {
  async getAll({ fields = 'name,user_agent_string' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/media_campaigns/s2s_test/list_user_agents/${query}` });
  }
  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.user_agent_string
      };
    });
  };
}

export const userAgentsService = new UserAgentsService();
