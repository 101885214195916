import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';
import { handleOrdering } from '../utils/functions';

export const COMPANY_TYPE = {
  PUBLISHER: 'Publisher',
  ADVERTISER: 'Advertiser',
  INSTALLER: 'Installer',
  OTHER: 'Other'
};

export const ACTIVITY = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const STATUS = {
  ACTIVE: 'Active',
  FAILED: 'Failed',
  SUSPENDED: 'Suspended'
};

export const STATUS_LABEL = {
  Active: 'Live',
  Failed: 'Failed',
  Suspended: 'Suspended'
};

export const STATS_CATEGORIES = {
  mb_traffic_source_cpa_rs: 'MB - Traffic source CPA/Rev-Share',
  mb_traffic_source_cpm_cpc: 'MB - Traffic source CPM/CPC',
  ow_affiliate_programs: 'OW - Affiliate Programs',
  webmasters: 'Webmasters',
  mb_advertiser: 'MB - Advertiser',
  ow_advertiser: 'OW - Advertiser'
};

export const MBTrafficCategory = {
  CpaRs: {
    name: 'mb_traffic_source_cpa_rs',
    title: 'CPA/RevShare'
  },
  CpmCpc: {
    name: 'mb_traffic_source_cpm_cpc',
    title: 'CPM/CPC'
  },

  // CpmCpc and CpaRs bound together - they share manager and submanager; Exists on Billing, does not exist on Coinis
  CpaRsCpmCpc: {
    name: 'cpa_rs_cpm_cpc',
    title: 'MB Traffic Source'
  }
};

export const MBAdvertiserCategoryName = 'mb_advertiser';

class CompaniesService {
  async getAll({ fields = 'name,id', has_traffic, has_product, assigned_type, offerwall, product_in_offerwall, categories_names } = {}) {
    const status = STATUS.ACTIVE;
    const query = getQueryString({
      fields,
      has_traffic,
      has_product,
      status,
      assigned_type,
      offerwall,
      product_in_offerwall,
      categories_name__in: categories_names && categories_names.join(',')
    });
    return await callApi({ url: `/api/companies/${query}` });
  }

  async getTrafficCompanies() {
    return await callApi({ url: '/api/companies/traffic_companies/' });
  }

  async getProductCompanies() {
    return await callApi({ url: '/api/companies/product_companies/' });
  }

  async getAllByModule({ fields = 'name,id', has_traffic, has_product, pub_permissions } = {}) {
    const status = STATUS.ACTIVE;
    const query = getQueryString({ fields, has_traffic, has_product, status });
    let queryPermissions = '';
    if (pub_permissions) {
      pub_permissions.forEach((element) => {
        queryPermissions = queryPermissions + '&pub_permissions=' + element;
      });
    }
    return await callApi({ url: `/api/companies/${query}${queryPermissions}` });
  }

  async getCompany(id, { fields } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/companies/${id}/${query}` });
  }

  async getPaged({
    fields,
    currentPage,
    pageSize,
    searchValue,
    status,
    tags,
    publisherCategories,
    advertiserCategories,
    activity,
    accountManager,
    broughtByManagers,
    broughtByDateRange,
    complexFilter,
    cancelToken,
    sorting
  }) {
    const categories = publisherCategories.concat(advertiserCategories);
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      fields,
      complex_filter: complexFilter,
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      tags__in: tags.join(','),
      status,
      categories__in: categories.join(','),
      has_activity_or_new: activity,
      ordering: ordering && ordering.join(','),
      account_manager: accountManager,
      brought_by_managers__in: broughtByManagers && broughtByManagers.join(','),
      brought_by_date_from:
        broughtByDateRange && broughtByDateRange.from && broughtByDateRange.from.format('YYYY-MM-DD') + 'T00:00:00.000000Z',
      brought_by_date_to: broughtByDateRange && broughtByDateRange.to && broughtByDateRange.to.format('YYYY-MM-DD') + 'T23:59:59.999999Z'
    });
    return await callApi({ url: `/api/companies/${query}`, cancelToken });
  }

  async getCategories() {
    return await callApi({ url: '/api/category/' });
  }

  async getPublisherAndAdvertiserInfo(id) {
    return await callApi({ url: `/api/companies/${id}/ssp_and_publisher_info/` });
  }

  async getCommunicationChannels() {
    return await callApi({ url: '/api/companies/communication_channels' });
  }

  async create(data) {
    data.api_config = JSON.stringify(this.generateApiConfig(data));
    return await callApi({ url: '/api/companies/', method: 'POST', data });
  }

  async edit(id, data) {
    data.api_config = JSON.stringify(this.generateApiConfig(data));
    return await callApi({ url: `/api/companies/${id}/`, method: 'PUT', data });
  }

  async reject(id) {
    const data = {
      status: STATUS.REJECTED
    };
    return await callApi({ url: `/api/companies/${id}/`, method: 'PATCH', data });
  }

  async resendPublisherMail(id) {
    return await callApi({ url: `/api/companies/${id}/resend_publisher_mail/`, method: 'POST' });
  }

  async resendSSPMail(id) {
    return await callApi({ url: `/api/companies/${id}/resend_ssp_mail/`, method: 'POST' });
  }

  async is2FaActivatedPublisher(id) {
    return await callApi({ url: `/api/companies/${id}/is_two_fa_activated_publisher/`, method: 'GET' });
  }

  async disable2FaPublisher(id) {
    return await callApi({ url: `/api/companies/${id}/deactivate_two_fa_publisher/`, method: 'POST' });
  }

  async is2FaActivatedDsp(id) {
    return await callApi({ url: `/api/companies/${id}/is_two_fa_activated_dsp/`, method: 'GET' });
  }

  async disable2FaDsp(id) {
    return await callApi({ url: `/api/companies/${id}/deactivate_two_fa_dsp/`, method: 'POST' });
  }

  async loginAsPublisher(id) {
    return await callApi({ url: `api/publisher/login/?company_id=${id}`, method: 'GET' });
  }

  async loginAsAdvertiser(id) {
    return await callApi({ url: `api/ssp/login/?company_id=${id}`, method: 'GET' });
  }

  generateApiConfig(fields) {
    const apiConfigKeys = [
      'api_show_only_live_domains',
      'api_show_description',
      'api_show_campaign_type',
      'api_show_campaign_id',
      'api_show_lead_prices',
      'api_show_pattern_settings',
      'api_cmp_use_redirect'
    ];
    let configObject = {};
    Object.keys(fields).forEach(function (key) {
      if (apiConfigKeys.includes(key)) {
        configObject[key] = fields[key];
      }
    });
    return configObject;
  }

  async delete(id) {
    return await callApi({ url: `/api/companies/${id}/`, method: 'DELETE' });
  }

  async validateEmail(email) {
    return await callApi({ url: `/api/companies/validate_contact_mail/?email=${email}` });
  }

  async suspend(id, data) {
    return await callApi({ url: `/api/companies/${id}/suspend/`, method: 'POST', data });
  }

  async getCompanyDiary({ id }) {
    return await callApi({ url: `/api/company_diaries/?company__in=${id}`, method: 'GET' });
  }

  async updateCompanyDiary({ id, data }) {
    return await callApi({ url: `/api/company_diaries/${id}/`, method: 'PUT', data });
  }

  async getAdvancedFiltering() {
    return await callApi({ url: '/api/advanced_filter/' });
  }

  async addAdvancedFilter(data) {
    return await callApi({ url: '/api/advanced_filter/', method: 'POST', data });
  }

  async editAdvancedFilter(id, data) {
    return await callApi({ url: `/api/advanced_filter/${id}/`, method: 'PUT', data });
  }

  async getCompanyNotes(id) {
    return await callApi({ url: `/api/company_notes/?company_id=${id}`, method: 'GET' });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const companiesService = new CompaniesService();
