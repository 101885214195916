import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal, Button, Form, Input, Popover, Divider } from 'antd';

import { externalURLsServices } from '../../../services/external-urls';

import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { confirmClose } from '../../../utils/confirmClose';
import { useBeforeUnload } from '../../../utils/hooks/useBeforeUnload';

import styles from '../ExternalURLs.module.css';

const allMacros = [
  {
    value: '{CHANNEL}',
    desc: ''
  },
  {
    value: '{TIMESTAMP}',
    desc: ''
  },
  {
    value: '{CLICK_ID}',
    desc: ''
  },
  {
    value: '{COUNTRY_CODE}',
    desc: ''
  }
];

export const AddOrEditModal = ({ visibleModal, externalURLData, onCloseModal, onAdd, onEdit }) => {
  const [loading, setLoading] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    if (!externalURLData) {
      return undefined;
    }

    const { name, external_url } = externalURLData;

    const externalURL = external_url;

    return { name, externalURL };
  }, [externalURLData]);

  const resetFields = useCallback(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form]);

  useBeforeUnload(wasFormChanged);

  useEffect(() => {
    resetFields();
  }, [resetFields, visibleModal]);

  const onSubmit = async (values) => {
    setLoading(true);

    const { name, externalURL } = values;
    const formData = {
      name,
      external_url: externalURL
    };

    if (externalURLData) {
      try {
        await externalURLsServices.editExternalURL(externalURLData.id, formData);
        onCloseModal();
        openSuccessNotification({ message: 'External URL successfully edited!' });
        setWasFormChanged(false);
        await onEdit();
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await externalURLsServices.addExternalURL(formData);
        onCloseModal();
        openSuccessNotification({ message: 'External URL successfully created!' });
        await onAdd();
        setWasFormChanged(false);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMacrosClick = (macroContent) => {
    const oldValue = form.getFieldValue('externalURL') || '';
    const newValue = whereTheCursorWas
      ? oldValue.substring(0, whereTheCursorWas) + macroContent + oldValue.substring(whereTheCursorWas, oldValue.length)
      : oldValue + macroContent;

    if (whereTheCursorWas !== undefined) {
      setWhereTheCursorWas((prevState) => prevState + macroContent.length);
    }

    form.setFields([{ name: ['externalURL'], value: newValue }]);
  };

  const generateMacrosContent = () => {
    return (
      <div className={styles.macrosContent}>
        {allMacros.map((el) => (
          <div key={el.value}>
            <span onClick={() => handleMacrosClick(el.value)}>{el.value}</span>
            <Divider dashed />
          </div>
        ))}
      </div>
    );
  };

  const title = (externalURLData ? 'Edit' : 'Add') + ' External URL';
  const btnText = (externalURLData ? 'Edit' : 'Save') + ' External URL';

  const handleClose = () => {
    wasFormChanged ? confirmClose(onCloseModal) : onCloseModal();
  };

  return (
    <Modal
      title={title}
      visible={visibleModal}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {btnText}
        </Button>
      ]}
      width={600}
    >
      <div className={styles.modalFormWrapper}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
          onValuesChange={() => setWasFormChanged(true)}
        >
          <div className={`${styles.widthModal50}`}>
            <span>Name</span>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter a name!'
                }
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </div>

          <div className={`${styles.widthModal100}`}>
            <span>External URL</span>
            <Form.Item
              name="externalURL"
              rules={[
                {
                  required: true,
                  message: 'Please enter an external URL!'
                }
              ]}
              onBlur={(e) => setWhereTheCursorWas(e.target.selectionStart)}
            >
              <Input
                placeholder="External URL"
                type="url"
                addonAfter={
                  <Popover title="System Macros" trigger="click" content={generateMacrosContent()}>
                    <span style={{ cursor: 'pointer' }} onClick={() => {}}>
                      Macros
                    </span>
                  </Popover>
                }
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
