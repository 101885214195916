import { Pagination, Table as AntTable } from 'antd';
import React from 'react';

import { ActionsTableCol } from './ActionsTableCol';

export const Table = ({ data, loading, totalItems, currentPage, pageSize, onPaginationChange, onEdit, onDelete }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    { title: 'Name', dataIndex: 'name' },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (companyTag) => <ActionsTableCol companyTag={companyTag} onEdit={onEdit} onDelete={onDelete} />
    }
  ];

  return (
    <div>
      <AntTable
        style={{ marginTop: '3%' }}
        loading={loading}
        rowKey={({ id }) => id}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
