import { UnlockFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import { PricesForm } from '../../../../components/PricesForm';
import { PricesImportExport } from '../../../../components/PricesImportExport';
import { Spinner } from '../../../../components/Spinner';
import { browsersService } from '../../../../services/browsers';
import { countriesService } from '../../../../services/countries';
import { deviceTypesService } from '../../../../services/device-types';
import { listsService } from '../../../../services/lists';
import { osService } from '../../../../services/os';
import { PriceRadioValues } from '../../../../utils/enums';
import { breakGroupedCountries, groupCountriesWithSamePrices, markListedCountries } from '../../../../utils/functions';
import { useEffectTrigger, useForm } from '../../../../utils/hooks';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { ModalForGlobalListSelector } from '../ModalForGlobalListSelector';
import styles from './Tabs.module.css';
import { getGlobalPriceLists, useCustomQuery } from '../../../../utils/hooks/queries';

const listName = 'pricesFormList';
const configLocal = { global_list: false, list_prices: [], id: undefined };
/*
NOTE:
Be careful because eveything here is hardcoded sync. Initial values is picked up first time and changed only if components unmounts (loaderTrigger)
Please be careful if you change anything because eveything is sync but can be broken easily.
*/
export const Pricing = ({ value, onChange, loadingTabsData, noPricesDisabled, isRevShare }) => {
  const [pricesRadioValue, setPricesRadioValue] = useState(PriceRadioValues.noPrice);
  // ID of selected global list from dropdown
  const [selectedListId, setSelectedListId] = useState(undefined);
  // Current list
  const [countriesAndIds, setCountriesAndIds] = useState();
  const [loadingGlobalList, setLoadingGlobalList] = useState(false);

  const [allOSs, setAllOSs] = useState([]);
  const [allOSLoading, setAllOsLoading] = useState(false);

  const [allBrowsers, setAllBrowsers] = useState([]);
  const [allBrowsersLoading, setAllBrowsersLoading] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allCountriesLoading, setAllCountriesLoading] = useState(false);

  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [allDeviceTypesLoading, setAllDeviceTypesLoading] = useState(false);

  const [groupedPriceData, setGroupedPriceData] = useState([]);
  const [loaderTrigger, setLoaderTrigger] = useState(false);

  const {
    isLoading: allGlobalPriceListsLoading,
    isFetching: allGlobalPriceListsRefetching,
    data: allGlobalPriceLists
  } = useCustomQuery(getGlobalPriceLists);

  const { noPrice, local, global } = PriceRadioValues;

  const isGlobalListDeleted = useCallback(() => {
    const { id } = value;
    if (id) {
      return !allGlobalPriceLists.find((list) => {
        return id === list.id;
      });
    } else {
      return false;
    }
  }, [allGlobalPriceLists, value]);

  const initialValues = useMemo(() => {
    const defaultTypeOfList = noPricesDisabled || isRevShare ? local : noPrice;

    if (!value || !Object.keys(value).length) {
      setPricesRadioValue(defaultTypeOfList);
      return {
        typeOfList: defaultTypeOfList
      };
    }

    const { id, global_list, assigned, list_prices } = value;
    if (global_list && isGlobalListDeleted()) {
      setPricesRadioValue(defaultTypeOfList);
      return {
        typeOfList: defaultTypeOfList
      };
    }

    if (!global_list) {
      if (!list_prices) {
        return {
          typeOfList: local
        };
      }
    }

    const typeOfList = global_list && !isRevShare ? PriceRadioValues.global : PriceRadioValues.local;
    const globalPriceList = global_list && !isRevShare ? id : undefined;
    const privateList = value.private;
    const formatedPriceList = list_prices.map((elem) => {
      const { id, country, price, os, browser, device_type } = elem;

      return {
        id,
        country,
        os,
        browser,
        price,
        device_type
      };
    });

    const { groupedData, countriesAndIds } = groupCountriesWithSamePrices(formatedPriceList);
    const pricesFormList = groupedData;
    setGroupedPriceData(groupedData);
    setSelectedListId(globalPriceList);
    const radioValue = value && value.global_list && !isRevShare ? global : value.list_prices || isRevShare ? local : noPrice;
    setPricesRadioValue(radioValue);
    setCountriesAndIds(countriesAndIds);

    return {
      id,
      typeOfList,
      globalPriceList,
      privateList,
      assigned,
      pricesFormList
    };
  }, [noPrice, local, global, isGlobalListDeleted, value, noPricesDisabled, isRevShare]);

  useDeepCompareEffect(() => {
    if (groupedPriceData) {
      markListedCountries({
        incomingValue: groupedPriceData,
        countryList: allCountries,
        countryListSetter: setAllCountries
      });
    }
  }, [groupedPriceData, allCountries]);

  useEffect(() => {
    let didCancel = false;

    const getCountries = async () => {
      try {
        setAllCountriesLoading(true);
        const countries = await countriesService.getAll();
        setAllCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllCountriesLoading(false);
      }
    };
    const getOS = async () => {
      setAllOsLoading(true);
      try {
        const os = await osService.getAll({ fields: 'id,name,img' });
        setAllOSs(os);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllOsLoading(false);
      }
    };
    const getBrowsers = async () => {
      setAllBrowsersLoading(true);
      try {
        const browsers = await browsersService.getAll({ fields: 'id,name,img' });
        setAllBrowsers(browsers);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllBrowsersLoading(false);
      }
    };
    const getDeviceTypes = async () => {
      setAllDeviceTypesLoading(true);
      try {
        const data = await deviceTypesService.getAll({ fields: 'id,name' });
        setAllDeviceTypes(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllDeviceTypesLoading(false);
      }
    };
    getBrowsers();
    getCountries();
    getOS();
    getDeviceTypes();
    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    let didCancel = false;

    const getGlobalPriceListData = async () => {
      const globalListId = value ? value.id : null;

      if (selectedListId && selectedListId !== globalListId) {
        try {
          setLoadingGlobalList(true);
          const listData = await listsService.getFormatedPriceList(selectedListId);
          if (!didCancel) {
            onChange(listData);
          }
        } catch (e) {
          showApiErrors(e);
        } finally {
          setLoadingGlobalList(false);
        }
      }
    };

    getGlobalPriceListData();

    return () => {
      didCancel = true;
    };
  }, [onChange, selectedListId, value]);

  const unlockForm = () => {
    const correctedData = value.list_prices.map((price) => {
      return { ...price, id: undefined };
    });
    const correctedGlobalListData = { ...value, id: undefined, global_list: false, list_prices: correctedData };
    setPricesRadioValue(local);
    onChange(correctedGlobalListData);
    triggerForRemount();
  };

  const clearData = useCallback(() => {
    onChange({});
    triggerForRemount();
  }, [onChange]);

  const confirmClear = () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Are you sure you want to clear data?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => clearData(),
      onCancel() {}
    });
  };

  const handleOnImport = (data) => {
    if (selectedListId) {
      unlockForm();
    }
    setPricesRadioValue(local);
    onChange({ ...data, global_list: false });
    triggerForRemount();
  };

  const loading = loadingTabsData || loadingGlobalList || allGlobalPriceListsLoading || loaderTrigger;
  const handleFormChangeValues = (changedValues, allValues) => {
    // We changed price list values
    if (allValues.pricesFormList) {
      const id = value?.id;
      const list_prices = breakGroupedCountries(allValues.pricesFormList, countriesAndIds);
      const global_list = PriceRadioValues.global === allValues?.typeOfList;
      onChange({ global_list, list_prices, id });
    }

    if (changedValues.typeOfList) {
      setPricesRadioValue(changedValues.typeOfList);

      if (changedValues.typeOfList === PriceRadioValues.global) {
        onChange({ global_list: true, list_prices: [], id: undefined });
        triggerForRemount();
      }
      // We transfered global list to local
      if (value?.global_list) {
        setSelectedListId(undefined);
        if (changedValues.typeOfList === PriceRadioValues.local) {
          onChange(configLocal);
          triggerForRemount();
        }
      }
      if (changedValues.typeOfList === PriceRadioValues.noPrice) {
        onChange({});
        triggerForRemount();
      }
    }
  };

  const modalVisible = useMemo(() => {
    return pricesRadioValue === PriceRadioValues.global && !selectedListId;
  }, [pricesRadioValue, selectedListId]);

  const getGlobalListName = (id) => allGlobalPriceLists.find((el) => el.id === id)?.name;

  const triggerForRemount = () => {
    setLoaderTrigger(true);
    setTimeout(() => {
      setLoaderTrigger(false);
    }, 0);
  };

  return (
    // TODO: Rewrite this making custom component which acts as form but it is not form, because form inside form is not WEB standard
    loading ? (
      <Spinner />
    ) : (
      <CustomForm
        initialValues={initialValues}
        handleFormChangeValues={handleFormChangeValues}
        modalVisible={modalVisible}
        onChange={onChange}
        setSelectedListId={setSelectedListId}
        allGlobalPriceListsLoading={allGlobalPriceListsLoading || allGlobalPriceListsRefetching}
        allGlobalPriceLists={allGlobalPriceLists}
        selectedListId={selectedListId}
        pricesRadioValue={pricesRadioValue}
        handleOnImport={handleOnImport}
        noPrice={noPrice}
        local={local}
        global={global}
        unlockForm={unlockForm}
        getGlobalListName={getGlobalListName}
        value={value}
        confirmClear={confirmClear}
        allCountries={allCountries}
        allBrowsers={allBrowsers}
        allOSs={allOSs}
        allDeviceTypes={allDeviceTypes}
        allCountriesLoading={allCountriesLoading}
        allBrowsersLoading={allBrowsersLoading}
        allOSLoading={allOSLoading}
        allDeviceTypesLoading={allDeviceTypesLoading}
        triggerForRemount={triggerForRemount}
        noPricesDisabled={noPricesDisabled}
        isRevShare={isRevShare}
      />
    )
  );
};

const CustomForm = ({
  initialValues,
  handleFormChangeValues,
  modalVisible,
  onChange,
  setSelectedListId,
  allGlobalPriceListsLoading,
  allGlobalPriceLists,
  selectedListId,
  pricesRadioValue,
  handleOnImport,
  noPrice,
  local,
  global,
  unlockForm,
  getGlobalListName,
  value,
  confirmClear,
  allCountries,
  allBrowsers,
  allOSs,
  allDeviceTypes,
  allCountriesLoading,
  allBrowsersLoading,
  allOSLoading,
  allDeviceTypesLoading,
  triggerForRemount,
  noPricesDisabled,
  isRevShare
}) => {
  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();

  const [form] = useForm(activateFormChangedTrigger);

  const handleCancelModal = () => {
    if (noPricesDisabled) {
      onChange(configLocal);
      triggerForRemount();
    } else {
      onChange({});
      triggerForRemount();
    }
  };
  return (
    <Form
      form={form}
      name="pricesForm"
      initialValues={initialValues}
      onValuesChange={(changedValues, allValues) => {
        activateFormChangedTrigger();
        handleFormChangeValues(changedValues, allValues);
      }}
    >
      <div>
        {!isRevShare && (
          <>
            <ModalForGlobalListSelector
              visible={modalVisible}
              onCancel={handleCancelModal}
              onChange={(value) => setSelectedListId(value)}
              allGlobalPriceListsLoading={allGlobalPriceListsLoading}
              allGlobalPriceLists={allGlobalPriceLists}
            />
            <div style={{ display: 'none' }}>
              <Form.Item name="id">
                <Input />
              </Form.Item>
            </div>

            <div className={styles.headerWithClear}>
              <div className={styles.pricesRadioAndSelectWrapper}>
                <div className={styles.customRadio}>
                  <Form.Item name="typeOfList">
                    <Radio.Group className={styles.radioBtnGroup}>
                      <Radio value={noPrice} disabled={noPricesDisabled}>
                        No Prices
                      </Radio>
                      <br></br>
                      <Radio value={local}>Local List</Radio>
                      <br></br>
                      <Radio value={global}>
                        Global List
                        <i>{selectedListId && `- ${value?.name || getGlobalListName(value?.id)}`}</i>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: pricesRadioValue === PriceRadioValues.global && selectedListId ? 'block' : 'none',
                    marginBottom: '24px'
                  }}
                >
                  <Button onClick={unlockForm}>
                    <UnlockFilled />
                    Unlock And Make Changes
                  </Button>
                </div>
              </div>

              <div className={styles.importExportAndClearWrapper}>
                <div style={{ width: value ? 90 : 'fit-content' }}>
                  <PricesImportExport list={value} onImport={handleOnImport} />
                </div>

                <Button type="primary" onClick={confirmClear}>
                  Clear
                </Button>
              </div>
            </div>
          </>
        )}

        <PricesForm
          form={form}
          disabled={pricesRadioValue !== local}
          allCountries={allCountries}
          allBrowsers={allBrowsers}
          allOSs={allOSs}
          allDeviceTypes={allDeviceTypes}
          list={value}
          listName={listName}
          loader={{
            allCountries: allCountriesLoading,
            allOS: allOSLoading,
            allBrowsers: allBrowsersLoading,
            allDeviceTypes: allDeviceTypesLoading
          }}
          formChangedTrigger={formChangedTrigger}
          isRevShare={isRevShare}
        />
      </div>
    </Form>
  );
};
