import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Input, Row, Select } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { useEffect, useMemo, useState } from 'react';

import { companiesService } from '../../../services/companies';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';
import { generateOptions, generateOptionsFromObject, snakeCaseToProperCase } from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';

import styles from './AdvancedFiltering.module.css';

const FIELD_TYPE = {
  TAGS: 'tags',
  CATEGORIES: 'categories'
};

const TYPE_OF_FILTERING = {
  ANY: 'has_any',
  ALL: 'has_all',
  NEITHER: 'does_not_have_some',
  EXACT: 'has_exact'
};

const TYPE_OF_ADVANCED_FILTERING_TAGS = {
  [TYPE_OF_FILTERING.ANY]: {
    negative: false,
    name: {
      [FIELD_TYPE.TAGS]: 'any_tag_in',
      [FIELD_TYPE.CATEGORIES]: 'any_category_in'
    }
  },
  [TYPE_OF_FILTERING.ALL]: {
    negative: false,
    name: {
      [FIELD_TYPE.TAGS]: 'all_tag_in',
      [FIELD_TYPE.CATEGORIES]: 'all_category_in'
    }
  },
  [TYPE_OF_FILTERING.NEITHER]: {
    negative: true,
    name: {
      [FIELD_TYPE.TAGS]: 'any_tag_in',
      [FIELD_TYPE.CATEGORIES]: 'any_category_in'
    }
  },
  [TYPE_OF_FILTERING.EXACT]: {
    negative: false,
    mix: { [TYPE_OF_FILTERING.NEITHER]: false, [TYPE_OF_FILTERING.ALL]: true } // NOTE: True means take same values, false means take oposite values
  }
};

const emptyAdvancedFilters = { data: [], name: undefined, id: undefined };

export const AdvancedFiltering = ({ onAdvancedFiltering, allAdvertiserCategories, allPublisherCategories, tags, clearFiltersTrigger }) => {
  const [advancedFilters, setAdvancedFilters] = useState(emptyAdvancedFilters);
  const [loading, setLoading] = useState(false);

  const [advancedFiltersFromDB, setAdvancedFiltersFromDB] = useState([]); // Fetched from db
  const [advancedFiltersLoading, setAdvancedFiltersLoading] = useState(false);
  const [selectedDBFilter, setSelectedDBFilter] = useState();

  const possibleValues = useMemo(
    () => ({
      [FIELD_TYPE.TAGS]: tags,
      [FIELD_TYPE.CATEGORIES]: [...allAdvertiserCategories, ...allPublisherCategories]
    }),
    [tags, allPublisherCategories, allAdvertiserCategories]
  );

  useEffect(() => {
    setAdvancedFilters(emptyAdvancedFilters);
    setSelectedDBFilter(undefined);
  }, [clearFiltersTrigger]);

  useEffect(() => {
    const getAdvancedFilters = async () => {
      try {
        setAdvancedFiltersLoading(true);
        const data = await companiesService.getAdvancedFiltering();
        setAdvancedFiltersFromDB(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAdvancedFiltersLoading(false);
      }
    };

    getAdvancedFilters();
  }, []);

  const transformAdvancedFilteringToQueryParam = () => {
    const result = [];
    for (const advancedFilter of advancedFilters.data) {
      const selectedValues = advancedFilter.values;
      if (!selectedValues.length) {
        openErrorNotification({ message: 'Please select some values in advanced filtering.' });
        return;
      }
      let paramString = '';
      const config = TYPE_OF_ADVANCED_FILTERING_TAGS[advancedFilter.typeOfFiltering];
      const negative = config.negative;

      if (negative) {
        paramString += '~';
      }
      if (!config.mix) {
        paramString += `(${config.name[advancedFilter.fieldName]}=${selectedValues})`;
        result.push(paramString);
      } else {
        const subResult = [];
        Object.entries(config.mix).forEach(([typeOfFiltering, sameValues]) => {
          const subConfig = TYPE_OF_ADVANCED_FILTERING_TAGS[typeOfFiltering];
          // TODO: Have mix inside mix
          let subParamString = '';
          if (subConfig.negative) {
            subParamString += '~';
          }

          const possibleValuesOnlyIds = possibleValues[advancedFilter.fieldName].map((v) => v.id);
          const correctValues = sameValues ? selectedValues : possibleValuesOnlyIds.filter((v) => !selectedValues.includes(v));
          subParamString += `(${subConfig.name[advancedFilter.fieldName]}=${correctValues})`;

          subResult.push(subParamString);
        });
        result.push(subResult.join('*'));
      }
    }

    onAdvancedFiltering(result.join('*'));
  };

  const handleAdvancedFilteringChange = (value, index, fieldName) => {
    setAdvancedFilters((prev) => {
      const newAdvancedFilters = [...prev.data];
      newAdvancedFilters[index][fieldName] = value;
      return { ...prev, data: newAdvancedFilters };
    });
  };

  const handleAddingNewAdvancedFilterRow = () => {
    setAdvancedFilters((prev) => ({
      ...prev,
      data: [
        ...prev.data,
        {
          typeOfFiltering: TYPE_OF_FILTERING.ANY,
          fieldName: FIELD_TYPE.TAGS,
          values: []
        }
      ]
    }));
  };

  const generateValuesBasedOnFieldName = (selectedValues, index) => {
    let label = 'title';
    let key = 'id';
    if (advancedFilters.data[index].fieldName === 'tags') {
      label = 'name';
    }

    return (
      <Select
        style={{ width: '100%' }}
        placeholder="Select values"
        value={selectedValues}
        onChange={(value) => {
          handleAdvancedFilteringChange(value, index, 'values');
        }}
        mode="multiple"
        optionFilterProp="data-searchvalue"
      >
        {generateOptions(possibleValues[advancedFilters.data[index].fieldName], label, key)}
      </Select>
    );
  };

  const handleRemoveRow = (index) => {
    setAdvancedFilters((prev) => ({ ...prev, data: prev.data.filter((el, i) => i !== index) }));
  };

  const handleAdvancedFilterChange = (id) => {
    const selectedFilter = advancedFiltersFromDB.find((el) => el.id === id);
    setAdvancedFilters(selectedFilter ?? emptyAdvancedFilters);
    setSelectedDBFilter(id);
  };

  const handleSaveFilter = async () => {
    try {
      setLoading(true);
      if (advancedFilters.id) {
        await companiesService.editAdvancedFilter(advancedFilters.id, advancedFilters);
      } else {
        await companiesService.addAdvancedFilter(advancedFilters);
      }

      openSuccessNotification({ message: `Successfully ${advancedFilters.id ? 'edited' : 'added'}.` });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterNameChange = (e) => {
    const value = e.target.value;
    setAdvancedFilters((prev) => ({ ...prev, name: value }));
  };

  return (
    <Row gutter={[24, 8]}>
      <Col span={24} className={styles.collapseCol}>
        <Collapse ghost onChange={() => onAdvancedFiltering(undefined)}>
          <CollapsePanel header="Advanced filtering">
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <Select
                  placeholder="Select saved filters"
                  style={{ width: 250 }}
                  onChange={handleAdvancedFilterChange}
                  allowClear
                  loading={advancedFiltersLoading}
                  value={selectedDBFilter}
                >
                  {generateOptions(advancedFiltersFromDB, 'name', 'id')}
                </Select>
              </Col>
              <Col span={16}>
                <Row gutter={[24, 8]}>
                  {advancedFilters.data?.map((el, index) => (
                    <Col gutter={(24, 8)} span={24} key={index}>
                      <Row gutter={[24, 8]}>
                        <Col span={6}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Select type of filtering"
                            value={el.typeOfFiltering}
                            onChange={(value) => handleAdvancedFilteringChange(value, index, 'typeOfFiltering')}
                          >
                            {generateOptionsFromObject(TYPE_OF_FILTERING, snakeCaseToProperCase)}
                          </Select>
                        </Col>
                        <Col span={5}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Select field for filtering"
                            value={el.fieldName}
                            onChange={(value) => {
                              handleAdvancedFilteringChange(value, index, 'fieldName');
                              // NOTE: Remove old values
                              setAdvancedFilters((prevProps) => {
                                const newData = [...prevProps.data];
                                newData[index] = { ...newData[index], values: [] };
                                return { ...prevProps, data: newData };
                              });
                            }}
                          >
                            {generateOptionsFromObject(FIELD_TYPE, snakeCaseToProperCase)}
                          </Select>
                        </Col>
                        <Col span={5}>{generateValuesBasedOnFieldName(el.values, index)}</Col>
                        <Col span={2}>
                          <CloseCircleOutlined onClick={() => handleRemoveRow(index)} />
                        </Col>
                      </Row>
                    </Col>
                  ))}

                  <Col span={24}>
                    {advancedFilters.data.length < 3 && <Button onClick={handleAddingNewAdvancedFilterRow}>Add advanced filter row</Button>}
                  </Col>
                </Row>
              </Col>
              <Col span={8} style={{ padding: 0 }}>
                <Row gutter={[8, 8]} className={styles.filterNameAndSaveFilterWrapperRow}>
                  <Col>
                    <Input placeholder="Filter name" value={advancedFilters.name} onChange={handleFilterNameChange} />
                  </Col>
                  <Col style={{ paddingRight: 0 }}>
                    <Button onClick={handleSaveFilter} loading={loading} type="primary">
                      Save filter
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Button onClick={transformAdvancedFilteringToQueryParam} type="primary">
                  Search using advanced filtering
                </Button>
              </Col>
            </Row>
          </CollapsePanel>
        </Collapse>
      </Col>
    </Row>
  );
};
