import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

class UserNotificationsService {
  async getAll({ fields = 'id,title' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/products/${query}` });
  }

  async getPaged({ currentPage, pageSize, filters, cancelToken, sorting }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      date_from: filters.dateRange && filters.dateRange.from && filters.dateRange.from.format('YYYY-MM-DD') + 'T00:00:00.000000Z',
      date_to: filters.dateRange && filters.dateRange.from && filters.dateRange.to.format('YYYY-MM-DD') + 'T23:59:59.999999Z',
      date_edited_from:
        filters.dateEditedRange && filters.dateEditedRange.from && filters.dateEditedRange.from.format('YYYY-MM-DD') + 'T00:00:00.000000Z',
      date_edited_to:
        filters.dateEditedRange && filters.dateEditedRange.from && filters.dateEditedRange.to.format('YYYY-MM-DD') + 'T23:59:59.999999Z',
      action__in: filters.action__in && filters.action__in.join(','),
      type: filters.type && filters.type.join(','),
      company__in: filters.company__in && filters.company__in.join(','),
      module__in: filters.module__in && filters.module__in.join(','),
      only_pending: filters.onlyPending,
      ordering: ordering && ordering.join(',')
    });

    return await callApi({
      url: `/api/pub_notifications/${query}`,
      cancelToken
    });
  }

  async addNotification(data) {
    return await callApi({ url: '/api/pub_notifications/', method: 'POST', data });
  }

  async getPermissions() {
    return await callApi({ url: '/api/pub_permissions/' });
  }

  async deleteUserNotification(id) {
    return await callApi({ url: `/api/pub_notifications/${id}/`, method: 'DELETE' });
  }

  async editNotification(data, id) {
    return await callApi({ url: `/api/pub_notifications/${id}/`, method: 'PATCH', data });
  }

  async getById({ id, fields }) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/pub_notifications/${id}${query}` });
  }

  async getNotificationHistory(id) {
    return await callApi({
      url: `/api/pub_notifications/${id}/show_notification_history/`,
      method: 'GET'
    });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.title,
        value: option.id
      };
    });
  };

  generateCompanyOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };

  generateModuleOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const userNotificationsService = new UserNotificationsService();
