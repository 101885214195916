import { Popover } from 'antd';
import React from 'react';

import { VirtualizedSelect } from './VirtualizedSelect';
import styles from './PreviewLpSelect.module.css';

const imgDimensions = { width: 250, height: 170 };

export const LpPreviewPopover = ({ screenshot, imgDimensions, eyeImgWidth }) => {
  return (
    <Popover
      content={
        <object data={screenshot} type="image/jpeg" {...imgDimensions}>
          <img src="/images/no_image_found.png" alt="LP Preview" {...imgDimensions} />
        </object>
      }
      placement="left"
      mouseEnterDelay={0.3}
    >
      <img src="/images/eye_preview.webp" alt="Preview Icon" className={styles.eyePreviewImg} width={eyeImgWidth} />
    </Popover>
  );
};

export const LpPreview = ({ option }) => {
  const { value, label, dateEdited } = option;
  const screenshot = `/api/lps/${value}/get-ss-file/?date_edited=${dateEdited}`;

  return (
    <>
      <LpPreviewPopover screenshot={screenshot} imgDimensions={imgDimensions} eyeImgWidth={17} />
      {label}
    </>
  );
};

const LandingPageOption = ({ style, option, selectValue, renderComponent }) => {
  return (
    <div className={styles.landingPageOption} key={option.value} style={style} onClick={() => selectValue(option)}>
      {renderComponent(option)}
    </div>
  );
};

// TODO: Use SelectWithSideLoader ??
export const PreviewLpSelect = ({
  options,
  loading,
  selectValue,
  disabled = false,
  multi = false,
  onChange = () => {},
  renderOptionComponent,
  ...otherProps
}) => {
  return (
    <VirtualizedSelect
      placeholder={`Select Landing Page${multi ? '/s' : ''}`}
      options={options}
      loading={loading}
      selectValue={selectValue}
      disabled={disabled}
      multi={multi}
      onChange={onChange}
      optionComponent={(commonProps) => <LandingPageOption {...commonProps} renderComponent={renderOptionComponent} />}
      valueRenderer={renderOptionComponent}
      filterOptions={(options, filter, currentValues) => {
        const filteredOptionsStartsWith = [];
        const filteredOptionsIncludes = [];

        options.forEach((option) => {
          if (currentValues && currentValues.indexOf(option.value) > -1) {
            return;
          }

          if (option.value.toString().includes(filter)) {
            filteredOptionsStartsWith.push(option);
          } else if (option.label.toLowerCase().includes(filter)) {
            filteredOptionsIncludes.push(option);
          }
        });

        return filteredOptionsStartsWith.concat(filteredOptionsIncludes);
      }}
      {...otherProps}
    />
  );
};
