import React from 'react';
import { Table as AntTable, Pagination } from 'antd';
import dayjs from 'dayjs';

import { ActionsTableCol } from './ActionsTableCol';

import styles from '../ExternalURLs.module.css';
import { TableTitle } from '../../../components/TableTitle';
import { getUserFullName } from '../../../utils/functions';

const TitleValue = {
  Name: 'name',
  ExternalUrl: 'external_url',
  DateCreated: 'date_created',
  DateEdited: 'date_edited'
};

export const Table = ({
  externalURLsData,
  totalRows,
  currentPage,
  pageSize,
  loading,
  sorting,
  onPaginationChange,
  onEdit,
  onDelete,
  onSortingClick
}) => {
  const columns = [
    {
      title: (
        <TableTitle
          text="Name"
          value={TitleValue.Name}
          order={sorting.find((s) => s.key === TitleValue.Name)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'name',
      width: 200
    },

    {
      title: (
        <TableTitle
          text="External URL"
          value={TitleValue.ExternalUrl}
          order={sorting.find((s) => s.key === TitleValue.ExternalUrl)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'external_url',
      width: 250
    },

    {
      title: 'Creator',
      dataIndex: 'creator_details',
      width: 200,
      render: (creator_details) => {
        const creator = getUserFullName(creator_details);
        return <div>{creator}</div>;
      }
    },

    {
      title: (
        <TableTitle
          text="Date and Time Created"
          value={TitleValue.DateCreated}
          order={sorting.find((s) => s.key === TitleValue.DateCreated)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_created',
      align: 'center',
      width: 150,
      render: (date_created) => dayjs(date_created).format('DD.MM.YYYY. - HH:mm:ss')
    },

    {
      title: (
        <TableTitle
          text="Date and Time Edited"
          value={TitleValue.DateEdited}
          order={sorting.find((s) => s.key === TitleValue.DateEdited)?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_edited',
      align: 'center',
      width: 150,
      render: (date_edited) => dayjs(date_edited).format('DD.MM.YYYY. - HH:mm:ss')
    },

    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 150,
      render: (record) => {
        return <ActionsTableCol externalURLData={record} onEdit={onEdit} onDelete={onDelete} />;
      }
    }
  ];

  return (
    <div className={styles.externalURLTable}>
      <AntTable
        columns={columns}
        dataSource={externalURLsData}
        loading={loading}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={['10', '20', '30']}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          );
        }}
      />
    </div>
  );
};
