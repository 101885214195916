import React from 'react';
import { Select as AntSelect, Spin } from 'antd';

function Select({ children, loading = false, ...rest }) {
  return (
    <Spin spinning={loading}>
      <AntSelect {...rest}>{children}</AntSelect>
    </Spin>
  );
}

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

export { Select };
