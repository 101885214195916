import React, { useEffect, useMemo, useState } from 'react';
import { Table as AntTable, Pagination, Tooltip } from 'antd';
import dayjs from 'dayjs';
import styles from '../UserNotifications.module.css';

import { ActionsTableCol } from './ActionsTableCol';
import { TableTitle } from '../../../components/TableTitle';
import {
  id,
  title,
  description,
  icon,
  type,
  company,
  publisherModule,
  creator,
  dateCreated,
  editor,
  dateEdited,
  startDate,
  endDate,
  status
} from '../../../utils/notifications';

const TitleValue = {
  Id: 'id',
  Title: 'title',
  Description: 'description',
  DateCreated: 'date_created',
  DateEdited: 'date_edited',
  EndDate: 'end_date',
  StartDate: 'start_date'
};

export const Table = ({
  loading,
  platformUsers,
  allCompanies,
  userNotifications,
  totalItems,
  currentPage,
  pageSize,
  sorting,
  checkedColumns,
  onPaginationChange,
  setNotificationId,
  setCloneModalVisible,
  setDetailsModalVisible,
  onDelete,
  onSortingClick
}) => {
  const [tableColumns, setTableColumns] = useState([]);

  const tableDataColumns = useMemo(
    () => [
      {
        title: (
          <TableTitle
            text={id.title}
            value={id.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.Id)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: id.key,
        dataIndex: 'id',
        onCell: () => {
          return {
            onClick: (e) => e.stopPropagation()
          };
        }
      },
      {
        title: (
          <TableTitle
            text={title.title}
            value={title.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.Title)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: title.key,
        dataIndex: 'title',
        width: 150
      },
      {
        title: (
          <TableTitle
            text={description.title}
            value={description.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.Description)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: description.key,
        dataIndex: 'description',
        width: 350,
        render: (description) => {
          return description.length > 100 ? (
            <Tooltip overlayInnerStyle={{ width: 300 }} title={description}>
              {description.slice(0, 99)}...
            </Tooltip>
          ) : (
            <>{description}</>
          );
        }
      },
      {
        title: icon.title,
        key: icon.key,
        dataIndex: 'icon',
        tooltip: 'Notification icon that users can see.',
        align: 'center',
        render: (icon) => {
          return icon ? <img src={icon} alt="" /> : '-';
        }
      },
      {
        title: type.title,
        key: type.key,
        dataIndex: 'type',
        align: 'center'
      },
      {
        title: publisherModule.title,
        key: publisherModule.key,
        dataIndex: 'module',
        width: 230,
        align: 'center',
        render: (module) => {
          let responseModules = '';
          if (module.length > 0) {
            if (module.length === 1) {
              return moduleName(module[0]);
            } else {
              module.forEach((element, index) => {
                if (index === 0) {
                  responseModules = responseModules + moduleName(element);
                } else {
                  responseModules = responseModules + ', ' + moduleName(element);
                }
              });
              return responseModules;
            }
          } else {
            return '-';
          }
        }
      },
      {
        title: company.title,
        key: company.key,
        dataIndex: 'company',
        width: 270,
        align: 'center',
        render: (company, record) => {
          if (record.all_companies) {
            return 'All Companies';
          } else {
            if (allCompanies) {
              let result = [];
              company.map((id) => allCompanies.filter((el) => id === el.id && result.push(el.name)));
              return result.length > 5 ? (
                <Tooltip overlayInnerStyle={{ width: 300 }} title={result.join(', ')}>
                  {result.slice(0, 5).join(', ')}...
                </Tooltip>
              ) : (
                <>{result.join(', ')}</>
              );
            }
          }
        }
      },
      {
        title: creator.title,
        key: creator.key,
        dataIndex: 'creator',
        align: 'center',
        render: (creator) => {
          if (platformUsers) {
            let kreator = platformUsers.filter((element) => element.id === creator);
            if (kreator[0]) {
              return kreator[0].first_name + ' ' + kreator[0].last_name;
            }
          }
        }
      },
      {
        title: (
          <TableTitle
            text={dateCreated.title}
            value={dateCreated.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.DateCreated)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: dateCreated.key,
        dataIndex: 'date_created',
        align: 'center',
        render: (date_created) => (date_created ? dayjs(date_created).format('DD.MM.YYYY. - HH:mm:ss') : '')
      },
      {
        title: editor.title,
        key: editor.key,
        dataIndex: 'editor',
        align: 'center',
        render: (editor) => {
          if (platformUsers) {
            let editer = platformUsers.filter((element) => element.id === editor);
            if (editer[0]) {
              return editer[0].first_name + ' ' + editer[0].last_name;
            }
          }
        }
      },
      {
        title: (
          <TableTitle
            text={dateEdited.title}
            value={dateEdited.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.DateEdited)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: dateEdited.key,
        dataIndex: 'date_edited',
        align: 'center',
        render: (date_edited) => (date_edited ? dayjs(date_edited).format('DD.MM.YYYY. - HH:mm:ss') : '')
      },
      {
        title: (
          <TableTitle
            text={startDate.title}
            value={startDate.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.StartDate)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: startDate.key,
        dataIndex: 'start_date',
        align: 'center',
        render: (start_date) => (start_date ? dayjs(start_date).format('DD.MM.YYYY. - HH:mm:ss') : '')
      },
      {
        title: (
          <TableTitle
            text={endDate.title}
            value={endDate.sortingValue}
            order={sorting.find((s) => s.key === TitleValue.EndDate)?.value}
            onSortingClick={onSortingClick}
          />
        ),
        key: endDate.key,
        dataIndex: 'end_date',
        align: 'center',
        render: (end_date) => (end_date ? dayjs(end_date).format('DD.MM.YYYY. - HH:mm:ss') : '')
      },
      {
        title: status.title,
        key: status.key,
        dataIndex: 'action',
        align: 'center'
      }
    ],
    [allCompanies, platformUsers, sorting, onSortingClick]
  );

  const actionsColumn = useMemo(
    () => ({
      title: 'Actions',
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <ActionsTableCol
          userNotification={record}
          setNotificationId={setNotificationId}
          setCloneModalVisible={setCloneModalVisible}
          setDetailsModalVisible={setDetailsModalVisible}
          onDelete={onDelete}
        />
      )
    }),
    [setNotificationId, setCloneModalVisible, setDetailsModalVisible, onDelete]
  );

  useEffect(() => {
    const checkedColumnKeys = Object.keys(checkedColumns).filter((key) => checkedColumns[key]);

    const updatedColumns = tableDataColumns.filter((column) => checkedColumnKeys.includes(String(column.key)));

    updatedColumns.push(actionsColumn);

    setTableColumns(updatedColumns);
  }, [checkedColumns, tableDataColumns, actionsColumn]);

  const moduleName = (moduleId) => {
    switch (moduleId) {
      case 1:
        return 'Offerwall';
      case 2:
        return 'Publisher';
      case 3:
        return 'Search';
      case 4:
        return 'N2S';
      case 5:
        return 'Affiliate programs';
      default:
        break;
    }
  };

  return (
    <AntTable
      className={styles.notificationsTable}
      loading={loading}
      dataSource={userNotifications}
      rowKey={({ id }) => id}
      columns={tableColumns}
      pagination={false}
      scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      onRow={({ id }) => {
        return {
          onClick: () => {
            setNotificationId(id);
            setDetailsModalVisible(true);
          }
        };
      }}
      footer={() => {
        // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
        return (
          <Pagination
            className="ant-table-pagination ant-table-pagination-right"
            total={totalItems}
            current={currentPage}
            onChange={onPaginationChange}
            showSizeChanger={false}
            pageSize={pageSize}
          />
        );
      }}
    />
  );
};
