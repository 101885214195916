import { Switch } from 'antd';
import React from 'react';

import styles from './FilterSwitch.module.css';

export const FilterSwitch = ({ label, defaultChecked, checked, onChange }) => {
  return (
    <div className={styles.switchWrapper}>
      <span className={styles.switchLabel}>{label}</span>
      <Switch defaultChecked={!!defaultChecked} checked={checked} onChange={onChange} />
    </div>
  );
};
