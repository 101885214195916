import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Button, Form } from 'antd';

import { listsService } from '../../../../services/lists';
import { openSuccessNotification } from '../../../../utils/notifications';
import { TargetingForm } from '../../../../components/TargetingForm';

import { transformTargetingData, targetingInitialValues } from '../../../../utils/targeting';

import styles from '../../Lists.module.css';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { confirmClose } from '../../../../utils/confirmClose';
import { useBeforeUnload, useEffectTrigger, useForm } from '../../../../utils/hooks';
import moment from 'moment';
import { repeatPeriodTypes } from '../../../media-campaigns/utils/enums';

export const AddOrEditTargetingList = ({ visibleModal, listType, list, isClone, onCloseModal, onClone, onEdit, onAdd }) => {
  const [loading, setLoading] = useState(false);
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();

  const [form] = useForm(activateFormChangedTrigger);

  const initialValues = useMemo(() => {
    if (!list) {
      return {
        privateList: false,
        patternFormList: [
          {
            repeatEveryNumber: 0,
            repeatEveryPeriod: repeatPeriodTypes.DAY,
            schedulerEnd: moment('2030-01-01'),
            patternSetupFormList: [{ s2s_type: 'percent', s2s: 100, percentage: 100 }]
          }
        ]
      };
    }

    return targetingInitialValues(list, isClone);
  }, [list, isClone]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, visibleModal]);

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = transformTargetingData(values);

    if (list && !isClone) {
      try {
        await listsService.editList(list.id, formData, listType);
        onCloseModal();
        openSuccessNotification({ message: 'List successfully edited!' });
        await onEdit();
        setWasFormChanged(false);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await listsService.addList(formData, listType);
        onCloseModal();
        openSuccessNotification({ message: `List successfully ${isClone ? 'cloned' : 'created'}!` });
        isClone ? await onClone() : await onAdd();
        setWasFormChanged(false);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const onCancel = () => {
    if (wasFormChanged) {
      confirmClose(() => {
        onCloseModal();
      });
    } else {
      onCloseModal();
    }
  };

  const type = list && isClone ? 'Clone' : list ? 'Edit' : 'Add';

  useBeforeUnload(wasFormChanged);

  return (
    <Modal
      title={type + ' Targeting List'}
      visible={visibleModal}
      wrapClassName={styles.AddOrEditTargetingListModal}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {(list && isClone ? 'Clone' : list ? 'Edit' : 'Save') + ' List'}
        </Button>
      ]}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => {
          setWasFormChanged(true);
          activateFormChangedTrigger();
        }}
      >
        <TargetingForm
          form={form}
          patternDetails={list?.targeting_patterns_details}
          disabled={false}
          formChangedTrigger={formChangedTrigger}
          isListForm={true}
        />
      </Form>
    </Modal>
  );
};
