import { getQueryString } from '../utils/query';
import { callApi } from './api';

class OSService {
  async getAll({ fields = 'name,id,img' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/os/${query}` });
  }

  async getFilters() {
    return await callApi({ url: '/api/os/get_filters/' });
  }

  generateOptionsForFilters = (arr) => {
    return arr.map((option) => {
      return {
        label: option,
        value: option
      };
    });
  };

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const osService = new OSService();
