import { Switch } from 'antd';
import React from 'react';

import styles from './OnOffSwitch.module.css';

export const OnOffSwitch = ({ defaultChecked, checked, disabled, onChange = () => {} }) => {
  return (
    <Switch
      className={styles.onOffSwitch}
      checkedChildren="ON"
      unCheckedChildren="OFF"
      defaultChecked={!!defaultChecked}
      disabled={!!disabled}
      onChange={onChange}
      checked={checked}
    />
  );
};
